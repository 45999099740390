<template>
  <div class="emission-categories">
    <h1 class="section-title">Included/ Excluded Categories</h1>
    <div v-for="(group, index) in $store.state.app.report.emissionCategoryGroups.nodes" :key="index">
      <div class="section-title">{{ group.title }}</div>
      <div
        class="categories"
        v-for="(category, index) in group.emissionCategories.nodes"
        :key="index"
      >
        <div class="grid">
          <div class="bold heading" v-if="index < 1">
            ISO & GHG Protocol Categories
          </div>
          <div class="bold heading" v-if="index < 1">
            Example of Emissions Sources
          </div>
          <div class="bold heading" v-if="index < 1">Ekos' Position</div>
          <div class="bold heading" v-if="index < 1">Include/ Exclude</div>
          <div class="bold heading" v-if="index < 1">Exclusion Criteria</div>
          <div class="bold heading" v-if="index < 1">Notes</div>
          <div class="">{{ category.title }}</div>
          <div class="">{{ category.description }}</div>
          <div :class="category.ekosPosition === 'Mandatory' ? 'bold' : ''">
            {{ category.ekosPosition }}
          </div>
          <select
            type="text"
            v-model="category.status"
            v-on:change="updateExclusionCriteria(category), updateEmissionCategory('status', category)"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          >
            <option value="TBC" selected>Please select</option>
            <option value="Include">Include</option>
            <option value="Exclude">Exclude</option>
            <option value="Not Applicable">Not Applicable</option>
          </select>
          <select
            type="text"
            v-model="category.exclusionCriteria"
            v-on:change="updateEmissionCategory('exclusionCriteria', category)"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          >
            <option value="TBC" selected>Please select</option>
            <template v-for="(option, index) in exclusionCriteriaOptions(category.status, category.ekosPosition)">
              <option :value="option" :key="index">{{option}}</option>
            </template>
          </select>
          <textarea
            rows="2"
            v-model="category.notes"
            v-on:change="updateEmissionCategory('notes', category)"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async updateEmissionCategory (field, category) {
      const data = { emissionCategory: { id: category.id } }
      data.emissionCategory[field] = category[field]
      // console.log(data.emissionCategory, 'updating emissionCategory data')
      this.$root.saving = true
      await this.$store.dispatch('updateEmissionCategory', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    exclusionCriteriaOptions (status, position) {
      if (status === 'Exclude' && position === 'Non-mandatory') {
        return ['Insignificant/ de minimis', 'Source data difficult/ expensive to obtain', 'Limited level of influence', 'Other - please explain']
      } else if (status === 'Exclude' && position === 'Mandatory') {
        return ['Insignificant/ de minimis', 'Other - please explain']
      } else if (status === 'Not Applicable' || status === 'Include') {
        return ['None']
      }
    },
    updateExclusionCriteria (category) {
      const options = this.exclusionCriteriaOptions(category.status, category.ekosPosition)
      category.exclusionCriteria = options[0]
      this.updateEmissionCategory('exclusionCriteria', category)
    }
  }
}
</script>

<style></style>
