<template>
<div class="dashboard-tabs">
  <Summary v-if="tab === 'summary'" :emissionsByScope="emissionsByScope" />
      <EmissionsInventoryReport
        v-if="tab === 'generate'"
        :carbonPrice="carbonPrice"
        :emissionsByScope="emissionsByScope"
        :emissionSources="emissionSources"
        :period="reportingPeriod"
      />
      <EIRInformation
        :emissionsByScope="emissionsByScope"
        :emissionSources="emissionSources"
        :period="reportingPeriod"
        :marketRateExists="marketRateExists"
        v-if="tab === 'report-information'"
        @navigate="$parent.selectTab('generate')"
      />
      <CompanyInformation v-if="tab === 'company-information'" />
</div>
</template>

<script>
import Summary from './summary/Summary'
import CompanyInformation from '@/components/emissionsInventoryReport/CompanyInformation.vue'
import EmissionsInventoryReport from '../emissionsInventoryReport/EmissionsInventoryReport.vue'
import EIRInformation from '../emissionsInventoryReport/EIRInformation.vue'
import emissionsMixin from '../../mixins/emissionsMixin.vue'
export default {
  mixins: [emissionsMixin],
  props: { tab: String, carbonPrice: Number, reportingPeriod: String },
  components: {
    Summary,
    EmissionsInventoryReport,
    CompanyInformation,
    EIRInformation
  },
  mounted () {
    this.setDefaultData()
  },
  methods: {
    setDefaultData () {
      if (!this.$store.state.app.report.emissionReductionNotes) {
        this.$store.state.app.report.emissionReductionNotes = 'Please refer to a separate, detailed reduction plan prepared by the organisation which documents the targets, responsibilities, actions and top level management commitment.'
        this.updateReport('emissionReductionNotes')
      }
    },
    async updateReport (field) {
      const data = {
        report: { id: this.$store.state.app.report.id }
      }
      data.report[field] = this.$store.state.app.report[field]
      this.$root.saving = true
      await this.$store.dispatch('updateReport', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    }
  },
  computed: {
    emissionsByScope () {
      const emissionsSources = this.$store.state.app.client.period.nodes[0].emissionsSources.nodes
      const filteredEmissionsSources = emissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
      return this.getEmissionsByScopeNew(filteredEmissionsSources)
    },
    emissionSources () {
      const emissionsSources = this.$store.state.app.client.period.nodes[0].emissionsSources.nodes
      // filter by emissionSource.type !== 'WTT' && emissionSource.type !== 'naturalGasTDL' && emissionSource.type !== 'electricityTDL' && emissionSource.rule !== 'hide-in-report' && emissionSource.rule !== 'hide'
      const filteredEmissionsSources = emissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
      return this.getAllEmissionSources(filteredEmissionsSources)
    },
    marketRateExists () {
      return this.checkEmissionsSourcesForMarketRate(this.$root.allEmissionSources)
    }
  }
}
</script>

<style>

</style>
