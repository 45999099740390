<template>
  <div class="other-emissions">
    <h1 class="section-title">Other Emissions</h1>
    <div class="other-emissions-container">
      <div class="inputs">
        <div
          v-for="emission in $store.state.app.report.otherEmissions.nodes"
          :key="emission.id"
          class="column"
        >
          <div class="row">
            <label>{{ emission.title }}</label>
            <select
              v-model="emission.status"
              v-on:change="updateOtherEmissions('status', emission)"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              class="dropdown"
              :name="emission.title"
              :id="emission.title"
            >
              <option value="TBC">TBC</option>
              <option value="Has Occured">Has Occured</option>
              <option value="Has Not Occured">Has Not Occured</option>
            </select>
          </div>
          <div v-if="emission.status === 'Has Occured'" class="report-input-section">
            <div class="report-input-container" style="max-width: 600px">
              <div class="report-input-item">
                <label>Explanation:</label>
                <textarea
                  rows="8"
                  cols="75"
                  v-model="emission.notes"
                  v-on:change="updateOtherEmissions('notes', emission)"
                  v-on:focus="$root.setEditing()"
                  v-on:blur="$root.unsetEditing()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async updateOtherEmissions (field, emission) {
      const data = { otherEmissions: { id: emission.id } }
      data.otherEmissions[field] = emission[field]
      // console.log(data.otherEmissions, 'updating otherEmissions data')
      this.$root.saving = true
      await this.$store.dispatch('updateOtherEmissions', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    }
  }
}
</script>

<style></style>
