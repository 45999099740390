// Fuels and electricity - Stationary fuels - Petrol consumption
// Updates to premium petrol to be made inline. Below, new schema for regular petrol
const FSPRegularPetrolSchema = {
  dataSource: '',
  dataQuality: '',
  assumptions: '',
  name: 'Regular Petrol Consumption (excluding transport)', // Title of Section on front end
  title: 'Regular Petrol Consumption (excluding transport)',
  scope: 1, // scope number
  sectionID: 'fuels', // Default Schema Scope Section Name
  identifier: 'petrol-regular', // Main Section component identifier for tooltips
  version: 2.78, // Do I have to increase this?
  annually: true,
  emissionSources: [
    {
      category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'Stationary Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
      name: 'Stationary Combustion - Petrol (regular)', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'default', // default, naturalGasTDL, electricityTDL, WTT
      id: 'petrol-regular',
      unit: 'L',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    },
    {
      category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
      name: 'Stationary Combustion - Petrol (regular) WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
      id: 'WTT',
      unit: 'L',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    }
  ],
  extraCalculations: [
    {
      type: 'WTT',
      variableName: 'petrolRegularWTT' // Main getWellToTankEmissions() variable Name
    }
  ],
  months: [
    {
      month: 'January',
      units: [0]
    },
    {
      month: 'February',
      units: [0]
    },
    {
      month: 'March',
      units: [0]
    },
    {
      month: 'April',
      units: [0]
    },
    {
      month: 'May',
      units: [0]
    },
    {
      month: 'June',
      units: [0]
    },
    {
      month: 'July',
      units: [0]
    },
    {
      month: 'August',
      units: [0]
    },
    {
      month: 'September',
      units: [0]
    },
    {
      month: 'October',
      units: [0]
    },
    {
      month: 'November',
      units: [0]
    },
    {
      month: 'December',
      units: [0]
    }
  ],
  variables: [
    { name: 'Carbon Emissions', variableName: 'kgCO2ePetrol' },
    { name: 'Carbon Dioxide Emissions', variableName: 'kgCO2PetrolRegular' },
    { name: 'Nitros Oxide Emissions', variableName: 'kgN2OPetrolRegular' },
    { name: 'Methane Emissions', variableName: 'kgCH4PetrolRegular' }
  ]
}

// Travel and transport - Mobile combustion - Fuels
// Emission source, extra calc and variables for regular petrol
const TMFEmissionSource = {
  category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
  subcategory: 'Mobile Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
  GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
  GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
  ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
  name: 'Mobile Combustion - Petrol (regular)', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
  type: 'default', // default, naturalGasTDL, electricityTDL, WTT
  id: 'petrol-regular',
  unit: 'L',
  annualTotal: 0,
  tCO2e: 0,
  CO2: 0,
  CH4: 0,
  N2O: 0,
  emissionSourceInfo: {
    dataSource: '',
    dataQuality: 'Please select',
    assumptions: ''
  }
}

const TMFExtraCalculation = {
  type: 'Petrol WTT',
  variableName: 'petrolRegularWTT' // Main getWellToTankEmissions() variable Name
}

const TMFRegularPetrolVariables = {
  emissionsOfCO2e: {
    variableName: 'kgCO2ePetrolRegular',
    total: 0
  },
  carbonDioxideEmissions: {
    variableName: 'kgCO2PetrolRegular',
    total: 0
  },
  nitrosOxideEmissions: {
    variableName: 'kgN2OPetrolRegular',
    total: 0
  },
  methaneEmissions: {
    variableName: 'kgCH4PetrolRegular',
    total: 0
  },
  total: 0
}

export default { FSPRegularPetrolSchema, TMFEmissionSource, TMFExtraCalculation, TMFRegularPetrolVariables }
