<template>
<div>
  <div class="reports"></div>
</div>
</template>

<script>
import Chartist from 'chartist'
// import legend from 'chartist-plugin-legend'

export default {
  data () {
    return {}
  },
  name: 'ranked-emissions-by-activity-graph',
  props: { emissionSources: Array, marketRateExists: Boolean },
  mounted () {
    this.renderGraph()
  },
  methods: {
    renderGraph () {
      var options = {
        seriesBarDistance: 0,
        axisX: {
          offset: 50,
          scaleMaxSpace: 10
        },
        axisY: {
          offset: 20,
          labelInterpolationFnc: function (value) {
            return value
          },
          scaleMinSpace: 15
        },
        // plugins: [
        //   legend({
        //     legendNames: ['GHG tCO2e'],
        //     clickable: false
        //   })
        // ],
        width: '21cm',
        height: '400px'
      }

      var data = {
        labels: this.activityNames,
        series: this.activitySeries
      }

      return Chartist.Bar('.emissions-by-sources-ranked-graph', data, options)
    }
  },
  computed: {
    activitySeries () {
      var series = []
      this.emissionSources.forEach(es => {
        // market or location based
        series.push(this.marketRateExists ? es['tCO2e (Market Rate)'] : es['tCO2e (Output)'])
      })
      return [series]
    },
    activityNames () {
      var names = []
      this.emissionSources.forEach((es, index) => {
        if (index < 2) {
          if (es['Emissions source'].length > 25) {
            names.push(es['Emissions source'].substring(0, 25) + '...')
          } else {
            names.push(es['Emissions source'])
          }
        } else if (index > 1 & index < 5) {
          if (es['Emissions source'].length > 40) {
            names.push(es['Emissions source'].substring(0, 40) + '...')
          } else {
            names.push(es['Emissions source'])
          }
        } else {
          if (es['Emissions source'].length > 55) {
            names.push(es['Emissions source'].substring(0, 55) + '...')
          } else {
            names.push(es['Emissions source'])
          }
        }
        // }
      })
      return names
    }
  }
}
</script>

<style></style>
