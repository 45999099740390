<template>
  <div class="section purchased">
    <div class="activity-title-row">
      <h1 class="activity-title">Investment Emissions</h1>
      <i class="title-icon fad fa-cars"></i>
    </div>
    <ContractedServicesComponent
      title="Scope One & Two Emissions (tCO2e)"
      :scope3="false"
      :percentage="true"
      v-bind:initialSchema="
        schema[this.getSchemaIndex('Scope One & Two Emissions (tCO2e)')]
      "
      schemaName="Scope One & Two Emissions (tCO2e)"
      :activityID="$options.name"
      identifier="investments-component"
    />
    <Notes
      :schemaIndex="0"
      :key="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions"
    />

    <NextSectionButton
      :nextRouteSchema="$root.financial.schemas[1]"
      :nextRouteActivity="$root.financial.sections[1]"
      sectionName="financial"
    />
  </div>
</template>

<script>
import NextSectionButton from '../../../NextSectionButton.vue'
import Notes from '../../../Notes.vue'
import ContractedServicesComponent from '../purchasedGSCapitial/ContractedServicesComponent'

export default {
  metaData () {
    return {
      title: `dCarbon — Investments`,
      description: ''
    }
  },
  name: 'activity-investments',
  components: {
    ContractedServicesComponent,
    Notes,
    NextSectionButton
  },
  props: { initialSchema: Object, scope: String },

  data () {
    return {
      schema: {}
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    calculateTotal (schema, schemaName) {
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    }
  }
}
</script>
