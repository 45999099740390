<template>
  <div class="reduction-recommendations" :style="'max-width: 800px'">
    <h3 class="secondary-title">Reduction Recommendation:</h3>
    <div class="input-section">
      <div class="input-container-row">
        <div class="category">
          <label>Category:</label>
          <select v-model="selectedCategory" @change="handleCategoryChange()">
            <option :value="null">Please select</option>
            <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.title }}</option>
          </select>
          <label>Type:</label>
          <select v-model="selectedType">
            <option :value="null">Please select</option>
            <option v-for="recType in recommendationTypes" :key="recType" :value="recType">{{ recType }}</option>
          </select>
        </div>
      </div>
      <div v-if="selectedCategory">
        <div class="secondary-title" :style="'font-weight:600'">{{ categories.find(category => category.id ===
          selectedCategory).title }}</div>
        <div class="input-container-row" v-for="recommendation in recommendations" :key="recommendation.id">
          <div class="type">{{ recommendation.type }}</div>
          <div class="content">{{ recommendation.content }}</div>
          <div @click="addRecommendation(recommendation)" class="square-button blue bold">Add</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      categories: [],
      selectedCategory: null,
      selectedType: null
    }
  },
  async beforeMount () {
    const res = await this.$store.dispatch('getReductionRecommendations')
    this.categories = res.readReductionRecommendationCategories.nodes
  },
  methods: {
    handleCategoryChange () {
      this.selectedType = null
    },
    async addRecommendation (recommendation) {
      this.$emit('addRecommendation', recommendation)
    }
  },
  computed: {
    recommendations () {
      if (!this.selectedCategory) return []
      const recommendations = this.categories.find(category => category.id === this.selectedCategory).recommendations.nodes
      if (!this.selectedType) return recommendations
      return recommendations.filter(recommendation => recommendation.type === this.selectedType)
    },
    recommendationTypes () {
      if (!this.selectedCategory) return []
      // loop through all category recommendations and add unique types to array
      const types = []
      this.categories.find(category => category.id === this.selectedCategory).recommendations.nodes.forEach(recommendation => {
        if (!types.includes(recommendation.type)) types.push(recommendation.type)
      })
      return types
    }
  }
}
</script>

<style></style>
