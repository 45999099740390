import { formatNumber } from '../utils/utils'

export function renderBusinessUnitBreakdownTable (data) {
  const marketRateExists = businessUnitHasMarketRate(data)
  let tableHeadings =
    '<tr><th>Business Unit</th><th>tCO<sub>2</sub>e (location-based)</th>'
  // add market based heading if market rate exists
  if (marketRateExists) {
    tableHeadings += '<th>tCO<sub>2</sub>e (market-based)</th>'
  }
  tableHeadings += '</tr>'
  let tableData = ''
  const allBusinessUnitData = getAllBusinessUnitData(data)
  const grandtCO2e = allBusinessUnitData.reduce((acc, bu) => {
    acc += bu.tCO2e
    return acc
  }, 0)
  const grandtCO2eMarketRate = allBusinessUnitData.reduce((acc, bu) => {
    acc += bu.tCO2eMarketRate
    return acc
  }, 0)
  const grandTotal = {
    title: 'Grand Total',
    tCO2e: formatNumber(grandtCO2e),
    tCO2eMarketRate: formatNumber(grandtCO2eMarketRate)
  }
  allBusinessUnitData.forEach(bu => {
    tableData += '<tr>'
    tableData += `<td>${bu.title}</td>`
    // add sum of emissions sources
    tableData += `<td>${formatNumber(bu.tCO2e)}</td>`
    // add market based if market rate exists
    if (marketRateExists) {
      tableData += `<td>${formatNumber(bu.tCO2eMarketRate)}</td>`
    }
    tableData += '</tr>'
  })
  // add total row
  let totalRow = `<tr class="heading"><td>Grand Total</td><td>${grandTotal.tCO2e}</td>`
  if (marketRateExists) {
    totalRow += `<td>${grandTotal.tCO2eMarketRate}</td>`
  }
  totalRow += '</tr>'
  // piece table together
  return `<table>${tableHeadings}${tableData}${totalRow}</table>`
}

function getAllBusinessUnitData (data) {
  const allBusinessUnitData = []
  data.forEach(bu => {
    let tCO2e = 0
    let tCO2eMarketRate = 0
    bu.periods.nodes.forEach(p => {
      p.emissionsSources.nodes.forEach(es => {
        if (es.rule !== 'hide-in-report' && es.rule !== 'hide') {
          tCO2e += es.tCO2e
          tCO2eMarketRate += es.tCO2eMarketRate
        }
      })
    })
    let businessUnitData = {
      title: bu.title,
      // sum of all emissions sources of all periods
      tCO2e: tCO2e,
      tCO2eMarketRate: tCO2eMarketRate
    }
    if (tCO2e > 0) allBusinessUnitData.push(businessUnitData)
  })
  return allBusinessUnitData.sort((a, b) => { return b.tCO2e - a.tCO2e })
}

function businessUnitHasMarketRate (data) {
  let hasMarketRate = false
  data.forEach(bu => {
    bu.periods.nodes.forEach(p => {
      p.emissionsSources.nodes.forEach(es => {
        if (es.tCO2eMarketRate !== es.tCO2e) {
          hasMarketRate = true
        }
      })
    })
  })
  return hasMarketRate
}
