const businessFlightsSchema = [
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'International Business Flights', // Title of Section on front end
    title: 'International Business Flights', // Title of Section on front end
    scope: 3, // scope number
    sectionID: 'business-travel', // Default Schema Scope Section Name
    identifier: 'international', // Main Section component identifier for tooltips
    version: 2.76,
    annually: true,
    emissionSources: [
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Short Haul International Average', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Short Haul International Average',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Short Haul International Economy Class', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Short Haul International Economy Class',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Short Haul International Business Class', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Short Haul International Business Class',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Long Haul International Average', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Long Haul International Average',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Long Haul International Economy Class', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Long Haul International Economy Class',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Long Haul International Premium Economy Class', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Long Haul International Premium Economy Class',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Long Haul International Business Class', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Long Haul International Business Class',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Long Haul International First Class', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'Long Haul International First Class', // default, naturalGasTDL, electricityTDL, WTT,
        id: 'Long Haul International First Class',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Short Haul International Average WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Short Haul International Average WTT',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Short Haul International Economy Class WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Short Haul International Economy Class WTT',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Short Haul International Business Class WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Short Haul International Business Class WTT',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Long Haul International Average WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Long Haul International Average WTT',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Long Haul International Economy Class WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Long Haul International Economy Class WTT',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Long Haul International Premium Economy Class WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Long Haul International Premium Economy Class WTT',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Long Haul International Business Class WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Long Haul International Business Class WTT',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'International Air Travel - Long Haul International First Class WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Long Haul International First Class WTT',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    flights: [
      {
        origin: '',
        destination: '',
        people: 0,
        class: '',
        distance: 0,
        eFactor: 0.0,
        emissionsNoRF: 0.0,
        emissionsWithRF: 0.0,
        originLocation: '',
        destinationLocation: ''
      }
    ],
    classes: [
      {
        name: 'Short Haul International Average',
        tCO2e: 'ShortIntAverage',
        CO2: 'ShortIntAverageCO2',
        N2O: 'ShortIntAverageN2O',
        CH4: 'ShortIntAverageCH4'
      },
      {
        name: 'Short Haul International Economy Class',
        tCO2e: 'ShortIntEconomy',
        CO2: 'ShortIntEconomyCO2',
        N2O: 'ShortIntEconomyN2O',
        CH4: 'ShortIntEconomyCH4'
      },
      {
        name: 'Short Haul International Business Class',
        tCO2e: 'ShortIntBusiness',
        CO2: 'ShortIntBusinessCO2',
        N2O: 'ShortIntBusinessN2O',
        CH4: 'ShortIntBusinessCH4'
      },
      {
        name: 'Long Haul International Average',
        tCO2e: 'LongIntAverage',
        CO2: 'LongIntAverageCO2',
        N2O: 'LongIntAverageN2O',
        CH4: 'LongIntAverageCH4'
      },
      {
        name: 'Long Haul International Economy Class',
        tCO2e: 'LongIntEconomy',
        CO2: 'LongIntEconomyCO2',
        N2O: 'LongIntEconomyN2O',
        CH4: 'LongIntEconomyCH4'
      },
      {
        name: 'Long Haul International Premium Economy Class',
        tCO2e: 'LongIntPremiumEconomy',
        CO2: 'LongIntPremiumEconomyCO2',
        N2O: 'LongIntPremiumEconomyN2O',
        CH4: 'LongIntPremiumEconomyCH4'
      },
      {
        name: 'Long Haul International Business Class',
        tCO2e: 'LongIntBusiness',
        CO2: 'LongIntBusinessCO2',
        N2O: 'LongIntBusinessN2O',
        CH4: 'LongIntBusinessCH4'
      },
      {
        name: 'Long Haul International First Class',
        tCO2e: 'LongIntFirst',
        CO2: 'LongIntFirstCO2',
        N2O: 'LongIntFirstN2O',
        CH4: 'LongIntFirstCH4'
      }
    ],
    extraCalculations: [
      {
        emissionSourceID: 'Short Haul International Average',
        type: 'Short Haul International Average WTT',
        variableName: 'ShortIntAverageWTT'
      },
      {
        emissionSourceID: 'Short Haul International Economy Class',
        type: 'Short Haul International Economy Class WTT',
        variableName: 'shortHaulEconomyPassengerKmWTT'
      },
      {
        emissionSourceID: 'Short Haul International Business Class',
        type: 'Short Haul International Business Class WTT',
        variableName: 'shortHaulBusinessPassengerKmWTT'
      },
      {
        emissionSourceID: 'Long Haul International Average',
        type: 'Long Haul International Average WTT',
        variableName: 'LongIntAverageWTT'
      },
      {
        emissionSourceID: 'Long Haul International Economy Class',
        type: 'Long Haul International Economy Class WTT',
        variableName: 'longHaulEconomyPassengerKmWTT'
      },
      {
        emissionSourceID: 'Long Haul International Premium Economy Class',
        type: 'Long Haul International Premium Economy Class WTT',
        variableName: 'longHaulPremiumEconomyPassengerKmWTT'
      },
      {
        emissionSourceID: 'Long Haul International Business Class',
        type: 'Long Haul International Business Class WTT',
        variableName: 'longHaulBusinessPassengerKmWTT'
      },
      {
        emissionSourceID: 'Long Haul International First Class',
        type: 'Long Haul International First Class WTT',
        variableName: 'longHaulFirstClassPassengerKmWTT'
      }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Domestic NZ Business Flights', // Title of Section on front end
    title: 'Domestic NZ Business Flights', // Title of Section on front end
    scope: 3, // scope number
    sectionID: 'business-travel', // Default Schema Scope Section Name
    identifier: 'domestic', // Main Section component identifier for tooltips
    version: 2.75,
    annually: true,
    emissionSources: [
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Domestic Air Travel - New Zealand Domestic Economy Class', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'New Zealand Domestic Economy Class',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Domestic Air Travel - Domestic Passenger WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Domestic Passenger WTT',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        emissionSourceID: 'New Zealand Domestic Economy Class',
        type: 'Domestic Passenger WTT',
        variableName: 'domesticPassengerKmWTT' // Main getWellToTankEmissions() variable Name
      }
    ],
    flights: [
      {
        origin: '',
        destination: '',
        people: 0,
        class: '',
        distance: 0,
        eFactor: 0.0,
        emissionsNoRF: 0.0,
        emissionsWithRF: 0.0,
        originLocation: '',
        destinationLocation: ''
      }
    ],
    classes: [
      {
        name: 'New Zealand Domestic, Economy Class',
        tCO2e: 'NZDomesticEconomy',
        CO2: 'NZDomesticEconomyCO2',
        N2O: 'NZDomesticEconomyN2O',
        CH4: 'NZDomesticEconomyCH4'
      }
    ]
  }
]

export default businessFlightsSchema
