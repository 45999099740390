var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-section"},[_c('h1',{staticClass:"section-title"},[_vm._v("Fertiliser")]),_c('h2',{staticClass:"secondary-title"},[_vm._v("Amount of fertiliser (kg)")]),_vm._l((_vm.schema.agricultureCategories),function(cat,i){return _c('div',{key:("fert-" + i)},[_c('div',{staticClass:"input-container-column"},_vm._l((5),function(index){return _c('div',{key:("fert-input-" + index),staticClass:"input-item long"},[_c('label',[_vm._v(" "+_vm._s(cat.emissionSources[index - 1].type)+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(
            _vm.schema.agricultureCategories[i].emissionSources[index - 1].numberOfUnits
          ),expression:"\n            schema.agricultureCategories[i].emissionSources[index - 1].numberOfUnits\n          ",modifiers:{"number":true}}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"type":"number","name":"numberOfUnits"},domProps:{"value":(
            _vm.schema.agricultureCategories[i].emissionSources[index - 1].numberOfUnits
          )},on:{"change":function () {
            _vm.schema.agricultureCategories[i].emissionSources[index - 1].numberOfUnits = _vm.schema.agricultureCategories[i].emissionSources[index - 1].numberOfUnits || 0;
            _vm.calculateCarbonEmissions()
          },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.schema.agricultureCategories[i].emissionSources[index - 1], "numberOfUnits", _vm._n($event.target.value))}}})])}),0)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }