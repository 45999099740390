const extraCalculations = [
  {
    emissionSourceID: 'Short Haul International Average',
    type: 'Short Haul International Average WTT',
    variableName: 'ShortIntAverageWTT'
  },
  {
    emissionSourceID: 'Short Haul International Economy Class',
    type: 'Short Haul International Economy Class WTT',
    variableName: 'shortHaulEconomyPassengerKmWTT'
  },
  {
    emissionSourceID: 'Short Haul International Business Class',
    type: 'Short Haul International Business Class WTT',
    variableName: 'shortHaulBusinessPassengerKmWTT'
  },
  {
    emissionSourceID: 'Long Haul International Average',
    type: 'Long Haul International Average WTT',
    variableName: 'LongIntAverageWTT'
  },
  {
    emissionSourceID: 'Long Haul International Economy Class',
    type: 'Long Haul International Economy Class WTT',
    variableName: 'longHaulEconomyPassengerKmWTT'
  },
  {
    emissionSourceID: 'Long Haul International Premium Economy Class',
    type: 'Long Haul International Premium Economy Class WTT',
    variableName: 'longHaulPremiumEconomyPassengerKmWTT'
  },
  {
    emissionSourceID: 'Long Haul International Business Class',
    type: 'Long Haul International Business Class WTT',
    variableName: 'longHaulBusinessPassengerKmWTT'
  },
  {
    emissionSourceID: 'Long Haul International First Class',
    type: 'Long Haul International First Class WTT',
    variableName: 'longHaulFirstClassPassengerKmWTT'
  }
]

const extraCalculationsDomestic = [
  {
    emissionSourceID: 'New Zealand Domestic Economy Class',
    type: 'Domestic Passenger WTT',
    variableName: 'domesticPassengerKmWTT' // Main getWellToTankEmissions() variable Name
  }
]

export default { extraCalculations, extraCalculationsDomestic }
