<template>
  <div class="section-navigator shadow">
      <div class="flex-parent">
        <div class="input-flex-container">
          <template v-for="(section, index) in visibleSections">
            <router-link
            :key="index"
              v-if="sections[index]"
              :to="{
                path: `${section.id}`,
                params: { initialSchema: schemas[index] }
              }"
            >
              {{ section.title }}
            </router-link>
          </template>
      </div>
    </div>
  </div>
</template>

<script>
// import routes from '../router/index'

export default {
  name: 'activity-navigator',
  props: {
    sections: { type: Array, required: true },
    schemas: { type: Array, required: true },
    category: { type: String, required: false }
  },
  data () {
    return {
      scopeSchemas: [],
      data: ['purchased', 'fuel-energy', 'outward-freight']
    }
  },
  beforeMount () {
    this.scopeSchemas = this.schemas
    this.$root.$on('component1', (item, response) => {
      var componentSchema = this.scopeSchemas[this.currentSectionIndex()]
      return componentSchema
    })
  },
  methods: {
    currentSectionIndex () {
      return this.sections.findIndex(
        section => section === this.$router.currentRoute.name
      )
    }
  },
  computed: {
    visibleSections () {
      let foundSections = []
      this.sections.forEach(section => {
        let foundSection = this.$root.visibleSections.find(vs => vs.id === section)
        if (foundSection) {
          foundSections.push(foundSection)
        }
      })
      return foundSections
    }
  }
}
</script>
