import { renderCells } from '../../utils/utils'

export function renderActionPlanTable (reductionRecommendations, currentYearIsBaseYear) {
  let cellCount = !currentYearIsBaseYear ? 7 : 6
  const dualReportingBaseYear = marketRateExists(reductionRecommendations, 'baseYearEmissionsSource') ? 'Base Year tCO<sub>2</sub>e (market-based)' : 'Base Year tCO<sub>2</sub>e (location-based)'
  const dualReportingCurrentYear = marketRateExists(reductionRecommendations, 'emissionsSource') ? 'Current Year tCO<sub>2</sub>e (market-based)' : 'Current Year tCO<sub>2</sub>e (location-based)'
  const headingData = ['Emissions source', dualReportingBaseYear, 'Target term', 'Initiative to be implemented', 'Person responsible', 'Comment on progress']
  if (!currentYearIsBaseYear) {
    // splice in the current year emissions source heading after the base year emissions source heading
    headingData.splice(2, 0, dualReportingCurrentYear)
  }
  let mainData = []
  reductionRecommendations.forEach(rec => {
    mainData.push(getName(rec))
    mainData.push(rec.baseYearEmissionsSource.tCO2eMarketRate || 0)
    if (!currentYearIsBaseYear) {
      mainData.push(rec.emissionsSource.tCO2eMarketRate || 0)
    }
    mainData.push(rec.term)
    mainData.push(rec.initiative)
    mainData.push(rec.responsibility)
    mainData.push(rec.previousPerformanceComment)
  })
  let heading = renderCells(headingData, cellCount, true)
  let main = renderCells(mainData, cellCount)
  return `<table>${heading}${main}</table>`
}

function marketRateExists (reductionRecommendations, property) {
  // loop through reductionRecommendations and check if baseYearEmissionsMR !== baseYearEmissions
  // if it does, return true
  // else return false
  let marketRateExists = false
  reductionRecommendations.forEach(rec => {
    if (rec[property].id === '0') return
    if (rec[property].tCO2e !== rec[property].tCO2eMarketRate) {
      marketRateExists = true
    }
  })
  return marketRateExists
}

function getName (rec) {
  // get name from either baseYearEmissionsSource or currentYearEmissionsSource
  return rec.baseYearEmissionsSource.name || rec.emissionsSource.name
}
