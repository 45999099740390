<template>
  <div>
    <div v-if="$store.state.user.clientID" :key="$root.time">
      <h1 class="main-heading">Travel & Transport</h1>
      <SectionNavigator
        :sections="$root.travelAndTransport.sections"
        :schemas="$root.travelAndTransport.schemas"
      />
      <router-view />
    </div>
  </div>
</template>

<script>
import SectionNavigator from '../SectionNavigator'

// import routes from '../../router/index'
export default {
  name: 'category-travel-and-transport',
  props: { sections: String, schemas: Array },
  components: {
    SectionNavigator
  }
}
</script>
