<template>
  <div class="monthly">
    <h3>Monthly Reports</h3>
    <div class="monthly-total-emissions-graph" />
  </div>
</template>

<script>
import Chartist from 'chartist'
import emissionsMixin from '../../mixins/emissionsMixin.vue'
// import legend from 'chartist-plugin-legend'

export default {
  data () {
    return {
      months: [
        { name: 'January', id: 1 },
        { name: 'February', id: 2 },
        { name: 'March', id: 3 },
        { name: 'April', id: 4 },
        { name: 'May', id: 5 },
        { name: 'June', id: 6 },
        { name: 'July', id: 7 },
        { name: 'August', id: 8 },
        { name: 'September', id: 9 },
        { name: 'October', id: 10 },
        { name: 'November', id: 11 },
        { name: 'December', id: 12 }
      ]
    }
  },
  name: 'monthly-total-emissions-graph',
  props: { emissionSources: Array, marketRateExists: Boolean },
  mixins: [emissionsMixin],
  mounted () {
    this.renderGraph()
  },
  methods: {
    renderGraph () {
      var options = {
        seriesBarDistance: 0,
        axisX: {
          offset: 50,
          scaleMaxSpace: 10
        },
        axisY: {
          offset: 20,
          labelInterpolationFnc: function (value) {
            return value
          },
          scaleMinSpace: 15
        },
        // plugins: [
        //   legend({
        //     legendNames: ['GHG tCO2e'],
        //     clickable: false
        //   })
        // ],
        width: '21cm',
        height: '400px'
      }

      var data = {
        labels: this.activityNames,
        series: this.activitySeries
      }

      return Chartist.Bar('.monthly-total-emissions-graph', data, options)
    }
  },
  computed: {
    monthsByBaseYear () {
      let months = [...JSON.parse(JSON.stringify(this.months))]
      const index = months.findIndex(month => month.name === this.$store.state.app.organisation.baseYearReportingMonthStart)
      const firstPart = months.splice(0, index)
      months.push(...firstPart)
      return months
      //   return this.months
    },
    monthlyPeriods () {
      let emissionsSourcesByMonth = []
      const organisations = JSON.parse(JSON.stringify(this.$store.state.app.client.allBusinessUnits.nodes))
      organisations.forEach(bu => {
        bu.periods.nodes.forEach(period => {
          // if month is not annual
          if (period.month !== 0) {
            let month = this.monthsByBaseYear.find(month => month.id.toString() === period.month.toString())
            if (month) {
              // find month index in emissionsSourceByMonth if not there create otherwise push
              let monthIndex = emissionsSourcesByMonth.findIndex(ms => ms.month === month.id)
              if (monthIndex === -1) {
                emissionsSourcesByMonth.push({
                  month: month.id,
                  name: month.name,
                  emissions: period.emissionsSources.nodes
                })
              } else {
                emissionsSourcesByMonth[monthIndex].emissions.push(...period.emissionsSources.nodes)
              }
            }
          }
        })
      })
      const index = emissionsSourcesByMonth.findIndex(month => month.name === this.$store.state.app.client.baseYearReportingMonthStart)
      const firstPart = emissionsSourcesByMonth.splice(0, index)
      emissionsSourcesByMonth.push(...firstPart)
      return emissionsSourcesByMonth.filter(month => month.emissions.length > 0)
    },
    activitySeries () {
      var series = []
      // add up emissions for each month
      this.monthlyPeriods.forEach(month => {
        var total = 0
        let marketRateExists = this.checkEmissionsSourcesForMarketRate(month.emissions)
        month.emissions.forEach(emission => {
          total += marketRateExists > emission.tCO2eMarketRate ? emission.tCO2eMarketRate : emission.tCO2e
        })
        series.push(total)
      })

      return [series]
    },
    activityNames () {
      var names = []
      this.monthlyPeriods.forEach(month => {
        names.push(month.name)
      })
      return names
    }
  }
}
</script>

<style></style>
