<template>
  <div class="admin-container section-navigator load-organisation">
     <div class="admin-logo">
        <img src="../../src/assets/images/dCarbonLogo.png" />
      </div>
    <h2 class="admin-text">Load an Inventory for a Business Unit:</h2>
    <template v-if="!$store.state.user.organisationID">
      <div v-for="organisation in $store.state.app.organisations" :key="organisation.id">
        <div
          class="square-button green admin-btn"
          @click="selectOrganisation(organisation.id)"
        >
          Load Inventory for Business Unit: {{ organisation.title }}
        </div>
      </div>
    </template>
    <div v-else>
      <h3 v-if="$store.state.app.organisation" class="secondary-title">
        Inventory currently loaded for: {{ $store.state.app.organisation.title }}
      </h3>
      <div
        v-if="$store.state.app.organisation && $store.state.app.organisations.length > 1"
        class="square-button green admin-btn"
        @click="unloadOrganisation"
      >
        Unload Inventory
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: ''
    }
  },
  methods: {
    async selectOrganisation (id) {
      await this.$store.dispatch('updateUser', { organisationID: id })
      await this.$store.dispatch('loadAppData')
      await this.$store.dispatch('saveMemberSession', this.$store.state.app.client.period.nodes[0].id)
      this.$root.loadData()
    },
    async unloadOrganisation () {
      await this.$store.dispatch('updateUser', { organisationID: 0 })
      await this.$store.dispatch('loadAppData')
      await this.$store.dispatch('saveMemberSession', this.$root.userDetails.periodID)
      this.$root.masterSchema = this.$root.defaultSchema
      this.$root.fuelEnergyTotal = 0
    }
  }
}
</script>
