<template>
  <div >
    <div v-if="$store.state.user.clientID" :key="$root.time">
      <h1 class="main-heading">Assets</h1>
      <SectionNavigator
        :sections="$root.assets.sections"
        :schemas="$root.assets.schemas"
      />
      <router-view />
    </div>

  </div>
</template>

<script>
import SectionNavigator from '../SectionNavigator'

// import routes from '../../router/index'
export default {
  name: 'category-assets',
  props: { sections: String, schemas: Array },
  components: {
    SectionNavigator
  }
}
</script>
