const agricultureSchema = [
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Livestock', // Title of Section on front end
    title: 'Livestock', // Title of Section on front end
    scope: 1, // scope number
    sectionID: 'agriculture', // Default Schema Scope Section Name
    identifier: 'agriculture-component', // Main Section component identifier for tooltips
    version: 2.75,
    active: true,
    toggleName: 'enterBy',
    emissionSources: [
      {
        category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Land use and Land Use changes', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        name: '', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'livestock',
        unit: 'HEAD',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    agricultureCategories: [
      {
        title: 'Enteric Fermentation',
        emissionSources: [
          {
            type: 'Dairy cattle',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'entericFermentationDairyCattleKgCO2ePerHead',
            variableNameCH4: 'entericFermentationDairyCattleKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Non-dairy cattle',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'entericFermentationNonDairyCattleKgCO2ePerHead',
            variableNameCH4: 'entericFermentationNonDairyCattleKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Sheep',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'entericFermentationSheepKgCO2ePerHead',
            variableNameCH4: 'entericFermentationSheepKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Deer',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'entericFermentationDeerKgCO2ePerHead',
            variableNameCH4: 'entericFermentationDeerKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Swine',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'entericFermentationSwineKgCO2ePerHead',
            variableNameCH4: 'entericFermentationSwineKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Goats',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'entericFermentationGoatsKgCO2ePerHead',
            variableNameCH4: 'entericFermentationGoatsKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Horses',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'entericFermentationHorsesKgCO2ePerHead',
            variableNameCH4: 'entericFermentationHorsesKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Alpaca',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'entericFermentationAlpacaKgCO2ePerHead',
            variableNameCH4: 'entericFermentationAlpacaKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Mules and Asses',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'entericFermentationMulesAndAssesKgCO2ePerHead',
            variableNameCH4: 'entericFermentationMulesAndAssesKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Poultry',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'entericFermentationPoultryKgCO2ePerHead',
            variableNameCH4: 'entericFermentationPoultryKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          }
        ]
      },
      {
        title: 'Agricultural soils (excretion to pasture)',
        emissionSources: [
          {
            type: 'Dairy cattle',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'agricultureDairyCattleKgCO2ePerHead',
            variableNameN2O: 'agricultureDairyCattleKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Non-dairy cattle',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'agricultureNonDairyCattleKgCO2ePerHead',
            variableNameN2O: 'agricultureNonDairyCattleKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Sheep',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'agricultureSheepKgCO2ePerHead',
            variableNameN2O: 'agricultureSheepKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Deer',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'agricultureDeerKgCO2ePerHead',
            variableNameN2O: 'agricultureDeerKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Swine',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'agricultureSwineKgCO2ePerHead',
            variableNameN2O: 'agricultureSwineKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Goats',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'agricultureGoatsKgCO2ePerHead',
            variableNameN2O: 'agricultureGoatsKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Horses',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'agricultureHorsesKgCO2ePerHead',
            variableNameN2O: 'agricultureHorsesKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Alpaca',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'agricultureAlpacaKgCO2ePerHead',
            variableNameN2O: 'agricultureAlpacaKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Mules and Asses',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'agricultureMulesAndAssesKgCO2ePerHead',
            variableNameN2O: 'agricultureMulesAndAssesKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Poultry',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'agriculturePoultryKgCO2ePerHead',
            variableNameN2O: 'agriculturePoultryKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          }
        ]
      },
      {
        title: 'Manure Management (manure stored)',
        emissionSources: [
          {
            type: 'Dairy cattle',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'manureManagementDairyCattleKgCO2ePerHead',
            variableNameCH4: 'manureManagementDairyCattleKgCH4PerHead',
            variableNameN2O: 'manureManagementDairyCattleKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Non-dairy cattle',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'manureManagementNonDairyCattleKgCO2ePerHead',
            variableNameCH4: 'manureManagementNonDairyCattleKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Sheep',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'manureManagementSheepKgCO2ePerHead',
            variableNameCH4: 'manureManagementSheepKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Deer',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'manureManagementDeerKgCO2ePerHead',
            variableNameCH4: 'manureManagementDeerKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Swine',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'manureManagementSwineKgCO2ePerHead',
            variableNameCH4: 'manureManagementSwineKgCH4PerHead',
            variableNameN2O: 'manureManagementSwineKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Goats',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'manureManagementGoatsKgCO2ePerHead',
            variableNameCH4: 'manureManagementGoatsKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Horses',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'manureManagementHorsesKgCO2ePerHead',
            variableNameCH4: 'manureManagementHorsesKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Alpaca',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'manureManagementAlpacaKgCO2ePerHead',
            variableNameCH4: 'manureManagementAlpacaKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Mules and Asses',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'manureManagementMulesAndAssesKgCO2ePerHead',
            variableNameCH4: 'manureManagementMulesAndAssesKgCH4PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Poultry',
            unit: 'Per head',
            numberOfUnits: 0,
            variableNameCO2e: 'manureManagementPoultryKgCO2ePerHead',
            variableNameCH4: 'manureManagementPoultryKgCH4PerHead',
            variableNameN2O: 'manureManagementPoultryKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          }
        ]
      }
    ],
    totalEmissions: {
      totalCarbonDioxideEquivalentEmissions: 0,
      totalCarbonDioxideEmissions: 0,
      totalNitrosOxideEmissions: 0,
      totalMethaneEmissions: 0
    }
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Fertiliser Use', // Title of Section on front end
    title: 'Fertiliser Use', // Title of Section on front end
    scope: 1, // scope number
    sectionID: 'agriculture', // Default Schema Scope Section Name
    identifier: 'agriculture-component', // Main Section component identifier for tooltips
    version: 2.75,
    active: true,
    toggleName: 'enterBy',
    emissionSources: [
      {
        category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Land use and Land Use changes', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        name: '', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'fertiliser',
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    agricultureCategories: [
      {
        title: 'Fertiliser Use',
        emissionSources: [
          {
            type: 'Non-urea nitrogen fertiliser',
            unit: 'kg',
            numberOfUnits: 0,
            variableNameCO2e:
              'fertiliserUseNonUreaNitrogenFertiliserKgCO2ePerHead',
            variableNameN2O:
              'fertiliserUseNonUreaNitrogenFertiliserKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Urea nitrogen fertiliser not coated with urease inhibitor',
            unit: 'kg',
            numberOfUnits: 0,
            variableNameCO2e:
              'fertiliserUseUreaNitrogenFertiliserNotUreaseInhibitorKgCO2ePerHead',
            variableNameCO2:
              'fertiliserUseUreaNitrogenFertiliserNotUreaseInhibitorKgCO2PerHead',
            variableNameN2O:
              'fertiliserUseUreaNitrogenFertiliserNotUreaseInhibitorKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Urea nitrogen fertiliser coated with urease inhibitor',
            unit: 'kg',
            numberOfUnits: 0,
            variableNameCO2e:
              'fertiliserUseUreaNitrogenFertiliserCoatedWithUreaseInhibitorKgCO2ePerHead',
            variableNameCO2:
              'fertiliserUseUreaNitrogenFertiliserCoatedWithUreaseInhibitorKgCO2PerHead',
            variableNameN2O:
              'fertiliserUseUreaNitrogenFertiliserCoatedWithUreaseInhibitorKgN2OPerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Limestone',
            unit: 'kg',
            numberOfUnits: 0,
            variableNameCO2e: 'fertiliserUseLimestoneKgCO2ePerHead',
            variableNameCO2: 'fertiliserUseLimestoneKgCO2PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          },
          {
            type: 'Dolomite',
            unit: 'kg',
            numberOfUnits: 0,
            variableNameCO2e: 'fertiliserUseDolomiteKgCO2ePerHead',
            variableNameCO2: 'fertiliserUseDolomiteKgCO2PerHead',
            carbonEquivalentEmissions: 0,
            carbonDioxideEmissions: 0,
            nitrosOxideEmissions: 0,
            methaneEmissions: 0
          }
        ]
      }
    ],
    totalEmissions: {
      totalCarbonDioxideEquivalentEmissions: 0,
      totalCarbonDioxideEmissions: 0,
      totalNitrosOxideEmissions: 0,
      totalMethaneEmissions: 0
    }
  }
]
export default agricultureSchema
