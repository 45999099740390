<template>
  <div class="input-section">
    <div class="tooltip">
      <h1 class="section-title">
        {{ title }}
      </h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <div
      style="margin-bottom:25px; margin-top:-50px;"
      class="controls-container"
      :style="{ 'flex-direction': 'row-reverse' }"
    >
      <div class="toggle-btn-container">
        <div
          class="toggle-btn"
          :class="!schema.annually ? 'active' : 'inactive'"
          @click=";(schema.annually = false)"
        >
          <p class="btn-text">By Flight</p>
        </div>
        <div
          class="toggle-btn"
          :class="schema.annually ? 'active' : 'inactive'"
          @click=";(schema.annually = true)"
        >
          <p class="btn-text">{{ $store.state.app.selectedMonth === '0' ? 'Annual' : 'Monthly' }} Total: PKM</p>
        </div>
      </div>
    </div>
    <Controls
      v-if="!schema.annually && $root.userDetails.organisationStatus !== 'Completed'"
      v-on:add="addUnit()"
      v-on:remove="removeUnit()"
      v-on:update="calculateCarbonEmissions()"
    />
    <div class="business-flights-by-flight" v-if="!schema.annually">
      <div
        class="input-container-row"
        v-for="(flight, index) in schema.flights"
        :key="`item-${index}`"
      >
        <div class="input-container-column">
          <label>Origin</label>
          <div class="input-box">
            <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
              type="text"
              min="0"
              v-model="flight.origin"
              v-on:change="calculateCarbonEmissions"
              v-on:input="
                ;[
                  getAirports(flight.origin),
                  updateCurrentlyEdited(`${identifier}-origin-${index}`)
                ]
              "
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing(), updateCurrentlyEdited('reset')"
            />
            <div
              class="airports"
              v-show="currentlyEditing === `${identifier}-origin-${index}`"
            >
              <p
                v-for="(airport, index) in airports"
                class="selection"
                :key="index"
                @click="selectOriginAirport(flight, airport)"
              >
                {{ airport.name }}
              </p>
            </div>
          </div>
        </div>
        <div class="input-container-column">
          <label>Destination</label>
          <div class="input-box">
            <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
              type="text"
              min="0"
              v-model="flight.destination"
              v-on:change="calculateCarbonEmissions"
              v-on:input="
                getAirports(flight.destination),
                  updateCurrentlyEdited(`${identifier}-destination-${index}`)
              "
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing(), updateCurrentlyEdited('reset')"
            />
            <div
              class="airports"
              v-show="currentlyEditing === `${identifier}-destination-${index}`"
            >
              <p
                v-for="(airport, index) in airports"
                class="selection"
                :key="index"
                @click="selectDestinationAirport(flight, airport)"
              >
                {{ airport.name }}
              </p>
            </div>
          </div>
        </div>
          <div class="input-container-column">
          <label>Distance</label>
          <p>{{$root.formatNumber(flight.distance)}}KM</p>
        </div>
        <div class="input-container-column">
          <label>People</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            class="small"
            type="number" step="0.01"
            min="0"
            v-model.number="flight.people"
            v-on:change="() => {
              flight.people = flight.people || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          />
        </div>

        <div class="input-container-column">
          <label>Class</label>
          <select :disabled="$root.userDetails.organisationStatus === 'Completed'"
            name="class"
            id="class"
            v-model="flight.class"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          >
            <option value="" disabled selected>Select a Class</option>
            <template v-for="(type, index) in schema.classes">
              <option v-bind:value="type.name" :key="index">{{
                type.name
              }}</option>
            </template>
          </select>
        </div>

        <div class="input-container-column center">
          <label>Return Flight</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            type="checkbox"
            v-model="flight.returnFlight"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          />
        </div>

        <div class="input-container-column">
          <label>Emissions</label>
          <h2>{{ $root.formatNumber(flight.emissionsWithRF) }}</h2>
        </div>
      </div>
    </div>
    <div v-else class="business-flights-totals input-section">
      <template v-for="source in schema.emissionSources">
        <div class="business-flights-row" v-if="source.type !== 'WTT'" :key="source.name">
          <label>{{ source.name }} {{ source.unit }}</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            type="number" step="0.01"
            min="0"
            v-model.number="source.annualTotal"
            v-on:change="() => {
              source.annualTotal = source.annualTotal || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          />
        </div>
      </template>
    </div>
    <h3 class="secondary-title">
      Carbon Emissions: {{ $root.formatNumber(tCO2e) }}
    </h3>
  </div>
</template>

<script>
import axios from '@/axios'
import Controls from '../../../controls'
import Modal from '../../../modal/Modal.vue'

export default {
  name: 'flights-component',
  props: {
    title: String,
    schemaName: String,
    initialSchema: Object,
    destinations: Array,
    item: Object,
    activityID: String,
    identifier: String,
    currentlyEditing: String
  },
  components: { Controls, Modal },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false,
      airports: []
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },
  mounted () {},
  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    updateCurrentlyEdited (currentEdit) {
      if (currentEdit === 'reset') {
        setTimeout(() => {
          this.$emit('updateEdited', '')
        }, 200)
      } else {
        this.$emit('updateEdited', currentEdit)
      }
    },
    selectOriginAirport (flight, airport) {
      flight.origin = airport.name
      flight.originLocation = airport.location
      this.calculateFlightDistance(flight)
      this.calculateCarbonEmissions()
    },
    selectDestinationAirport (flight, airport) {
      flight.destination = airport.name
      flight.destinationLocation = airport.location
      this.calculateFlightDistance(flight)
      this.calculateCarbonEmissions()
    },
    calculateCarbonEmissions () {
      // need to wait for data to populate with selected flight
      setTimeout(() => {
        var self = this
        return axios
          .post(
            '' +
            process.env.VUE_APP_APIURL +
            'api/calculateScopeThreeBusinessFlights/',
            {
              schema: this.schema
            // calculationData: calculationData.scopeOneCompanyVehiclesFuels
            }
          )
          .then(function (response) {
            self.schema = response.data.result
            self.$emit('calculateTotal', response.data.result, self.schemaName)
          })
          .catch(function (error) {
            console.log(error)
          })
          .then(function () {
            self.$root.saveData()
          })
      }, 500)
    },
    addUnit () {
      // Add unit value (0) to each unit array in month array
      var flight = {
        origin: '',
        destination: '',
        people: 0,
        class: '',
        distance: 0,
        returnFlight: false,
        eFactor: 0.0,
        emissionsNoRF: 0.0,
        emissionsWithRF: 0.0
      }

      this.schema.flights.push(flight)
    },
    removeUnit () {
      if (this.schema.flights.length > 1) {
        this.schema.flights.pop()
      }
    },
    getAirports (name) {
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div')
      )

      if (name.length > 3) {
        const request = {
          query: name,
          types: ['airport']
          // fields: ['formatted_address', 'geometry']
        }

        service.textSearch(request, (results, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            results
          ) {
            const lcAirports = []
            results.forEach((result) => {
              lcAirports.push({
                name: result.name,
                location: result.geometry.location
              })
            })
            this.airports = lcAirports
          }
        })
      } else {
        this.airports = []
      }
    },
    calculateFlightDistance (flight) {
      // console.log(flight)
      if (flight.origin && flight.destination) {
        var distance = this.getDistance(
          typeof flight.originLocation.lat === 'function' ? flight.originLocation.lat() : flight.originLocation.lat,
          typeof flight.originLocation.lng === 'function' ? flight.originLocation.lng() : flight.originLocation.lng,
          typeof flight.destinationLocation.lat === 'function' ? flight.destinationLocation.lat() : flight.destinationLocation.lat,
          typeof flight.destinationLocation.lng === 'function' ? flight.destinationLocation.lng() : flight.destinationLocation.lng
        )
        flight.distance = distance
      }
    },
    getDistance (lat1, lon1, lat2, lon2) {
      var p = 0.017453292519943295 // Math.PI / 180
      var c = Math.cos
      var a =
        0.5 -
        c((lat2 - lat1) * p) / 2 +
        (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2

      return 12742 * Math.asin(Math.sqrt(a)) // 2 * R; R = 6371 km
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.identifier}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  computed: {
    tCO2e () {
      var tCO2e = 0
      this.schema.emissionSources.forEach(source => {
        tCO2e += source.tCO2e
      })
      return tCO2e
    }
  }
}
</script>
