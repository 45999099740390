<template>
  <div class="input-section">
    <!-- Modal start -->
    <div class="tooltip">
      <h1 class="section-title">
        Chemical Used in Manufacturing or Processing
      </h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <!-- Modal end -->
    <Controls
      v-if="$root.userDetails.organisationStatus !== 'Completed'"
      v-on:add="addGasType()"
      v-on:remove="removeGasType()"
      v-on:update="calculateCarbonEmissions()"
    />
    <div
      class=" input-container-row"
      v-for="n in schema.numberOfGasType"
      :key="n"
    >
      <div>
        <label for="gasType"
          >Gas Type <span class="measurement">(e.g CO2)</span></label
        >
        <select :disabled="$root.userDetails.organisationStatus === 'Completed'"
          name="gas"
          id="gas"
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
          v-model="schema.gasTypes[n - 1].gasType"
        >
          <template v-for="(gas, index) in schema.gases">
            <option v-bind:value="gas.name" :key="index">{{ gas.name }}</option>
          </template>
        </select>
      </div>
      <div>
        <label for="quantity">Quantity</label>
        <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
          v-on:change="() => {
            schema.gasTypes[n - 1].quantity = schema.gasTypes[n - 1].quantity || 0;
            calculateCarbonEmissions()
          }"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
          v-model.number="schema.gasTypes[n - 1].quantity"
          type="number" step="0.01"
          name="quantity"
          min="0"
        />
      </div>
      <div>
        <label for="unit">Units</label>
        <select :disabled="$root.userDetails.organisationStatus === 'Completed'"
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
          name="unit"
          id="unit-type"
          v-model="schema.gasTypes[n - 1].units"
        >
          <option value="kg">kg</option>
          <option value="L">L</option>
        </select>
      </div>
    </div>
    <!-- <div class="total">
      <h4>Total Carbon Emissions:</h4>
      <h4>{{ schema.totalCarbonEmissions }} tCO2e</h4>
    </div> -->
  </div>
</template>

<script>
import axios from '@/axios'
import Controls from '../../../controls'
import Modal from '../../../modal/Modal.vue'

export default {
  name: 'chemicals-component',
  props: { initialSchema: Object, schemaName: String, activityID: String },
  components: { Controls, Modal },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    // this.schema = this.initialSchema
    this.schema = JSON.parse(JSON.stringify(this.initialSchema)) // makes copy
    this.getTooltip()
  },
  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions () {
      var schema = JSON.stringify({ input: this.schema })
      var self = this
      var config = {
        method: 'post',
        url: '' + process.env.VUE_APP_APIURL + 'api/calculateScopeOneChemicals',
        headers: {
          'Content-Type': 'application/json'
        },
        data: schema
      }
      return axios(config)
        .then(function (response) {
          // console.log(response.data.result)
          self.schema = response.data.result
          self.$emit('calculateTotal', response.data.result, self.schemaName)
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          self.$root.saveData()
        })
    },
    addGasType () {
      this.schema.gasTypes.push({
        gasType: 'CO2',
        quantity: 0,
        units: 'kg',
        eFactor: 0.0,
        carbonEmissions: 0.0
      })
      this.schema.numberOfGasType++
    },
    removeGasType () {
      if (this.schema.numberOfGasType > 1) {
        this.schema.gasTypes.pop()
        this.schema.numberOfGasType--
        this.calculateCarbonEmissions()
      }
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.$options.name}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
