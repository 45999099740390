<template>
  <div class="input-section">
    <!-- Modal start -->
    <div class="tooltip">
      <h1 class="section-title">{{ title }}</h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <!-- Modal end -->
    <Controls
      v-if="$root.userDetails.organisationStatus !== 'Completed'"
      v-on:add="addUnit()"
      v-on:remove="removeUnit()"
      v-on:update="calculateCarbonEmissions()"
    />
    <div
      class="input-container-row"
      :class="type"
      v-for="(flight, index) in schema.flights"
      :key="`item-${index}`"
    >
      <div v-if="type === 'Public Transport'">
        <label>Mode of transport</label>
        <select :disabled="$root.userDetails.organisationStatus === 'Completed'"
          v-if="type === 'Public Transport'"
          name="mode"
          id="mode"
          v-model="flight.modeOfTransport"
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        >
          <template v-for="(transport, index) in schema.transports">
            <option v-bind:value="transport.name" :key="index">{{
              transport.name
            }}</option>
          </template>
          <!-- <label for="from">From</label> -->
        </select>
      </div>

      <div>
        <label for="from">From</label>
        <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
          type="message"
          placeholder="Richmond"
          v-model="flight.origin"
          class="small-input"
        />
      </div>

      <div>
        <label for="to">To</label>
        <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
          type="message"
          placeholder="Nelson"
          v-model="flight.destination"
        />
      </div>

      <div>
        <label for="kms">kms</label>
        <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
          v-on:change="() => {
            flight.kms = flight.kms || 0;
            calculateCarbonEmissions()
          }"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
          type="number" step="0.01"
          min="0"
          v-model.number="flight.kms"
        />
      </div>
      <div>
        <label>
          Carbon Emissions
        </label>
        <h2>{{ $root.formatNumber(flight.carbonEmissions) }}</h2>
      </div>
    </div>
    <h3 class="secondary-title">
      {{ $store.state.app.selectedMonth === '0' ? 'Annual' : 'Monthly' }} Total:: {{ $root.formatNumber(schema.emissionSources[0].annualTotal) }}
    </h3>
    <h3 class="secondary-title">
      Carbon Emissions: {{ $root.formatNumber(schema.emissionSources[0].tCO2e) }}
    </h3>
  </div>
</template>

<script>
import axios from '@/axios'
import Controls from '../../../controls'
import Modal from '../../../modal/Modal.vue'

export default {
  name: 'flights-component',
  props: {
    title: String,
    type: String,
    schemaName: String,
    initialSchema: Object,
    item: Object,
    activityID: String,
    identifier: String
  },
  components: { Controls, Modal },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },
  mounted () {},
  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions () {
      var self = this
      return axios
        .post(
          `${
            process.env.VUE_APP_APIURL
          }api/calculateScopeThreeBusinessTravelDistanceTraveled/`,
          {
            schema: this.schema
          }
        )
        .then(function (response) {
          self.schema = response.data.result
          self.$emit('calculateTotal', response.data.result, self.schemaName)
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          self.$root.saveData()
        })
    },
    addUnit () {
      // Add unit value (0) to each unit array in month array
      var flight = { from: '', to: '', kms: 0, carbonEmissions: 0 }

      if (this.type === 'Public Transport') {
        flight = {
          modeOfTransport: 'Bus',
          from: '',
          to: '',
          kms: 0,
          carbonEmissions: 0
        }
      }
      this.schema.flights.push(flight)
    },
    removeUnit () {
      if (this.schema.flights.length > 1) {
        this.schema.flights.pop()
      }
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.identifier}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
