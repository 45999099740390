export function formatNumber (amount, decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    if (!isFinite(amount)) {
      return 0
    }
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString()
    let j = i.length > 3 ? i.length % 3 : 0

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    )
  } catch (e) {
    console.log(e)
  }
}

export function renderCells (cells, cellCount = 0, heading) {
  let cellData = ''
  if (cellCount > 0) {
    // generate number of cells based on cellCount per row
    var count = 1
    cells.forEach((cell, index) => {
      if (count === 1) {
        cellData += `<tr ${heading ? "class='heading'" : ''}>`
      }
      cell = cell === Infinity || cell === 0 ? '0.00' : cell
      cellData += `<td>${
        cell !== null
          ? isNaN(cell) || cell === '' || typeof cell === 'string'
            ? typeof cell === 'string' ? cell.replace(/\n/g, '<br/>') : cell
            : formatNumber(cell)
          : cell !== undefined
            ? ''
            : '0.00'
      }</td>`
      if (count === cellCount) {
        cellData += '</tr>'
        count = 1
      } else {
        count++
      }
    })
  } else {
    // basic two column row generation
    cells.forEach(cell => {
      cellData += '<tr>'
      cellData += `<td>${cell.title}</td>`
      cellData += `<td>${typeof cell.value === 'string' ? cell.value : formatNumber(cell.value)}</td>`
      cellData += '</tr>'
    })
  }
  return cellData
}

export function percentageChanged (oldValue, newValue) {
  const percentage = ((newValue - oldValue) / oldValue) * 100
  const formattedNumber = formatNumber(percentage)
  return isNaN(percentage) || !isFinite(percentage) ? '-' : formattedNumber + '%'
}

export function reportingPeriod (selectedYear, clientReportingMonthStart) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const startYear = parseInt(selectedYear)
  const endYear = months.findIndex(el => el === clientReportingMonthStart) !== 0
    ? startYear + 1
    : startYear
  const startMonth = clientReportingMonthStart.substring(0, 3)
  const endMonth = months.findIndex(el => el === clientReportingMonthStart) !== 0
    ? months[months.findIndex(el => el === clientReportingMonthStart) - 1].substring(0, 3)
    : months[11].substring(0, 3)
  return `${startMonth} ${startYear} - ${endMonth} ${endYear}`
}
