<template>
  <div class="dashboard">
    <div class="title-bar">
      <h1 class="main-heading">Reduction Plan</h1>
      <div class="left">
        <VueJsonToCsv
          :json-data="reductionPlanBreakdown"
          :csv-title="`dCarbon Reduction Plan Breakdown for ${client} - ${reportingPeriod}`"
        >
        <div class="square-button blue">
          <div class="btn-text-icon">
            <div>Download Reduction Plan Breakdown</div>
            <i class="fad fa-download"></i>
          </div>
        </div>
        </VueJsonToCsv>
      </div>
    </div>
    <!-- <div class="row">
      <div class="layout-settings" @click="showSettings=true">
        <div class="title">Layout Settings</div>
        <i class="fas fa-cog"></i>
      </div>
    </div>
        <ReductionReportSettings v-if="$store.state.app.report" :settings="$store.state.app.report.settings" :show="showSettings" @hide="showSettings = false" /> -->
    <div class="section">
      <div class="dashboard-bar">
        <h1 class="activity-title">
          {{
            tab === 'details'
            ? 'Targets'
            : tab === 'recommendations'
              ? 'Actions'
              : tab === 'performance'
                ? 'Emissions Reduction Performance'
                : 'View Report'
          }}
        </h1>
        <div class="toggle-btn-container">
          <div v-for="tab in tabs" :key="tab.id" class="toggle-btn" :class="{
            active: tabIsActive(tab.id),
            inactive: !tabIsActive(tab.id)
          }" @click="selectTab(tab.id)">
            <p class="btn-text">{{ tab.title }}</p>
          </div>
          <div class="toggle-btn" @click="$router.push('/dashboard')">
            <p class="btn-text">Back to Dashboard</p>
          </div>
        </div>
      </div>
      <ReductionPlanTabs v-if="$root.loaded && !$store.state.app.loading" :tab="tab" />
      <div v-else class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv'
import ReductionPlanTabs from './ReductionPlanTabs.vue'
import { reportingPeriod } from '../../utils/utils'
// import ReductionReportSettings from './reductionReportSettings.vue'
export default {
  components: { ReductionPlanTabs, VueJsonToCsv },
  data () {
    return {
      tab: 'recommendations',
      tabs: [
        { id: 'details', title: 'Targets' },
        { id: 'recommendations', title: 'Actions' },
        { id: 'report', title: 'Report' }
      ],
      showSettings: false
    }
  },
  beforeMount () {
    if (!window.location.hash) {
      this.selectTab(this.tabs[0].id)
    } else {
      this.selectTab(window.location.hash.replace('#', ''))
    }
  },
  computed: {
    reportingPeriod () {
      return reportingPeriod(this.$store.state.app.selectedYear, this.$store.state.app.client?.reportingMonthStart || 'April')
    },
    client () {
      return this.$store.state.app.client ? this.$store.state.app.client.title : ''
    },
    reductionPlanBreakdown () {
      const completedInventories = this.$store.state.app.completedInventories
      return this.$store.state.app.reductionPlan?.reductionEmissionSources.nodes.map(res => {
        const name = res.baseYearEmissionsSource.name ? res.baseYearEmissionsSource.name : res.emissionsSource.name
        if (!name) return
        const emissionSourceOverYears = []
        completedInventories.forEach(inventory => {
          const emissionSource = inventory.emissionsSources.nodes.find(source => source.name === name)
          emissionSourceOverYears.push({
            [inventory.year + ' (location based)']: emissionSource ? emissionSource.tCO2e : 0,
            [inventory.year + ' (market based)']: emissionSource ? emissionSource.tCO2eMarketRate : 0
          })
        })

        return {
          'Activity Name (EF name)': res.emissionsSource.name ? res.emissionsSource.name : res.baseYearEmissionsSource.name,
          'Activity Group (Factor group)': res.emissionsSource.subcategory ? res.emissionsSource.subcategory : res.baseYearEmissionsSource.subcategory,
          [res.baseYearEmissionsSource.period.year + ' (location based)']: res.baseYearEmissionsSource.tCO2e ? res.baseYearEmissionsSource.tCO2e : 0,
          [res.baseYearEmissionsSource.period.year + ' (market based)']: res.baseYearEmissionsSource.tCO2eMarketRate ? res.baseYearEmissionsSource.tCO2eMarketRate : 0,
          ...emissionSourceOverYears.reduce((acc, cur) => ({ ...acc, ...cur }), {})
        }
      }) || []
    }
  },
  methods: {
    selectTab (id) {
      if (this.tabs.find(x => x.id === id)) {
        window.location.hash = id
        this.tab = id
      }
    },
    tabIsActive (id) {
      return window.location.hash.replace('#', '') === id
    }
  }
}
</script>
