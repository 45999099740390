const staffCommuteEmissionSources = [{
  category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
  subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
  GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
  GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
  ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
  name: 'Staff Commuting - Petrol Hybrid', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
  type: 'default', // default, naturalGasTDL, electricityTDL, WTT
  id: 'hybrid',
  unit: 'KM',
  annualTotal: 0,
  tCO2e: 0,
  CO2: 0,
  CH4: 0,
  N2O: 0,
  emissionSourceInfo: {
    dataSource: '',
    dataQuality: 'Please select',
    assumptions: ''
  }
},
{
  category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
  subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
  GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
  GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
  ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
  name: 'Staff Commuting - Electric Vehicle', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
  type: 'default', // default, naturalGasTDL, electricityTDL, WTT
  id: 'ev',
  unit: 'KM',
  annualTotal: 0,
  tCO2e: 0,
  CO2: 0,
  CH4: 0,
  N2O: 0,
  emissionSourceInfo: {
    dataSource: '',
    dataQuality: 'Please select',
    assumptions: ''
  }
},
{
  category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
  subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
  GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
  GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
  ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
  name: 'Staff Commuting - PHEV', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
  type: 'default', // default, naturalGasTDL, electricityTDL, WTT
  id: 'phev',
  unit: 'KM',
  annualTotal: 0,
  tCO2e: 0,
  CO2: 0,
  CH4: 0,
  N2O: 0,
  emissionSourceInfo: {
    dataSource: '',
    dataQuality: 'Please select',
    assumptions: ''
  }
},
{
  category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
  subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
  GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
  GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
  ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
  name: 'Staff Commuting - Petrol Hybrid WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
  type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
  id: 'hybrid-wtt',
  unit: 'KM',
  annualTotal: 0,
  tCO2e: 0,
  CO2: 0,
  CH4: 0,
  N2O: 0,
  emissionSourceInfo: {
    dataSource: '',
    dataQuality: 'Please select',
    assumptions: ''
  }
},
{
  category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
  subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
  GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
  GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
  ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
  name: 'Staff Commuting - Electric Vehicle WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
  type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
  id: 'ev-wtt',
  unit: 'KM',
  annualTotal: 0,
  tCO2e: 0,
  CO2: 0,
  CH4: 0,
  N2O: 0,
  emissionSourceInfo: {
    dataSource: '',
    dataQuality: 'Please select',
    assumptions: ''
  }
},
{
  category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
  subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
  GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
  GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
  ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
  name: 'Staff Commuting - PHEV WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
  type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
  id: 'phev-wtt',
  unit: 'KM',
  annualTotal: 0,
  tCO2e: 0,
  CO2: 0,
  CH4: 0,
  N2O: 0,
  emissionSourceInfo: {
    dataSource: '',
    dataQuality: 'Please select',
    assumptions: ''
  }
}]

const businessTravelEmissionSources = [
  {
    category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Business Travel - Petrol Hybrid', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'default', // default, naturalGasTDL, electricityTDL, WTT
    id: 'hybrid',
    unit: 'KM',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  },
  {
    category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Business Travel - Electric Vehicle', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'default', // default, naturalGasTDL, electricityTDL, WTT
    id: 'ev',
    unit: 'KM',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  },
  {
    category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Business Travel - PHEV', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'default', // default, naturalGasTDL, electricityTDL, WTT
    id: 'phev',
    unit: 'KM',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  },
  {
    category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Business Travel - Petrol Hybrid WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
    id: 'hybrid-wtt',
    unit: 'KM',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  },
  {
    category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Business Travel - Electric Vehicle WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
    id: 'ev-wtt',
    unit: 'KM',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  },
  {
    category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Business Travel - PHEV WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
    id: 'phev-wtt',
    unit: 'KM',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  }
]

const extraCalculations = [
  {
    type: 'hybrid',
    tCO2eVariableName: 'petrolHybridKmDefaultKgCO2e',
    CO2VariableName: 'petrolHybridKmDefaultKgCO2',
    N2OVariableName: 'petrolHybridKmDefaultKgN2O',
    CH4VariableName: 'petrolHybridKmDefaultKgCH4'
  },
  {
    type: 'ev',
    tCO2eVariableName: 'electricVehicleKmDefaultKgCO2e',
    CO2VariableName: 'electricVehicleKmDefaultKgCO2',
    N2OVariableName: 'electricVehicleKmDefaultKgN2O',
    CH4VariableName: 'electricVehicleKmDefaultKgCH4'
  },
  {
    type: 'phev',
    tCO2eVariableName: 'petrolPlugInHybridKmDefaultKgCO2e',
    CO2VariableName: 'petrolPlugInHybridKmDefaultKgCO2',
    N2OVariableName: 'petrolPlugInHybridKmDefaultKgN2O',
    CH4VariableName: 'petrolPlugInHybridKmDefaultKgCH4'
  },
  {
    type: 'hybrid-wtt',
    tCO2eVariableName: 'petrolHybridKmDefaultKgCO2eWTT' // Main getWellToTankEmissions() variable Name
  },
  {
    type: 'ev-wtt',
    tCO2eVariableName: 'electricVehicleKmDefaultKgCO2eWTT' // Main getWellToTankEmissions() variable Name
  },
  {
    type: 'phev-wtt',
    tCO2eVariableName: 'petrolPlugInHybridKmDefaultKgCO2eWTT' // Main getWellToTankEmissions() variable Name
  }
]

const monthlyEmissionFactors = {
  hybridMonths: [
    { name: 'January', amount: 0 },
    { name: 'February', amount: 0 },
    { name: 'March', amount: 0 },
    { name: 'April', amount: 0 },
    { name: 'May', amount: 0 },
    { name: 'June', amount: 0 },
    { name: 'July', amount: 0 },
    { name: 'August', amount: 0 },
    { name: 'September', amount: 0 },
    { name: 'October', amount: 0 },
    { name: 'November', amount: 0 },
    { name: 'December', amount: 0 }
  ],
  evMonths: [
    { name: 'January', amount: 0 },
    { name: 'February', amount: 0 },
    { name: 'March', amount: 0 },
    { name: 'April', amount: 0 },
    { name: 'May', amount: 0 },
    { name: 'June', amount: 0 },
    { name: 'July', amount: 0 },
    { name: 'August', amount: 0 },
    { name: 'September', amount: 0 },
    { name: 'October', amount: 0 },
    { name: 'November', amount: 0 },
    { name: 'December', amount: 0 }
  ],
  phevMonths: [
    { name: 'January', amount: 0 },
    { name: 'February', amount: 0 },
    { name: 'March', amount: 0 },
    { name: 'April', amount: 0 },
    { name: 'May', amount: 0 },
    { name: 'June', amount: 0 },
    { name: 'July', amount: 0 },
    { name: 'August', amount: 0 },
    { name: 'September', amount: 0 },
    { name: 'October', amount: 0 },
    { name: 'November', amount: 0 },
    { name: 'December', amount: 0 }
  ]
}
export default { staffCommuteEmissionSources, businessTravelEmissionSources, extraCalculations, monthlyEmissionFactors }
