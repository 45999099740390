<template>
  <div class="input-section">
    <!-- <i class="fas fa-user"></i> -->
    <h1 class="section-title activity-title">
      Inventory Status & Assurance Type
    </h1>
    <div class="input-container" style="max-width: 600px">
      <div class="input-item">
        <label>Inventory Status:</label>
        <select
          type="text"
          v-model.lazy.trim="$store.state.app.report.status"
          v-on:change="updateAssuranceType(), updateReportData('status')"
        >
          <option value="Unverified Inventory">Unverified Inventory</option>
          <option value="Quality Reviewed Inventory"
            >Quality Reviewed Inventory</option
          >
          <option value="Externally Reviewed Inventory"
            >Externally Reviewed Inventory</option
          >
          <option value="Verified Inventory">Verified Inventory</option>
        </select>
      </div>
      <div class="input-item">
        <label>Assurance Type:</label>
        <select
          type="text"
          v-model="$store.state.app.report.assuranceType"
          v-on:change="updateReportData('assuranceType')"
        >
          <template v-for="(option, index) in options">
            <option :value="option" :key="index">{{ option }}</option>
          </template>
          <!-- <option value="Unverified Inventory">Unverified Inventory</option>
          <option value="N/A">N/A</option> -->
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    updateReportData (fieldName) {
      this.$emit('updateReportData', fieldName)
    },
    updateAssuranceType () {
      this.$store.state.app.report.assuranceType = this.options[0]
      this.$emit('updateReportData', 'assuranceType')
    }
  },
  computed: {
    options () {
      // Unverified Inventory,Quality Reviewed Inventory,Externally Reviewed Inventory,Verified Inventory
      let options = []
      if (this.$store.state.app.report.status === 'Unverified Inventory') {
        options = ['N/A']
      } else if (this.$store.state.app.report.status === 'Quality Reviewed Inventory') {
        options = ['No Assurance']
      } else if (this.$store.state.app.report.status === 'Externally Reviewed Inventory') {
        options = ['No Assurance']
      } else if (this.$store.state.app.report.status === 'Verified Inventory') {
        options = [
          'Reasonable All Scopes',
          'Reasonable Scope 1 & 2 (Category 1 & 2) Limited Scope 3 (Category 3-6)',
          'Limited All Scopes'
        ]
      }
      return options
    }
  }
}
</script>
