const staffCommuteSchema = [
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Staff Vehicle Mileage', // Title of Section on front end
    title: 'Staff Vehicle Mileage',
    scope: 3, // scope number
    sectionID: 'staff-commute', // Default Schema Scope Section Name
    identifier: 'mileage', // Main Section component identifier for tooltips
    version: 2.76,
    active: true,
    toggleName: 'enterByVehicles',
    emissionSources: [
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Petrol', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'petrol',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Diesel', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'diesel',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Petrol WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'petrol-wtt',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Diesel WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'diesel-wtt',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Petrol Hybrid', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'hybrid',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Electric Vehicle', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'ev',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - PHEV', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'phev',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Petrol Hybrid WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'hybrid-wtt',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Electric Vehicle WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'ev-wtt',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - PHEV WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'phev-wtt',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'petrol',
        tCO2eVariableName: 'privateCarDefaultPetrolKgCO2e',
        CO2VariableName: 'privateCarDefaultPetrolKgCO2',
        N2OVariableName: 'privateCarDefaultPetrolKgN2O',
        CH4VariableName: 'privateCarDefaultPetrolKgCH4'
      },
      {
        type: 'diesel',
        tCO2eVariableName: 'privateCarDefaultDieselKgCO2e',
        CO2VariableName: 'privateCarDefaultDieselKgCO2',
        N2OVariableName: 'privateCarDefaultDieselKgN2O',
        CH4VariableName: 'privateCarDefaultDieselKgCH4'
      },
      {
        type: 'petrol-wtt',
        tCO2eVariableName: 'petrolKmWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'diesel-wtt',
        tCO2eVariableName: 'dieselKmWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'hybrid',
        tCO2eVariableName: 'petrolHybridKmDefaultKgCO2e',
        CO2VariableName: 'petrolHybridKmDefaultKgCO2',
        N2OVariableName: 'petrolHybridKmDefaultKgN2O',
        CH4VariableName: 'petrolHybridKmDefaultKgCH4'
      },
      {
        type: 'ev',
        tCO2eVariableName: 'electricVehicleKmDefaultKgCO2e',
        CO2VariableName: 'electricVehicleKmDefaultKgCO2',
        N2OVariableName: 'electricVehicleKmDefaultKgN2O',
        CH4VariableName: 'electricVehicleKmDefaultKgCH4'
      },
      {
        type: 'phev',
        tCO2eVariableName: 'petrolPlugInHybridKmDefaultKgCO2e',
        CO2VariableName: 'petrolPlugInHybridKmDefaultKgCO2',
        N2OVariableName: 'petrolPlugInHybridKmDefaultKgN2O',
        CH4VariableName: 'petrolPlugInHybridKmDefaultKgCH4'
      },
      {
        type: 'hybrid-wtt',
        tCO2eVariableName: 'petrolHybridKmDefaultKgCO2eWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'ev-wtt',
        tCO2eVariableName: 'electricVehicleKmDefaultKgCO2eWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'phev-wtt',
        tCO2eVariableName: 'petrolPlugInHybridKmDefaultKgCO2eWTT' // Main getWellToTankEmissions() variable Name
      }
    ],
    months: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    dieselMonths: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    hybridMonths: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    evMonths: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    phevMonths: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    variableName: 'privateCarDefaultPetrolKgCO2e', // privateCarDefaultPetrolKgCO2, privateCarDefaultPetrolKgCH4, privateCarDefaultPetrolKgN2O
    variableNameDiesel: 'privateCarDefaultDieselKgCO2e', // privateCarDefaultDieselKgCO2, privateCarDefaultDieselKgCH4, privateCarDefaultDieselKgN2O
    annually: true
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Staff Vehicle Fuels', // Title of Section on front end
    title: 'Staff Vehicle Fuels',
    scope: 3, // scope number
    sectionID: 'staff-commute', // Default Schema Scope Section Name
    identifier: 'fuels', // Main Section component identifier for tooltips
    version: 2.76,
    active: false,
    toggleName: 'enterByVehicles',
    emissionSources: [
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Petrol', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'petrol',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Diesel', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'diesel',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Petrol WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'petrol-wtt',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Diesel WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'diesel-wtt',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'petrol',
        tCO2eVariableName: 'kgCO2ePetrol',
        CO2VariableName: 'kgCO2PerUnitPetrol',
        N2OVariableName: 'kgN2OPerUnitPetrol',
        CH4VariableName: 'kgCH4PerUnitPetrol'
      },
      {
        type: 'diesel',
        tCO2eVariableName: 'kgCO2eDieselCompanyVehicles',
        CO2VariableName: 'kgCO2PerUnitDieselCompanyVehicles',
        N2OVariableName: 'kgn2OPerUnitDieselCompanyVehicles',
        CH4VariableName: 'kgCH4PerUnitDieselCompanyVehicles'
      },
      {
        type: 'petrol-wtt',
        tCO2eVariableName: 'petrolWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'diesel-wtt',
        tCO2eVariableName: 'dieselWTT' // Main getWellToTankEmissions() variable Name
      }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Staff Working from Home', // Title of Section on front end
    title: 'Staff Working from Home',
    scope: 3, // scope number
    sectionID: 'staff-commute', // Default Schema Scope Section Name
    identifier: 'work-from-home', // Main Section component identifier for tooltips
    version: 2.77,
    emissionSources: [
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Working From Home', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'days',
        unit: 'DAYS',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'days',
        tCO2eVariableName: 'kgCO2eStaffPerDay',
        CO2VariableName: 'kgCO2StaffPerDay',
        N2OVariableName: 'kgN2OStaffPerDay',
        CH4VariableName: 'kgCH4StaffPerDay'
      }
    ],
    months: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    variableName: 'kgCO2eStaffPerDay', // kgCO2StaffPerDay, kgCH4StaffPerDay, kgN2OStaffPerDay
    annualTotal: 0,
    carbonEmissions: 0.0,
    annually: true
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Staff Commute Public Transport', // Title of Section on front end
    title: 'Staff Commute Public Transport', // Title of Section on front end
    scope: 3, // scope number
    sectionID: 'staff-commute', // Default Schema Scope Section Name
    identifier: 'staff-commute-public-transport', // Main Section component identifier for tooltips
    version: 1.00,
    emissionSources: [
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Bus', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'bus',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Bus WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'bus-wtt',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Rail', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'rail',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Staff Commuting - Rail WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'rail-wtt',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'bus',
        tCO2eVariableName: 'busNationalAveragePkmKgCO2e',
        CO2VariableName: 'busNationalAveragePkmKgCO2',
        N2OVariableName: 'busNationalAveragePkmKgN2O',
        CH4VariableName: 'busNationalAveragePkmKgCH4'
      },
      {
        type: 'bus-wtt',
        tCO2eVariableName: 'busNationalAveragePkmKgCO2eWTT'
      },
      {
        type: 'rail',
        tCO2eVariableName: 'metropolitanAveragePkmKgCO2e',
        CO2VariableName: 'metropolitanAveragePkmKgCO2',
        N2OVariableName: 'metropolitanAveragePkmKgN2O',
        CH4VariableName: 'metropolitanAveragePkmKgCH4'
      },
      {
        type: 'rail-wtt',
        tCO2eVariableName: 'metropolitanAveragePkmKgCO2eWTT'
      }
    ],
    months: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    railMonths: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    variableName: 'kgCO2ePerKmRentalCars', // kgCO2PerKmRentalCars, kgCH4PerKmRentalCars, kgN2OPerKmRentalCars
    annually: true
  }
]
export default staffCommuteSchema
