<template>
  <div class="report-input-section">
    <!-- <i v-if="admin" class="fas fa-user"></i> -->
    <h1 v-if="title" class="section-title activity-title">{{ title }}</h1>
    <div class="report-input-container" style="max-width: 600px">
      <div class="report-input-item">
        <label>{{label}}</label>
        <textarea
          rows="8"
          cols="75"
          v-model="$store.state.app.report[property]"
          v-on:change="updateReportData(property)"
          :placeholder="placeholder"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    label: { type: String, default: 'Notes:' },
    property: [String, Object],
    admin: Boolean,
    placeholder: { type: String, default: 'Notes' }
  },
  data () {
    return {
      notes: ''
    }
  },
  beforeMount () {
    this.notes = this.text
  },
  methods: {
    updateReportData (fieldName) {
      this.$emit('updateReportData', fieldName)
    }
  }
}
</script>
