var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-section"},[_c('div',{staticClass:"tooltip"},[_c('h1',{staticClass:"section-title"},[_vm._v("Fuels")]),_c('div',{staticClass:"modal-btn",on:{"click":_vm.openModal}},[_c('i',{staticClass:"fas fa-question-circle"})])]),_c('div',{staticClass:"modal-wrapper",class:_vm.modalOpen ? 'active' : ''},[_c('Modal',{attrs:{"tooltip":_vm.tooltip},model:{value:(_vm.modalOpen),callback:function ($$v) {_vm.modalOpen=$$v},expression:"modalOpen"}})],1),_c('div',[_c('h3',{staticClass:"secondary-title"},[_vm._v("Volume consumed (litres)")]),_c('div',{staticClass:"input-container-column",staticStyle:{"max-width":"300px"}},[_c('div',{staticClass:"input-item long"},[_c('label',{attrs:{"for":"petrol"}},[_vm._v("Petrol (regular)")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.getEmissionSource('Mobile Combustion - Petrol (regular)').annualTotal),expression:"getEmissionSource('Mobile Combustion - Petrol (regular)').annualTotal",modifiers:{"number":true}}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"min":"0","type":"number","step":"0.01","name":"petrol-regular"},domProps:{"value":(_vm.getEmissionSource('Mobile Combustion - Petrol (regular)').annualTotal)},on:{"change":function () {
            _vm.getEmissionSource('Mobile Combustion - Petrol (regular)').annualTotal = _vm.getEmissionSource('Mobile Combustion - Petrol (regular)').annualTotal || 0;
            _vm.calculateCarbonEmissions()
          },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.getEmissionSource('Mobile Combustion - Petrol (regular)'), "annualTotal", _vm._n($event.target.value))}}})]),_c('div',{staticClass:"input-item long"},[_c('label',{attrs:{"for":"petrol"}},[_vm._v("Petrol (premium)")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.getEmissionSource('Mobile Combustion - Petrol (premium)').annualTotal),expression:"getEmissionSource('Mobile Combustion - Petrol (premium)').annualTotal",modifiers:{"number":true}}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"min":"0","type":"number","step":"0.01","name":"petrol-premium"},domProps:{"value":(_vm.getEmissionSource('Mobile Combustion - Petrol (premium)').annualTotal)},on:{"change":function () {
            _vm.getEmissionSource('Mobile Combustion - Petrol (premium)').annualTotal = _vm.getEmissionSource('Mobile Combustion - Petrol (premium)').annualTotal || 0;
            _vm.calculateCarbonEmissions()
          },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.getEmissionSource('Mobile Combustion - Petrol (premium)'), "annualTotal", _vm._n($event.target.value))}}})]),_c('div',{staticClass:"input-item long"},[_c('label',{attrs:{"for":"diesel"}},[_vm._v("Diesel")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.getEmissionSource('Mobile Combustion - Diesel').annualTotal),expression:"getEmissionSource('Mobile Combustion - Diesel').annualTotal",modifiers:{"number":true}}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"min":"0","type":"number","step":"0.01","name":"diesel"},domProps:{"value":(_vm.getEmissionSource('Mobile Combustion - Diesel').annualTotal)},on:{"change":function () {
            _vm.getEmissionSource('Mobile Combustion - Diesel').annualTotal = _vm.getEmissionSource('Mobile Combustion - Diesel').annualTotal || 0;
            _vm.calculateCarbonEmissions()
          },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.getEmissionSource('Mobile Combustion - Diesel'), "annualTotal", _vm._n($event.target.value))}}})]),_c('div',{staticClass:"input-item long"},[_c('label',{attrs:{"for":"lpg"}},[_vm._v("LPG")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.getEmissionSource('Mobile Combustion - LPG').annualTotal),expression:"getEmissionSource('Mobile Combustion - LPG').annualTotal",modifiers:{"number":true}}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"min":"0","type":"number","step":"0.01","name":"lpg"},domProps:{"value":(_vm.getEmissionSource('Mobile Combustion - LPG').annualTotal)},on:{"change":function () {
            _vm.getEmissionSource('Mobile Combustion - LPG').annualTotal = _vm.getEmissionSource('Mobile Combustion - LPG').annualTotal || 0;
            _vm.calculateCarbonEmissions()
          },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.getEmissionSource('Mobile Combustion - LPG'), "annualTotal", _vm._n($event.target.value))}}})]),_c('div',{staticClass:"input-item long"},[_c('label',{attrs:{"for":"aviationGas"}},[_vm._v("Aviation Gas")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.getEmissionSource('Mobile Combustion - Aviation Gas').annualTotal),expression:"getEmissionSource('Mobile Combustion - Aviation Gas').annualTotal",modifiers:{"number":true}}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"min":"0","type":"number","name":"aviationGas"},domProps:{"value":(_vm.getEmissionSource('Mobile Combustion - Aviation Gas').annualTotal)},on:{"change":function () {
            _vm.getEmissionSource('Mobile Combustion - Aviation Gas').annualTotal = _vm.getEmissionSource('Mobile Combustion - Aviation Gas').annualTotal || 0;
            _vm.calculateCarbonEmissions()
          },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.getEmissionSource('Mobile Combustion - Aviation Gas'), "annualTotal", _vm._n($event.target.value))}}})]),_c('div',{staticClass:"input-item long"},[_c('label',{attrs:{"for":"aviationFuelKerosene"}},[_vm._v("Aviation Fuel Kerosene")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.getEmissionSource('Mobile Combustion - Aviation Fuel Kerosene').annualTotal),expression:"getEmissionSource('Mobile Combustion - Aviation Fuel Kerosene').annualTotal",modifiers:{"number":true}}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"min":"0","type":"number","name":"aviationFuelKerosene"},domProps:{"value":(_vm.getEmissionSource('Mobile Combustion - Aviation Fuel Kerosene').annualTotal)},on:{"change":function () {
            _vm.getEmissionSource('Mobile Combustion - Aviation Fuel Kerosene').annualTotal = _vm.getEmissionSource('Mobile Combustion - Aviation Fuel Kerosene').annualTotal || 0;
            _vm.calculateCarbonEmissions()
          },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.getEmissionSource('Mobile Combustion - Aviation Fuel Kerosene'), "annualTotal", _vm._n($event.target.value))}}})]),_c('div',{staticClass:"input-item long"},[_c('label',{attrs:{"for":"bioethanol"}},[_vm._v("Bioethanol")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.getEmissionSource('Mobile Combustion - Bioethanol').annualTotal),expression:"getEmissionSource('Mobile Combustion - Bioethanol').annualTotal",modifiers:{"number":true}}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"min":"0","type":"number","name":"bioethanol"},domProps:{"value":(_vm.getEmissionSource('Mobile Combustion - Bioethanol').annualTotal)},on:{"change":function () {
            _vm.getEmissionSource('Mobile Combustion - Bioethanol').annualTotal = _vm.getEmissionSource('Mobile Combustion - Bioethanol').annualTotal || 0;
            _vm.calculateCarbonEmissions()
          },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.getEmissionSource('Mobile Combustion - Bioethanol'), "annualTotal", _vm._n($event.target.value))}}})]),_c('div',{staticClass:"input-item long"},[_c('label',{attrs:{"for":"biodiesel"}},[_vm._v("Biodiesel")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.getEmissionSource('Mobile Combustion - Biodiesel').annualTotal),expression:"getEmissionSource('Mobile Combustion - Biodiesel').annualTotal",modifiers:{"number":true}}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"min":"0","type":"number","name":"biodiesel"},domProps:{"value":(_vm.getEmissionSource('Mobile Combustion - Biodiesel').annualTotal)},on:{"change":function () {
            _vm.getEmissionSource('Mobile Combustion - Biodiesel').annualTotal = _vm.getEmissionSource('Mobile Combustion - Biodiesel').annualTotal || 0;
            _vm.calculateCarbonEmissions()
          },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.getEmissionSource('Mobile Combustion - Biodiesel'), "annualTotal", _vm._n($event.target.value))}}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }