<template>
  <div class="section air-con">
    <div class="activity-title-row">
      <h1 class="activity-title">Refrigerants</h1>
      <i class="title-icon fad fa-tractor"></i>
    </div>
    <AirConComponentBasic
      title="Air Conditioning/Refrigerants"
      v-bind:initialSchema="
        schema[this.getSchemaIndex('Air Conditioning/Refrigerants')]
      "
      schemaName="Air Conditioning/Refrigerants"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
    />
    <div class="spacer"></div>
    <Notes
      :key="0"
      :schemaIndex="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions"
    />

    <NextSectionButton
      :nextRouteSchema="$root.industryAndAgriculture.schemas[2]"
      :nextRouteActivity="$root.industryAndAgriculture.sections[2]"
      sectionName="industryAndAgriculture"
    />
  </div>
</template>

<script>
import Notes from '../../../Notes.vue'
import NextSectionButton from '../../../NextSectionButton.vue'
import AirConComponentBasic from './AirConComponentBasic'

export default {
  metaData () {
    return {
      title: `dCarbon — Aircon`,
      description: ''
    }
  },
  name: 'activity-aircon',
  props: { initialSchema: Object, scope: String },
  components: { AirConComponentBasic, Notes, NextSectionButton },
  data () {
    return {
      schema: {}
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    calculateTotal (schema, schemaName) {
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    }
  }
}
</script>
