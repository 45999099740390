<template>
  <div v-if="$store.state.user.clientID" class="emissions-report-company-info">
    <div class="business-includes-container">
      <h1 class="section-title">
        What parts of your business are included in this measurement?
      </h1>
      <div class="description" style="text-align: left; max-width: 900px;">
        You need to include the core elements of your operations. if one or more
        of your core operations are outsourced to a thirdparty, you will need to
        include these componts in your calculation.
      </div>
      <div class="control-input-container">
        <Controls
          class="controls"
          v-on:add="createLegalEntity()"
          v-on:remove="$store.state.app.report.legalEntities.nodes.length > 1 ? deleteLegalEntity() : ''"
        />
        <div
          v-for="(entity, i) in $store.state.app.report.legalEntities.nodes"
          :key="`n-${i}`"
          class="column"
          style="display: flex;
                flex-direction: column;
                align-items: flex-start;"
        >
          <div class="business-includes row">
            <div class="business-includes-title">
              Legal entities (include any subsidiaries)
            </div>
            <input
              type="text"
              id="title"
              placeholder="Entity Name"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              v-model.lazy.trim="entity.title"
              v-on:change="updateLegalEntity('title', entity)"
            />
          </div>

          <div class="business-includes row">
            <div class="business-includes-title">Business unit / Location</div>
            <input
              type="text"
              id="location"
              placeholder="Business Location"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              v-model.lazy.trim="entity.location"
              v-on:change="updateLegalEntity('location', entity)"
            />
          </div>
          <div class="business-includes row">
            <div class="business-includes-title">Describe the activities or purpose of this BU / site</div>
            <textarea
              name=""
              id="purpose"
              cols="30"
              rows="2"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              v-model.lazy.trim="entity.purpose"
              v-on:change="updateLegalEntity('purpose', entity)"
            ></textarea>
          </div>
          <div class="business-includes row">
            <div class="business-includes-title">Included / Excluded</div>
            <select
              type="text"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              v-model.lazy.trim="entity.status"
              v-on:change="updateLegalEntity('status', entity)"
            >
              <option value="TBC" selected>TBC</option>
              <option value="Included">Included</option>
              <option value="Excluded">Excluded</option>
            </select>
          </div>

          <div class="business-includes row">
            <div class="business-includes-title">Reason for exclusion</div>
            <textarea
              name=""
              id="reasonForExclusion"
              cols="30"
              rows="2"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              v-model.lazy.trim="entity.reasonForExclusion"
              v-on:change="updateLegalEntity('reasonForExclusion', entity)"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Controls from '../../components/controls.vue'

export default {
  components: { Controls },
  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    async createLegalEntity () {
      const data = {
        legalEntity: {
          reportID: this.$store.state.app.report.id,
          title: '',
          location: '',
          status: 'TBC',
          reasonForExclusion: ''
        }
      }
      // console.log(data.legalEntity, 'creating legalEntity')
      this.$root.saving = true
      await this.$store.dispatch('createLegalEntity', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    async deleteLegalEntity () {
      let metric = this.$store.state.app.report.legalEntities.nodes[this.$store.state.app.report.legalEntities.nodes.length - 1]
      const data = [metric.id]
      this.$root.saving = true
      await this.$store.dispatch('deleteLegalEntities', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    async updateLegalEntity (fieldName, entity) {
      const data = { legalEntity: { id: entity.id } }
      data.legalEntity[fieldName] = entity[fieldName]
      // console.log(data.legalEntity, 'updating legalEntity data')
      this.$root.saving = true
      await this.$store.dispatch('updateLegalEntity', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    }
  }
}
</script>

<style></style>
