const endOfLifeDisposalSchema = [ // HERE
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Landfill Waste', // Title of Section on front end
    title: 'End Of Life Disposal - Landfill Waste',
    scope: 3, // scope number
    sectionID: 'end-of-life-disposal', // Default Schema Scope Section Name
    identifier: 'landfill-component', // Main Section component identifier for tooltips
    version: 2.76,
    active: true,
    toggleName: 'enterBy',
    emissionSources: [
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'End of Life Disposal General Waste to Landfill -', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'CO2',
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'End of Life Disposal Office Waste to Landfill -', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'office-waste',
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    units: [
      {
        title: 'Unit 1',
        generalType: 0,
        officeType: 0,
        officeRecovery: { value: 'without', name: 'Other/Unknown' },
        generalRecovery: { value: 'without', name: 'Other/Unknown' },
        months: [
          { name: 'January', generalWaste: 0, officeWaste: 0 },
          { name: 'February', generalWaste: 0, officeWaste: 0 },
          { name: 'March', generalWaste: 0, officeWaste: 0 },
          { name: 'April', generalWaste: 0, officeWaste: 0 },
          { name: 'May', generalWaste: 0, officeWaste: 0 },
          { name: 'June', generalWaste: 0, officeWaste: 0 },
          { name: 'July', generalWaste: 0, officeWaste: 0 },
          { name: 'August', generalWaste: 0, officeWaste: 0 },
          { name: 'September', generalWaste: 0, officeWaste: 0 },
          { name: 'October', generalWaste: 0, officeWaste: 0 },
          { name: 'November', generalWaste: 0, officeWaste: 0 },
          { name: 'December', generalWaste: 0, officeWaste: 0 }
        ],
        annualTotalGeneralWaste: 0,
        annualTotalEmissionsGeneralWaste: 0,
        annualTotalOfficeWaste: 0,
        annualTotalEmissionsOfficeWaste: 0,
        totalCarbonEmissions: 0.0
      }
    ],
    annually: true,
    totalEmissions: {
      totalCarbonEmissions: 0
    }
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Waste Composting', // Title of Section on front end
    title: 'End of Life Disposal - Waste Composting',
    scope: 3, // scope number
    sectionID: 'end-of-life-disposal', // Default Schema Scope Section Name
    identifier: 'waste-composting', // Main Section component identifier for tooltips
    version: 2.75,
    active: true,
    toggleName: 'enterBy',
    emissionSources: [
      {
        category:
          'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Waste Composting', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'waste-composting',
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'waste-composting',
        tCO2eVariableName: 'WasteCompostingKgCO2ePerKG',
        N2OVariableName: 'WasteCompostingKgN2OPerKG',
        CH4VariableName: 'WasteCompostingKgCH4PerKG'
      }
    ],
    units: [
      {
        months: [
          { name: 'January', measurement: 0 },
          { name: 'February', measurement: 0 },
          { name: 'March', measurement: 0 },
          { name: 'April', measurement: 0 },
          { name: 'May', measurement: 0 },
          { name: 'June', measurement: 0 },
          { name: 'July', measurement: 0 },
          { name: 'August', measurement: 0 },
          { name: 'September', measurement: 0 },
          { name: 'October', measurement: 0 },
          { name: 'November', measurement: 0 },
          { name: 'December', measurement: 0 }
        ],
        annualTotal: 0
      }
    ],
    variableName: 'WasteCompostingKgCO2ePerKG',
    annualGrandTotal: 0,
    totalCarbonEmissions: 0,
    annually: true,
    type: 'month'
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Disposal in NZ', // Title of Section on front end
    title: 'End of Life Disposal - Disposal in NZ',
    scope: 3, // scope number
    sectionID: 'end-of-life-disposal', // Default Schema Scope Section Name
    identifier: 'disposal-in-nz', // Main Section component identifier for tooltips
    version: 2.75,
    annually: true,
    active: true,
    emissionSources: [
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Food (NZ) KG', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Garden (NZ) KG', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Paper (NZ) KG', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Wood (NZ) KG', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Textil (NZ) KG', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    activities: [
      {
        name: 'Food (NZ) KG',
        unit: 'KG',
        variableName: 'FoodNZkgCO2e',
        variableNameCH4: 'FoodNZkgCH4',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'Garden (NZ) KG',
        unit: 'KG',
        variableName: 'GardenNZkgCO2e',
        variableNameCH4: 'GardenNZkgCH4',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'Paper (NZ) KG',
        unit: 'KG',
        variableName: 'PaperNZkgCO2e',
        variableNameCH4: 'PaperNZkgCH4',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'Wood (NZ) KG',
        unit: 'KG',
        variableName: 'WoodNZkgCO2e',
        variableNameCH4: 'WoodNZkgCH4',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'Textil (NZ) KG',
        unit: 'KG',
        variableName: 'TextilNZkgCO2e',
        variableNameCH4: 'TextilNZkgCH4',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Disposal Overseas', // Title of Section on front end
    title: 'End of Life Disposal - Disposal Overseas',
    scope: 3, // scope number
    sectionID: 'end-of-life-disposal', // Default Schema Scope Section Name
    identifier: 'disposal-overseas', // Main Section component identifier for tooltips
    version: 2.75,
    annually: true,
    active: false,
    emissionSources: [
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Wood (recycled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        // id ??
        unit: 'Tonnes',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Wood (landfilled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        // id ??
        unit: 'Tonnes',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Glass (recycled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        // id ??
        unit: 'Tonnes',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Glass (landfilled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        // id ??
        unit: 'Tonnes',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Plastic (recycled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        // id ??
        unit: 'Tonnes',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Plastic (landfilled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        // id ??
        unit: 'Tonnes',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Paper (recycled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        // id ??
        unit: 'Tonnes',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Paper (landfilled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        // id ??
        unit: 'Tonnes',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    activities: [
      {
        name: 'Wood (recycled overseas) - Tonnes',
        unit: 'Tonnes',
        variableName: 'WoodRecycledOverseaskgCO2e',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'Wood (landfilled overseas) - Tonnes',
        unit: 'Tonnes',
        variableName: 'WoodLandfilledOverseaskgCO2e',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'Glass (recycled overseas) - Tonnes',
        unit: 'Tonnes',
        variableName: 'GlassRecycledOverseaskgCO2e',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'Glass (landfilled overseas) - Tonnes',
        unit: 'Tonnes',
        variableName: 'GlassLandfilledOverseaskgCO2e',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'Plastic (recycled overseas) - Tonnes',
        unit: 'Tonnes',
        variableName: 'PlasticRecycledOverseaskgCO2e',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'Plastic (landfilled overseas) - Tonnes',
        unit: 'Tonnes',
        variableName: 'PlasticLandfilledOverseaskgCO2e',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'Paper (recycled overseas) - Tonnes',
        unit: 'Tonnes',
        variableName: 'PaperRecycledOverseaskgCO2e',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'Paper (landfilled overseas) - Tonnes',
        unit: 'Tonnes',
        variableName: 'PaperLandfilledOverseaskgCO2e',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      }
    ]
  }
]
export default endOfLifeDisposalSchema
