const outwardFreightSchema = [
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Sea Freight Sent', // Title of Section on front end
    title: 'Other Freight Received',
    scope: 3, // scope number
    sectionID: 'outward-freight', // Default Schema Scope Section Name
    identifier: 'sea-freight', // Main Section component identifier for tooltips
    version: 2.79,
    annually: true,
    emissionSources: [
      {
        category:
          'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Upstream Freight', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Outward Freight - Sea Freight', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Bulk Carrier 0–9999 dwt',
        unit: 'TKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'Bulk Carrier - Average WTT',
        variableName: 'bulkCarrierAverageKgCO2eWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'General Cargo - Average WTT',
        variableName: 'generalCargoAverageKgCO2eWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'Container Ship - Average WTT',
        variableName: 'containerShipAverageKgCO2eWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'Vehicle Transport - Average WTT',
        variableName: 'vehicleTransportAverageKgCO2eWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'RoRo-Ferry - Average WTT',
        variableName: 'roRoFerryAverageKgCO2eWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'Refrigerated Cargo - All dwt WTT',
        variableName: 'refrigeratedCargoAlldwtKgCO2eWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'Domestic Coastal Freight - Container Freight',
        variableName: 'domesticCoastalFreightKgCO2eWTT'
      }
    ],
    items: [
      {
        itemId: 1,
        origin: '',
        destination: '',
        vesselType: '',
        vesselSize: '',
        weightOfFreight: 0,
        distance: 0,
        freightDescription: '',
        eFactor: 0.0,
        carbonEmissions: 0.0
      }
    ],
    vesselSizes: [
      {
        type: 'Bulk Carrier',
        sizes: [
          {
            name: 'Average',
            tCO2e: 'bulkCarrierAverageKgCO2e',
            CO2: 'bulkCarrierAverageKgCO2',
            N2O: 'bulkCarrierAverageKgN2O',
            CH4: 'bulkCarrierAverageKgCH4'
          }
        ]
      },
      {
        type: 'General Cargo',
        sizes: [
          {
            name: 'Average',
            tCO2e: 'generalCargoAverageKgCO2e',
            CO2: 'generalCargoAverageKgCO2',
            N2O: 'generalCargoAverageKgN2O',
            CH4: 'generalCargoAverageKgCH4'
          }
        ]
      },
      {
        type: 'Container Ship',
        sizes: [
          {
            name: 'Average',
            tCO2e: 'containerShipAverageKgCO2e',
            CO2: 'containerShipAverageKgCO2',
            N2O: 'containerShipAverageKgN2O',
            CH4: 'containerShipAverageKgCH4'
          }
        ]
      },
      {
        type: 'Vehicle Transport',
        sizes: [
          {
            name: 'Average',
            tCO2e: 'vehicleTransportAverageKgCO2e',
            CO2: 'vehicleTransportAverageKgCO2',
            N2O: 'vehicleTransportAverageKgN2O',
            CH4: 'vehicleTransportAverageKgCH4'
          }
        ]
      },
      {
        type: 'RoRo-Ferry',
        sizes: [
          {
            name: 'Average',
            tCO2e: 'roRoFerryAverageKgCO2e',
            CO2: 'roRoFerryAverageKgCO2',
            N2O: 'roRoFerryAverageKgN2O',
            CH4: 'roRoFerryAverageKgCH4'
          }
        ]
      },
      {
        type: 'Refrigerated Cargo',
        sizes: [
          {
            name: 'All dwt',
            tCO2e: 'refrigeratedCargoAlldwtKgCO2e',
            CO2: 'refrigeratedCargoAlldwtKgCO2',
            N2O: 'refrigeratedCargoAlldwtKgN2O',
            CH4: 'refrigeratedCargoAlldwtKgCH4'
          }
        ]
      },
      {
        type: 'Domestic Coastal Freight',
        sizes: [
          {
            name: 'Container Freight',
            tCO2e: 'domesticCoastalFreightKgCO2e',
            CO2: 'domesticCoastalFreightKgCO2',
            N2O: 'domesticCoastalFreightKgN2O',
            CH4: 'domesticCoastalFreightKgCH4'
          }
        ]
      }
    ],
    totalInputs: [
      {
        name: 'Bulk Carrier - Average',
        tCO2e: 'bulkCarrierAverageKgCO2e',
        CO2: 'bulkCarrierAverageKgCO2',
        N2O: 'bulkCarrierAverageKgN2O',
        CH4: 'bulkCarrierAverageKgCH4',
        unit: 'TKM',
        annualTotal: 0
      },
      {
        name: 'General Cargo - Average',
        tCO2e: 'generalCargoAverageKgCO2e',
        CO2: 'generalCargoAverageKgCO2',
        N2O: 'generalCargoAverageKgN2O',
        CH4: 'generalCargoAverageKgCH4',
        unit: 'TKM',
        annualTotal: 0
      },
      {
        name: 'Container Ship - Average',
        tCO2e: 'containerShipAverageKgCO2e',
        CO2: 'containerShipAverageKgCO2',
        N2O: 'containerShipAverageKgN2O',
        CH4: 'containerShipAverageKgCH4',
        unit: 'TKM',
        annualTotal: 0
      },
      {
        name: 'Vehicle Transport - Average',
        tCO2e: 'vehicleTransportAverageKgCO2e',
        CO2: 'vehicleTransportAverageKgCO2',
        N2O: 'vehicleTransportAverageKgN2O',
        CH4: 'vehicleTransportAverageKgCH4',
        unit: 'TKM',
        annualTotal: 0
      },
      {
        name: 'RoRo-Ferry - Average',
        tCO2e: 'roRoFerryAverageKgCO2e',
        CO2: 'roRoFerryAverageKgCO2',
        N2O: 'roRoFerryAverageKgN2O',
        CH4: 'roRoFerryAverageKgCH4',
        unit: 'TKM',
        annualTotal: 0
      },
      {
        name: 'Refrigerated Cargo - All dwt',
        tCO2e: 'refrigeratedCargoAlldwtKgCO2e',
        CO2: 'refrigeratedCargoAlldwtKgCO2',
        N2O: 'refrigeratedCargoAlldwtKgN2O',
        CH4: 'refrigeratedCargoAlldwtKgCH4',
        unit: 'TKM',
        annualTotal: 0
      },
      {
        name: 'Domestic Coastal Freight - Average',
        tCO2e: 'domesticCoastalFreightKgCO2e',
        CO2: 'domesticCoastalFreightKgCO2',
        N2O: 'domesticCoastalFreightKgN2O',
        CH4: 'domesticCoastalFreightKgCH4',
        unit: 'TKM',
        annualTotal: 0
      }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Air Freight Sent', // Title of Section on front end
    title: 'Air Freight Sent',
    scope: 3, // scope number
    sectionID: 'outward-freight', // Default Schema Scope Section Name
    identifier: 'air-freight', // Main Section component identifier for tooltips
    version: 2.75,
    annually: true,
    emissionSources: [
      {
        category:
          'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Upstream Freight', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Outward Freight Air Freight -', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Domestic',
        unit: 'TKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'Domestic WTT',
        variableName: 'AirFreightDomesticWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'Short Haul (<3,700 km) WTT',
        variableName: 'AirFreightShortHaulWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'Long Haul (>3,700 km) WTT',
        variableName: 'AirFreightLongHaulWTT' // Main getWellToTankEmissions() variable Name
      }
    ],
    items: [
      {
        itemId: 1,
        origin: '',
        destination: '',
        type: '',
        weight: 0,
        distance: 0,
        tCO2e: 0
      }
    ],
    types: [
      {
        name: 'Domestic',
        tCO2e: 'AirFreightDomesticKgCO2e',
        CO2: 'AirFreightDomesticKgCO2',
        N2O: 'AirFreightDomesticKgN2O',
        CH4: 'AirFreightDomesticKgCH4'
      },
      {
        name: 'Short Haul (<3,700 km)',
        tCO2e: 'AirFreightShortHaulKgCO2e',
        CO2: 'AirFreightShortHaulKgCO2',
        N2O: 'AirFreightShortHaulKgN2O',
        CH4: 'AirFreightShortHaulKgCH4'
      },
      {
        name: 'Long Haul (>3,700 km)',
        tCO2e: 'AirFreightLongHaulKgCO2e',
        CO2: 'AirFreightLongHaulKgCO2',
        N2O: 'AirFreightLongHaulKgN2O',
        CH4: 'AirFreightLongHaulKgCH4'
      }
    ],
    totalInputs: [
      {
        name: 'Domestic',
        tCO2e: 'AirFreightDomesticKgCO2e',
        CO2: 'AirFreightDomesticKgCO2',
        N2O: 'AirFreightDomesticKgN2O',
        CH4: 'AirFreightDomesticKgCH4',
        unit: 'TKM',
        annualTotal: 0
      },
      {
        name: 'Short Haul (<3,700 km)',
        tCO2e: 'AirFreightShortHaulKgCO2e',
        CO2: 'AirFreightShortHaulKgCO2',
        N2O: 'AirFreightShortHaulKgN2O',
        CH4: 'AirFreightShortHaulKgCH4',
        unit: 'TKM',
        annualTotal: 0
      },
      {
        name: 'Long Haul (>3,700 km)',
        tCO2e: 'AirFreightLongHaulKgCO2e',
        CO2: 'AirFreightLongHaulKgCO2',
        N2O: 'AirFreightLongHaulKgN2O',
        CH4: 'AirFreightLongHaulKgCH4',
        unit: 'TKM',
        annualTotal: 0
      }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Other Freight Sent', // Title of Section on front end
    title: 'Other Freight Sent',
    scope: 3, // scope number
    sectionID: 'outward-freight', // Default Schema Scope Section Name
    identifier: 'road-freight', // Main Section component identifier for tooltips
    version: 2.75,
    annually: true,
    emissionSources: [
      {
        category:
          'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Upstream Freight', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Outward Freight Other Freight -', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'Domestic',
        unit: 'TKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'Truck WTT',
        variableName: 'AllTruckFreightWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'Courier Van WTT',
        variableName: 'vanFreightTKMWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'Rail WTT',
        variableName: 'railFreightTKMWTT' // Main getWellToTankEmissions() variable Name
      }
    ],
    items: [
      {
        itemId: 1,
        origin: '',
        destination: '',
        type: '',
        weight: 0,
        distance: 0,
        tCO2e: 0
      }
    ],
    types: [
      {
        name: 'Truck',
        tCO2e: 'AllTruckFreightKgCO2e',
        CO2: 'AllTruckFreightKgCO2',
        N2O: 'AllTruckFreightKgN2O',
        CH4: 'AllTruckFreightKgCH4'
      },
      {
        name: 'Courier Van',
        tCO2e: 'VanFreightKgCO2e',
        CO2: 'VanFreightKgCO2',
        N2O: 'VanFreightKgN2O',
        CH4: 'VanFreightKgCH4'
      },
      {
        name: 'Rail',
        tCO2e: 'RailFreightKgCO2e',
        CO2: 'RailFreightKgCO2',
        N2O: 'RailFreightKgN2O',
        CH4: 'RailFreightKgCH4'
      }
    ],
    totalInputs: [
      {
        name: 'Truck',
        tCO2e: 'AllTruckFreightKgCO2e',
        CO2: 'AllTruckFreightKgCO2',
        N2O: 'AllTruckFreightKgN2O',
        CH4: 'AllTruckFreightKgCH4',
        unit: 'TKM',
        annualTotal: 0
      },
      {
        name: 'Courier Van',
        tCO2e: 'VanFreightKgCO2e',
        CO2: 'VanFreightKgCO2',
        N2O: 'VanFreightKgN2O',
        CH4: 'VanFreightKgCH4',
        unit: 'TKM',
        annualTotal: 0
      },
      {
        name: 'Rail',
        tCO2e: 'RailFreightKgCO2e',
        CO2: 'RailFreightKgCO2',
        N2O: 'RailFreightKgN2O',
        CH4: 'RailFreightKgCH4',
        unit: 'TKM',
        annualTotal: 0
      }
    ]
  }
]
export default outwardFreightSchema
