<template>
  <div class="carbon-credits">
    <h1 class="section-title activity-title">
      Base Year Inventory
    </h1>
    <div class="input-section">
      <div class="input-container-row">
        <label>Base Year:</label>
        <!-- updateClient('baseYearID') -->
        <select
          v-model="$store.state.app.client.baseYearID"
          v-on:change="updateClient('baseYearID')"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        >
          <option value="0">Please select</option>
          <option
            v-for="year in availableBaseYears"
            :key="year.id"
            :value="year.id"
            >{{
              $store.state.app.selectedYear === year.year
                ? "Current Inventory"
                : year.year
            }}</option
          >
        </select>
      </div>
      <div class="input-container-row">
        <label>Start Month:</label>
        <!-- updateClient('baseYearID') -->
        <select
          v-model="$store.state.app.client.baseYearReportingMonthStart"
          v-on:change="updateClient('baseYearReportingMonthStart')"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        >
          <option
            v-for="(month, index) in months"
            :key="index"
            :value="month"
            >{{ month }}</option
          >
        </select>
      </div>
      <div v-if="$store.state.app.client.baseYearReportingMonthStart !== $store.state.app.client.reportingMonthStart" class="input-container-row">
        <label>Description:</label>
        <textarea
          rows="4"
          cols="75"
          v-model="$store.state.app.client.baseYearDescription"
          v-on:change="updateClient('baseYearDescription')"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { apolloClient } from '../../vue-apollo.js'

export default {
  props: {
    baseYear: [Number, String]
  },
  data () {
    return {
      schema: {
        baseYear: ''
      },
      availableBaseYears: [],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    }
  },
  async beforeMount () {
    this.schema.baseYear = this.baseYear ? this.baseYear : ''
    this.availableBaseYears = await this.getAvailableBaseYears()
  },
  methods: {
    async updateClient (field) {
      const data = {
        client: { id: this.$store.state.app.client.id }
      }
      data.client[field] = this.$store.state.app.client[field]
      this.$root.saving = true
      await this.$store.dispatch('updateClient', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    async getAvailableBaseYears () {
      let baseYears = []
      const { data } = await apolloClient.query({
        query: gql`
          query(
            $filter: PeriodFilterFields!
            $sort: PeriodSortFields!
          ) {
            readPeriods(filter: $filter, sort: $sort) {
              id
              status
              year
            }
          }
        `,
        variables: {
          filter: {
            clientID: {
              eq: parseInt(this.$store.state.user.clientID)
            },
            status: { ne: 'Inactive' }
            // year: { ne: this.$store.state.app.selectedYear }
          },
          sort: {
            year: 'ASC'
          }
        },
        fetchPolicy: 'no-cache'
      })
      if (data) {
        baseYears = data.readPeriods
      }
      return baseYears
    }
  }
}
</script>

<style></style>
