
import gql from 'graphql-tag'

export default {
  CREATE_PERIOD: gql`
    mutation createPeriod ($input: CreatePeriodInput!) {
      createPeriod(input: $input) {
        id
        year
        status
        month
        emissionsSources {
          nodes {
            ID
            ESID
            name
            type
            rule
            tCO2e
            tCO2eMarketRate
            CO2
            CH4
            N2O
            PFC
            HFC
            SF6
            annualTotal
            unit
            category
            subcategory
            GHGScope
            GHGSubcategory
            ISOCategory
          }
        }
      }
    }
  `,
  READ_ONE_CLIENT_PERIOD_QUERY: gql`
    query readOnePeriod ($clientID: ID!, $year: String!, $status: StatusEnum!) {
      readOnePeriod (filter: { clientID: { eq: $clientID }, year: { eq: $year }, status: { ne: $status }}){
        id
        status
        masterSchema
      }
    }
  `,
  READ_ONE_PERIOD_QUERY: gql`
    query readOnePeriod ($organisationID: ID!, $year: String!, $month: String, $status: StatusEnum!) {
      readOnePeriod (filter: { organisationID: { eq: $organisationID }, year: { eq: $year }, month: { eq: $month }, status: { ne: $status }}){
        id
        status
        masterSchema
      }
    }
  `,
  UPDATE_PERIOD_MUTATION: gql`
    mutation updatePeriod ($input: UpdatePeriodInput!) {
      updatePeriod(input: $input) {
        status
      }
    }
  `,
  SET_ACTIVE_PERIOD: gql`
    mutation ($organisationID: ID!, $year: String!, $month: String!) {
      setActivePeriod (organisationID: $organisationID, year: $year, month: $month) {
        id
      }
    }
  `,
  SAVE_PERIOD: gql`
    mutation ($organisationID: ID!, $year: String!, $month: String!, $masterSchema: String!) {
      savePeriod (organisationID: $organisationID, year: $year, month: $month, masterSchema: $masterSchema) {
        id
        status
        masterSchema
      }
    }
  `,
  UPDATE_PERIOD_STATUSES: gql`
  mutation ($clientID: ID!, $year: String!,  $status: StatusEnum!) {
    updatePeriodStatuses (clientID: $clientID, year: $year, status: $status) {
      status
    }
  }
  `,
  READ_EMISSIONS_SOURCES: gql`
  query readEmissionsSources($periodID: ID!, $name: String!) {
    readEmissionsSources(filter: {periodID: {eq: $periodID} name: {contains: $name} }, limit: 15) {
      nodes {
        id
        name
        tCO2e
        tCO2eMarketRate
        GHGScope
        subcategory
      }
    }
  }
`,
  READ_PERIODS: gql`
  query readPeriods ($clientID: ID!, $status: StatusEnum!){
  readPeriods (filter: {clientID: {eq: $clientID}, status: {eq: $status}}, sort: {year: ASC}) {
    id
    year
    status
    month
    emissionsSources {
      nodes {
        ID
        ESID
        name
        type
        rule
        tCO2e
        tCO2eMarketRate
        CO2
        CH4
        N2O
        PFC
        HFC
        SF6
        annualTotal
        unit
        category
        subcategory
        GHGScope
        GHGSubcategory
        ISOCategory
      }
    }
  }
}`

}
