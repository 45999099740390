const businessTravelSchema = [
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Business Travel Vehicle Mileage', // Title of Section on front end
    title: 'Business Travel Vehicle Mileage', // Title of Section on front end
    scope: 3, // scope number
    sectionID: 'business-travel', // Default Schema Scope Section Name
    identifier: 'business-travel-mileage', // Main Section component identifier for tooltips
    version: 2.77,
    active: true,
    toggleName: 'enterByVehicles',
    emissionSources: [
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Petrol', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'petrol',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Diesel', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'diesel',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Petrol WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'petrol-wtt',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Diesel WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'diesel-wtt',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Petrol Hybrid', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'hybrid',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Electric Vehicle', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'ev',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - PHEV', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'phev',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Petrol Hybrid WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'hybrid-wtt',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Electric Vehicle WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'ev-wtt',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - PHEV WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'phev-wtt',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'petrol',
        tCO2eVariableName: 'privateCarDefaultPetrolKgCO2e',
        CO2VariableName: 'privateCarDefaultPetrolKgCO2',
        N2OVariableName: 'privateCarDefaultPetrolKgN2O',
        CH4VariableName: 'privateCarDefaultPetrolKgCH4'
      },
      {
        type: 'diesel',
        tCO2eVariableName: 'privateCarDefaultDieselKgCO2e',
        CO2VariableName: 'privateCarDefaultDieselKgCO2',
        N2OVariableName: 'privateCarDefaultDieselKgN2O',
        CH4VariableName: 'privateCarDefaultDieselKgCH4'
      },
      {
        type: 'petrol-wtt',
        tCO2eVariableName: 'petrolKmWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'diesel-wtt',
        tCO2eVariableName: 'dieselKmWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'hybrid',
        tCO2eVariableName: 'petrolHybridKmDefaultKgCO2e',
        CO2VariableName: 'petrolHybridKmDefaultKgCO2',
        N2OVariableName: 'petrolHybridKmDefaultKgN2O',
        CH4VariableName: 'petrolHybridKmDefaultKgCH4'
      },
      {
        type: 'ev',
        tCO2eVariableName: 'electricVehicleKmDefaultKgCO2e',
        CO2VariableName: 'electricVehicleKmDefaultKgCO2',
        N2OVariableName: 'electricVehicleKmDefaultKgN2O',
        CH4VariableName: 'electricVehicleKmDefaultKgCH4'
      },
      {
        type: 'phev',
        tCO2eVariableName: 'businessTravelPetrolPlugInHybridKmDefaultKgCO2e',
        CO2VariableName: 'businessTravelPetrolPlugInHybridKmDefaultKgCO2',
        N2OVariableName: 'businessTravelPetrolPlugInHybridKmDefaultKgN2O',
        CH4VariableName: 'businessTravelPetrolPlugInHybridKmDefaultKgCH4'
      },
      {
        type: 'hybrid-wtt',
        tCO2eVariableName: 'petrolHybridKmDefaultKgCO2eWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'ev-wtt',
        tCO2eVariableName: 'electricVehicleKmDefaultKgCO2eWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'phev-wtt',
        tCO2eVariableName: 'businessTravelPetrolPlugInHybridKmDefaultKgCO2eWTT' // Main getWellToTankEmissions() variable Name
      }
    ],
    months: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    dieselMonths: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    hybridMonths: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    evMonths: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    phevMonths: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    variableName: 'privateCarDefaultPetrolKgCO2e',
    variableNameDiesel: 'privateCarDefaultDieselKgCO2e',
    annually: true
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Business Travel Vehicle Fuels', // Title of Section on front end
    title: 'Business Travel Vehicle Fuels', // Title of Section on front end
    scope: 3, // scope number
    sectionID: 'business-travel', // Default Schema Scope Section Name
    identifier: 'business-travel-fuels', // Main Section component identifier for tooltips
    version: 2.76,
    active: false,
    toggleName: 'enterByVehicles',
    emissionSources: [
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Petrol', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'petrol',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Diesel', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'diesel',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Petrol WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'petrol-wtt',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Diesel WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'diesel-wtt',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'petrol',
        tCO2eVariableName: 'kgCO2ePetrol',
        CO2VariableName: 'kgCO2PerUnitPetrol',
        N2OVariableName: 'kgN2OPerUnitPetrol',
        CH4VariableName: 'kgCH4PerUnitPetrol'
      },
      {
        type: 'diesel',
        tCO2eVariableName: 'kgCO2eDieselCompanyVehicles',
        CO2VariableName: 'kgCO2PerUnitDieselCompanyVehicles',
        N2OVariableName: 'kgn2OPerUnitDieselCompanyVehicles',
        CH4VariableName: 'kgCH4PerUnitDieselCompanyVehicles'
      },
      {
        type: 'petrol-wtt',
        tCO2eVariableName: 'petrolWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'diesel-wtt',
        tCO2eVariableName: 'dieselWTT' // Main getWellToTankEmissions() variable Name
      }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Business Travel Taxi Money', // Title of Section on front end
    title: 'Business Travel Taxi Money', // Title of Section on front end
    scope: 3, // scope number
    sectionID: 'business-travel', // Default Schema Scope Section Name
    identifier: 'business-travel-taxi-money', // Main Section component identifier for tooltips
    version: 2.77,
    active: true,
    toggleName: 'enterByTaxis',
    emissionSources: [
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Taxi', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'cost',
        unit: '$',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Taxi WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'cost-wtt',
        unit: '$',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'cost',
        tCO2eVariableName: 'kgCO2ePerDollarTaxi',
        CO2VariableName: 'kgCO2PerDollarTaxi',
        N2OVariableName: 'kgN2OPerDollarTaxi',
        CH4VariableName: 'kgCH4PerDollarTaxi'
      },
      {
        type: 'cost-wtt',
        tCO2eVariableName: 'TaxiDistanceWTT'
      }
    ],
    months: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    variableName: 'kgCO2ePerDollarTaxi',
    annually: true
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Business Travel Taxi Distance', // Title of Section on front end
    title: 'Business Travel Taxi Distance', // Title of Section on front end
    scope: 3, // scope number
    sectionID: 'business-travel', // Default Schema Scope Section Name
    identifier: 'business-travel-taxi-mileage', // Main Section component identifier for tooltips
    version: 2.76,
    active: false,
    toggleName: 'enterByTaxis',
    emissionSources: [
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Taxi', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'distance',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Taxi WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'distance-wtt',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'distance',
        tCO2eVariableName: 'kgCO2ePerKmDistanceTraveled',
        CO2VariableName: 'kgCO2PerDistanceKmTaxi',
        N2OVariableName: 'kgN2OPerDistanceKmTaxi',
        CH4VariableName: 'kgCH4PerDistanceKmTaxi'
      },
      {
        type: 'distance-wtt',
        tCO2eVariableName: 'TaxiDistanceWTT'
      }
    ],
    flights: [
      {
        from: '',
        to: '',
        kms: 0,
        carbonEmissions: 0
      }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Business Travel Rental Cars', // Title of Section on front end
    title: 'Business Travel Rental Cars', // Title of Section on front end
    scope: 3, // scope number
    sectionID: 'business-travel', // Default Schema Scope Section Name
    identifier: 'business-travel-rental', // Main Section component identifier for tooltips
    version: 2.76,
    emissionSources: [
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Rental cars', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'distance',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Rental Cars WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'distance-wtt',
        unit: 'KM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'distance',
        tCO2eVariableName: 'kgCO2ePerKmRentalCars',
        CO2VariableName: 'kgCO2PerKmRentalCars',
        N2OVariableName: 'kgN2OPerKmRentalCars',
        CH4VariableName: 'kgCH4PerKmRentalCars'
      },
      {
        type: 'distance-wtt',
        tCO2eVariableName: 'RentalCarWTT'
      }
    ],
    months: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    variableName: 'kgCO2ePerKmRentalCars', // kgCO2PerKmRentalCars, kgCH4PerKmRentalCars, kgN2OPerKmRentalCars
    annually: true
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Business Travel Public Transport', // Title of Section on front end
    title: 'Business Travel Public Transport', // Title of Section on front end
    scope: 3, // scope number
    sectionID: 'business-travel', // Default Schema Scope Section Name
    identifier: 'business-travel-public-transport', // Main Section component identifier for tooltips
    version: 1.00,
    emissionSources: [
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Bus', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'bus',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Bus WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'bus-wtt',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Rail', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'rail',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Travel - Rail WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'rail-wtt',
        unit: 'PKM',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'bus',
        tCO2eVariableName: 'busNationalAveragePkmKgCO2e',
        CO2VariableName: 'busNationalAveragePkmKgCO2',
        N2OVariableName: 'busNationalAveragePkmKgN2O',
        CH4VariableName: 'busNationalAveragePkmKgCH4'
      },
      {
        type: 'bus-wtt',
        tCO2eVariableName: 'busNationalAveragePkmKgCO2eWTT'
      },
      {
        type: 'rail',
        tCO2eVariableName: 'metropolitanAveragePkmKgCO2e',
        CO2VariableName: 'metropolitanAveragePkmKgCO2',
        N2OVariableName: 'metropolitanAveragePkmKgN2O',
        CH4VariableName: 'metropolitanAveragePkmKgCH4'
      },
      {
        type: 'rail-wtt',
        tCO2eVariableName: 'metropolitanAveragePkmKgCO2eWTT'
      }
    ],
    months: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    railMonths: [
      { name: 'January', amount: 0 },
      { name: 'February', amount: 0 },
      { name: 'March', amount: 0 },
      { name: 'April', amount: 0 },
      { name: 'May', amount: 0 },
      { name: 'June', amount: 0 },
      { name: 'July', amount: 0 },
      { name: 'August', amount: 0 },
      { name: 'September', amount: 0 },
      { name: 'October', amount: 0 },
      { name: 'November', amount: 0 },
      { name: 'December', amount: 0 }
    ],
    variableName: 'kgCO2ePerKmRentalCars', // kgCO2PerKmRentalCars, kgCH4PerKmRentalCars, kgN2OPerKmRentalCars
    annually: true
  }
]
export default businessTravelSchema
