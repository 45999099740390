const purchasedGoodsAndServicesSchema = [
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Purchased Goods and Services', // Title of Section on front end
    title: 'Purchased Goods and Services',
    scope: 3, // scope number
    sectionID: 'purchased', // Default Schema Scope Section Name
    identifier: 'purchased-goods', // Main Section component identifier for tooltips
    version: 2.75,
    annually: true,
    emissionSources: [
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Purchased Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Paper & Board: Mixed', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Purchased Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Paper & Board: Paper', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        unit: 'Ream',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Purchased Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Plastics: Average Plastics', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        unit: 'T',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Purchased Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Glass', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        unit: 'T',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Purchased Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Wood', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        unit: 'T',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Purchased Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'IT Services & Data Storage', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        unit: 'GB',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    activities: [
      {
        name: 'Paper & Board: Mixed',
        unit: 'KG',
        variableName: 'paperAndBoardMixedKgCO2e', // reamsOfPaperKgCO2e
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'Paper & Board: Paper',
        unit: 'Ream',
        variableName: 'paperAndBoardPaperKgCO2e',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'Plastics: Average Plastics',
        unit: 'Tonnes',
        variableName: 'averagePlasticsKgCO2e',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'Glass',
        unit: 'Tonnes',
        variableName: 'glassKgCO2e',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'Wood',
        unit: 'Tonnes',
        variableName: 'woodKgCO2e',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      },
      {
        name: 'IT Services & Data Storage',
        unit: 'Gigabytes of Data Used',
        variableName: 'ITservicesDataStorageKgCO2e',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Potable Water', // Title of Section on front end
    title: 'Potable Water',
    scope: 3, // scope number
    sectionID: 'purchased', // Default Schema Scope Section Name
    identifier: 'portable-water', // Main Section component identifier for tooltips
    version: 2.75,
    annually: true,
    emissionSources: [
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Purchased Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Purchased Goods - Water Supply', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'waste-water',
        unit: 'M3',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'waste-water',
        tCO2eVariableName: 'potableWaterKgCO2e',
        CO2VariableName: 'potableWaterKgCO2',
        N2OVariableName: 'potableWaterKgN2O',
        CH4VariableName: 'potableWaterKgCH4'
      }
    ],
    units: [
      {
        months: [
          { name: 'January', measurement: 0 },
          { name: 'February', measurement: 0 },
          { name: 'March', measurement: 0 },
          { name: 'April', measurement: 0 },
          { name: 'May', measurement: 0 },
          { name: 'June', measurement: 0 },
          { name: 'July', measurement: 0 },
          { name: 'August', measurement: 0 },
          { name: 'September', measurement: 0 },
          { name: 'October', measurement: 0 },
          { name: 'November', measurement: 0 },
          { name: 'December', measurement: 0 }
        ],
        annualTotal: 0
      }
    ],
    variableName: 'potableWaterKgCO2e',
    annualGrandTotal: 0,
    totalCarbonEmissions: 0,
    type: 'month'
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Contracted Services and Providers', // Title of Section on front end
    title: 'Contracted Services and Providers',
    scope: 3, // scope number
    sectionID: 'purchased', // Default Schema Scope Section Name
    identifier: 'contracted-services', // Main Section component identifier for tooltips
    version: 2.77,
    annually: true,
    emissionSources: [
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Purchased Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Purchased Goods - Other', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        unit: 'tCO2e',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: '',
          assumptions: ''
        }
      }
    ],
    contractors: [
      {
        values: {
          name: 'Contractor 1',
          electricity: 0,
          naturalGas: 0,
          diesel: 0,
          petrol: 0
        },
        notes: {
          electricity: '',
          naturalGas: '',
          diesel: '',
          petrol: ''
        }
      }
    ],
    variables: {
      electricity: {
        tCO2e: 'electricityNewZealandKgCO2e',
        CO2: 'electricityNewZealandKgCO2',
        N2O: 'electricityNewZealandKgN2O',
        CH4: 'electricityNewZealandKgCH4',
        unit: 'kWh',
        label: 'Electricity'
      },
      naturalGas: {
        tCO2e: 'kgCO2eNaturalGas',
        N2O: 'kgN2OPerUnitNaturalGas',
        CH4: 'kgCH4PerUnitNaturalGas',
        CO2: 'kgCO2PerUnitNaturalGas',
        unit: 'kWh',
        label: 'Natural Gas'
      },
      diesel: {
        tCO2e: 'kgCO2eDieselCompanyVehicles',
        N2O: 'kgn2OPerUnitDieselCompanyVehicles',
        CH4: 'kgCH4PerUnitDieselCompanyVehicles',
        CO2: 'kgCO2PerUnitDieselCompanyVehicles',
        unit: 'L',
        label: 'Diesel'
      },
      petrol: {
        tCO2e: 'kgCO2ePetrol',
        N2O: 'kgN2OPerUnitPetrol',
        CH4: 'kgCH4PerUnitPetrol',
        CO2: 'kgCO2PerUnitPetrol',
        unit: 'L',
        label: 'Petrol'
      }
    }
  }
]
export default purchasedGoodsAndServicesSchema
