<template>
  <div class="input-section">
    <h1 class="section-title">Fertiliser</h1>
    <h2 class="secondary-title">Amount of fertiliser (kg)</h2>
    <div v-for="(cat, i) in schema.agricultureCategories" :key="`fert-${i}`">
      <div class="input-container-column">
        <div
          class="input-item long"
          v-for="index in 5"
          :key="`fert-input-${index}`"
        >
          <label>
            {{ cat.emissionSources[index - 1].type }}
          </label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            type="number"
            name="numberOfUnits"
            v-model.number="
              schema.agricultureCategories[i].emissionSources[index - 1].numberOfUnits
            "
            v-on:change="() => {
              schema.agricultureCategories[i].emissionSources[index - 1].numberOfUnits = schema.agricultureCategories[i].emissionSources[index - 1].numberOfUnits || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'

export default {
  components: {
  },
  name: 'agriculture-component',
  props: { initialSchema: Object, schemaName: String, activityID: String },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },

  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },

  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions () {
      var schema = JSON.stringify({ schema: this.schema })
      var self = this
      var config = {
        method: 'post',
        url:
          '' + process.env.VUE_APP_APIURL + 'api/calculateScopeOneAgriculture',
        headers: {
          'Content-Type': 'application/json'
        },
        data: schema
      }
      return axios(config)
        .then(function (response) {
          self.schema = response.data.result
          self.$emit('calculateTotal', response.data.result, self.schemaName)
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          self.$root.saveData()
        })
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.$options.name}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
