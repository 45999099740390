<template>
  <div class="reports">
  </div>
</template>

<script>
import Chartist from 'chartist'
import legend from 'chartist-plugin-legend'

export default {
  data () {
    return {}
  },
  name: 'emissions-by-activity-graph',
  props: { emissionsByScope: Object },
  mounted () {
    this.emissionsByActivityGraph()
  },
  methods: {
    emissionsByActivityGraph () {
      var data = {
        series: this.activitySeries
      }
      var sum = function (a, b) {
        return a + b
      }

      var options = {
        labelInterpolationFnc: function (value) {
          let percentage = Math.round((value / data.series.reduce(sum)) * 100)
          return percentage > 1 ? percentage + '%' : ''
        },
        width: '400px',
        height: '400px',
        plugins: [
          legend({
            legendNames: this.activityNames,
            clickable: false
          })
        ],
        donut: true
      }

      return Chartist.Pie('.emissions-by-activity-graph', data, options)
    }
  },
  computed: {
    activitySeries () {
      var series = []
      this.emissionsByScope.activities.forEach(activity => {
        series.push(activity.tCO2e)
      })
      return series
    },
    activityNames () {
      var names = []
      this.emissionsByScope.activities.forEach(activity => {
        names.push(activity.name)
      })
      return names
    }
  }
}
</script>

<style></style>
