<template>
  <div class="ct-chart"></div>
</template>

<script>
import Chartist from 'chartist'
import legend from 'chartist-plugin-legend'
import emissionsMixin from '../../mixins/emissionsMixin.vue'

export default {
  name: 'BarChartComponent',
  mixins: [emissionsMixin],
  props: {
    completedAndCurrentInventories: Array
  },
  mounted () {
    new Chartist.Bar('.reduction-performance-graph', {
      labels: this.labelNames,
      series: this.series
    }, {
      stackBars: true,
      axisY: {
        labelInterpolationFnc: function (value) {
          return value
        }
      },
      plugins: [
        legend({
          legendNames: this.legendNames,
          clickable: false,
          position: 'bottom',
          horizontal: true
        })
      ],
      width: '21cm',
      height: '400px',
      donut: true
    }).on('draw', function (data) {
      if (data.type === 'bar') {
        data.element.attr({
          style: 'stroke-width: 30px'
        })
      }
    })
  },
  computed: {
    totalsPerSeries () {
      const bars = this.series[0].length
      let totals = []
      for (let i = 0; i < bars; i++) {
        totals.push(this.series.reduce((acc, series) => acc + series[i], 0))
      }
      return totals
    },
    series () {
      let series = []
      if (!this.completedAndCurrentInventories.length) {
        return series
      }

      // Extract all unique activity names
      const allActivityNames = [...new Set(this.inventoryActivities.flatMap(year => year.activities.map(activity => activity.name)))]

      // Initialize arrays to hold the tCO2eMarketRate values for each activity
      let activityArrays = allActivityNames.map(activityName => {
        return this.inventoryActivities.map(year => {
          const activity = year.activities.find(act => act.name === activityName)
          return activity ? activity.tCO2eMarketRate : 0
        })
      })
      return activityArrays
    },
    inventoryActivities () {
      // for completed inventories emissions sources gather emissions By Activitiy
      let inventoryActivities = []
      this.completedAndCurrentInventories.forEach(inventory => {
        const filteredEmissionsSources = inventory.emissionsSources.nodes.filter(source => source.rule !== 'hide-in-report' && source.rule !== 'hide')
        inventoryActivities.push({ activities: this.getEmissionsByActivity(filteredEmissionsSources), year: inventory.year })
      })
      return inventoryActivities
    },
    labelNames () {
      return [...this.completedAndCurrentInventories].map(inventory => inventory.year)
    },
    legendNames () {
      return [...new Set(this.inventoryActivities.flatMap(year => year.activities.map(activity => activity.name)))]
    }
  }
}
</script>

<style scoped>
.ct-legend {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
