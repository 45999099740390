<template>
  <div v-if="$store.state.user.groups.includes('administrators')">
    <LoadClient />

  </div>
</template>

<script>
export default {
  name: 'Admin',
  components: {
    LoadClient: () => import('./LoadClient.vue')
  }
}
</script>

<style>
</style>
