<template>
  <div class="input-section">
    <h1 class="section-title activity-title">Prepared / Reviewed By</h1>
    <div class="input-container" style="max-width: 600px">
      <div class="input-item">
        <label>Prepared By:</label>
        <input
          type="text"
          placeholder="Enter name"
          v-model.lazy.trim="$store.state.app.report.preparedBy"
          v-on:change="updateReportData('preparedBy')"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
      </div>
      <div class="input-item">
        <label>Reviewed By:</label>
        <input
          type="text"
          placeholder="Enter name"
          v-model.lazy.trim="$store.state.app.report.reviewedBy"
          v-on:change="updateReportData('reviewedBy')"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    updateReportData (fieldName) {
      this.$emit('updateReportData', fieldName)
    }
  }
}
</script>
