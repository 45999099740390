const staffCommuteEmissionSources = [
  {
    category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Staff Commuting - Rail', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'default', // default, naturalGasTDL, electricityTDL, WTT
    id: 'rail',
    unit: 'KM',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  },
  {
    category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Staff Commuting - Rail WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
    id: 'rail-wtt',
    unit: 'KM',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  }
]

const businessTravelEmissionSources = [
  {
    category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Business Travel - Rail', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'default', // default, naturalGasTDL, electricityTDL, WTT
    id: 'rail',
    unit: 'KM',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  },
  {
    category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Business Travel - Rail WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
    id: 'rail-wtt',
    unit: 'KM',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  }
]

const extraCalculations = [
  {
    type: 'rail',
    tCO2eVariableName: 'metropolitanAveragePkmKgCO2e',
    CO2VariableName: 'metropolitanAveragePkmKgCO2',
    N2OVariableName: 'metropolitanAveragePkmKgN2O',
    CH4VariableName: 'metropolitanAveragePkmKgCH4'
  },
  {
    type: 'rail-wtt',
    tCO2eVariableName: 'metropolitanAveragePkmKgCO2eWTT'
  }
]

const railMonths = [
  { name: 'January', amount: 0 },
  { name: 'February', amount: 0 },
  { name: 'March', amount: 0 },
  { name: 'April', amount: 0 },
  { name: 'May', amount: 0 },
  { name: 'June', amount: 0 },
  { name: 'July', amount: 0 },
  { name: 'August', amount: 0 },
  { name: 'September', amount: 0 },
  { name: 'October', amount: 0 },
  { name: 'November', amount: 0 },
  { name: 'December', amount: 0 }
]

export default { staffCommuteEmissionSources, businessTravelEmissionSources, extraCalculations, railMonths }
