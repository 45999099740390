<template>
  <div class="carbon-data-status-select">
    <label>Reporting Status:</label>
    <select
      class="carbon-data-status-input"
      type="text"
      v-model="$root.userDetails.organisationStatus"
      v-on:change="updatePeriod()"
      v-on:focus="$root.setEditing()"
      v-on:blur="$root.unsetEditing()"
    >
      <option value="Completed">Completed</option>
      <option value="Active">Active</option>
    </select>
  </div>
</template>

<script>
import periodQueries from '../../queries/period.js'

export default {
  methods: {
    async updatePeriod () {
      this.$root.userDetails.clientStatus = this.$root.userDetails.organisationStatus
      this.$root.saving = true
      await this.$apollo.mutate({
        mutation: periodQueries.UPDATE_PERIOD_STATUSES,
        variables: {
          clientID: this.$store.state.user.clientID,
          year: this.$store.state.app.selectedYear,
          status: this.$root.userDetails.organisationStatus
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        setTimeout(() => {
          this.$root.saving = false
        }, 2000)
      })
    }
  }
}
</script>
