<template>
  <div class="report-cover report-page">
    <div v-if="$root.userDetails.clientStatus === 'Active'" class="watermark1">WORKING VERSION</div>
    <div v-if="$root.userDetails.clientStatus === 'Active'" class="watermark2">WORKING VERSION</div>

    <div class="report-logo">
      <img src="../../assets/images/dCarbonLogo.png" />
    </div>
    <div class="report-inner">
      <h1 class="title">{{ type !== 'reduction' ? 'Carbon Inventory Report:' : 'Carbon Reduction Plan' }}</h1>
      <div class="organisation-name">{{ $store.state.app.client.title }}</div>
      <div v-if="$store.state.app.client.tradingName " class="trading-name"> Trading As {{ $store.state.app.client.tradingName }}</div>
      <div class="report-info width2">
        <div class="row">
          <div>Period:</div>
          <div>
            {{ $store.state.app.report.period }}
          </div>
        </div>
        <div class="row">
          <div>Base year:</div>
          <div>
            <!-- change to first ever year -->
            {{ baseYear }}
          </div>
        </div>
        <div v-if="type !== 'reduction'" class="row">
          <div>Status:</div>
          <div>{{ $store.state.app.report.status }}</div>
        </div>
        <div v-if="type !== 'reduction'" class="row">
          <div>Assurance type:</div>
          <div>{{ $store.state.app.report.assuranceType }}</div>
        </div>
        <div v-if="type !== 'reduction'" class="row">
          <div>Certification type:</div>
          <div>{{ $store.state.app.report.certificationType.title ? this.$store.state.app.report.certificationType.title : 'TBC' }}</div>
        </div>
        <div class="row">
          <div>Last updated date:</div>
          <div>{{ dateString }}</div>
        </div>
      </div>
      <div class="img-container width2">
        <img
          class="small-image"
          src="../../assets/images/dCarbonIcon.png"
        />
      </div>
      <div class="ekos-web width2">ekos.co.nz | ekos@ekos.co.nz</div>
      <div class="report-info row width2">
        <div v-if="type !== 'reduction'" class="row">
          <div>Prepared By:</div>
          <div style="margin-left: 10px">{{ $store.state.app.report.preparedBy }}</div>
        </div>
        <div v-if="type !== 'reduction'" class="row">
          <div>Reviewed By:</div>
          <div style="margin-left: 10px">{{ $store.state.app.report.reviewedBy }}</div>
        </div>
      </div>
      <div class="org-contact-title">Organisation contact details</div>
      <div class="report-info width2">
          <div class="row">
            <div>Business sector</div>
            <div>{{ $store.state.app.client.businessSector }}</div>
          </div>
          <div class="row">
            <div>Contact person</div>
            <div>{{ `${$store.state.app.client.contactPerson.firstName ? $store.state.app.client.contactPerson.firstName : ''} ${$store.state.app.client.contactPerson.surname ? $store.state.app.client.contactPerson.surname : ''}` }}</div>
          </div>
          <div class="row">
            <div>Contact number</div>
            <div>{{ $store.state.app.client.contactPerson.contactNumber ? $store.state.app.client.contactPerson.contactNumber : ''  }}</div>
          </div>
          <div class="row">
            <div>Contact email</div>
            <div>{{ $store.state.app.client.contactPerson.email}}</div>
          </div>
          <div class="row">
            <div>Company website</div>
            <div>{{ $store.state.app.client.websiteAddress }}</div>
          </div>
      </div>
    </div>
    <div class="page-footer">Emissions Inventory Report</div>
  </div>
</template>

<script>
import dataMixin from '../../mixins/dataMixin.vue'
export default {
  mixins: [dataMixin],
  props: {
    type: { type: String, required: true }
  },
  computed: {
    baseYear () {
      return this.$store.state.app.client.baseYear ? this.getReportingPeriod(parseInt(this.$store.state.app.client.baseYear.year), this.$store.state.app.client.baseYearReportingMonthStart) : this.$store.state.app.selectedYear
    },
    dateString () {
      if (this.type === 'reduction') {
        return this.$store.state.app.reductionPlan.lastEdited
      }
      let time = this.$store.state.app.report.lastEdited
      let converted = time.split(' ')[0]
      return converted
    }
  }
}
</script>
