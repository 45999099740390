<template>
  <div class="add-emissions-source">
    <h3 class="secondary-title">Add Emissions Source:</h3>
    <div class="row">
      <div class="inner-row">
        <label for="year">Year:</label>
        <select v-model="periodID">
          <option :value=$store.state.app.client.period.nodes[0].id>Current Inventory</option>
          <option v-for="period in $store.state.app.client.completedInventories.nodes" :key="period.id"
            :value="period.id">
            {{ period.year }}
          </option>
        </select>
      </div>
      <div class="inner-row">
        <label for="emissions source">Emissions Source:</label>
        <autocomplete class="search-bar" :search="fetchEmissionsSources" :get-result-value="getResultValue"
          :placeholder="'Search for emissions source'" @submit="submit"></autocomplete>
      </div>
    </div>
  </div>
</template>

<script>
import periodQueries from '../../../queries/period.js'
export default {
  name: 'AddEmissionsSource',
  data () {
    return {
      periodID: this.$store.state.app.client.period.nodes[0].id
    }
  },
  methods: {
    fetchEmissionsSources (input) {
      if (input.length < 3) {
        return []
      }
      return this.$apollo.query({
        query: periodQueries.READ_EMISSIONS_SOURCES,
        variables: {
          periodID: parseInt(this.periodID),
          name: input
        },
        fetchPolicy: 'no-cache'
      }).then(async response => {
        // filter out emissions sources that are already in reductionEmissionSources
        const reductionEmissionSources = this.$store.state.app.reductionPlan.reductionEmissionSources.nodes
        const filteredEmissionsSources = response.data.readEmissionsSources.nodes.filter(emissionsSource => {
          return !reductionEmissionSources.find(reductionEmissionSource => reductionEmissionSource.emissionsSource.id === emissionsSource.id || reductionEmissionSource.baseYearEmissionsSource.id === emissionsSource.id)
        })
        return filteredEmissionsSources
      })
    },
    getResultValue (result) {
      return result.name
    },
    async submit (result) {
      const baseYearEmissionsSources = this.$store.state.app.client.baseYear.emissionsSources.nodes
      const currentYearEmissionsSources = this.$store.state.app.client.period.nodes[0].emissionsSources.nodes
      // find the corresponding emissions source id
      const emissionsSourceID = this.selectedFromBaseYear ? this.findEmissionsSourceCounterPart(currentYearEmissionsSources, result) : result.id
      const baseYearEmissionsSourceID = this.selectedFromBaseYear ? result.id : this.findEmissionsSourceCounterPart(baseYearEmissionsSources, result)
      const data = {
        reductionPlanID: this.$store.state.app.reductionPlan.id,
        emissionsSourceID: emissionsSourceID,
        baseYearEmissionsSourceID: baseYearEmissionsSourceID,
        addedByAnalyst: true
      }
      // console.log('data', data)
      let res = await this.$store.dispatch('createReductionEmissionSources', [data])
      // push to reductionEmissionSources in state
      this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.push(res[0].data.createReductionEmissionSource)
    },
    findEmissionsSourceCounterPart (emissionsSources, result) {
      // if cant find return 0
      let foundEmissionsSource = emissionsSources.find(emissionsSource => emissionsSource.name.toLowerCase() === result.name.toLowerCase())
      // console.log('foundEmissionsSource', foundEmissionsSource)
      if (!foundEmissionsSource) {
        return '0'
      }
      return foundEmissionsSource.ID
    }
  },
  computed: {
    selectedFromBaseYear () {
      return this.periodID === this.$store.state.app.client.baseYear.id
    }
  }
}
</script>
