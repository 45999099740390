var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notes-row"},[_c('div',{staticClass:"input-section",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"input-container-column"},[_c('div',{staticClass:"input-container-column"},[_c('div',{staticClass:"input-item long"},[_c('label',[_vm._v("Data Source:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.clientDataSource),expression:"clientDataSource"}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"placeholder":"ie supplier invoice","type":"text"},domProps:{"value":(_vm.clientDataSource)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.clientDataSource=$event.target.value},function($event){return _vm.$emit('dataSourceChanged', {
                val: $event.target.value,
                schemaIndex: _vm.schemaIndex
              })}],"change":function($event){return _vm.$root.saveData()}}})]),_c('div',{staticClass:"input-item long"},[_c('label',[_vm._v("Data Quality:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.quality),expression:"quality"}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"name":"unit","id":"unit-type"},on:{"input":function($event){return _vm.$emit('dataQualityChanged', {
                val: $event.target.value,
                schemaIndex: _vm.schemaIndex
              })},"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.quality=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.$root.saveData()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Please select")]),_c('option',{attrs:{"value":"Poor"}},[_vm._v("Poor")]),_c('option',{attrs:{"value":"Low"}},[_vm._v("Low")]),_c('option',{attrs:{"value":"Medium"}},[_vm._v("Medium")]),_c('option',{attrs:{"value":"Good"}},[_vm._v("Good")])])])]),_c('div',{staticClass:"input-container-row"},[_c('div',{staticClass:"input-item long"},[_c('label',{staticStyle:{"margin-right":"50px"}},[_vm._v("Assumptions:")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.userAssumptions),expression:"userAssumptions"}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"placeholder":"Any assumptions made about this data","rows":"4","cols":"50"},domProps:{"value":(_vm.userAssumptions)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.userAssumptions=$event.target.value},function($event){return _vm.$emit('assumptionsChanged', {
                val: $event.target.value,
                schemaIndex: _vm.schemaIndex
              })}],"change":function($event){return _vm.$root.saveData()}}})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }