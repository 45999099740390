<template>
  <div class="section chemicals">
    <div class="activity-title-row">
      <h1 class="activity-title">Chemicals</h1>
      <i class="title-icon fad fa-tractor"></i>
    </div>
    <!-- v-bind:initialSchema="schema" -->
    <ChemicalsComponent
      v-bind:initialSchema="schema[this.getSchemaIndex('Chemical Used in Manufacturing or Processing')]"
      schemaName="Chemical Used in Manufacturing or Processing"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
    />
     <div class="spacer"></div>
    <Notes
      :key="0"
      :schemaIndex="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions"
    />

    <NextSectionButton
      :nextRouteSchema="$root.industryAndAgriculture.schemas[1]"
      :nextRouteActivity="$root.industryAndAgriculture.sections[1]"
      sectionName="industryAndAgriculture"
    />
  </div>
</template>

<script>
import ChemicalsComponent from './ChemicalsComponent'
import Notes from '../../../Notes'
import NextSectionButton from '../../../NextSectionButton.vue'

export default {
  metaData () {
    return {
      title: `dCarbon — Industrial Processes`,
      description: ''
    }
  },
  name: 'activity-industrial-processes',
  props: { initialSchema: Object, scope: String },
  components: { ChemicalsComponent, Notes, NextSectionButton },
  data () {
    return {
      schema: {}
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    calculateTotal (schema, schemaName) {
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    }
  }
}
</script>
