<script>
export default {
  methods: {
    devMode () {
      return process.env.NODE_ENV
    },
    getReportingPeriod (year, month) {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
      const startYear = year
      const endYear =
        months.findIndex(el => el === month) !== 0
          ? year + 1
          : year
      const startMonth = month.substring(0, 3)
      const endMonth =
        months.findIndex(el => el === month) !== 0
          ? months[months.findIndex(el => el === month) - 1].substring(0, 3)
          : months[11].substring(0, 3)
      return `1 ${startMonth} ${startYear} - ${this.getLastDayOfMonth(months.findIndex(el => el === month) - 1)} ${endMonth} ${endYear}`
    },
    getLastDayOfMonth (monthIndex) {
      // console.log(monthIndex, 'monthIndex')
      var today = new Date()
      var lastDayOfMonth = new Date(today.getFullYear(), monthIndex + 1, 0)
      return lastDayOfMonth.getDate()
    }
  }
}
</script>
