const investmentSchema = [
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Scope One & Two Emissions (tCO2e)', // Title of Section on front end
    title: 'Investment - Scope One & Two Emissions (tCO2e)',
    scope: 3, // scope number
    sectionID: 'investments', // Default Schema Scope Section Name
    identifier: 'investments-component', // Main Section component identifier for tooltips
    version: 2.75,
    active: true,
    toggleName: 'enterBy',
    emissionSources: [
      {
        category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Investments', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 15, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Investment -', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'CO2',
        unit: 'tCO2e',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    services: [
      {
        values: {
          name: 'Investment 1',
          stationaryFuels: 0,
          companyVehicles: 0,
          industrialProcesses: 0,
          fugitiveEmissions: 0,
          landUseChange: 0,
          electricity: 0,
          percentageOwned: 10
        },
        notes: {
          stationaryFuels: '',
          companyVehicles: '',
          industrialProcesses: '',
          fugitiveEmissions: '',
          landUseChange: '',
          electricity: ''
        }
      }
    ],
    totalEmissions: {
      carbonEmissions: 0
    }
  }
]

export default investmentSchema
