<template>
  <div v-if="$store.state.app.organisation" class="row menu month-menu"
    @click="showMonthMenu = !showMonthMenu">
    <p class="pointer">
      <i class="fad fa-file-invoice" style="margin-right: 5px"></i>
      {{ formattedMonth }}
    </p>
    <i class="fas fa-chevron-down"></i>
    <div v-if="showMonthMenu" class="user-menu dropdown-height">
      <div :key="`month-annual`" class="menu-item" @click="$emit('onMonthChange', 0)">
        <div class="pointer">
          <i class="fad fa-file-invoice" style="margin-right: 5px"></i>
          <span style="margin-left: 3px; font-weight: 600;">Annual</span>
        </div>
      </div>
      <div v-for="(month) in monthsByBaseYear" :key="month.id" class="menu-item" @click="$emit('onMonthChange', month.id)">
        <div class="pointer">
          <i class="fad fa-file-invoice" style="margin-right: 5px"></i>
          {{ month.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      months: [
        { name: 'January', id: 1 },
        { name: 'February', id: 2 },
        { name: 'March', id: 3 },
        { name: 'April', id: 4 },
        { name: 'May', id: 5 },
        { name: 'June', id: 6 },
        { name: 'July', id: 7 },
        { name: 'August', id: 8 },
        { name: 'September', id: 9 },
        { name: 'October', id: 10 },
        { name: 'November', id: 11 },
        { name: 'December', id: 12 }
      ],
      showMonthMenu: false
    }
  },
  created () {
    window.addEventListener('click', (e) => {
      // close bu-menu when clicked outside
      if (this.showMonthMenu && !document.querySelector('.month-menu').contains(e.target)) this.showMonthMenu = false
    })
  },
  computed: {
    monthsByBaseYear () {
      let months = [...JSON.parse(JSON.stringify(this.months))]
      const index = months.findIndex(month => month.name === this.$store.state.app.organisation.baseYearReportingMonthStart)
      const firstPart = months.splice(0, index)
      months.push(...firstPart)
      return months
    //   return this.months
    },
    formattedMonth () {
      const selectedMonth = this.$store.state.app.selectedMonth
      return selectedMonth === '0' ? 'Annual' : this.months.find(month => month.id === parseInt(selectedMonth)).name
    }
  }
}
</script>
