import { renderCells, formatNumber } from '../../utils/utils'

export function renderTargetsTable (timeframe, metricData, showCombined = false) {
  let metrics = [
    { key: 'absolute', title: 'Absolute Reduction Targets' },
    { key: 'intensity', title: 'Intensity Reduction Targets' }
  ]
  if (showCombined) metrics.push({ key: 'scopeOneTwo', title: 'Combined Scope 1 & Two Reduction Targets' })
  let tableString = ''

  metrics.forEach(metric => {
    tableString += `<h2>${metric.title}</h2>`
    tableString += renderTable(metric.key, timeframe, metricData)
  })
  return tableString
}

function renderTable (metric, timeframe, metricData) {
  let cellCount = 7
  const headingData = ['Base year emissions', 'Current year emissions', 'Target term', 'Timeframe to achieve by', 'Overall reduction goal', '% reduction made so far against base year', 'Pathway']
  const timeFrames = ['Short', 'Medium', 'Long']
  let mainData = []
  timeFrames.forEach(term => {
    const reductionPercentage = getReductionPercentage(timeframe[term.toLowerCase() + 'TermTimeFrame'], term, metricData[metric])
    const reductionPercentageString = term === 'Short' ? `${reductionPercentage}%` : `${reductionPercentage}% (projected)`
    mainData.push(metricData[metric].baseYear.emissions)
    mainData.push(metricData[metric].currentYear.emissions)
    mainData.push(term)
    mainData.push(timeframe[term.toLowerCase() + 'TermTimeFrame'].toString())
    mainData.push(timeframe[metric + term + 'TermPercentage'] + '%')
    mainData.push(reductionPercentageString)
    mainData.push(onTrack(timeframe[metric + term + 'TermPercentage'], reductionPercentage))
  })
  let heading = renderCells(headingData, cellCount, true)
  let main = renderCells(mainData, cellCount)
  return `<table>${heading}${main}</table>`
}

function getReductionPercentage (timeframe, term, metric) {
  // return percentage reduced,
  // if short term compare base year with current year else use the year to determine a forecast
  if (term === 'Short') {
    return percentageReduced(metric.baseYear.emissions, metric.currentYear.emissions)
  } else {
    // calculate emissions reduction per year by comparing base year with current year emissions across the number of years between the base year and timeframe
    let baseYearEmissions = metric.baseYear.emissions
    let currentYearEmissions = metric.currentYear.emissions
    let difference = baseYearEmissions - currentYearEmissions
    let baseYearTimeAgo = metric.currentYear.year - metric.baseYear.year
    let reductionPerYear = difference / baseYearTimeAgo
    let projectedReduction = reductionPerYear * (timeframe - metric.currentYear.year)
    // return projectReduction as a percentage of base year emissions
    return percentageReduced(baseYearEmissions, baseYearEmissions - projectedReduction)
  }
}

function percentageReduced (oldValue, newValue) {
  if (!oldValue) return formatNumber(0) // avoid division by zero
  const reduced = (oldValue - newValue) / oldValue * 100
  return formatNumber(reduced) > 100 ? 100 : formatNumber(reduced)
}

function onTrack (target, achieved) {
  return target < achieved ? 'On track' : 'Not on track'
}
