<template>
  <div class="purchased">
    <!-- Modal start -->
    <div class="tooltip">
      <h1 class="section-title">{{ title }}</h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <!-- Modal end -->
    <div
      class="activities"
      v-for="(activity, index) in schema.activities"
      :key="`activity-${index}`"
    >
      <div class="activity">
        <h4 v-if="index === 0">Activity</h4>
        <h4 v-if="index === 0">Unit</h4>
        <h4 v-if="index === 0">{{ $store.state.app.selectedMonth === '0' ? 'Annual' : 'Monthly' }} Total:</h4>
        <h4 v-if="index === 0" style="text-align: right">Carbon Emissions</h4>
        <p>{{ activity.name }}</p>
        <p>{{ activity.unit }}</p>
        <div class="input-section">
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-on:change="() => {
                schema.emissionSources[index].annualTotal =schema.emissionSources[index].annualTotal || 0;
                calculateCarbonEmissions()
              }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model.number="schema.emissionSources[index].annualTotal"
            min="0"
            type="number" step="0.01"
            name="annualTotal"
          />
        </div>
        <p>{{ $root.formatNumber(schema.emissionSources[index].tCO2e) }}</p>
        <!-- <p>to change</p> -->
      </div>
    </div>
    <h2 style="text-align: left">
      <!-- Total {{ $root.formatNumber(schema.totalEmissions.totalCarbonEmissions) }} -->
      Total {{ $root.formatNumber(totalCarbonEmissions) }}
    </h2>
  </div>
</template>

<script>
import axios from '@/axios'
import Modal from '../../../modal/Modal.vue'

export default {
  components: { Modal },
  name: 'purchased-component',
  props: {
    title: String,
    initialSchema: Object,
    activityID: String,
    identifier: String,
    schemaName: String
  },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },
  mounted () {
    // this.calculateCarbonEmissions()
  },
  computed: {
    totalCarbonEmissions () {
      let total = 0
      const schema = this.$root.allSchemas.find(s => s.name === this.schemaName)
      schema.emissionSources.forEach(source => {
        total += source.tCO2e
      })
      return total
    }
  },
  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions () {
      var self = this

      return axios
        .post(
          '' +
            process.env.VUE_APP_APIURL +
            'api/calculateScopeThreePurchasedGSCapitial',
          {
            schema: self.schema
          }
        )
        .then(function (response) {
          // handle success
          self.schema = response.data.result
          self.$emit(
            'calculateTotal',
            response.data.result,
            self.schemaName
          )
          self.$root.saveData()
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    },
    getUnit (activity) {
      var activityType = this.schema.activityTypes.find(
        type => type.name === activity.name
      )
      activity.unit = activityType.unit
      // console.log(activityType.unit)
      this.calculateCarbonEmissions()
    },
    addActivity () {
      this.schema.activities.push({
        name: 'Accommodation',
        unit: 'Dollar Spend',
        annualTotal: 0.0,
        carbonEmissions: 0.0
      })
    },
    removeActivity () {
      if (this.schema.activities.length > 1) {
        this.schema.activities.pop()
        this.calculateCarbonEmissions()
      }
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.identifier}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
