<template>
  <div>
    <ReductionPlan />
    <addEmissionsSource />
  </div>
</template>

<script>
import ReductionPlan from './ReductionPlan.vue'
import addEmissionsSource from './recommendations/addEmissionsSource.vue'

export default {
  components: {
    addEmissionsSource,
    ReductionPlan
  }
}
</script>

<style></style>
