<template>
  <div class="section upstream-leased-assets">
    <div class="activity-title-row">
      <h1 class="activity-title">Upstream Leased Assets</h1>
      <i class="title-icon fad fa-coins"></i>
    </div>
    <ElectricityComponent
      title="Electricity"
      v-bind:initialSchema="schema[this.getSchemaIndex('Electricity')]"
      schemaName="Electricity"
      measurement="kWh"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="electricity"
    />
    <Notes
      :schemaIndex="0"
      :key="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
     <div class="spacer"></div>
    <FuelsComponent
      title="Stationary Fuels"
      measurement="litres"
      v-bind:initialSchema="schema[this.getSchemaIndex('Stationary Fuels')]"
      schemaName="Stationary Fuels"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="stationary-fuels"
    />
    <Notes
      :schemaIndex="1"
      :key="1"
      :dataSource="schema[1].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[1].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[1].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
     <div class="spacer"></div>
    <FuelsComponent
      title="Mobile Fuels"
      measurement="litres"
      v-bind:initialSchema="schema[this.getSchemaIndex('Mobile Fuels')]"
      schemaName="Mobile Fuels"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="mobile-fuels"
    />
    <Notes
      :schemaIndex="2"
      :key="2"
      :dataSource="schema[2].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[2].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[2].assumptions"
      @assumptionsChanged="updateAssumptions"
    />

    <NextSectionButton
      :nextRouteSchema="$root.assets.schemas[1]"
      :nextRouteActivity="$root.assets.sections[1]"
      sectionName="assets"
    />
  </div>
</template>

<script>
import NextSectionButton from '../../../NextSectionButton.vue'
import Notes from '../../../Notes.vue'
import ElectricityComponent from '../../../scopeTwo/sections/electricity/ElectricityComponent'
import FuelsComponent from './UpstreamLeasedAssetFuelsComponent'

export default {
  metaData () {
    return {
      title: `dCarbon — Upstream Leased Assets`,
      description: ''
    }
  },
  name: 'activity-upstream-leased-assets',
  components: {
    ElectricityComponent,
    FuelsComponent,
    Notes,
    NextSectionButton
  },
  props: { initialSchema: Object, scope: String },

  data () {
    return {
      schema: {}
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    calculateTotal (schema, schemaName) {
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    }
  }
}
</script>
