<template>
  <div class="section company-vehicles">
    <div class="activity-title-row">
      <h1 class="activity-title">Mobile Combustion</h1>

      <i class="title-icon fad fa-cars"></i>
    </div>
            <h2 class="secondary-title">Such as company vehicles, lawn mowers, forklifts, boats, aircrafts etc.</h2>
    <div
      class="controls-container"
      :style="{ 'flex-direction': 'row-reverse' }"
    >
      <div class="toggle-btn-container">
        <div
          class="toggle-btn"
          :class="enterBy === 'fuels' ? 'active' : 'inactive'"
          @click=";(enterBy = 'fuels'), updateActiveStatus('Fuels')"
        >
          <p class="btn-text">Enter by Fuels</p>
        </div>
        <div
          class="toggle-btn"
          :class="enterBy === 'vehicles' ? 'active' : 'inactive'"
          @click=";(enterBy = 'vehicles'), updateActiveStatus('Vehicles')"
        >
          <p class="btn-text">Enter by Vehicles</p>
        </div>
      </div>
    </div>
    <FuelsComponent
      v-if="enterBy === 'fuels'"
      @calculateTotal="calculateTotal"
      v-bind:initialSchema="schema[this.getSchemaIndex('Fuels')]"
      schemaName="Fuels"
      :activityID="$options.name"
    />
    <VehiclesComponent
      v-if="enterBy === 'vehicles'"
      @calculateTotal="calculateTotal"
      v-bind:initialSchema="schema[this.getSchemaIndex('Vehicles')]"
      schemaName="Vehicles"
      :activityID="$options.name"
    />
    <div class="spacer"></div>
    <Notes
      v-if="enterBy === 'fuels'"
      :schemaIndex="0"
      :key="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <Notes
      v-if="enterBy === 'vehicles'"
      :schemaIndex="1"
      :key="1"
      :dataSource="schema[1].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[1].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[1].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <NextSectionButton
      :nextRouteSchema="$root.travelAndTransport.schemas[1]"
      :nextRouteActivity="$root.travelAndTransport.sections[1]"
      sectionName="travelAndTransport"
    />
  </div>
</template>

<script>
import FuelsComponent from './FuelsComponent'
import VehiclesComponent from './VehiclesComponent'
import Notes from '../../../Notes'
import NextSectionButton from '../../../NextSectionButton.vue'

export default {
  metaData () {
    return {
      title: `dCarbon — Company Vehicles`,
      description: ''
    }
  },
  name: 'activity-company-vehicles',
  props: { initialSchema: Object, scope: String },
  components: { FuelsComponent, VehiclesComponent, Notes, NextSectionButton },
  data () {
    return {
      schema: {},
      enterBy: 'fuels'
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  mounted () {
    this.getActiveSchemas()
  },
  methods: {
    updateDataSource (value) {
      // console.log(value)
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      // console.log(value)
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      // console.log(value)
      this.schema[value.schemaIndex].assumptions = value.val
    },
    updateActiveStatus (schemaName) {
      this.schema.forEach(schema => {
        schema.active = false
      })
      this.schema[this.getSchemaIndex(schemaName)].active = true
    },
    getActiveSchemas () {
      if (this.schema[this.getSchemaIndex('Fuels')].active === true) {
        this.enterBy = 'fuels'
      } else {
        this.enterBy = 'vehicles'
      }
    },
    calculateTotal (schema, schemaName) {
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    }
  }
}
</script>
