const biofuelSources = [
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Bioethanol Consumption', // Title of Section on front end
    title: 'Bioethanol Consumption',
    scope: 1, // scope number
    sectionID: 'fuels', // Default Schema Scope Section Name
    identifier: 'bioethanol', // Main Section component identifier for tooltips
    version: 2.77,
    annually: true,
    emissionSources: [
      {
        category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Stationary Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Bioethanol', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'bioethanol',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Bioethanol WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'WTT',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'WTT',
        variableName: 'bioethanolWTT' // Main getWellToTankEmissions() variable Name
      }
    ],
    months: [
      {
        month: 'January',
        units: [0]
      },
      {
        month: 'February',
        units: [0]
      },
      {
        month: 'March',
        units: [0]
      },
      {
        month: 'April',
        units: [0]
      },
      {
        month: 'May',
        units: [0]
      },
      {
        month: 'June',
        units: [0]
      },
      {
        month: 'July',
        units: [0]
      },
      {
        month: 'August',
        units: [0]
      },
      {
        month: 'September',
        units: [0]
      },
      {
        month: 'October',
        units: [0]
      },
      {
        month: 'November',
        units: [0]
      },
      {
        month: 'December',
        units: [0]
      }
    ],
    variables: [
      { name: 'Carbon Emissions', variableName: 'bioethanolKgCO2e' },
      { name: 'Carbon Dioxide Emissions', variableName: 'bioethanolKgCO2' },
      { name: 'Nitros Oxide Emissions', variableName: 'bioethanolKgCH4' },
      { name: 'Methane Emissions', variableName: 'bioethanolKgN2O' }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Biodiesel Consumption', // Title of Section on front end
    title: 'Biodiesel Consumption',
    scope: 1, // scope number
    sectionID: 'fuels', // Default Schema Scope Section Name
    identifier: 'biodiesel', // Main Section component identifier for tooltips
    version: 2.77,
    annually: true,
    emissionSources: [
      {
        category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Stationary Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Biodiesel', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'biodiesel',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Biodiesel WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'WTT',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'WTT',
        variableName: 'biodieselWTT' // Main getWellToTankEmissions() variable Name
      }
    ],
    months: [
      {
        month: 'January',
        units: [0]
      },
      {
        month: 'February',
        units: [0]
      },
      {
        month: 'March',
        units: [0]
      },
      {
        month: 'April',
        units: [0]
      },
      {
        month: 'May',
        units: [0]
      },
      {
        month: 'June',
        units: [0]
      },
      {
        month: 'July',
        units: [0]
      },
      {
        month: 'August',
        units: [0]
      },
      {
        month: 'September',
        units: [0]
      },
      {
        month: 'October',
        units: [0]
      },
      {
        month: 'November',
        units: [0]
      },
      {
        month: 'December',
        units: [0]
      }
    ],
    variables: [
      { name: 'Carbon Emissions', variableName: 'biodieselKgCO2e' },
      { name: 'Carbon Dioxide Emissions', variableName: 'biodieselKgCO2' },
      { name: 'Nitros Oxide Emissions', variableName: 'biodieselKgCH4' },
      { name: 'Methane Emissions', variableName: 'biodieselKgN2O' }
    ]
  }
]

const mobileCombustionEmissionSources = [
  {
    category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Mobile Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Mobile Combustion - Bioethanol', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'default', // default, naturalGasTDL, electricityTDL, WTT
    id: 'bioethanol-mc',
    unit: 'L',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  },
  {
    category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Mobile Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Mobile Combustion - Biodiesel', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'default', // default, naturalGasTDL, electricityTDL, WTT
    id: 'biodiesel-mc',
    unit: 'L',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  }
]

const mobileCombustionEmissionSourcesWTT = [
  {
    category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Mobile Combustion - Bioethanol WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
    id: 'Bioethanol WTT',
    unit: 'L',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  },
  {
    category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Mobile Combustion - Biodiesel WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
    id: 'Biodiesel WTT',
    unit: 'L',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  }
]

const extraCalculations = [
  {
    type: 'Bioethanol WTT',
    variableName: 'bioethanolWTT'
  },
  {
    type: 'Biodiesel WTT',
    variableName: 'biodieselWTT'
  }
]

const bioethanolMobileCombustion = {
  emissionsOfCO2e: {
    variableName: 'bioethanolKgCO2e',
    total: 0
  },
  carbonDioxideEmissions: {
    variableName: 'bioethanolKgCO2',
    total: 0
  },
  nitrosOxideEmissions: {
    variableName: 'bioethanolKgN2O',
    total: 0
  },
  methaneEmissions: {
    variableName: 'bioethanolKgCH4',
    total: 0
  }
}

const biodieselMobileCombustion = {
  emissionsOfCO2e: {
    variableName: 'biodieselKgCO2e',
    total: 0
  },
  carbonDioxideEmissions: {
    variableName: 'biodieselKgCO2',
    total: 0
  },
  nitrosOxideEmissions: {
    variableName: 'biodieselKgN2O',
    total: 0
  },
  methaneEmissions: {
    variableName: 'biodieselKgCH4',
    total: 0
  }
}

export default { biofuelSources, mobileCombustionEmissionSources, mobileCombustionEmissionSourcesWTT, extraCalculations, bioethanolMobileCombustion, biodieselMobileCombustion }
