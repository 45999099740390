<template>
  <div class="input-section">
    <h1 class="section-title activity-title">Consolidation method</h1>
    <div class="input-container" style="max-width: 500px">
      <div class="input-item">
        <label>Consolidation approach</label>
        <select
          type="text"
          v-model="$store.state.app.report.consolidationApproach"
          v-on:change="updateReportData('consolidationApproach')"
        >
          <option value="TBC" selected>Please select</option>
          <option value="Control - Operational">Control - Operational</option>
          <option value="Control - Financial">Control - Financial</option>
          <option value="Equity Share">Equity Share</option>
        </select>
      </div>
      <div class="input-item">
        <label>Reason for selection</label>
        <textarea
          name=""
          id="title"
          cols="30"
          rows="2"
          v-model="$store.state.app.report.consolidationReason"
          v-on:change="updateReportData('consolidationReason')"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    updateReportData (fieldName) {
      this.$emit('updateReportData', fieldName)
    }
  }
}
</script>
