<template>
  <div class="input-section">
    <div class="activity-title-row">
      <!-- Modal start -->
      <div class="tooltip">
        <h1 class="section-title">{{ title }}</h1>
        <div class="modal-btn" @click="openModal">
          <i class="fas fa-question-circle"></i>
        </div>
      </div>

      <!-- Modal end -->
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <div v-if="!schema.annually">
      <div v-for="(source, i) in filteredSources" :key="source.id">
        <div class="secondary-title">{{ source.name.split('- ')[1] }} {{measurement}}</div>
        <div class="input-container-grid">
          <div
            class="input-item stacked"
            v-for="(month, index) in schema[getMonthArrayKey(i, source)]"
            :key="`month-${index - 1}`"
          >
            <label>{{ month.name }}</label>
            <div class="month-input-container">
              <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
                type="number" step="0.01"
                min="0"
                v-model.number="month.amount"
                v-on:change="() => {
                  month.amount = month.amount || 0;
                  calculateCarbonEmissions()
                }"
                v-on:focus="$root.setEditing()"
                v-on:blur="$root.unsetEditing()"
              />
              <!-- <div class="measurement">{{ measurement }}</div> -->
            </div>
          </div>
        </div>
        <h3 class="secondary-title">
          {{ $store.state.app.selectedMonth === '0' ? 'Annual' : 'Monthly' }} Total:: {{ source.annualTotal }}
        </h3>
      </div>
    </div>
    <div v-else class="input-container-column">
      <div>
        <h3 class="secondary-title">{{ $store.state.app.selectedMonth === '0' ? 'Annual' : 'Monthly' }} Total: ({{ measurement }})</h3>
        <div v-for="source in filteredSources" :key="source.id" class="input-item long">
          <label class="upper">{{ source.name.split('- ')[1] }}</label>
          <!-- {{ measurement }} -->
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            type="number" step="0.01"
            min="0"
            v-model.number="source.annualTotal"
            v-on:change="() => {
              source.annualTotal = source.annualTotal || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          />
        </div>
      </div>
    </div>
    <h3 class="secondary-title">
      Total Carbon Emissions {{ $root.formatNumber(tCO2e) }}
    </h3>
  </div>
</template>

<script>
import axios from '@/axios'
import Modal from '../../../modal/Modal.vue'

export default {
  components: { Modal },
  name: 'travel-component',
  props: {
    title: String,
    initialSchema: Object,
    measurement: String,
    schemaName: String,
    showDiesel: Boolean,
    activityID: String,
    identifier: String
  },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.tooltip = this.getTooltip()
  },
  mounted () {},
  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    getMonthArrayKey (i, source) {
      return i === 0 ? 'months' : `${source.id}Months`
    },
    calculateCarbonEmissions () {
      var self = this
      return axios
        .post(
          process.env.VUE_APP_APIURL +
            'api/calculateScopeThreeBusinessTravel/',
          {
            schema: this.schema,
            annually: self.schema.annually
          }
        )
        .then(function (response) {
          // console.log(response.data.result)
          self.schema = response.data.result
          self.$emit('calculateTotal', response.data.result, self.schemaName)
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          self.$root.saveData()
        })
    },
    addUnit () {
      // Add unit value (0) to each unit array in month array
      var item = {
        country: '',
        rooms: 0,
        nights: 0,
        eFactor: 0,
        carbonEmissions: 0
      }

      this.schema.accommodation.push(item)
    },
    removeUnit () {
      if (this.schema.accommodation.length > 1) {
        this.schema.accommodation.pop()
      }
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.identifier}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  computed: {
    tCO2e () {
      var tCO2e = 0
      this.schema.emissionSources.forEach(source => {
        if (source.type !== 'WTT') {
          tCO2e += source.tCO2e
        }
      })
      return tCO2e
    },
    filteredSources () {
      return this.schema.emissionSources.filter(source => !(source.name.includes('WTT')))
    }
  }
}
</script>
