<template>
<!-- <div></div> -->
  <div class="controls"  @click="emitUpdate()">
    <div v-if="showAdd" class="add" @click="emitAdd()">+</div>
    <div v-if="showRemove" class="remove" @click="emitRemove()">-</div>
  </div>
</template>

<script>
export default {
  name: 'controls',
  props: { showAdd: { type: Boolean, default: true }, showRemove: { type: Boolean, default: true } },
  data () {
    return {}
  },
  methods: {
    emitAdd () {
      // console.log('emit add from control component')
      this.$emit('add')
    },
    emitRemove () {
      // console.log('emit remove from control component')
      this.$emit('remove')
    },
    emitUpdate () {
      this.$emit('update')
    }
  }
}
</script>
