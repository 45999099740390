<template>
  <div class="reduction-report">
    <div v-if="$store.state.app.clientDataLoaded && this.reportDataLoaded && this.$store.state.app.report">
      <div id="reduction-report">
        <ReportCoverPage type='reduction' />
        <TableOfContents :pages="pages" :footerText="'Reduction Plan Report'" />
        <div class="spacer"></div>
        <ReductionReportContent :pages="pages" :overflowData="overflowData" :baseYearIntensityMetric="baseYearIntensityMetric" />
      </div>
      <div class="btn-wrapper">
        <div class="submit-btn" @click="downloadReport()">
          <div class="btn-text">
            Download
          </div>
        </div>
      </div>
    </div>
    <div v-else class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import TableOfContents from '../emissionsInventoryReport/TableOfContents.vue'
import ReportCoverPage from '../emissionsInventoryReport/ReportCoverPage.vue'
import ReductionReportContent from './ReductionReportContent.vue'
import { renderActionPlanTable } from '../../snippets/reductionPlan/actionPlan'

export default {
  components: { TableOfContents, ReductionReportContent, ReportCoverPage },
  data () {
    return {
      pages: [],
      originalPages: [],
      reportDataLoaded: false,
      baseYearIntensityMetric: '',
      overflowData: {},
      settings: { 'actionPlan': {
        'split': true,
        'firstSplitCount': 5,
        'splitCount': 5
      } }
    }
  },
  async beforeMount () {
    await this.setReportTemplate()
    this.baseYearIntensityMetric = await this.getBaseYearIntensityMetric()
    this.reportDataLoaded = true
  },
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  methods: {
    async setReportTemplate (pages) {
      const response = await this.getReportTemplate()
      this.pages = response
      this.generateOverflowContent()
      this.adjustPages()
      // console.log(pages, 'pages')
    },
    async getReportTemplate () {
      var config = {
        method: 'get',
        url: `${process.env.VUE_APP_APIURL}report/generateReportSchema/ReductionPlanTemplate`,
        headers: {}
      }
      return axios(config)
        .then(function (response) {
          // console.log(response, 'generate report schema')
          return response.data.pages
        })
        .catch(function (error) {
          console.error(error)
        })
    },
    async getBaseYearIntensityMetric () {
      const baseYear = this.$store.state.app.client.baseYear?.year
      const metric = this.$store.state.app.reductionPlan.reductionTarget.organisationSpecificMetric
      if (baseYear && metric) {
        const res = await this.$store.dispatch('getReportFTE', { year: baseYear, metric: metric })
        return res.readOneReport.emissionIntensityMetrics.nodes[0]?.value
      } else {
        return 0
      }
    },
    downloadReport () {
      window.print()
    },
    adjustPages () {
      this.pages = this.checkTableOverflows(this.pages)
    },
    checkTableOverflows (pages) {
      if (this.settings.actionPlan.split) {
        pages = this.checkActionPlanTableOverflows(pages, this.settings.actionPlan)
      }
      return pages
    },
    generateOverflowContent () {
      if (this.settings.actionPlan.split) {
        this.getActionPlan(this.settings.actionPlan)
      }
    },
    checkActionPlanTableOverflows (pages, settings) {
      let actionPlanSplitCount = 1 + Math.ceil((this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.length - settings.firstSplitCount) / settings.splitCount)
      pages.slice(0).forEach((page, index) => {
        // check table actionPlan
        if (page.headings.length && page.headings[0].content.includes('[actionPlan]')) {
          // splice page and insert overflow data
          for (let i = 0; i < actionPlanSplitCount; i++) {
            if (i > 0) {
              let newPage = {
                content: '',
                title: null,
                headings: [
                  { content: `[actionPlan${i}]` }
                ]
              }
              pages.splice(index + i, 0, newPage)
              // pages.push(newPage)
            }
          }
        }
      })
      return pages
    },
    getActionPlan (settings) {
      // console.log('settings', settings)
      const splitCount = 5 // settings.splitCount
      const currentYearIsBaseYear = this.$store.state.app.client.period.nodes[0].year === this.$store.state.app.client.baseYear.year
      // add overflow data, filtering reduction Emissions Sources based on split count
      const reductionEmissionsSources = this.$store.state.app.reductionPlan.reductionEmissionSources.nodes
      const splitReductionEmissionsSources = []
      for (let i = 0; i < reductionEmissionsSources.length; i += splitCount) {
        splitReductionEmissionsSources.push(reductionEmissionsSources.slice(i, i + splitCount))
      }
      splitReductionEmissionsSources.forEach((data, index) => {
        let property = index === 0 ? 'actionPlan' : `actionPlan${index}`
        this.overflowData[property] = renderActionPlanTable(data, currentYearIsBaseYear)
      })

      // return renderActionPlanTable(this.$store.state.app.reductionPlan.reductionEmissionSources.nodes, currentYearIsBaseYear)
    }
  }
  // computed: {
  //   settings () {
  //     return JSON.parse(this.$store.state.app.reductionPlan.settings)
  //   }
  // }
}
</script>

<style></style>
