<template>
  <div class="summary">
    <div class="summary-content">
      <!-- Carbon Footprint by Activity -->
      <div class="activity-section footprint">
        <div class="activity-title-row">
          <h3 class="section-title">
            Carbon Footprint by Activity
          </h3>
          <h3 class="section-title">Carbon Offsets by Activity</h3>
        </div>
        <div class="activity-data-row">
          <div class="activity-data">
            <div class="grid-title top footprint">
              <div class="row">
                <div class="heading">Activity</div>
                <div class="heading lower">tCO2e</div>
              </div>
            </div>
            <div class="activity-grid">
              <template
                v-for="(activity, index) in emissionsByScope.activities"
              >
                <div class="item" :key="`${index}-1`">{{ activity.name }}</div>
                <div class="item" :key="`${index}-2`">
                  {{ $root.formatNumber(activity.tCO2e) }}
                </div>
              </template>
            </div>
            <div class="grid-title bottom footprint">
              <div class="row">
                <div class="heading">Grand Total</div>
                <div class="heading lower">tCO2e</div>
              </div>
              <div class="row">
                <div class="heading">Zero Carbon Option (100%)</div>
                <div class="heading">
                  {{ $root.formatNumber(emissionsByScope.totalEmissions) }}
                </div>
              </div>
              <div class="row">
                <div class="heading">Climate Positive Option (120%)</div>
                <div class="heading">
                  {{
                    $root.formatNumber(emissionsByScope.totalEmissions * 1.2)
                  }}
                </div>
              </div>
              <div class="row">
                <div class="heading">Zero Carbon Option with Pre-offset (100%)</div>
                <div class="heading">
                  {{ $root.formatNumber(emissionsByScope.totalPreOffsetEmissions)  }}
                </div>
              </div>
              <div class="row">
                <div class="heading">Climate Positive Option with Pre-offset (120%)</div>
                <div class="heading">
                  {{ $root.formatNumber(emissionsByScope.totalPreOffsetEmissions * 1.2) }}
                </div>
              </div>
            </div>
          </div>
          <div class="activity-data">
            <div class="grid-title top offset">
              <div class="row">
                <div class="heading">Activity</div>
                <div class="heading">Cost NZD</div>
              </div>
            </div>
            <div class="activity-grid">
              <template
                v-for="(activity, index) in emissionsByScope.activities"
              >
                <div class="item" :key="`${index}-1`">{{ activity.name }}</div>
                <div class="item" :key="`${index}-2`">
                  ${{ $root.formatNumber(activity.offset) }}
                </div>
              </template>
            </div>
            <div class="grid-title bottom offset">
              <div class="row">
                <div class="heading">Grand Total</div>
                <div class="heading lower">Offset with</div>
              </div>
              <div class="row">
                <div class="heading">Zero Carbon Option (100%)</div>
                <div class="heading">
                  ${{ $root.formatNumber(emissionsByScope.totalOffset) }}
                </div>
              </div>
              <div class="row">
                <div class="heading">Climate Positive Option (120%)</div>
                <div class="heading">
                  ${{ $root.formatNumber(emissionsByScope.totalOffset * 1.2) }}
                </div>
              </div>
              <div class="row">
                <div class="heading">Zero Carbon Option with Pre-offset (100%)</div>
                <div class="heading">
                  ${{ $root.formatNumber(emissionsByScope.totalPreOffset) }}
                </div>
              </div>
              <div class="row">
                <div class="heading">Climate Positive Option with Pre-offset (120%)</div>
                <div class="heading">
                  ${{ $root.formatNumber(emissionsByScope.totalPreOffset * 1.2) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="activity-section">
          <div class="activity-title-row">
            <h3 class="section-title">Carbon Footprint by Scope</h3>
            <h3 class="section-title">Carbon Offset by Scope</h3>
          </div>
          <div class="activity-data-row">
            <div class="activity-data">
              <div class="grid-title top footprint">
                <div class="row">
                  <div class="heading">Scope 1 Emissions</div>
                  <div class="heading lower">Emissions</div>
                </div>
                <div class="row">
                  <div class="heading">Direct Emissions</div>
                  <div class="heading lower">tCO2e</div>
                </div>
              </div>
              <div class="activity-grid">
                <template v-for="(activity, index) in scopeOneActivities">
                  <div class="item" :key="`${index}-1`">
                    {{ activity.name }}
                  </div>
                  <div class="item" :key="`${index}-2`">
                    {{ $root.formatNumber(activity.tCO2e) }}
                  </div>
                </template>
              </div>
              <div class="grid-title bottom footprint">
                <div class="row">
                  <div class="heading total">Total:</div>
                  <div class="heading total">
                    {{ $root.formatNumber(emissionsByScope.scopeOneEmissions) }}
                  </div>
                </div>
              </div>
              <div class="grid-title top footprint bottom footprint">
                <div class="row">
                  <div class="heading">Scope 2 Emissions</div>
                  <div class="heading lower">Emissions</div>
                </div>
                <div class="row">
                  <div class="heading">Indirect Energy</div>
                  <div class="heading lower">tCO2e</div>
                </div>
                <div class="row">
                  <div class="heading total">Total:</div>
                  <div class="heading total">
                    {{ $root.formatNumber(emissionsByScope.scopeTwoEmissions) }}
                  </div>
                </div>
              </div>
              <div class="grid-title top footprint">
                <div class="row">
                  <div class="heading">Scope 3 Emissions</div>
                  <div class="heading lower">Emissions</div>
                </div>
                <div class="row">
                  <div class="heading">Indirect Emissions</div>
                  <div class="heading lower">tCO2e</div>
                </div>
              </div>
              <div class="activity-grid">
                <template v-for="(activity, index) in scopeThreeActivities">
                  <div class="item" :key="`${index}-1`">
                    {{ activity.name }}
                  </div>
                  <div class="item" :key="`${index}-2`">
                    {{ $root.formatNumber(activity.tCO2e) }}
                  </div>
                </template>
              </div>
              <div class="grid-title footprint">
                <div class="row">
                  <div class="heading total">Total:</div>
                  <div class="heading total">
                    {{
                      $root.formatNumber(emissionsByScope.scopeThreeEmissions)
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="activity-data">
              <!-- Carbon offsets by scope  -->
              <div class="grid-title top offset">
                <div class="row">
                  <div class="heading">Scope 1 Emissions</div>
                  <div class="heading lower">Cost to Offset</div>
                </div>
                <div class="row">
                  <div class="heading">Direct Emissions</div>
                  <div class="heading"></div>
                </div>
              </div>
              <div class="activity-grid">
                <template v-for="(activity, index) in scopeOneActivities">
                  <div class="item" :key="`${index}-1`">
                    {{ activity.name }}
                  </div>
                  <div class="item" :key="`${index}-2`">
                    ${{ $root.formatNumber(activity.offset) }}
                  </div>
                </template>
              </div>
              <div class="grid-title offset bottom">
                <div class="row">
                  <div class="heading total">Total:</div>
                  <div class="heading total">
                    ${{ $root.formatNumber(emissionsByScope.scopeOneOffset) }}
                  </div>
                </div>
              </div>
              <div class="grid-title offset top bottom">
                <div class="row">
                  <div class="heading">Scope 2 Emissions</div>
                  <div class="heading lower">Cost to Offset</div>
                </div>
                <div class="row">
                  <div class="heading">Purchased Energy</div>
                  <div class="heading"></div>
                </div>
                <div class="row">
                  <div class="heading total">Total:</div>
                  <div class="heading total">
                    ${{ $root.formatNumber(emissionsByScope.scopeTwoOffset) }}
                  </div>
                </div>
              </div>
              <div class="grid-title offset top">
                <div class="row">
                  <div class="heading">Scope 3 Emissions</div>
                  <div class="heading lower">Cost to Offset</div>
                </div>
                <div class="row">
                  <div class="heading">Indirect Emissions</div>
                  <div class="heading"></div>
                </div>
              </div>
              <div class="activity-grid">
                <template v-for="(activity, index) in scopeThreeActivities">
                  <div class="item" :key="`${index}-1`">
                    {{ activity.name }}
                  </div>
                  <div class="item" :key="`${index}-2`">
                    ${{ $root.formatNumber(activity.offset) }}
                  </div>
                </template>
              </div>
              <div class="grid-title offset">
                <div class="row">
                  <div class="heading total">Total:</div>
                  <div class="heading total">
                    ${{ $root.formatNumber(emissionsByScope.scopeThreeOffset) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'summary-results',
  props: { emissionsByScope: Object },
  computed: {
    scopeOneActivities () {
      return this.emissionsByScope.activities.filter(
        activity => activity.scope === 1
      )
    },
    scopeTwoActivities () {
      return this.emissionsByScope.activities.filter(
        activity => activity.scope === 2
      )
    },
    scopeThreeActivities () {
      return this.emissionsByScope.activities.filter(
        activity => activity.scope === 3
      )
    }
  }
}
</script>

<style></style>
