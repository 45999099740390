<template>
  <div id="app">
    <div v-if="$store.state.user.authenticated" class="app-container">
      <Toolbar
        v-if="isActivityRoute"
        @onYearChange="onDateChange"
        @onMonthChange="onDateChange"
      />
      <MainNavigationPanel :key="$root.time" v-if="isActivityRoute" />
      <div class="main-area" :class="isActivityRoute ? '' : 'full'">
        <div class="main-content" :class="!isActivityRoute ? 'no-space' : ''">
          <CalculationResults v-if="isActivityRoute && !$store.state.app.loading"/>
          <router-view v-if="!$store.state.app.loading" />
          <div v-else class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
      </div>
      <div v-if="devMode() === 'development'" class="reset-btn" @click="$root.resetSchema">Reset Schema</div>
    </div>
  </div>
</template>

<script>
import CalculationResults from './components/CalculationResults.vue'
import MainNavigationPanel from './components/navigation/MainNavigationPanel'
import Toolbar from './components/toolbar/Toolbar'
import dataMixin from './mixins/dataMixin.vue'

export default {
  name: 'app',
  components: { MainNavigationPanel, Toolbar, CalculationResults },
  mixins: [dataMixin],
  data () {
    return {
      showModal: false,
      scopeOneSections: [],
      scopeOneSchemas: [],
      scopeTwoSections: [],
      scopeTwoSchemas: [],
      scopeThreeSections: [],
      scopeThreeSchemas: [],
      loadDataInterval: null
    }
  },
  beforeMount () {
    this.scopeOneSchemas = this.$root.scopeOneSchemas
    this.scopeOneSections = this.$root.scopeOneSections
    this.scopeTwoSchemas = this.$root.scopeTwoSchemas
    this.scopeTwoSections = this.$root.scopeTwoSections
    this.scopeThreeSchemas = this.$root.scopeThreeSchemas
    this.scopeThreeSections = this.$root.scopeThreeSections
  },
  mounted () {
    window.localStorage.removeItem('loginDetails')
  },
  methods: {
    async onDateChange (date) {
      this.$store.state.app.loading = true

      // if date of 4 characters is year else month
      if ((date).toString().length === 4) {
        if (parseInt(date) === parseInt(this.$store.state.app.selectedYear)) return
        this.$store.state.app.selectedYear = date.toString()
      } else {
        if (parseInt(date) === parseInt(this.$store.state.app.selectedMonth)) return
        this.$store.state.app.selectedMonth = date.toString()
      }

      await this.$store.dispatch('loadAppData')
      if ((date).toString().length === 4) await this.$root.updateSessionLocale(date)
      await this.$root.loadData()
      this.$store.state.app.loading = false

      if (this.$route.name !== 'dashboard') this.$router.push({ path: '/dashboard' })
    }
  },
  computed: {
    mode () {
      return process.env.NODE_ENV
    },
    isActivityRoute () {
      if (this.$route.path === '/') {
        return false
      } else {
        return true
      }
    }
  },
  watch: {
    $route (to, from) {
      // console.log('route changed')
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 100)
    }
  }
}
</script>
