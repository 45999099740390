<template>
  <div class="section business-travel">
    <div class="activity-title-row">
      <h1 class="activity-title">Business Travel</h1>
      <i class="title-icon fad fa-cars"></i>
    </div>
    <div class="controls-container" :style="{ 'flex-direction': 'row' }">
      <h2 class="secondary-title">Reimbursed Travel in Private Vehicles</h2>
      <div class="toggle-btn-container">
        <div
          class="toggle-btn"
          :class="enterByVehicles === 'mileage' ? 'active' : 'inactive'"
          @click=";(enterByVehicles = 'mileage'), updateActiveStatus('enterByVehicles', 'Business Travel Vehicle Mileage')"
        >
          <p class="btn-text">Enter by Mileage</p>
        </div>
        <div
          class="toggle-btn"
          :class="enterByVehicles === 'fuels' ? 'active' : 'inactive'"
          @click=";(enterByVehicles = 'fuels'), updateActiveStatus('enterByVehicles', 'Business Travel Vehicle Fuels')"
        >
          <p class="btn-text">Enter by Fuels</p>
        </div>
      </div>
    </div>
    <BusinessTravelComponent
      v-if="enterByVehicles === 'mileage'"
      title="Business Travel"
      measurement="kms"
      :showDiesel="true"
      v-bind:initialSchema="schema[this.getSchemaIndex('Business Travel Vehicle Mileage')]"
      schemaName="Business Travel Vehicle Mileage"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="business-travel-mileage"
    />
    <Notes
      v-if="enterByVehicles === 'mileage'"
      :key="0"
      :schemaIndex="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <BusinessTravelFuelsComponent
      v-if="enterByVehicles === 'fuels'"
      title="Fuels"
      measurement="litres"
      v-bind:initialSchema="schema[this.getSchemaIndex('Business Travel Vehicle Fuels')]"
      schemaName="Business Travel Vehicle Fuels"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="business-travel-fuels"
    />
    <Notes
      v-if="enterByVehicles === 'fuels'"
      :key="1"
      :schemaIndex="1"
      :dataSource="schema[1].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[1].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[1].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>
    <div class="controls-container" :style="{ 'flex-direction': 'row' }">
      <h2 class="secondary-title">Taxi Business Travel Emissions</h2>
      <div class="toggle-btn-container">
        <div
          class="toggle-btn"
          :class="enterByTaxis === 'money' ? 'active' : 'inactive'"
          @click=";(enterByTaxis = 'money'), updateActiveStatus('enterByTaxis', 'Business Travel Taxi Money')"
        >
          <p class="btn-text">Enter by Cost</p>
        </div>
        <div
          class="toggle-btn"
          :class="enterByTaxis === 'distance' ? 'active' : 'inactive'"
          @click=";(enterByTaxis = 'distance'), updateActiveStatus('enterByTaxis', 'Business Travel Taxi Distance')"
        >
          <p class="btn-text">Enter by Distance</p>
        </div>
      </div>
    </div>
    <BusinessTravelComponent
      v-if="enterByTaxis === 'money'"
      title="Taxis Cost"
      measurement="$"
      v-bind:initialSchema="schema[this.getSchemaIndex('Business Travel Taxi Money')]"
      schemaName="Business Travel Taxi Money"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="business-travel-taxi-money"
    />
    <Notes
      v-if="enterByTaxis === 'money'"
      :key="2"
      :schemaIndex="2"
      :dataSource="schema[2].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[2].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[2].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <BusinessTravelDistanceTraveledComponent
      v-if="enterByTaxis === 'distance'"
      title="Taxis Distance Travelled"
      type="flights"
      v-bind:initialSchema="schema[this.getSchemaIndex('Business Travel Taxi Distance')]"
      schemaName="Business Travel Taxi Distance"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="business-travel-taxi-mileage"
    />
    <Notes
      v-if="enterByTaxis === 'distance'"
      :key="3"
      :schemaIndex="3"
      :dataSource="schema[3].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[3].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[3].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>

    <BusinessTravelComponent
      title="Rental Cars"
      measurement="kms"
      v-bind:initialSchema="schema[this.getSchemaIndex('Business Travel Rental Cars')]"
      schemaName="Business Travel Rental Cars"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="business-travel-rental"
    />
    <Notes
      :key="4"
      :schemaIndex="4"
      :dataSource="schema[4].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[4].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[4].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>
    <BusinessTravelComponent
      title="Public Transport"
      measurement="kms"
      v-bind:initialSchema="schema[this.getSchemaIndex('Business Travel Public Transport')]"
      schemaName="Business Travel Public Transport"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="business-travel-public-transport"
    />
    <Notes
      :key="5"
      :schemaIndex="5"
      :dataSource="schema[5].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[5].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[5].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <NextSectionButton
      :nextRouteSchema="$root.travelAndTransport.schemas[4]"
      :nextRouteActivity="$root.travelAndTransport.sections[4]"
      sectionName="travelAndTransport"
    />

  </div>
</template>

<script>
import BusinessTravelComponent from './BusinessTravelComponent'
import BusinessTravelFuelsComponent from './BusinessTravelFuelsComponent'
import BusinessTravelDistanceTraveledComponent from './BusinessTravelDistanceTraveledComponent'
import Notes from '../../../Notes'
import NextSectionButton from '../../../NextSectionButton.vue'

export default {
  metaData () {
    return {
      title: `dCarbon — Business Travel`,
      description: ''
    }
  },
  name: 'activity-business-travel',
  components: {
    BusinessTravelComponent,
    BusinessTravelFuelsComponent,
    BusinessTravelDistanceTraveledComponent,
    Notes,
    NextSectionButton
  },
  props: { initialSchema: Object, scope: String },

  data () {
    return {
      schema: {},
      enterByVehicles: 'mileage',
      enterByTaxis: 'money'
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  mounted () {
    this.getActiveSchemas()
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    calculateTotal (schema, schemaName) {
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getActiveSchemas () {
      if (this.schema[this.getSchemaIndex('Business Travel Vehicle Mileage')].active === true) {
        this.enterByVehicles = 'mileage'
      } else {
        this.enterByVehicles = 'fuels'
      }
      if (this.schema[this.getSchemaIndex('Business Travel Taxi Money')].active === true) {
        this.enterByTaxis = 'money'
      } else {
        this.enterByTaxis = 'distance'
      }
    },
    updateActiveStatus (toggleName, schemaName) {
      let resetSchemas = this.schema.filter(s => s.toggleName === toggleName)
      resetSchemas.forEach(schema => {
        schema.active = false
      })
      this.schema[this.getSchemaIndex(schemaName)].active = true
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    }
  }
}
</script>
