<template>
  <div class="input-section">
    <div class="tooltip">
      <h1 class="section-title">
        {{ title }}
      </h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <Controls
      v-if="$root.userDetails.organisationStatus !== 'Completed'"
      v-on:add="addUnit()"
      v-on:remove="removeUnit()"
      v-on:update="calculateCarbonEmissions()"
    />
    <div
      class="input-container-row"
      v-for="(hotelStay, index) in schema.accommodation"
      :key="`item-${index}`"
    >
      <div>
        <label>Country</label>
        <select :disabled="$root.userDetails.organisationStatus === 'Completed'"
          name="country"
          id="country"
          v-model="hotelStay.country"
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        >
          <option value="" disabled selected>Select a Country</option>
          <template v-for="(country, index) in countries">
            <option v-bind:value="country.name" :key="index">{{
              country.name
            }}</option>
          </template>
        </select>
      </div>

      <div>
        <label>Rooms</label>
        <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
          type="number"
          min="0"
          v-model.number="hotelStay.rooms"
          v-on:change="() => {
            hotelStay.rooms = hotelStay.rooms || 0;
            calculateCarbonEmissions()
          }"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
      </div>

      <div>
        <label>Nights </label>
        <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
          type="number"
          min="0"
          v-model.number="hotelStay.nights"
          v-on:change="() => {
            hotelStay.nights = hotelStay.nights || 0;
            calculateCarbonEmissions()
          }"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
      </div>
      <div>
        <label>
          Carbon Emissions
        </label>
        <h2>{{ $root.formatNumber(hotelStay.carbonEmissions) }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import Controls from '../../../controls'
import Modal from '../../../modal/Modal.vue'

export default {
  name: 'accommodation-component',
  props: {
    title: String,
    initialSchema: Object,
    item: Object,
    activityID: String,
    schemaName: String
  },
  components: { Controls, Modal },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },
  mounted () {},
  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions () {
      var self = this
      return axios
        .post(
          '' +
            process.env.VUE_APP_APIURL +
            'api/calculateScopeThreeBusinessAcommodation/',
          {
            schema: this.schema
            // calculationData: calculationData.scopeOneCompanyVehiclesFuels
          }
        )
        .then(function (response) {
          self.schema = response.data.result
          self.$emit('calculateTotal', response.data.result, self.schemaName)
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          self.$root.saveData()
        })
    },
    addUnit () {
      // Add unit value (0) to each unit array in month array
      var item = {
        country: '',
        rooms: 0,
        nights: 0,
        eFactor: 0,
        carbonEmissions: 0
      }

      this.schema.accommodation.push(item)
    },
    removeUnit () {
      if (this.schema.accommodation.length > 1) {
        this.schema.accommodation.pop()
      }
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.$options.name}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  computed: {
    countries () {
      let countries = JSON.parse(JSON.stringify(this.schema.countries))
      return countries.sort((a, b) => a.name.localeCompare(b.name))
    }
  }
}
</script>
