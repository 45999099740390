var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-section"},[_c('div',{staticClass:"activity-title-row"},[_c('div',{staticClass:"tooltip"},[_c('h1',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.title))]),(_vm.tooltip)?_c('div',{staticClass:"modal-btn",on:{"click":_vm.openModal}},[_c('i',{staticClass:"fas fa-question-circle"})]):_vm._e()])]),_c('div',{staticClass:"modal-wrapper",class:_vm.modalOpen ? 'active' : ''},[_c('Modal',{attrs:{"tooltip":_vm.tooltip},model:{value:(_vm.modalOpen),callback:function ($$v) {_vm.modalOpen=$$v},expression:"modalOpen"}})],1),_c('div',{staticClass:"controls",staticStyle:{"padding-top":"25px"}},[(_vm.$root.userDetails.organisationStatus !== 'Completed')?_c('Controls',{directives:[{name:"show",rawName:"v-show",value:(!_vm.schema.annually),expression:"!schema.annually"}],on:{"add":function($event){return _vm.addUnit()},"remove":function($event){return _vm.removeUnit()},"update":function($event){return _vm.calculateCarbonEmissions()}}}):_vm._e()],1),(!_vm.schema.annually)?_c('div',{staticClass:"input-container-column"},_vm._l((_vm.schema.months[0].units.length),function(index){return _c('div',{key:index},[_c('h2',{staticClass:"secondary-title"},[_vm._v("Unit "+_vm._s(index)+" ("+_vm._s(_vm.measurement)+")")]),_c('div',{staticClass:"input-container-grid"},_vm._l((4),function(i){return _c('div',{key:i,staticClass:"input-item stacked"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.schema.months[i - 1].month))]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.schema.months[i - 1].units[index - 1]),expression:"schema.months[i - 1].units[index - 1]",modifiers:{"number":true}}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"type":"number","step":"0.01","min":"0"},domProps:{"value":(_vm.schema.months[i - 1].units[index - 1])},on:{"change":function () {
              _vm.schema.months[i - 1].units[index - 1] = _vm.schema.months[i - 1].units[index - 1] || 0;
              _vm.calculateCarbonEmissions()
            },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.schema.months[i - 1].units, index - 1, _vm._n($event.target.value))}}})])}),0),_c('div',{staticClass:"input-container-grid"},_vm._l((4),function(i){return _c('div',{key:i,staticClass:"input-item stacked"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.schema.months[i + 3].month))]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.schema.months[i + 3].units[index - 1]),expression:"schema.months[i + 3].units[index - 1]",modifiers:{"number":true}}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"type":"number","step":"0.01","min":"0"},domProps:{"value":(_vm.schema.months[i + 3].units[index - 1])},on:{"change":function () {
              _vm.schema.months[i + 3].units[index - 1] = _vm.schema.months[i + 3].units[index - 1] || 0;
              _vm.calculateCarbonEmissions()
            },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.schema.months[i + 3].units, index - 1, _vm._n($event.target.value))}}})])}),0),_c('div',{staticClass:"input-container-grid"},_vm._l((4),function(i){return _c('div',{key:i,staticClass:"input-item stacked"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.schema.months[i + 7].month))]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.schema.months[i + 7].units[index - 1]),expression:"schema.months[i + 7].units[index - 1]",modifiers:{"number":true}}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"type":"number","step":"0.01","min":"0"},domProps:{"value":(_vm.schema.months[i + 7].units[index - 1])},on:{"change":function () {
              _vm.schema.months[i + 7].units[index - 1] = _vm.schema.months[i + 7].units[index - 1] || 0;
              _vm.calculateCarbonEmissions()
            },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.schema.months[i + 7].units, index - 1, _vm._n($event.target.value))}}})])}),0),_c('div',{staticClass:"month-unit"},[_c('h2',{staticClass:"secondary-title"},[_vm._v(" Unit Total "+_vm._s(_vm.totalUnitLitres[index - 1])+" ")])])])}),0):_c('div',{staticClass:"month-unit"},[_c('h2',{staticClass:"secondary-title"},[_vm._v(_vm._s(_vm.$store.state.app.selectedMonth === '0' ? 'Yearly' : 'Monthly')+" Total ("+_vm._s(_vm.measurement)+")")]),_c('div',{staticClass:"unit"},[_c('div',{staticClass:"input-item"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.schema.emissionSources[0].annualTotal),expression:"schema.emissionSources[0].annualTotal",modifiers:{"number":true}}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"type":"number","step":"0.01","min":"0"},domProps:{"value":(_vm.schema.emissionSources[0].annualTotal)},on:{"change":function () {
              _vm.schema.emissionSources[0].annualTotal = _vm.schema.emissionSources[0].annualTotal || 0;
              _vm.calculateCarbonEmissions()
            },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.schema.emissionSources[0], "annualTotal", _vm._n($event.target.value))}}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }