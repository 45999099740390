<template>
  <div
    class="section processing-sold-goods upstream-leased-assets waste-water-waste"
  >
    <div class="activity-title-row">
      <h1 class="activity-title">Processing Sold Goods</h1>
      <i class="title-icon fad fa-hand-holding-usd"></i>
    </div>

    <ElectricityComponent
      title="Electricity"
      measurement="kWh"
      v-bind:initialSchema="schema[getSchemaIndex('Electricity')]"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="electricity"
      schemaName="Electricity"
    />
    <Notes
      :schemaIndex="0"
      :key="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>

    <StationaryFuelsComponent
      title="Stationary Fuels"
      measurement="litres"
      schemaName="Stationary Fuels"
      v-bind:initialSchema="schema[getSchemaIndex('Stationary Fuels')]"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="stationary-fuels"
    />
    <Notes
      :schemaIndex="1"
      :key="1"
      :dataSource="schema[1].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[1].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[1].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>
      <StationaryFuelsComponent
      title="Mobile Fuels"
      measurement="litres"
      v-bind:initialSchema="
        schema[this.getSchemaIndex('Mobile Fuels')]
      "
      schemaName="Mobile Fuels"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="mobile-fuels"
    />
      <Notes
      :schemaIndex="2"
      :key="2"
      :dataSource="schema[2].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[2].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[2].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
        <div class="spacer"></div>

    <LandfillWasteComponent
      type="product"
      title="Landfill Waste"
      schemaName="Landfill Waste"
      v-bind:initialSchema="schema[getSchemaIndex('Landfill Waste')]"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="landfill-waste"
    />
    <Notes
      :schemaIndex="3"
      :key="3"
      :dataSource="schema[3].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[3].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[3].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>

    <WasteWaterTreatmentComponent
      type="product"
      title="Wastewater Treatment"
      schemaName="Waste Water Treatment"
      v-bind:initialSchema="schema[getSchemaIndex('Waste Water Treatment')]"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="waste-water-treatment"
    />
    <Notes
      :schemaIndex="4"
      :key="4"
      :dataSource="schema[4].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[4].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[4].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <NextSectionButton
      :nextRouteSchema="$root.goodsAndServices.schemas[2]"
      :nextRouteActivity="$root.goodsAndServices.sections[2]"
      sectionName="goodsAndServices"

    />
  </div>
</template>

<script>
import NextSectionButton from '../../../NextSectionButton.vue'
import Notes from '../../../Notes.vue'
import ElectricityComponent from '../../../scopeTwo/sections/electricity/ElectricityComponent'
import StationaryFuelsComponent from '../upstreamLeasedAssets/UpstreamLeasedAssetFuelsComponent'
import LandfillWasteComponent from '../wasteWaterWaste/LandfillWasteComponent'
import WasteWaterTreatmentComponent from '../wasteWaterWaste/WasteWaterTreatmentComponent'

export default {
  metaData () {
    return {
      title: `dCarbon — Processing Sold Goods`,
      description: ''
    }
  },
  name: 'activity-processing-sold-goods',
  components: {
    ElectricityComponent,
    StationaryFuelsComponent,
    LandfillWasteComponent,
    WasteWaterTreatmentComponent,
    Notes,
    NextSectionButton
  },
  props: { initialSchema: Object, scope: String },

  data () {
    return {
      schema: {}
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    calculateTotal (schema, schemaName) {
      // console.log(schemaName)
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    }
  }
}
</script>
