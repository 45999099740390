<template>
  <div class="section inward-freight">
    <div class="activity-title-row">
      <h1 class="activity-title">Inward Freight</h1>
      <i class="title-icon fad fa-truck-moving"></i>
    </div>

    <InwardFreightSeaFreightComponent
      title="Sea Freight Received"
      v-bind:initialSchema="schema[this.getSchemaIndex('Sea Freight Received')]"
      schemaName="Sea Freight Received"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="sea-freight"
    />
    <Notes
      :schemaIndex="0"
      :key="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>

    <InwardFreightAirFreightComponent
      title="Air Freight Received"
      v-bind:initialSchema="schema[this.getSchemaIndex('Air Freight Received')]"
      schemaName="Air Freight Received"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="air-freight"
    />
    <Notes
      :schemaIndex="1"
      :key="1"
      :dataSource="schema[1].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[1].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[1].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>

    <InwardFreightAirFreightComponent
      title="Other Freight Received"
      v-bind:initialSchema="
        schema[this.getSchemaIndex('Other Freight Received')]
      "
      schemaName="Other Freight Received"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="road-freight"
    />
    <Notes
      :schemaIndex="2"
      :key="2"
      :dataSource="schema[2].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[2].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[2].assumptions"
      @assumptionsChanged="updateAssumptions"
    />

  <NextSectionButton
    :nextRouteSchema="$root.freight.schemas[1]"
    :nextRouteActivity="$root.freight.sections[1]"
    sectionName="freight"
  />

  </div>
</template>

<script>
import InwardFreightSeaFreightComponent from './InwardFreightSeaFreightComponent'
import InwardFreightAirFreightComponent from './InwardFreightAirFreightComponent'
import Notes from '../../../Notes.vue'
import NextSectionButton from '../../../NextSectionButton.vue'

export default {
  metaData () {
    return {
      title: `dCarbon — Inward Freight`,
      description: ''
    }
  },
  name: 'activity-inward-freight',
  components: {
    InwardFreightSeaFreightComponent,
    InwardFreightAirFreightComponent,
    Notes,
    NextSectionButton
  },
  props: { initialSchema: Object, scope: String },

  data () {
    return {
      schema: {}
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  computed: {
    sectionIndex () {
      return this.nextSections.findIndex(ns =>
        ns.id === this.$options.name.split('activity-')[1]
      ) + 1
    },
    nextSchemaIndex () {
      return this.$root.freight.sections.findIndex(handle => handle === this.nextSections[this.sectionIndex].id)
    },
    nextSections () {
      return this.$root.visibleSections.filter(vs => {
        return this.$root.freight.sections.find(handle => handle === vs.id)
      }
      )
    }
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    calculateTotal (schema, schemaName) {
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    }
  }
}
</script>
