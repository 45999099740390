<template>
  <div class="input-section">
    <div class="activity-title-row">
      <!-- Modal start -->
      <div class="tooltip">
        <h1 class="section-title">{{ title }}</h1>
        <div v-if="tooltip" class="modal-btn" @click="openModal">
          <i class="fas fa-question-circle"></i>
        </div>
      </div>

      <!-- Modal end -->
    </div>
            <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
        <Modal v-model="modalOpen" :tooltip="tooltip" />
      </div>
    <div class="controls" style="padding-top: 25px;">
      <Controls
        v-if="$root.userDetails.organisationStatus !== 'Completed'"
        v-show="!schema.annually"
        v-on:add="addUnit()"
        v-on:remove="removeUnit()"
        v-on:update="calculateCarbonEmissions()"
      />
    </div>
    <div v-if="!schema.annually" class="input-container-column">
      <div v-for="index in schema.months[0].units.length" :key="index">
        <h2 class="secondary-title">Unit {{ index }} ({{ measurement }})</h2>
        <div class="input-container-grid">
          <div class="input-item stacked" v-for="i in 4" :key="i">
            <label for="" class="">{{ schema.months[i - 1].month }}</label>
            <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
              v-model.number="schema.months[i - 1].units[index - 1]"
              v-on:change="() => {
                schema.months[i - 1].units[index - 1] = schema.months[i - 1].units[index - 1] || 0;
                calculateCarbonEmissions()
              }"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              type="number" step="0.01"
              min="0"
            />
          </div>
        </div>
        <div class="input-container-grid">
          <div class="input-item stacked" v-for="i in 4" :key="i">
            <label for="" class="">{{ schema.months[i + 3].month }}</label>
            <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
              v-model.number="schema.months[i + 3].units[index - 1]"
              v-on:change="() => {
                schema.months[i + 3].units[index - 1] = schema.months[i + 3].units[index - 1] || 0;
                calculateCarbonEmissions()
              }"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              type="number" step="0.01"
              min="0"
            />
          </div>
        </div>
        <div class="input-container-grid">
          <div class="input-item stacked" v-for="i in 4" :key="i">
            <label for="" class="">{{ schema.months[i + 7].month }}</label>
            <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
              v-model.number="schema.months[i + 7].units[index - 1]"
              v-on:change="() => {
                schema.months[i + 7].units[index - 1] = schema.months[i + 7].units[index - 1] || 0;
                calculateCarbonEmissions()
              }"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              type="number" step="0.01"
              min="0"
            />
          </div>
        </div>
        <div class="month-unit">
          <h2 class="secondary-title">
            Unit Total {{ totalUnitLitres[index - 1] }}
          </h2>
        </div>
      </div>
    </div>
    <div v-else class="month-unit">
      <h2 class="secondary-title">{{$store.state.app.selectedMonth === '0' ? 'Yearly' : 'Monthly'}} Total ({{ measurement }})</h2>
      <div class="unit">
        <div class="input-item">
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-model.number="schema.emissionSources[0].annualTotal"
            v-on:change="() => {
                schema.emissionSources[0].annualTotal = schema.emissionSources[0].annualTotal || 0;
                calculateCarbonEmissions()
              }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            type="number" step="0.01"
            min="0"
          />
          <!-- <p class="unit-text">{{ measurement }}</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import Controls from '../../../controls'
import Modal from '../../../modal/Modal.vue'

export default {
  name: 'fuel-consumption-component',
  components: { Controls, Modal },
  props: {
    measurement: String,
    title: String,
    schemaName: String,
    initialSchema: Object,
    activityID: String,
    identifier: String
  },
  data () {
    return {
      total: {},
      schema: {
        months: []
      },
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
    // this.total['Annual Total'] = 0.0
  },
  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions (months) {
      var self = this
      return axios
        .post(`${process.env.VUE_APP_APIURL}api/calculateScopeOneFuels`,
          {
            input: self.schema,
            annually: self.schema.annually
          }
        )
        .then(function (response) {
          // handle success
          self.schema = response.data.result
          // console.log(response.data.result)
          self.$emit('calculateTotal', response.data.result, self.schemaName)
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
        .then(function () {
          // always executed
          self.$root.saveData()
        })
    },
    addUnit () {
      // Add unit value (0) to each unit array in month array
      this.schema.months.forEach(month => {
        month.units.push(0)
      })
    },
    removeUnit () {
      if (this.schema.months[0].units.length > 1) {
        this.schema.months.forEach(month => {
          month.units.pop()
        })
      }
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.identifier}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  computed: {
    annualTotalLitres () {
      var annualTotalLitres = 0
      this.schema.months.forEach(month => {
        annualTotalLitres += parseFloat(month.units.reduce((a, b) => a + b, 0))
      })
      return annualTotalLitres
    },
    totalUnitLitres () {
      var unitTotal = [0]
      this.schema.months.forEach(month => {
        for (let i = 0; i < month.units.length; i++) {
          // If unit total empty set to 0
          if (!unitTotal[i]) {
            unitTotal[i] = 0
          }
          unitTotal[i] += parseInt(month.units[i])
        }
      })
      return unitTotal
    }
  }
}
</script>
