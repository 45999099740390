// with = LFGR
// without = non LFGR
const landfills = [
  {
    value: 'without',
    name: 'Other/Unknown'
  },
  {
    value: 'with',
    name: 'LFGR'
  },
  {
    value: 'without',
    name: 'non-LFGR'
  },
  {
    value: 'with',
    name: 'AB Lime Ltd (Winton)'
  },
  {
    value: 'without',
    name: 'Ahipara Landfill'
  },
  {
    value: 'with',
    name: 'Bonny Glenn (Rangitikei District)'
  },
  {
    value: 'without',
    name: 'Broadlands Road Landfill'
  },
  {
    value: 'without',
    name: 'Burma Road Landfill'
  },
  {
    value: 'without',
    name: 'Butlers Landfill'
  },
  {
    value: 'without',
    name: "Central Hawke's Bay District Landfill"
  },
  {
    value: 'without',
    name: 'Claris Landfill (Great Barrier Island)'
  },
  {
    value: 'without',
    name: 'Colson Road Regional Landfill'
  },
  {
    value: 'without',
    name: 'Eketahuna Landfill'
  },
  {
    value: 'without',
    name: 'Eves Valley Landfill'
  },
  {
    value: 'without',
    name: 'Fairfield Landfill (Dunedin)'
  },
  {
    value: 'with',
    name: 'Green Island Landfill'
  },
  {
    value: 'without',
    name: 'Haast Refuse Station'
  },
  {
    value: 'with',
    name: 'Hampton Downs Landfill'
  },
  {
    value: 'without',
    name: 'Innovative waste Kaikoura'
  },
  {
    value: 'without',
    name: 'Karamea Refuse Tip'
  },
  {
    value: 'with',
    name: 'Kate Valley (Amberley)'
  },
  {
    value: 'with',
    name: 'Levin Landfill'
  },
  {
    value: 'with',
    name: 'Marlborough Regional Council (Bluegums)'
  },
  {
    value: 'without',
    name: "McLeans's Pit Landfill"
  },
  {
    value: 'without',
    name: 'Mount Cooee Landfill'
  },
  {
    value: 'with',
    name: 'Omarunui Landfill'
  },
  {
    value: 'without',
    name: 'Palmerston Landfill'
  },
  {
    value: 'without',
    name: 'Pongaroa Landfill'
  },
  {
    value: 'with',
    name: 'Redruth Landfill'
  },
  {
    value: 'with',
    name: 'Redvale Landfill'
  },
  {
    value: 'without',
    name: 'Rotorua District Sanitary Landfill'
  },
  {
    value: 'without',
    name: 'Ruapehu District Landfill'
  },
  {
    value: 'without',
    name: 'Russell Landfill'
  },
  {
    value: 'with',
    name: 'Silverstream Landfill'
  },
  {
    value: 'with',
    name: 'Southern Landfill'
  },
  {
    value: 'with',
    name: 'Spicer Landfill'
  },
  {
    value: 'with',
    name: 'Tirohia Landfill (Paeroa)'
  },
  {
    value: 'without',
    name: 'Tokoroa Landfill'
  },
  {
    value: 'with',
    name: 'Victoria Flats Landfill (Queenstown/Cromwell)'
  },
  {
    value: 'without',
    name: 'Waiouru Landfill'
  },
  {
    value: 'without',
    name: 'Wairoa Landfill'
  },
  {
    value: 'without',
    name: 'Waitomo District Landfill'
  },
  {
    value: 'with',
    name: 'Whitford Landfill - Waste Disposal Services'
  },
  {
    value: 'with',
    name: 'York Valley Landfill'
  }
]

export default landfills
