<template>
  <editor-content :editor="editor" />
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'

export default {
  components: {
    EditorContent
  },

  props: {
    value: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      editor: null
    }
  },

  watch: {
    value (value) {
      const isSame = this.editor.getHTML() === value
      if (isSame) return
      this.editor.commands.setContent(value, false)
    }
  },

  mounted () {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit
      ],
      // onUpdate: () => {
      //   this.$emit('input', this.editor.getHTML())
      // },
      onFocus: () => {
        this.$emit('focus')
      },
      onBlur: () => {
        this.$emit('onBlurUpdate', this.editor.getHTML())
      }
    })
  },

  beforeDestroy () {
    this.editor.destroy()
  }
}
</script>

<style lang="scss">
/* Basic editor styles */
.tiptap {
    margin-top: 20px;
    color: #007B9D;
    border-radius: 6px;
    border: 2px solid #BAE4F0;
    background-color: transparent;
    padding: 9px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    max-width: 640px;
    min-height: 142px;
  > * + * {
    margin-top: 0.75em;
  }

  p {
    margin: initial;
  }

  p, ul {
    color: inherit;
    text-align: start;
  }

  ul,
  ol {
    list-style-type: disc;
    padding: 0 1rem;
    li { display: list-item; }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}
</style>
