
const mainNavigationData = [
  {
    title: 'Fuels and Electricity',
    object: 'fuelsAndElectricity',
    path: '/fuels-and-electricity',
    icon: 'fad fa-gas-pump'
  },
  {
    title: 'Travel and Transport',
    object: 'travelAndTransport',
    path: '/travel-and-transport',
    icon: 'fad fa-cars'
  },
  {
    title: 'Freight',
    object: 'freight',
    path: '/freight',
    icon: 'fad fa-truck-moving'
  },
  {
    title: 'Goods and Services',
    object: 'goodsAndServices',
    path: '/goods-and-services',
    icon: 'fad fa-hand-holding-usd'
  },
  {
    title: 'Waste',
    object: 'waste',
    path: '/waste',
    icon: 'fad fa-trash'
  },
  {
    title: 'Industry and Agriculture',
    object: 'industryAndAgriculture',
    path: '/industry-and-agriculture',
    icon: 'fad fa-tractor'
  },
  {
    title: 'Assets',
    object: 'assets',
    path: '/assets',
    icon: 'fad fa-coins'
  },
  {
    title: 'Financial',
    object: 'financial',
    path: '/financial',
    icon: 'fad fa-chart-line'
  },
  {
    title: 'Additional Sources',
    object: 'additionalSources',
    path: '',
    icon: 'fad fa-file-plus'
  }

]

export default mainNavigationData
