
import gql from 'graphql-tag'

export default {
  READ_CLIENTS_QUERY: gql`
    query readClients($title: String!) {
      readClients(filter: { title: {startswith: $title} }, limit: 15) {
        nodes {
          title
          id
          organisations {
            id
            title
          }
        }
      }
    }
  `,
  READ_CLIENT_ORGANISATIONS: gql`
    query readClientOrganisations($clientID: ID!) {
      readOrganisations (filter: {clientID: {eq: $clientID}}) {
        nodes {
            id
            title
        }
      }
    }
  `
}
