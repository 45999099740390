<template>
  <div v-if="$store.state.app.organisation" class="row menu bu-menu" @click="businessUnits.length ? showBUMenu = !showBUMenu : ''">
    <p class="organisation-name" :class="businessUnits.length ? 'pointer' : ''">
      <span v-if="$store.state.app.organisation"><i class="fad fa-sitemap" style="margin-right: 5px"></i> {{
        $store.state.app.organisation.title }}</span>
    </p>
    <i v-if="businessUnits.length" class="fas fa-chevron-down"></i>
    <div v-if="showBUMenu" class="user-menu dropdown-height">
      <div v-for="bu in businessUnits" :key="bu.id" class="menu-item" @click="selectOrganisation(bu.id)">
        <div class="pointer">
          <i class="fad fa-sitemap" style="margin-right: 5px"></i>
          {{ bu.title }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      showBUMenu: false
    }
  },
  created () {
    window.addEventListener('click', (e) => {
      // close bu-menu when clicked outside
      if (this.showBUMenu && !document.querySelector('.bu-menu').contains(e.target)) this.showBUMenu = false
    })
  },
  methods: {
    async selectOrganisation (id) {
      this.$store.state.app.loading = true
      await this.$store.dispatch('updateUser', { organisationID: id })
      await this.$store.dispatch('loadAppData')
      await this.$root.loadData()
      this.$store.dispatch('saveMemberSession', this.$store.state.app.client.period.nodes[0].id)
      if (this.$route.name !== 'dashboard') this.$router.push({ path: '/dashboard' })
      this.showBUMenu = false
      this.$store.state.app.loading = false
    }
  },
  computed: {
    businessUnits () {
      // return title of all business units in client state and title != organistion.title order by title
      return this.$store.state.app.client.allBusinessUnits.nodes.filter(bu => bu.title !== this.$store.state.app.organisation.title)
    }
  }
}
</script>
