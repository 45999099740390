import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store'

import FuelsMainSection from '../components/scopeOne/sections/fuels/FuelsMainSection.vue'
import CompanyVehiclesMainSection from '../components/scopeOne/sections/companyVehicles/CompanyVehiclesMainSection.vue'
import IndustrialProcessesMainSection from '../components/scopeOne/sections/industrialProcesses/IndustrialProcessesMainSection.vue'
import AirConMainSection from '../components/scopeOne/sections/airCon/AirConMainSection.vue'
import AgricultureMainSection from '../components/scopeOne/sections/agriculture/AgricultureMainSection.vue'

import ElectricityMainSection from '../components/scopeTwo/sections/electricity/ElectricityMainSection.vue'

import PurchasedGSCapitialMainSection from '../components/scopeThree/sections/purchasedGSCapitial/PurchasedGSCapitialMainSection.vue'
import InwardFreightMainSection from '../components/scopeThree/sections/inwardFreight/InwardFreightMainSection.vue'

import OutwardFreightMainSection from '../components/scopeThree/sections/outwardFreight/OutwardFreightMainSection.vue'
import FreightNotPaidByClientMainSection from '../components/scopeThree/sections/freightNotPaidByClient/FreightNotPaidByClientMainSection.vue'

import WasteWaterWasteMainSection from '../components/scopeThree/sections/wasteWaterWaste/WasteWaterWasteMainSection.vue'
import BusinessFlightsMainSection from '../components/scopeThree/sections/businessFlights/BusinessFlightsMainSection.vue'
import BusinessAccommodationMainSection from '../components/scopeThree/sections/businessAccommodation/BusinessAccommodationMainSection.vue'
import BusinessTravelMainSection from '../components/scopeThree/sections/businessTravel/BusinessTravelMainSection.vue'
import StaffCommuteMainSection from '../components/scopeThree/sections/staffCommute/StaffCommuteMainSection.vue'
import UpstreamLeasedAssetsMainSection from '../components/scopeThree/sections/upstreamLeasedAssets/UpstreamLeasedAssetsMainSection.vue'
import ProcessingSoldGoodsMainSection from '../components/scopeThree/sections/processingSoldGoods/ProcessingSoldGoodsMainSection.vue'
import UseOfSoldGoodsMainSection from '../components/scopeThree/sections/useOfSoldGoods/UseOfSoldGoodsMainSection.vue'
import EndOfLifeDisposalMainSection from '../components/scopeThree/sections/endOfLifeDisposal/EndOfLifeDisposalMainSection.vue'
import DownstreamLeasedAssetsMainSection from '../components/scopeThree/sections/downstreamLeasedAssets/DownstreamLeasedAssetsMainSection.vue'
import FranchisesMainSection from '../components/scopeThree/sections/franchises/FranchisesMainSection.vue'
import InvestmentsMainSection from '../components/scopeThree/sections/investments/InvestmentsMainSection.vue'

import PrivacyPolicy from '../components/PrivacyPolicy.vue'
import TermsOfUse from '../components/TermsOfUse.vue'

import UserDashboard from '../components/userDashboard/UserDashboard.vue'
import Admin from '../components/admin/Admin.vue'
import LoadOrganisation from '../components/LoadOrganisation.vue'
import AdditionalSourcesMainSection from '../components/custom/AdditionalSourcesMainSection.vue'

// Navigation containers
import FuelsAndElectricity from '../components/navigation/FuelsAndElectricity'
import TravelAndTransport from '../components/navigation/TravelAndTransport'
import Freight from '../components/navigation/Freight'
import GoodsAndServices from '../components/navigation/GoodsAndServices'
import Waste from '../components/navigation/Waste'
import IndustryAndAgriculture from '../components/navigation/IndustryAndAgriculture'
import Assets from '../components/navigation/Assets'
import Financial from '../components/navigation/Financial'

// Report Generation
import EmissionsInventoryReport from '../components/emissionsInventoryReport/EmissionsInventoryReport'

// Reduction Plan
import ReductionPlanLayout from '../components/reductionPlan/ReductionPlanLayout'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/dashboard'
  },
  {
    path: '/reduction-plan',
    name: 'reduction-plan',
    component: ReductionPlanLayout
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: TermsOfUse
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: UserDashboard
  },
  {
    path: '/organisations',
    name: 'organisations',
    component: LoadOrganisation
  },
  {
    path: '/admin',
    name: '/admin',
    component: Admin
  },
  {
    path: '/additional-sources',
    name: '/additional-sources',
    component: AdditionalSourcesMainSection
  },
  {
    path: '/fuels-and-electricity',
    name: 'fuels-and-electricity',
    component: FuelsAndElectricity,
    props: true,
    children: [
      {
        path: 'fuels',
        name: 'fuels',
        component: FuelsMainSection,
        props: { default: true, scope: 'scope-one' }
      },
      {
        path: 'electricity',
        name: 'electricity',
        component: ElectricityMainSection,
        props: { default: true, scope: 'scope-two' }
      }
    ]
  },
  {
    path: '/travel-and-transport',
    name: 'travel-and-transport',
    component: TravelAndTransport,
    props: true,
    children: [
      {
        path: 'company-vehicles',
        name: 'company-vehicles',
        component: CompanyVehiclesMainSection,
        props: { default: true, scope: 'scope-one' }
      },
      {
        path: 'staff-commute',
        name: 'staff-commute',
        component: StaffCommuteMainSection,
        props: { default: true, scope: 'scope-three' }
      },
      {
        path: 'business-travel',
        name: 'business-travel',
        component: BusinessTravelMainSection,
        props: { default: true, scope: 'scope-three' }
      },
      {
        path: 'business-flights',
        name: 'business-flights',
        component: BusinessFlightsMainSection,
        props: { default: true, scope: 'scope-three' }
      },
      {
        path: 'business-accommodation',
        name: 'business-accommodation',
        component: BusinessAccommodationMainSection,
        props: { default: true, scope: 'scope-three' }
      }
    ]
  },
  {
    path: '/freight',
    name: 'freight',
    component: Freight,
    props: true,
    children: [
      {
        path: 'inward-freight',
        name: 'inward-freight',
        component: InwardFreightMainSection,
        props: { default: true, scope: 'scope-three' }
      },
      {
        path: 'outward-freight',
        name: 'outward-freight',
        component: OutwardFreightMainSection,
        props: { default: true, scope: 'scope-three' }
      },
      {
        path: 'freight-not-paid-for',
        name: 'freight-not-paid-for',
        component: FreightNotPaidByClientMainSection,
        props: { default: true, scope: 'scope-three' }
      }
    ]
  },
  {
    path: '/goods-and-services',
    name: 'goods-and-services',
    component: GoodsAndServices,
    props: true,
    children: [
      {
        path: 'purchased',
        name: 'purchased',
        component: PurchasedGSCapitialMainSection,
        props: { default: true, scope: 'scope-three' }
      },
      {
        path: 'processing-sold-goods',
        name: 'processing-sold-goods',
        component: ProcessingSoldGoodsMainSection,
        props: { default: true, scope: 'scope-three' }
      },
      {
        path: 'use-of-sold-goods',
        name: 'use-of-sold-goods',
        component: UseOfSoldGoodsMainSection,
        props: { default: true, scope: 'scope-three' }
      },
      {
        path: 'end-of-life-disposal',
        name: 'end-of-life-disposal',
        component: EndOfLifeDisposalMainSection,
        props: { default: true, scope: 'scope-three' }
      }
    ]
  },
  {
    path: '/waste',
    name: 'waste',
    component: Waste,
    props: true,
    children: [
      {
        path: 'waste-water-waste',
        name: 'waste-water-waste',
        component: WasteWaterWasteMainSection,
        props: { default: true, scope: 'scope-three' }
      }
    ]
  },
  {
    path: '/industry-and-agriculture',
    name: 'industry-and-agriculture',
    component: IndustryAndAgriculture,
    props: true,
    children: [
      {
        path: 'industrial-processes',
        name: 'industrial-processes',
        component: IndustrialProcessesMainSection,
        props: { default: true, scope: 'scope-one' }
      },
      {
        path: 'aircon',
        name: 'aircon',
        component: AirConMainSection,
        props: { default: true, scope: 'scope-one' }
      },
      {
        path: 'agriculture',
        name: 'agriculture',
        component: AgricultureMainSection,
        props: { default: true, scope: 'scope-one' }
      }
    ]
  },
  {
    path: '/assets',
    name: 'assets',
    component: Assets,
    props: true,
    children: [
      {
        path: 'upstream-leased-assets',
        name: 'upstream-leased-assets',
        component: UpstreamLeasedAssetsMainSection,
        props: { default: true, scope: 'scope-three' }
      },
      {
        path: 'downstream-leased-assets',
        name: 'downstream-leased-assets',
        component: DownstreamLeasedAssetsMainSection,
        props: { default: true, scope: 'scope-three' }
      }
    ]
  },
  {
    path: '/financial',
    name: 'financial',
    component: Financial,
    props: true,
    children: [
      {
        path: 'franchises',
        name: 'franchises',
        component: FranchisesMainSection,
        props: { default: true, scope: 'scope-three' }
      },
      {
        path: 'investments',
        name: 'investments',
        component: InvestmentsMainSection,
        props: { default: true, scope: 'scope-three' }
      }
    ]
  },
  {
    path: '/emissions-inventory-report',
    name: 'emissions-inventory-report',
    component: EmissionsInventoryReport,
    props: true
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach(async (to, from, next) => {
  if (store.state.user.authAttempted && !store.state.user.authenticated) {
    window.location = process.env.VUE_APP_APIURL + 'Security/login'
    return
  }

  next()
})

export default router
