<template>
  <div>
    <div class="other-emissions">
      <h1 class="section-title activity-title">Double Counting & Pre Offset</h1>
      <div class="other-emissions-container">
        <div class="inputs">
          <div class="column">
            <div class="row input-section">
              <label>Recognised Offset Deduction</label>
              <select
                v-model="$store.state.app.report.recognisedOffsetDeduction"
                v-on:change="updateReportData('recognisedOffsetDeduction')"
                v-on:focus="$root.setEditing()"
                v-on:blur="$root.unsetEditing()"
              >
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
            </div>
            <ReportNotes
              @updateReportData="updateReportData"
              property="doubleCountingPreOffsetExtra"
              :admin="true"
            />
          </div>
        </div>
      </div>
    </div>
    <RecognisedOffsetDeductions
      v-if="$store.state.app.report.recognisedOffsetDeduction"
    />
    <div class="other-emissions">
      <div class="other-emissions-container">
        <div class="inputs">
          <div class="column">
            <div class="row input-section">
              <label
                >Confirm there were no known instances of double counting of
                emissions within this inventory</label
              >
              <select
                v-model="$store.state.app.report.noKnownInstancesOfDoubleCounting"
                v-on:change="updateReportData('noKnownInstancesOfDoubleCounting')"
                v-on:focus="$root.setEditing()"
                v-on:blur="$root.unsetEditing()"
              >
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
            </div>
            <ReportNotes
              v-if="!$store.state.app.report.noKnownInstancesOfDoubleCounting"
              @updateReportData="updateReportData"
              property="instancesOfDoubleCountingNotes"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RecognisedOffsetDeductions from './RecognisedOffsetDeductions.vue'
import ReportNotes from './ReportNotes.vue'

export default {
  components: { ReportNotes, RecognisedOffsetDeductions },
  methods: {
    updateReportData (fieldName) {
      this.$emit('updateReportData', fieldName, true)
    }
  }
}
</script>
