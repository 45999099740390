<template>
  <div class="section fuels">
    <div class="activity-title-row">
      <h1 class="activity-title">Stationary Fuels</h1>
      <i class="title-icon fad fad fa-gas-pump"></i>
    </div>

    <template v-for="(fuel, index) in schema">
      <FuelsConsumptionComponent
        :title="fuel.name"
        :measurement="fuel.emissionSources[0].unit"
        :schemaName="fuel.name"
        v-bind:initialSchema="fuel"
        @calculateTotal="calculateTotal"
        :activityID="$options.name"
        :identifier="fuel.identifier"
        :key="fuel.indentifier"
      />
      <Notes
        :schemaIndex="index"
        :key="index"
        :dataSource="schema[index].dataSource"
        @dataSourceChanged="updateDataSource"
        :dataQuality="schema[index].dataQuality"
        @dataQualityChanged="updateDataQuality"
        :assumptions="schema[index].assumptions"
        @assumptionsChanged="updateAssumptions"
      />
      <div class="spacer" :key="`spacer-${fuel.identifier}`"></div>
    </template>

    <NextSectionButton
      :nextRouteSchema="$root.fuelsAndElectricity.schemas[1]"
      :nextRouteActivity="$root.fuelsAndElectricity.sections[1]"
      sectionName="fuelsAndElectricity"
    />
  </div>
</template>

<script>
import NextSectionButton from '../../../NextSectionButton.vue'
import Notes from '../../../Notes.vue'
import FuelsConsumptionComponent from './FuelConsumptionComponent'

export default {
  metaData () {
    return {
      title: `dCarbon — Stationary Fuels`,
      description: ''
    }
  },
  name: 'activity-fuels',
  props: { initialSchema: Object, scope: String },
  components: { FuelsConsumptionComponent, Notes, NextSectionButton },
  data () {
    return {
      schema: {},
      data: {},
      annually: true
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
    // console.log('schema is')
    // console.log(this.schema)
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    calculateTotal (schema, schemaName) {
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    }
  },
  watch: {
    data: function (newVal, oldVal) {
      // console.log('changed')
      var array = []
      var count = 0
      for (const [key, value] of Object.entries(
        this.data['Natural Gas Consumption']
      )) {
        console.log(key)
        array[count] = value
        count++
      }
      this.totalFuelEmissions = array
    }
  }
}
</script>
