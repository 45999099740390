<template>
  <div v-if="nextRouteVisible" class="notes-row">
    <div class="input-section" style="width:100%">
      <div class="input-container-column">
        <div class="input-container-row" style="justify-content: flex-end;">
          <router-link
            class="next-page-btn"
            :to="{
              path: `${nextRoute.activity}`,
              params: { initialSchema: nextRoute.schema }
            }"
          >
            <p class="button-text">
              {{ nextRoute.activity.replaceAll('-', ' ') }}
              <i class="far fa-chevron-right"></i>
            </p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nextRouteSchema: Array,
    nextRouteActivity: String,
    sectionName: String
  },
  computed: {
    nextRouteVisible () {
      let section = this.$root.visibleSections.find(vs => vs.id === this.nextRouteActivity)
      return !!section
    },
    nextRoute () {
      if (this.nextRouteVisible) {
        return { activity: this.nextRouteActivity, schema: this.nextRouteSchema }
      } else {
        let originalIndex = this.$root[this.sectionName].sections.findIndex(s => s === this.nextRouteActivity)
        for (let i = originalIndex + 1; i < this.$root[this.sectionName].sections.length; i++) {
          let nextSection = this.$root.visibleSections.find(vs => vs.id === this.$root[this.sectionName].sections[i])
          if (nextSection) {
            return { activity: this.$root[this.sectionName].sections[i], schema: this.$root[this.sectionName].schemas[i] }
          }
        }
        return false
      }
    }
  }
}
</script>
