const wasteWaterWasteSchema = [
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Landfill Waste', // Title of Section on front end
    title: 'Waste & Wastewater - Landfill Waste',
    scope: 3, // scope number
    sectionID: 'waste-water-waste', // Default Schema Scope Section Name
    identifier: 'landfill-waste', // Main Section component identifier for tooltips
    version: 2.76,
    active: true,
    toggleName: 'enterBy',
    emissionSources: [
      {
        category:
          'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Waste', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Waste & Wastewater General Waste to Landfill -', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'CO2',
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Waste', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Waste & Wastewater Office Waste to Landfill -', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'office-waste',
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    units: [
      {
        title: 'Unit 1',
        generalType: 0,
        officeType: 0,
        officeRecovery: { value: 'without', name: 'Other/Unknown' },
        generalRecovery: { value: 'without', name: 'Other/Unknown' },
        months: [
          { name: 'January', generalWaste: 0, officeWaste: 0 },
          { name: 'February', generalWaste: 0, officeWaste: 0 },
          { name: 'March', generalWaste: 0, officeWaste: 0 },
          { name: 'April', generalWaste: 0, officeWaste: 0 },
          { name: 'May', generalWaste: 0, officeWaste: 0 },
          { name: 'June', generalWaste: 0, officeWaste: 0 },
          { name: 'July', generalWaste: 0, officeWaste: 0 },
          { name: 'August', generalWaste: 0, officeWaste: 0 },
          { name: 'September', generalWaste: 0, officeWaste: 0 },
          { name: 'October', generalWaste: 0, officeWaste: 0 },
          { name: 'November', generalWaste: 0, officeWaste: 0 },
          { name: 'December', generalWaste: 0, officeWaste: 0 }
        ],
        annualTotalGeneralWaste: 0,
        annualTotalEmissionsGeneralWaste: 0,
        annualTotalOfficeWaste: 0,
        annualTotalEmissionsOfficeWaste: 0,
        totalCarbonEmissions: 0.0
      }
    ],
    annually: true
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Waste Water Treatement', // Title of Section on front end
    title: 'Waste & Wastewater - Wastewater Treatment',
    scope: 3, // scope number
    sectionID: 'waste-water-waste', // Default Schema Scope Section Name
    identifier: 'waste-water-treatment', // Main Section component identifier for tooltips
    version: 2.75,
    active: true,
    toggleName: 'enterBy',
    emissionSources: [
      {
        category:
          'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Waste', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Wastewater Treatment', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'waste-water',
        unit: 'M3',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'waste-water',
        tCO2eVariableName: 'WasteWaterKgCO2ePerCM',
        CO2VariableName: 'WasteWaterKgCO2PerCM',
        N2OVariableName: 'WasteWaterKgN2OPerCM',
        CH4VariableName: 'WasteWaterKgCH4PerCM'
      }
    ],
    units: [
      {
        months: [
          { name: 'January', measurement: 0 },
          { name: 'February', measurement: 0 },
          { name: 'March', measurement: 0 },
          { name: 'April', measurement: 0 },
          { name: 'May', measurement: 0 },
          { name: 'June', measurement: 0 },
          { name: 'July', measurement: 0 },
          { name: 'August', measurement: 0 },
          { name: 'September', measurement: 0 },
          { name: 'October', measurement: 0 },
          { name: 'November', measurement: 0 },
          { name: 'December', measurement: 0 }
        ],
        annualTotal: 0
      }
    ],
    variableName: 'WasteWaterKgCO2ePerCM',
    annualGrandTotal: 0,
    totalCarbonEmissions: 0,
    annually: true,
    type: 'month'
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Waste Composting', // Title of Section on front end
    title: 'Wastewater Waste - Waste Composting',
    scope: 3, // scope number
    sectionID: 'waste-water-waste', // Default Schema Scope Section Name
    identifier: 'waste-composting', // Main Section component identifier for tooltips
    version: 2.75,
    active: true,
    toggleName: 'enterBy',
    emissionSources: [
      {
        category:
          'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Waste', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Waste Composting', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'waste-composting',
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'waste-composting',
        tCO2eVariableName: 'WasteCompostingKgCO2ePerKG',
        N2OVariableName: 'WasteCompostingKgN2OPerKG',
        CH4VariableName: 'WasteCompostingKgCH4PerKG'
      }
    ],
    units: [
      {
        months: [
          { name: 'January', measurement: 0 },
          { name: 'February', measurement: 0 },
          { name: 'March', measurement: 0 },
          { name: 'April', measurement: 0 },
          { name: 'May', measurement: 0 },
          { name: 'June', measurement: 0 },
          { name: 'July', measurement: 0 },
          { name: 'August', measurement: 0 },
          { name: 'September', measurement: 0 },
          { name: 'October', measurement: 0 },
          { name: 'November', measurement: 0 },
          { name: 'December', measurement: 0 }
        ],
        annualTotal: 0
      }
    ],
    variableName: 'WasteCompostingKgCO2ePerKG',
    annualGrandTotal: 0,
    totalCarbonEmissions: 0,
    annually: true,
    type: 'month'
  }
]
export default wasteWaterWasteSchema
