<template>
  <div v-if="$store.state.app.organisation" class="row menu year-menu"
    @click="showYearMenu = !showYearMenu">
    <p class="pointer">
      <i class="fad fa-file-contract" style="margin-right: 5px"></i>
      {{ formattedReportingPeriod }}
    </p>
    <i class="fas fa-chevron-down"></i>
    <div v-if="showYearMenu" class="user-menu dropdown-height">
      <div v-for="year in yearRange" :key="year" class="menu-item" @click="$emit('onYearChange', minYear + year)">
        <div class="pointer">
          <i class="fad fa-file-invoice" style="margin-right: 5px"></i>
          <template v-if="isAnnual">
            {{ $store.state.app.client.reportingMonthStart.substring(0, 3) }}
            {{ minYear + year }} -
            {{
              months.findIndex(
                el => el === $store.state.app.client.reportingMonthStart
              ) !== 0
              ? months[
                months.findIndex(
                  el => el === $store.state.app.client.reportingMonthStart
                ) - 1
              ].substring(0, 3)
              : months[11].substring(0, 3)
            }}
            {{
              months.findIndex(
                el => el === $store.state.app.client.reportingMonthStart
              ) !== 0
              ? minYear + year + 1
              : minYear + year
            }}
          </template>
          <template v-else>
            {{ minYear + year }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      showYearMenu: false
    }
  },
  created () {
    window.addEventListener('click', (e) => {
      // close bu-menu when clicked outside
      if (this.showYearMenu && !document.querySelector('.year-menu').contains(e.target)) this.showYearMenu = false
    })
  },
  computed: {
    isAnnual () {
      return this.$store.state.app.selectedMonth === '0'
    },
    minYear () {
      // find oldest year in client complete inventories and subtract 5 years
      // const inventories = this.$store.state.app.client.completedInventories.nodes
      // if (inventories.length === 0) return new Date().getFullYear() - 5
      // const years = inventories.map(inventory => inventory.year)
      // return Math.min(...years) - 5
      return 2016
    },
    yearRange () {
      return (new Date().getFullYear() + 1) - this.minYear
    },
    formattedReportingPeriod () {
      const reportingMonthStart = this.$store.state.app.client.reportingMonthStart
      const selectedYear = this.$store.state.app.selectedYear
      const selectedMonth = this.$store.state.app.selectedMonth

      if (parseInt(selectedMonth) !== 0) return selectedYear

      const startMonth = reportingMonthStart.substring(0, 3)
      const startYear = selectedYear

      const reportingMonthIndex = this.months.findIndex(el => el === reportingMonthStart)
      const endMonth = reportingMonthIndex !== 0
        ? this.months[reportingMonthIndex - 1].substring(0, 3)
        : this.months[11].substring(0, 3)

      const endYear = reportingMonthIndex !== 0
        ? parseInt(selectedYear) + 1
        : selectedYear

      return `${startMonth} ${startYear} - ${endMonth} ${endYear}`
    }
  }
}
</script>
