<script>
export default {
  methods: {
    renderCells (cells, cellCount = 0, heading) {
      let cellData = ''
      if (cellCount > 0) {
        // generate number of cells based on cellCount per row
        var count = 1
        cells.forEach((cell, index) => {
          if (count === 1) {
            cellData += `<tr ${heading ? "class='heading'" : ''}>`
          }
          cell = cell === Infinity || cell === 0 ? '0.00' : cell
          cellData += `<td>${
            cell !== null
              ? isNaN(cell) || cell === '' || typeof cell === 'string'
                ? cell
                : this.$root.formatNumber(cell)
              : cell !== undefined
                ? ''
                : '0.00'
          }</td>`
          if (count === cellCount) {
            cellData += '</tr>'
            count = 1
          } else {
            count++
          }
        })
      } else {
        // basic two column row generation
        cells.forEach(cell => {
          cellData += '<tr>'
          cellData += `<td>${cell.title}</td>`
          cellData += `<td>${typeof cell.value === 'string' ? cell.value : this.$root.formatNumber(cell.value)}</td>`
          cellData += '</tr>'
        })
      }
      return cellData
    },
    getEnergyConsumptionMethod (marketRate) {
      return marketRate ? '(market-based)' : '(location-based)'
    },
    getComparisonHeadings () {
      const currentEnergyConsumptionMethod = this.getEnergyConsumptionMethod(this.marketRateExists)
      const baseEnergyConsumptionMethod = this.getEnergyConsumptionMethod(this.baseMarketRateExists)
      const previousEnergyConsumptionMethod = this.getEnergyConsumptionMethod(this.previousMarketRateExists)
      if (this.baseYearExists && !this.previousYearExists) {
        return [
          'Activities',
          `Base year tCO<sub>2</sub>e ${baseEnergyConsumptionMethod}`,
          `Current year tCO<sub>2</sub>e ${currentEnergyConsumptionMethod}`,
          '% Change against base year'
        ]
      } else if (this.baseYearExists && this.previousYearExists) {
        return [
          'Activities',
          `Base year tCO<sub>2</sub>e ${baseEnergyConsumptionMethod}`,
          `Previous year tCO<sub>2</sub>e ${previousEnergyConsumptionMethod}`,
          `Current year tCO<sub>2</sub>e ${currentEnergyConsumptionMethod}`,
          '% Change against base year',
          '% Change against previous year'
        ]
      } else {
        return ['Activities', `Current year tCO<sub>2</sub>e ${currentEnergyConsumptionMethod}`]
      }
    },
    getComparisonEmissionSourceData (activity) {
      let data = []
      if (this.baseYearExists && !this.previousYearExists) {
        let baseYearActivity = this.matchingActivitiesInBaseYear.find(
          cs => cs.name === activity.name
        )
        data.push(activity.name)
        baseYearActivity ? data.push(baseYearActivity.emissions) : data.push('-')
        data.push(activity.tCO2e)
        baseYearActivity ? data.push(baseYearActivity.changePercentage) : data.push('-')
      } else if (this.baseYearExists && this.previousYearExists) {
        let baseYearActivity = this.matchingActivitiesInBaseYear.find(
          cs => cs.name === activity.name
        )
        let previousYearActivity = this.matchingActivitiesInPreviousYear.find(
          cs => cs.name === activity.name
        )
        data.push(activity.name)
        baseYearActivity ? data.push(baseYearActivity.emissions) : data.push('-')
        previousYearActivity ? data.push(previousYearActivity.emissions) : data.push('-')
        data.push(activity.tCO2e)
        baseYearActivity
          ? data.push(baseYearActivity.changePercentage)
          : data.push('-')
        previousYearActivity
          ? data.push(previousYearActivity.changePercentage)
          : data.push('-')
      } else {
        data.push(activity.name)
        data.push(activity.tCO2e)
      }
      return data
    },
    getComparisonGrandTotalData () {
      if (this.baseYearExists && !this.previousYearExists) {
        const baseYearEmissionsSources = this.$store.state.app.client.baseYear.emissionsSources.nodes
        let filteredBaseYearEmissionsSources = baseYearEmissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
        const baseTotals = this.getComparisonTotals(filteredBaseYearEmissionsSources)
        // market or location based
        const totalEmissions = this.marketRateExists ? this.emissionsByScope.totalEmissionsMarketRate : this.emissionsByScope.totalEmissions
        return [
          'Grand Total',
          baseTotals.total,
          totalEmissions,
          baseTotals.percentage
        ]
      } else if (this.baseYearExists && this.previousYearExists) {
        const baseYearEmissionsSources = this.$store.state.app.client.baseYear.emissionsSources.nodes
        let filteredBaseYearEmissionsSources = baseYearEmissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
        const baseTotals = this.getComparisonTotals(filteredBaseYearEmissionsSources)

        const previousYearEmissionsSources = this.$store.state.app.client.previousYear.emissionsSources.nodes
        let filteredPreviousYearEmissionsSources = previousYearEmissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
        const previousTotals = this.getComparisonTotals(filteredPreviousYearEmissionsSources)
        // market or location based
        const totalEmissions = this.marketRateExists ? this.emissionsByScope.totalEmissionsMarketRate : this.emissionsByScope.totalEmissions
        return [
          'Grand Total',
          baseTotals.total,
          previousTotals.total,
          totalEmissions,
          baseTotals.percentage,
          previousTotals.percentage
        ]
      } else {
        return ['Grand Total', this.marketRateExists ? this.emissionsByScope.totalEmissionsMarketRate : this.emissionsByScope.totalEmissions]
      }
    },
    getEmissionsIntensityData () {
      if (this.baseYearExists && !this.previousYearExists) {
        const baseEmissions = this.$store.state.app.client.baseYear.emissionsSources.nodes
        const baseTotals = this.getIntensityTotals(baseEmissions, this.baseYearFTE)
        // market or location based
        const totalIntensity = (this.marketRateExists ? this.emissionsByScope.totalEmissionsMarketRate : this.emissionsByScope.totalEmissions) / this.currentYearFTE
        return [
          'Emissions Intensity',
          baseTotals.intensity,
          totalIntensity,
          baseTotals.percentage,
          this.$store.state.app.reductionPlan.reductionTarget.organisationSpecificMetric,
          this.baseYearFTE,
          this.currentYearFTE,
          this.currentYearFTE > 0 ? this.percentageChanged(this.baseYearFTE, this.currentYearFTE) : '-'
        ]
      } else if (this.baseYearExists && this.previousYearExists) {
        const baseEmissions = this.$store.state.app.client.baseYear.emissionsSources.nodes
        const baseTotals = this.getIntensityTotals(baseEmissions, this.baseYearFTE)
        const previousEmissions = this.$store.state.app.client.previousYear.emissionsSources.nodes
        const previousTotals = this.getIntensityTotals(previousEmissions, this.previousYearFTE)
        // market or location based
        const totalIntensity = (this.marketRateExists ? this.emissionsByScope.totalEmissionsMarketRate : this.emissionsByScope.totalEmissions) / this.currentYearFTE
        return [
          'Emissions Intensity',
          baseTotals.intensity,
          previousTotals.intensity,
          totalIntensity,
          baseTotals.percentage,
          previousTotals.percentage,
          this.$store.state.app.reductionPlan.reductionTarget.organisationSpecificMetric,
          this.baseYearFTE,
          this.previousYearFTE,
          this.currentYearFTE,
          this.currentYearFTE > 0 ? this.percentageChanged(this.baseYearFTE, this.currentYearFTE) : '-',
          this.previousYearFTE > 0 ? this.percentageChanged(this.baseYearFTE, this.currentYearFTE) : '-'
        ]
      } else {
        return ['Emissions Intensity', (this.marketRateExists ? this.emissionsByScope.totalEmissionsMarketRate : this.emissionsByScope.totalEmissions) / this.currentYearFTE, this.$store.state.app.reductionPlan.reductionTarget.organisationSpecificMetric, this.currentYearFTE]
      }
    },
    getIntensityTotals (emissionsSources, FTE) {
      const schemaMarketRateExists = this.checkEmissionsSourcesForMarketRate(emissionsSources)
      let schemaIntensity = (schemaMarketRateExists ? this.getTotalsFromEmissionsSources(emissionsSources).emissionsMR : this.getTotalsFromEmissionsSources(emissionsSources).emissions) / FTE
      const currentIntensity = (this.marketRateExists ? this.emissionsByScope.totalEmissionsMarketRate : this.emissionsByScope.totalEmissions) / this.currentYearFTE
      let percentage = this.percentageChanged(
        schemaIntensity,
        currentIntensity
      )
      return { intensity: schemaIntensity, percentage: percentage }
    },
    getComparisonTotals (emissionsSources) {
      const schemaMarketRateExists = this.checkEmissionsSourcesForMarketRate(emissionsSources)
      let total = schemaMarketRateExists ? this.getTotalsFromEmissionsSources(emissionsSources).emissionsMR : this.getTotalsFromEmissionsSources(emissionsSources).emissions
      let percentage = this.percentageChanged(
        total,
        this.marketRateExists ? this.emissionsByScope.totalEmissionsMarketRate : this.emissionsByScope.totalEmissions
      )
      return { total: total, percentage: percentage }
    },
    getMatchingActivities (emissionsSources, emissionsSourcesToCompare) {
      let matchingSources = []
      let currentActvities = this.getEmissionsByActivity(emissionsSourcesToCompare)
      currentActvities.forEach(ca => {
        let allActivities = this.getEmissionsByActivity(emissionsSources)
        let foundActivity = allActivities.find(
          activity => activity.name === ca.name
        )
        if (foundActivity) {
          matchingSources.push({
            name: foundActivity.name,
            emissions: foundActivity.tCO2eMarketRate, // add market rate by default
            changePercentage: this.percentageChanged(
              foundActivity.tCO2eMarketRate,
              ca.tCO2eMarketRate
            )
          })
        }
      })
      return matchingSources
    },
    getOutstandingActivities (emissionsSources, emissionsSourcesToCompare) {
      let allActivities = this.getEmissionsByActivity(emissionsSources)
      let allActivitiesToCompare = this.getEmissionsByActivity(emissionsSourcesToCompare)
      let outstandingSources = []
      if (allActivities.length > 0) {
        outstandingSources = allActivities.filter(x => {
          let searchedSource = allActivitiesToCompare.find(
            cs => x.name === cs.name
          )
          return searchedSource ? !searchedSource : true
        })
      }
      return outstandingSources
    },
    getFinalComparisonSources () {
      let activitiesToAdd = []
      let outstandingSources = this.activitiesExclusiveToPreviousYear
      if (outstandingSources.length > 0) {
        outstandingSources.forEach(activity => {
          activitiesToAdd.push(activity.name)
          activitiesToAdd.push('-')
          activitiesToAdd.push(activity.tCO2e)
          activitiesToAdd.push('-')
          activitiesToAdd.push('-')
          activitiesToAdd.push('-')
        })
      }
      return activitiesToAdd
    },
    percentageChanged (oldValue, newValue) {
      const percentage = ((newValue - oldValue) / oldValue) * 100
      const formattedNumber = this.$root.formatNumber(percentage)
      return isNaN(percentage) || !isFinite(percentage) ? '-' : formattedNumber + '%'
    }
  },
  computed: {
    comparisonAgainstBaseYear () {
      let cellCount = 2
      if (this.baseYearExists && !this.previousYearExists) {
        cellCount += 2
      } else if (this.baseYearExists && this.previousYearExists) {
        cellCount += 4
      }
      let headingData = this.getComparisonHeadings()

      let heading = this.renderCells(headingData, cellCount, true)
      let comparisonData = []
      this.emissionsByScope.activities.forEach(activity => {
        comparisonData = comparisonData.concat(
          this.getComparisonEmissionSourceData(activity)
        )
      })
      comparisonData = comparisonData.concat(
        this.extraComparisonTableData
      )

      let grandTotalData = this.getComparisonGrandTotalData()

      let grandTotal = this.renderCells(grandTotalData, cellCount, false)

      let comparisonDataTable = this.renderCells(
        comparisonData,
        cellCount,
        false
      )

      if (this.showFTE && this.$store.state.app.reductionPlan.reductionTarget.organisationSpecificMetric) {
        let emissionsIntensityData = this.getEmissionsIntensityData()
        let emissionsIntensityTotal = this.renderCells(emissionsIntensityData, cellCount, false)
        return `<table class="comparisonDataTable">${heading}${comparisonDataTable}${grandTotal}${emissionsIntensityTotal}</table>`
      } else {
        return `<table class="comparisonDataTable">${heading}${comparisonDataTable}${grandTotal}</table>`
      }
    },
    'performance-against-previous-years-graph' () {
      const currentEnergyConsumptionMethod = this.getEnergyConsumptionMethod(this.marketRateExists)
      const baseEnergyConsumptionMethod = this.getEnergyConsumptionMethod(this.baseMarketRateExists)
      const previousEnergyConsumptionMethod = this.getEnergyConsumptionMethod(this.previousMarketRateExists)

      let html = `<ul class="ct-legend performance-comparison-legend" style="margin: 0px auto;"><li class="ct-series-2" data-legend="2">Current Year tCO<sub>2</sub>e ${currentEnergyConsumptionMethod}</li>`
      if (this.baseYearExists && !this.previousYearExists) {
        html += `<li class="ct-series-0" data-legend="0">Base year tCO<sub>2</sub>e ${baseEnergyConsumptionMethod}</li>`
        // return [
        //   'Activities',
        //   'Base year tCO<sub>2</sub>e',
        //   'Current year tCO<sub>2</sub>e',
        //   '% Change against base year'
        // ]
      } else if (this.baseYearExists && this.previousYearExists) {
        html += `<li class="ct-series-0" data-legend="0">Base year tCO<sub>2</sub>e ${baseEnergyConsumptionMethod}</li><li class="ct-series-1" data-legend="1">Previous year tCO<sub>2</sub>e ${previousEnergyConsumptionMethod}</li>`
      } else if (!this.baseYearExists && this.previousYearExists) {
        html += `<li class="ct-series-1" data-legend="1">Previous year tCO<sub>2</sub>e ${previousEnergyConsumptionMethod}</li>`
      }
      html += '</ul>'
      html += `<div class="performance-against-previous-years-graph"></div>`
      return html
    },
    activitiesExclusiveToPreviousYear () {
      let outstandingActivities = []
      let activitiesNotInBase = []
      let finalActivities = []
      if (this.baseYearExists && this.previousYearExists) {
        const previousEmissionsSources = this.$store.state.app.client.previousYear.emissionsSources.nodes
        const filteredPreviousEmissionsSources = previousEmissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
        const baseEmissionsSources = this.$store.state.app.client.baseYear.emissionsSources.nodes
        const filteredBaseEmissionsSources = baseEmissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
        activitiesNotInBase = this.getOutstandingActivities(filteredPreviousEmissionsSources, filteredBaseEmissionsSources)
        let allActivities = this.emissionsByScope.activities
        finalActivities = activitiesNotInBase.filter(x => {
          let searchedActivity = allActivities.find(
            cs => x.name === cs.name
          )
          return searchedActivity ? !searchedActivity : true
        })
      } else if (!this.baseYearExists && this.previousYearExists) {
        finalActivities = this.outstandingActivitiesFromPreviousYear
      }
      if (finalActivities.length > 0) {
        outstandingActivities = finalActivities
      }
      return outstandingActivities
    },
    matchingActivitiesInBaseYear () {
      let activities = []
      if (this.baseYearExists) {
        const emissionsSources = this.$store.state.app.client.baseYear.emissionsSources.nodes
        const filteredEmissionsSources = emissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
        activities = this.getMatchingActivities(filteredEmissionsSources, this.$root.allEmissionSources)
      }
      return activities
    },
    matchingActivitiesInPreviousYear () {
      let activities = []
      if (this.previousYearExists) {
        const emissionsSources = this.$store.state.app.client.previousYear.emissionsSources.nodes
        const filteredEmissionsSources = emissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
        activities = this.getMatchingActivities(filteredEmissionsSources, this.$root.allEmissionSources)
      }
      return activities
    },
    outstandingActivitiesFromBaseYear () {
      if (this.baseYearExists) {
        const emissionsSources = this.$store.state.app.client.baseYear.emissionsSources.nodes
        const filteredEmissionsSources = emissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
        return this.getOutstandingActivities(filteredEmissionsSources, this.$root.allEmissionSources)
      } else {
        return []
      }
    },
    outstandingActivitiesFromPreviousYear () {
      if (this.previousYearExists) {
        const emissionsSources = this.$store.state.app.client.previousYear.emissionsSources.nodes
        const filteredEmissionsSources = emissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
        return this.getOutstandingActivities(filteredEmissionsSources, this.$root.allEmissionSources)
      } else {
        return []
      }
    },
    extraComparisonTableData () {
      let activitiesToAdd = []
      if (this.baseYearExists && !this.previousYearExists) {
        if (this.outstandingActivitiesFromBaseYear.length > 0) {
          this.outstandingActivitiesFromBaseYear.forEach(activity => {
            activitiesToAdd.push(activity.name)
            activitiesToAdd.push(activity.tCO2e)
            activitiesToAdd.push('-')
            activitiesToAdd.push('-')
          })
        }
      } else if (this.baseYearExists && this.previousYearExists) {
        if (this.outstandingActivitiesFromBaseYear.length > 0) {
          this.outstandingActivitiesFromBaseYear.forEach(baseActivity => {
            let previousYearActivity = this.outstandingActivitiesFromPreviousYear.find(
              previous => previous.name === baseActivity.name
            )
            activitiesToAdd.push(baseActivity.name)
            activitiesToAdd.push(baseActivity.tCO2e)
            previousYearActivity ? activitiesToAdd.push(previousYearActivity.tCO2e) : activitiesToAdd.push('-')
            activitiesToAdd.push('-')
            activitiesToAdd.push('-')
            activitiesToAdd.push('-')
          })
        }
        // add Final sources only calculated in previous year
        let finalSources = this.getFinalComparisonSources()
        activitiesToAdd = activitiesToAdd.concat(finalSources)
      }
      return activitiesToAdd
    },
    marketRateExists () {
      return this.checkEmissionsSourcesForMarketRate(this.$root.allEmissionSources)
    },
    baseMarketRateExists () {
      if (!this.baseYearExists) {
        return false
      }
      const emissionsSources = this.$store.state.app.client.baseYear.emissionsSources.nodes
      return this.checkEmissionsSourcesForMarketRate(emissionsSources)
    },
    previousMarketRateExists () {
      if (!this.previousYearExists) {
        return false
      }
      const emissionsSources = this.$store.state.app.client.previousYear.emissionsSources.nodes
      return this.checkEmissionsSourcesForMarketRate(emissionsSources)
    },
    baseYearExists () {
      if (
        !this.$store.state.app.client.baseYear.year || !this.$store.state.app.client.baseYear.emissionsSources.nodes.length ||
        this.$store.state.app.client.baseYear.year === this.$store.state.app.report.year
      ) {
        return false
      } else {
        return true
      }
    },
    previousYearExists () {
      if (this.$store.state.app.client.baseYear?.id === this.$store.state.app.client.previousYear?.id) {
        return false
      }
      // if current year is base year
      if (this.$store.state.app.client.baseYear.year === this.$store.state.app.report.year) {
        return false
      }

      let expectedPreviousYear = parseInt(this.$store.state.app.selectedYear) - 1
      if (this.$store.state.app.client.previousYear) {
        let previousYear = parseInt(this.$store.state.app.client.previousYear.year)
        var previousYearTotal = 0
        try {
          previousYearTotal = this.getTotalsFromEmissionsSources(this.$store.state.app.client.previousYear.emissionsSources.nodes).emissions
        } catch (err) {
          previousYearTotal = 0
        }
        if (
          previousYear === expectedPreviousYear &&
          this.$store.state.app.client.baseYear.year !== previousYear &&
          previousYearTotal > 0
        ) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }

  }
}
</script>
