<template>
  <div class="section business-flights">
    <div class="activity-title-row">
      <h1 class="activity-title">Business Flights</h1>
      <i class="title-icon fad fa-cars"></i>
    </div>
    <BusinessFlightsComponent
      title="Domestic NZ Business Flights"
      v-bind:initialSchema="schema[this.getSchemaIndex('Domestic NZ Business Flights')]"
      schemaName="Domestic NZ Business Flights"
      @calculateTotal="calculateTotal"
      @updateEdited="updateEdited"
      :activityID="$options.name"
      :currentlyEditing="currentlyEditing"
      identifier="domestic"
    />
    <Notes
      :schemaIndex="1"
      :dataSource="schema[1].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[1].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[1].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>
    <BusinessFlightsComponent
      title="International Business Flights"
      v-bind:initialSchema="schema[this.getSchemaIndex('International Business Flights')]"
      schemaName="International Business Flights"
      @calculateTotal="calculateTotal"
      @updateEdited="updateEdited"
      :activityID="$options.name"
      :currentlyEditing="currentlyEditing"
      identifier="international"
    />
    <Notes
      :schemaIndex="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions"
    />

    <NextSectionButton
      :nextRouteSchema="$root.travelAndTransport.schemas[3]"
      :nextRouteActivity="$root.travelAndTransport.sections[3]"
      sectionName="travelAndTransport"
    />
  </div>
</template>

<script>
import BusinessFlightsComponent from './BusinessFlightsComponent'

import Notes from '../../../Notes.vue'
import NextSectionButton from '../../../NextSectionButton.vue'

export default {
  metaData () {
    return {
      title: `dCarbon — Business Flights`,
      description: ''
    }
  },
  name: 'activity-business-flights',
  props: { initialSchema: Object, scope: String },

  components: {
    BusinessFlightsComponent,
    Notes,
    NextSectionButton
  },
  data () {
    return {
      schema: {},
      currentlyEditing: ''
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    },
    calculateTotal (schema, schemaName) {
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    updateEdited (edited) {
      this.currentlyEditing = edited
    }
  }
}
</script>
