<template>
  <div v-if="show" class="modal">
    <div class="modal-content">
      <span class="hide" @click="updateReport('settings'); $emit('hide')">&times;</span>
      <div class="modal-title">
        <h2>Report Layout Settings</h2>
        <i class="fas fa-redo" @click="$store.dispatch('resetReportSettings')"></i>
      </div>
      <div>
        <div>
        <label class="label-checkbox">
          Split emissions by activities table:
          <input type="checkbox" v-model="emissionsByActivities.split" />
        </label>
        </div>
        <div v-if="emissionsByActivities.split">
          <label>
            First table max rows:
            <input
              type="number"
              v-model.number="emissionsByActivities.firstSplitCount"
            />
          </label>
          <label>
            Subsequent table max rows:
            <input
              type="number"
              v-model.number="emissionsByActivities.splitCount"
            />
          </label>
        </div>
      </div>
      <div>
        <label class="label-checkbox">
          Split activity data collection table:
          <input type="checkbox" v-model="activityDataCollection.split" />
        </label>
        <div v-if="activityDataCollection.split">
          <label>
            First table max rows:
            <input
              type="number"
              v-model.number="activityDataCollection.firstSplitCount"
            />
          </label>
          <label>
            Subsequent table max rows:
            <input
              type="number"
              v-model.number="activityDataCollection.splitCount"
            />
          </label>
        </div>
      </div>
      <div>
        <label class="label-checkbox">
          Split business units table:
          <input type="checkbox" v-model="businessUnits.split" />
        </label>
        <div v-if="businessUnits.split">
          <label>
            First table max rows:
            <input
              type="number"
              v-model.number="businessUnits.firstSplitCount"
            />
          </label>
          <label>
            Subsequent table max rows:
            <input type="number" v-model.number="businessUnits.splitCount" />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    settings: Object,
    show: Boolean
  },
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    emissionsByActivities () {
      return this.settings.emissionsByActivities
    },
    activityDataCollection () {
      return this.settings.activityDataCollection
    },
    businessUnits () {
      return this.settings.businessUnits
    }
  },
  methods: {
    async updateReport (field, loadAfterSaving = false) {
      const data = { report: { id: this.$store.state.app.report.id } }
      data.report[field] = JSON.stringify(this.$store.state.app.report[field])
      data.load = loadAfterSaving
      // console.log(data.report, 'updating report data')
      this.$root.saving = true
      await this.$store.dispatch('updateReport', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    }
  }
}
</script>

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-title {
  display: flex;
  align-items: center;
}
.modal-title i {
  margin-left: 10px;
  cursor: pointer;
}
.modal-content {
  background-color: white;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 900px;
}
.label-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 300px;
}
.hide {
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}
</style>
