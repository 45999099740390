<template>
  <div class="section business-accommodation">
    <div class="activity-title-row">
      <h1 class="activity-title">Business Accommodation</h1>
      <i class="title-icon fad fa-cars"></i>
    </div>
    <BusinessAccommodationComponent
      title="Business Accommodation"
      v-bind:initialSchema="schema[this.getSchemaIndex('Business Accommodation')]"
      schemaName="Business Accommodation"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
    />
    <div class="spacer"></div>
    <Notes
      :key="0"
      :schemaIndex="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
  </div>
</template>

<script>
import Notes from '../../../Notes.vue'
import BusinessAccommodationComponent from './BusinessAccommodationComponent'

export default {
  metaData () {
    return {
      title: `dCarbon — Business Accommodation`,
      description: ''
    }
  },
  name: 'activity-business-accommodation',
  props: { initialSchema: Object, scope: String, schemaName: String },
  components: {
    BusinessAccommodationComponent,
    Notes
  },
  data () {
    return {
      schema: {}
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    calculateTotal (schema, schemaName) {
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    }
  }
}
</script>
