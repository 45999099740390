<template>
  <div>
    <div v-if="loaded" class="dashboard-tabs">
      <Information v-if="tab === 'details'"/>
        <Recommendations
        v-if="tab === 'recommendations'"
        />
        <ReductionReport v-if="tab === 'report'" />
      </div>
      <div v-else class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
  </div>
  </template>

<script>
import ReductionReport from './ReductionReport.vue'
import Recommendations from './Recommendations.vue'
import Information from './Information.vue'
export default {
  components: { Recommendations, ReductionReport, Information },
  props: { tab: String },
  data () {
    return {
      loaded: false
    }
  },
  async mounted () {
    await this.$store.dispatch('loadCompletedInventories')
    const resB = await this.loadReductionPlan()
    if (!resB) {
      const reductionPlan = {
        clientID: this.$store.state.app.client.id
      }
      await this.createReductionPlan(reductionPlan)
    }
    // console.log('loading reduction plan es')
    // await this.$store.dispatch('loadReductionPlanEmissionSources')
    this.loaded = true
  },
  methods: {
    async loadReductionPlan () {
      const data = await this.$store.dispatch('loadReductionPlan')
      if (data) {
        // add monitoring to reduction plan
        let monitoring = await this.$store.dispatch('loadReductionMonitoring', { id: data.id })
        if (!monitoring) {
          const res = await this.$store.dispatch('createReductionMonitoring', { reductionMonitoring: { year: this.$store.state.app.selectedYear, reductionPlanID: data.id } })
          monitoring = res.data.createReductionMonitoring
        }
        data.reductionMonitoring = monitoring

        // console.log(data, 'reduction plan data')
        // const reductionMonitoring = await this.$store.dispatch('loadReductionMonitoring', { id: data.id })
        // console.log(reductionMonitoring, 'reduction monitoring data')
        // data.reductionMonitoring = reductionMonitoring
      }
      return data
    },
    async createReductionPlan (reductionPlan) {
      const res = await this.$store.dispatch('createReductionPlan', reductionPlan)
      // create reduction monitoring and add to reduction plan
      const year = this.$store.state.app.selectedYear
      const reductionMonitoring = await this.$store.dispatch('createReductionMonitoring', { reductionMonitoring: { year, reductionPlanID: res.data.createReductionPlan.id } })
      res.data.createReductionPlan.reductionMonitoring = reductionMonitoring.data.createReductionMonitoring
      await this.$store.commit('SET_REDUCTION_PLAN', res.data.createReductionPlan)
    },
    async loadReductionMonitoring (reductionPlan) {
      const res = await this.$store.dispatch('loadReductionMonitoring', reductionPlan)
      return res
    }
  }
}
</script>
