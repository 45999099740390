function getMetaData (vm) {
  const { metaData } = vm.$options
  if (metaData) {
    return typeof metaData === 'function' ? metaData.call(vm) : metaData
  }
}

export default {
  created () {
    try {
      const metaData = getMetaData(this)
      if (metaData) {
        document.title = metaData.title
        document.head.querySelector('meta[name="description"]').content =
          metaData.description
      }
    } catch (error) {
      // console.log(error)
    }
  },
  updated () {
    const metaData = getMetaData(this)
    if (metaData) {
      document.title = metaData.title
      document.head.querySelector('meta[name="description"]').content =
        metaData.description
    }
  }
}
