<template>
  <div class="performance-against-previous-years">
    <h1 class="section-title">Emission Performance Against Previous Years</h1>

    <div v-html="comparisonAgainstBaseYear" />
    <div class="performance-against-previous-years-graph"></div>
    <PerformanceComparison
      v-if="$store.state.user.clientID && !this.$root.refreshReports"
      :emissionActivities="emissionsByScope.activities"
      :baseYearActivities="matchingActivitiesInBaseYear"
      :previousYearActivities="matchingActivitiesInPreviousYear"
      :outstandingBaseYearActivities="outstandingActivitiesFromBaseYear"
      :outstandingPreviousYearActivities="outstandingActivitiesFromPreviousYear"
      :onlyPreviousYearActivities="activitiesExclusiveToPreviousYear"
    />
    <ReportNotes
      @updateReportData="updateReport"
      property="emissionsAgainstPreviousYearsNotes"
      placeholder="commentary or explanation to any significant changes in emissions against previous years"
    />
  </div>
</template>

<script>
import emissionsMixin from '../../mixins/emissionsMixin.vue'
import yearComparisonMixin from '../../mixins/yearComparisonMixin.vue'
import PerformanceComparison from '../graphs/PerformanceComparison.vue'
import ReportNotes from './ReportNotes.vue'

export default {
  components: { PerformanceComparison, ReportNotes },
  mixins: [yearComparisonMixin, emissionsMixin],
  props: { emissionsByScope: Object, emissionSources: Array },
  methods: {
    async updateReport (field, loadAfterSaving = false) {
      const data = { report: { id: this.$store.state.app.report.id } }
      data.report[field] = this.$store.state.app.report[field]
      data.load = loadAfterSaving
      // console.log(data.report, 'updating report data')
      this.$root.saving = true
      await this.$store.dispatch('updateReport', data)
      // if (loadAfterSaving) {
      //   console.log('loading')
      //   await this.$store.dispatch('loadAppData')
      // }
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    }
  }
}
</script>

<style>
</style>
