<template>
  <div class="section staff-commute">
    <div class="activity-title-row">
      <h1 class="activity-title">Staff Commuting</h1>
      <i class="title-icon fad fa-cars"></i>
    </div>
    <div class="controls-container" :style="{ 'flex-direction': 'row' }">
      <h2 class="secondary-title">Staff Vehicle Emissions</h2>
      <div class="toggle-btn-container">
        <div
          class="toggle-btn"
          :class="enterByVehicles === 'mileage' ? 'active' : 'inactive'"
          @click=";(enterByVehicles = 'mileage'), updateActiveStatus('enterByVehicles', 'Staff Vehicle Mileage')"
        >
          <p class="btn-text">Enter by Mileage</p>
        </div>
        <div
          class="toggle-btn"
          :class="enterByVehicles === 'fuels' ? 'active' : 'inactive'"
          @click=";(enterByVehicles = 'fuels'), updateActiveStatus('enterByVehicles', 'Staff Vehicle Fuels')"
        >
          <p class="btn-text">Enter by Fuels</p>
        </div>
      </div>
    </div>
    <BusinessTravelComponent
      v-if="enterByVehicles === 'mileage'"
      title="Vehicle Mileage"
      measurement="kms"
      :showDiesel="true"
      v-bind:initialSchema="schema[this.getSchemaIndex('Staff Vehicle Mileage')]"
      schemaName="Staff Vehicle Mileage"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="vehicle-mileage"
    />
    <BusinessTravelFuelsComponent
      v-if="enterByVehicles === 'fuels'"
      title="Fuels"
      measurement="litres"
      v-bind:initialSchema="schema[this.getSchemaIndex('Staff Vehicle Fuels')]"
      schemaName="Staff Vehicle Fuels"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="fuels"
    />
    <Notes
      v-if="enterByVehicles === 'mileage'"
      :key="0"
      :schemaIndex="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <Notes
      v-if="enterByVehicles === 'fuels'"
      :key="1"
      :schemaIndex="1"
      :dataSource="schema[1].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[1].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[1].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>
    <BusinessTravelComponent
      title="Staff Working from Home"
      measurement="days"
      v-bind:initialSchema="schema[this.getSchemaIndex('Staff Working from Home')]"
      schemaName="Staff Working from Home"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="work-from-home"
    />
    <Notes
      :key="2"
      :schemaIndex="2"
      :dataSource="schema[2].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[2].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[2].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>
    <BusinessTravelComponent
      title="Public Transport"
      measurement="kms"
      v-bind:initialSchema="schema[this.getSchemaIndex('Staff Commute Public Transport')]"
      schemaName="Staff Commute Public Transport"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="staff-commute-public-transport"
    />
    <Notes
      :key="3"
      :schemaIndex="3"
      :dataSource="schema[3].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[3].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[3].assumptions"
      @assumptionsChanged="updateAssumptions"
    />

    <NextSectionButton
      :nextRouteSchema="$root.travelAndTransport.schemas[2]"
      :nextRouteActivity="$root.travelAndTransport.sections[2]"
      sectionName="travelAndTransport"
    />
  </div>
</template>

<script>
import BusinessTravelComponent from '../businessTravel/BusinessTravelComponent'
import BusinessTravelFuelsComponent from '../businessTravel/BusinessTravelFuelsComponent'
import Notes from '../../../Notes.vue'
import NextSectionButton from '../../../NextSectionButton.vue'
export default {
  metaData () {
    return {
      title: `dCarbon — Staff Commuting`,
      description: ''
    }
  },
  name: 'activity-staff-commute',
  components: {
    BusinessTravelComponent,
    BusinessTravelFuelsComponent,
    Notes,
    NextSectionButton
  },
  props: { initialSchema: Object, scope: String },

  data () {
    return {
      schema: {},
      enterByVehicles: 'mileage'
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  mounted () {
    this.getActiveSchemas()
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    calculateTotal (schema, schemaName) {
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getActiveSchemas () {
      if (this.schema[this.getSchemaIndex('Staff Vehicle Mileage')].active === true) {
        this.enterByVehicles = 'mileage'
      } else {
        this.enterByVehicles = 'fuels'
      }
    },
    updateActiveStatus (toggleName, schemaName) {
      let resetSchemas = this.schema.filter(s => s.toggleName === toggleName)
      resetSchemas.forEach(schema => {
        schema.active = false
      })
      this.schema[this.getSchemaIndex(schemaName)].active = true
    },
    resetSchema (name) {
      if (name === 'mileage') {
        this.schema[this.getSchemaIndex('Staff Vehicle Mileage')] = {
          dataSource: '',
          dataQuality: '',
          assumptions: '',
          name: 'Staff Vehicle Mileage', // Title of Section on front end
          title: 'Staff Vehicle Mileage',
          scope: 3, // scope number
          sectionID: 'staff-commute', // Default Schema Scope Section Name
          identifier: 'mileage', // Main Section component identifier for tooltips
          version: this.schema[this.getSchemaIndex('Staff Vehicle Mileage')].version,
          active: false,
          toggleName: 'enterByVehicles',
          emissionSources: [
            {
              category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
              GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
              ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
              name: 'Staff Commuting - Petrol', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
              type: 'default', // default, naturalGasTDL, electricityTDL, WTT
              id: 'petrol',
              unit: 'KM',
              annualTotal: 0,
              tCO2e: 0,
              CO2: 0,
              CH4: 0,
              N2O: 0,
              emissionSourceInfo: {
                dataSource: '',
                dataQuality: 'Please select',
                assumptions: ''
              }
            },
            {
              category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
              GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
              ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
              name: 'Staff Commuting - Diesel', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
              type: 'default', // default, naturalGasTDL, electricityTDL, WTT
              id: 'diesel',
              unit: 'KM',
              annualTotal: 0,
              tCO2e: 0,
              CO2: 0,
              CH4: 0,
              N2O: 0,
              emissionSourceInfo: {
                dataSource: '',
                dataQuality: 'Please select',
                assumptions: ''
              }
            },
            {
              category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
              GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
              ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
              name: 'Staff Commuting - Petrol WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
              type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
              id: 'petrol-wtt',
              unit: 'KM',
              annualTotal: 0,
              tCO2e: 0,
              CO2: 0,
              CH4: 0,
              N2O: 0,
              emissionSourceInfo: {
                dataSource: '',
                dataQuality: 'Please select',
                assumptions: ''
              }
            },
            {
              category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
              GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
              ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
              name: 'Staff Commuting - Diesel WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
              type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
              id: 'diesel-wtt',
              unit: 'KM',
              annualTotal: 0,
              tCO2e: 0,
              CO2: 0,
              CH4: 0,
              N2O: 0,
              emissionSourceInfo: {
                dataSource: '',
                dataQuality: 'Please select',
                assumptions: ''
              }
            }
          ],
          extraCalculations: [
            {
              type: 'petrol',
              tCO2eVariableName: 'privateCarDefaultPetrolKgCO2e',
              CO2VariableName: 'privateCarDefaultPetrolKgCO2',
              N2OVariableName: 'privateCarDefaultPetrolKgN2O',
              CH4VariableName: 'privateCarDefaultPetrolKgCH4'
            },
            {
              type: 'diesel',
              tCO2eVariableName: 'privateCarDefaultDieselKgCO2e',
              CO2VariableName: 'privateCarDefaultDieselKgCO2',
              N2OVariableName: 'privateCarDefaultDieselKgN2O',
              CH4VariableName: 'privateCarDefaultDieselKgCH4'
            },
            {
              type: 'petrol-wtt',
              tCO2eVariableName: 'petrolKmWTT' // Main getWellToTankEmissions() variable Name
            },
            {
              type: 'diesel-wtt',
              tCO2eVariableName: 'dieselKmWTT' // Main getWellToTankEmissions() variable Name
            }
          ],
          months: [
            { name: 'January', amount: 0 },
            { name: 'February', amount: 0 },
            { name: 'March', amount: 0 },
            { name: 'April', amount: 0 },
            { name: 'May', amount: 0 },
            { name: 'June', amount: 0 },
            { name: 'July', amount: 0 },
            { name: 'August', amount: 0 },
            { name: 'September', amount: 0 },
            { name: 'October', amount: 0 },
            { name: 'November', amount: 0 },
            { name: 'December', amount: 0 }
          ],
          dieselMonths: [
            { name: 'January', amount: 0 },
            { name: 'February', amount: 0 },
            { name: 'March', amount: 0 },
            { name: 'April', amount: 0 },
            { name: 'May', amount: 0 },
            { name: 'June', amount: 0 },
            { name: 'July', amount: 0 },
            { name: 'August', amount: 0 },
            { name: 'September', amount: 0 },
            { name: 'October', amount: 0 },
            { name: 'November', amount: 0 },
            { name: 'December', amount: 0 }
          ],
          variableName: 'privateCarDefaultPetrolKgCO2e', // privateCarDefaultPetrolKgCO2, privateCarDefaultPetrolKgCH4, privateCarDefaultPetrolKgN2O
          variableNameDiesel: 'privateCarDefaultDieselKgCO2e', // privateCarDefaultDieselKgCO2, privateCarDefaultDieselKgCH4, privateCarDefaultDieselKgN2O
          annually: true
        }
      } else if (name === 'fuels') {
        this.schema[this.getSchemaIndex('Staff Vehicle Fuels')] = {
          dataSource: '',
          dataQuality: '',
          assumptions: '',
          name: 'Staff Vehicle Fuels', // Title of Section on front end
          title: 'Staff Vehicle Fuels',
          scope: 3, // scope number
          sectionID: 'staff-commute', // Default Schema Scope Section Name
          identifier: 'fuels', // Main Section component identifier for tooltips
          version: this.schema[this.getSchemaIndex('Staff Vehicle Mileage')].version,
          active: false,
          toggleName: 'enterByVehicles',
          emissionSources: [
            {
              category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
              GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
              ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
              name: 'Staff Commuting - Petrol', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
              type: 'default', // default, naturalGasTDL, electricityTDL, WTT
              id: 'petrol',
              unit: 'L',
              annualTotal: 0,
              tCO2e: 0,
              CO2: 0,
              CH4: 0,
              N2O: 0,
              emissionSourceInfo: {
                dataSource: '',
                dataQuality: 'Please select',
                assumptions: ''
              }
            },
            {
              category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              subcategory: 'Staff Commuting', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
              GHGSubcategory: 7, // Customer Data Collection Sheet BLANK Nov2021
              ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
              name: 'Staff Commuting - Diesel', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
              type: 'default', // default, naturalGasTDL, electricityTDL, WTT
              id: 'diesel',
              unit: 'L',
              annualTotal: 0,
              tCO2e: 0,
              CO2: 0,
              CH4: 0,
              N2O: 0,
              emissionSourceInfo: {
                dataSource: '',
                dataQuality: 'Please select',
                assumptions: ''
              }
            },
            {
              category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
              GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
              ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
              name: 'Staff Commuting - Petrol WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
              type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
              id: 'petrol-wtt',
              unit: 'L',
              annualTotal: 0,
              tCO2e: 0,
              CO2: 0,
              CH4: 0,
              N2O: 0,
              emissionSourceInfo: {
                dataSource: '',
                dataQuality: 'Please select',
                assumptions: ''
              }
            },
            {
              category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation ', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
              GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
              GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
              ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
              name: 'Staff Commuting - Diesel WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
              type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
              id: 'diesel-wtt',
              unit: 'L',
              annualTotal: 0,
              tCO2e: 0,
              CO2: 0,
              CH4: 0,
              N2O: 0,
              emissionSourceInfo: {
                dataSource: '',
                dataQuality: 'Please select',
                assumptions: ''
              }
            }
          ],
          extraCalculations: [
            {
              type: 'petrol',
              tCO2eVariableName: 'kgCO2ePetrol',
              CO2VariableName: 'kgCO2PerUnitPetrol',
              N2OVariableName: 'kgN2OPerUnitPetrol',
              CH4VariableName: 'kgCH4PerUnitPetrol'
            },
            {
              type: 'diesel',
              tCO2eVariableName: 'kgCO2eDieselCompanyVehicles',
              CO2VariableName: 'kgCO2PerUnitDieselCompanyVehicles',
              N2OVariableName: 'kgn2OPerUnitDieselCompanyVehicles',
              CH4VariableName: 'kgCH4PerUnitDieselCompanyVehicles'
            },
            {
              type: 'petrol-wtt',
              tCO2eVariableName: 'petrolWTT' // Main getWellToTankEmissions() variable Name
            },
            {
              type: 'diesel-wtt',
              tCO2eVariableName: 'dieselWTT' // Main getWellToTankEmissions() variable Name
            }
          ]
        }
      }
      this.$root.saveData()
      this.calculateTotal()
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    }
  }
}
</script>
