<template>
  <div class="other-emissions">
    <div class="certification-type">
      <h1 class="section-title activity-title">Certification Type</h1>
      <div class="other-emissions-container">
        <div class="inputs">
          <div class="column">
            <div class="row">
              <label>Type: </label>
              <select
                v-model="$store.state.app.report.certificationTypeID"
                v-on:change="updateReportData('certificationTypeID'), updateCreditsToOffset(), updateCertExplanation()"
                v-on:focus="$root.setEditing()"
                v-on:blur="$root.unsetEditing()"
                class="dropdown"
                name="choice"
              >
                <option :value="0">TBC</option>
                <option
                  v-for="certType in $store.state.app.report.certificationTypes"
                  :value="certType.id"
                  :key="certType.id"
                  >{{ certType.title }}</option
                >
              </select>
            </div>
              <div class="credits-to-offset" v-if="selectedCertificationType !== 'Carbon Footprint'">
                <div
                  class="row"
                >
                <div class="column input-text">
                  <label v-if="selectedCertificationType !== 'Carbon Conscious'">Credits to Offset (Total: {{ totalCredits }}): </label>
                  <label v-else>Credits to Offset (Optional)</label>
                  <label v-if="selectedCertificationType === 'Carbon Conscious'">Confirm total credits to offset.</label>
                  <label v-if="selectedCertificationType === 'Climate Positive'">Enter total credits to offset if more than the default 120%.</label>

                </div>
                  <input
                    v-if="selectedCertificationType !== 'Net Zero Carbon'"
                    type="number"
                    min="0"
                    v-model.number="$store.state.app.report.customCreditOffsetAmount"
                    v-on:change="() => {
                      $store.state.app.report.customCreditOffsetAmount = $store.state.app.report.customCreditOffsetAmount || 0
                      updateReportData('customCreditOffsetAmount')
                      }"
                    v-on:focus="$root.setEditing()"
                    v-on:blur="$root.unsetEditing()"
                  />
                </div>
                <div v-if="selectedCertificationType === 'Carbon Conscious' || selectedCertificationType === 'Climate Positive'" class="explanation">
                  <div class="notes">Only enter an amount if it is different to the default total for the chosen certification type. i.e. more than 120% for Climate Positive,
                    or a specified amount for Carbon Conscious and provide explanation below.</div>
                  <ReportNotes
                    property="offsetExplanation"
                    :label="`Explanation:`"
                    @updateReportData="updateReportData"
                    :admin="false"
                  />
                </div>
                <div v-if="offsetsRequired" class="set-offsets notes">Select offset type(s) in section below.</div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <CarbonCredits @updateReportData="updateReportData" :certificationType="selectedCertificationType" :offsetsRequired="offsetsRequired"/>
  </div>
</template>

<script>
import ReportNotes from './ReportNotes.vue'
import CarbonCredits from './CarbonCredits.vue'
import emissionsMixin from '../../mixins/emissionsMixin.vue'
export default {
  mixins: [emissionsMixin],
  components: { ReportNotes, CarbonCredits },
  props: {
    marketRateExists: Boolean
  },
  methods: {
    updateReportData (fieldName) {
      this.$emit('updateReportData', fieldName, true)
    },
    updateCertExplanation () {
      this.$store.state.app.report.offsetExplanation = this.selectedCertificationType === 'Carbon Conscious' || this.selectedCertificationType === 'ClimatePositive' ? this.$store.state.app.report.offsetExplanation : ''
      this.updateReportData('offsetExplanation')
    },
    updateCreditsToOffset () {
      const emissionsSources = this.$store.state.app.client.period.nodes[0].emissionsSources.nodes
      const filteredEmissionsSources = emissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
      let creditsToOffset = 0
      if (this.selectedCertificationType === 'Carbon Conscious' || this.selectedCertificationType === 'Carbon Footprint') {
        creditsToOffset = 0
      } else if (this.selectedCertificationType === 'Climate Positive') {
        creditsToOffset = this.getCreditsToOffset(filteredEmissionsSources, 1.2)
      } else {
        creditsToOffset = this.getCreditsToOffset(filteredEmissionsSources, 1)
      }
      this.$store.state.app.report.customCreditOffsetAmount = creditsToOffset
      this.updateReportData('customCreditOffsetAmount')
    }
  },
  computed: {
    totalCredits () {
      const emissionsSources = this.$store.state.app.client.period.nodes[0].emissionsSources.nodes
      const filteredEmissionsSources = emissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
      let mulitple = this.selectedCertificationType === 'Climate Positive' ? 1.2 : 1
      // market or location based
      return this.getCreditsToOffset(filteredEmissionsSources, mulitple)
    },
    selectedCertificationType () {
      const selectedCertificationType = this.$store.state.app.report.certificationTypes.find(ct => ct.id === this.$store.state.app.report.certificationTypeID)
      return selectedCertificationType ? selectedCertificationType.title : 'TBC'
    },
    offsetsRequired () {
      if ((this.selectedCertificationType === 'Carbon Conscious' && this.$store.state.app.report.customCreditOffsetAmount === 0) || this.selectedCertificationType === 'Carbon Footprint') {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style></style>
