const industrialProcessesSchema = [
  {
    dataSource: 'industrial test',
    dataQuality: 'Poor',
    assumptions: 'test',
    name: 'Chemical Used in Manufacturing or Processing', // Title of Section on front end
    title: 'Chemical Used in Manufacturing or Processing', // Title of Section on front end
    scope: 1, // scope number
    sectionID: 'industrial-processes', // Default Schema Scope Section Name
    identifier: 'activity-industrial-processes', // Main Section component identifier for tooltips
    version: 2.75,
    active: true,
    toggleName: 'enterBy',
    emissionSources: [
      {
        category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Chemical & Industrial Processes', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Gas type -', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        id: 'GT',
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    gasTypes: [
      {
        gasType: 'CO2',
        quantity: 0,
        units: 'kg',
        eFactor: 0.0,
        carbonEmissions: 0.0
      },
      {
        gasType: 'CH4',
        quantity: 0,
        units: 'kg',
        eFactor: 0.0,
        carbonEmissions: 0.0
      },
      {
        gasType: 'C3H8 (propane)',
        quantity: 0,
        units: 'kg',
        eFactor: 0.0,
        carbonEmissions: 0.0
      }
    ],
    gases: [
      { name: 'CO2', variableName: 'carbonDioxideKgCO2e' },
      { name: 'CH4', variableName: 'methaneKgCO2e' },
      { name: 'C3H8 (propane)', variableName: 'propaneKgCO2e' },
      { name: 'N2O', variableName: 'nitrousOxideKgCO2e' },
      { name: 'C4H10 (isobutane)', variableName: 'isobutaneKgCO2e' }
    ],
    totalEmissions: {
      totalCarbonDioxideEquivalentEmissions: 0,
      totalCarbonDioxideEmissions: 0,
      totalNitrosOxideEmissions: 0,
      totalMethaneEmissions: 0
    },
    numberOfGasType: 3
  }
]
export default industrialProcessesSchema
