<template>
  <div class="sea-freight">
    <!-- Modal start -->
    <div class="tooltip">
      <h1 class="section-title">{{ title }}</h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <!-- Modal end -->
    <div v-if="!schema.annually">
    <Controls
      v-if="$root.userDetails.organisationStatus !== 'Completed'"
      v-on:add="addUnit()"
      v-on:remove="removeUnit()"
      v-on:update="calculateCarbonEmissions()"
    />
      <div
        class="freight"
        v-for="(item, index) in schema.items"
        :key="`item-${index}`"
      >
        <p v-if="index === 0">Item ID</p>
        <p v-if="index === 0">Origin</p>
        <p v-if="index === 0">Destination</p>
        <p v-if="index === 0">Vessel Type</p>
        <p v-if="index === 0">Weight Of Freight (kgs)</p>
        <p v-if="index === 0">Distance (kms)</p>
        <p v-if="index === 0">Carbon Emissions</p>
        <!-- <p>{{ index + 1 }}</p> -->

        <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
          type="number" step="0.01"
          min="0"
          v-model.number="item.itemId"
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
        <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
          type="message"
          placeholder="Port Name"
          v-model="item.origin"
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
        <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
          type="message"
          placeholder="Port Name"
          v-model="item.destination"
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
        <select :disabled="$root.userDetails.organisationStatus === 'Completed'"
          name="vesselType"
          id="vessel-type"
          v-model="item.vesselType"
          v-on:change="updateVesselSize(item, item.vesselType), calculateCarbonEmissions()"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        >
          <option value="" disabled selected>Please select</option>
          <template v-for="(type, index) in schema.vesselSizes">
            <option v-bind:value="type.type" :key="index">{{
              type.type
            }}</option>
          </template>
        </select>
        <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
          type="number" step="0.01"
          min="0"
          v-model.number="item.weightOfFreight"
          v-on:change="() => {
            item.weightOfFreight = item.weightOfFreight || 0;
            calculateCarbonEmissions()
          }"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
        <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
          class="ekos-input"
          type="number" step="0.01"
          min="0"
          v-model.number="item.distance"
          v-on:change="() => {
            item.distance = item.distance || 0;
            calculateCarbonEmissions()
          }"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
        <h2>{{ $root.formatNumber(item.carbonEmissions) }}</h2>
      </div>
      <div>
        <h2 class="secondary-title">
          Total Carbon Emissions:
          {{ $root.formatNumber(tCO2e) }}
        </h2>
      </div>
    </div>
    <div v-else class="sea-freight-totals input-section">
      <div class="sea-freight-wrapper">
        <template v-for="input in schema.totalInputs">
          <div class="sea-freight-row" :key="input.name">
            <label>{{ input.name }} {{ input.unit }}</label>
            <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
              type="number" step="0.01"
              min="0"
              v-model.number="input.annualTotal"
              v-on:change="() => {
                input.annualTotal = input.annualTotal || 0;
                calculateCarbonEmissions()
              }"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import Controls from '../../../controls'
import Modal from '../../../modal/Modal.vue'

export default {
  name: 'sea-freight-component',
  props: {
    title: String,
    initialSchema: Object,
    item: Object,
    schemaName: String,
    activityID: String,
    identifier: String
  },
  components: { Controls, Modal },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },
  mounted () {},
  methods: {
    updateVesselSize (item, type) {
      let sizes = this.getVesselSizes(type)
      item.vesselSize = sizes[0].name
    },
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions (customEfactor = false) {
      var self = this
      return axios
        .post(
          '' +
            process.env.VUE_APP_APIURL +
            'api/calculateScopeThreeSeaFreight/',
          {
            schema: this.schema
          }
        )
        .then(function (response) {
          self.schema = response.data.result
          self.$emit('calculateTotal', response.data.result, self.schemaName)
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          self.$root.saveData()
        })
    },

    addUnit () {
      var item = {
        itemId: 1,
        origin: '',
        destination: '',
        vesselTypes: [
          { name: 'Bulk Carrier', variableName: 'bulkCarrierKgCO2e' },
          { name: 'General Cargo', variableName: 'generalCargoKgCO2e' },
          { name: 'Container Ship', variableName: 'containerShipKgCO2e' },
          { name: 'Vehicle Transport', variableName: 'vehicleTransportKgCO2e' },
          { name: 'RoRo-Ferry', variableName: 'roRoFerryKgCO2e' },
          {
            name: 'Refrigerated Cargo',
            variableName: 'refrigeratedCargoKgCO2e'
          }
        ],
        vesselType: '',
        vesselSize: '',
        weightOfFreight: 0,
        distance: 0,
        freightDescription: '',
        eFactor: 0.0,
        carbonEmissions: 0.0
      }

      this.schema.items.push(item)
    },
    removeUnit () {
      if (this.schema.items.length > 1) {
        this.schema.items.pop()
      }
    },
    getVesselSizes (type) {
      var vesselSizes = this.schema.vesselSizes.find(vs => vs.type === type)

      if (!vesselSizes) {
        return []
      } else {
        return vesselSizes.sizes
      }
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.identifier}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  computed: {
    tCO2e () {
      var tCO2e = 0
      this.schema.emissionSources.forEach(source => {
        if (source.type !== 'WTT') {
          tCO2e += source.tCO2e
        }
      })
      return tCO2e
    }
  }
}
</script>
