<template>
  <div class="emissions-inventory-report-container emissions-report">
    <div class="cover-page-line"></div>
    <div class="page-header">
      investing in nature
      <div class="page-header-ekos">ekos</div>
    </div>
    <div class="emissions-inventory-report-sections">
      <div class="report-page" v-for="(page, index) in pages" :key="index"
        :class="index + 1 === pages.length ? 'last' : ''">
        <div v-if="$root.userDetails.clientStatus === 'Active'" class="watermark1">
          WORKING VERSION
        </div>
        <div v-if="
          $root.userDetails.clientStatus === 'Active' &&
          index !== pages.length - 1
        " class="watermark2">
          WORKING VERSION
        </div>
        <div class="page-line"></div>
        <div class="page-header">
          investing in nature
          <div class="page-header-ekos">ekos</div>
        </div>
        <div v-if="page.content" v-html="parseContent(page.content)"></div>
        <div class="heading" v-for="heading in page.headings" :key="heading.number">
          <h1 v-if="heading.title" :class="heading.type === 'Heading' ? 'heading' : 'subheading'">
            {{ heading.headingNumber }} {{ heading.title }}
          </h1>
          <div v-if="heading.content" v-html="parseContent(heading.content)"></div>
        </div>
        <!--  Shows on all pages in report and print version -->
        <div class="page-footer">Reduction Plan Report</div>
        <div class="page-number">Page {{ index + 3 }}</div>
      </div>
      <ReductionPerfomanceGraph v-if="loaded" :completedAndCurrentInventories="completedAndCurrentInventories" />
    </div>
  </div>
</template>

<script>
import { renderActionPlanTable } from '../../snippets/reductionPlan/actionPlan'
import { renderMonitoringTable } from '../../snippets/reductionPlan/monitoring'
import { renderTargetsTable } from '../../snippets/reductionPlan/targets'
import { renderPerformanceTable } from '../../snippets/reductionPlan/performanceTable'
import ReductionPerfomanceGraph from '../graphs/ReductionPerformanceGraph'
import emissionsMixin from '../../mixins/emissionsMixin.vue'
export default {
  name: 'ReductionReportContent',
  props: {
    pages: { type: Array, required: true },
    baseYearIntensityMetric: { type: Number, required: true },
    overflowData: { type: Object, required: true }
  },
  components: {
    ReductionPerfomanceGraph
  },
  mixins: [emissionsMixin],
  data () {
    return {
      reportOutputSchema: {},
      completedAndCurrentInventories: [],
      loaded: false
    }
  },
  async beforeMount () {
    if (this.$store.state.app.completedInventories.length) {
      // get completed inventories and merge with current inventory
      const completedInventories =
        this.$store.state.app.completedInventories || []
      this.completedAndCurrentInventories = [
        ...completedInventories.filter(
          (period) =>
            period.year !== this.$store.state.app.client.period.nodes[0].year
        ),
        this.$store.state.app.client.period.nodes[0]
      ].filter(
        (inventory) => inventory.year <= this.$store.state.app.selectedYear
      )
    }
    this.loaded = true
  },
  methods: {
    parseContent (content) {
      const self = this
      const regex = /\[(.*?)\]/g
      let result = content.replace(regex, function (x) {
        // console.log(x) // all liquid variables
        let noBrackets = x.replace('[', '').replace(']', '')
        if (self.overflowData[noBrackets]) {
          return self.overflowData[noBrackets]
        } else {
          let replacement = self.reportOutputSchema[noBrackets]
            ? self.reportOutputSchema[noBrackets]
            : self[noBrackets]
          if (replacement !== undefined) {
            return replacement
          } else {
            return ''
          }
        }
      })
      return result
    },
    getTargetsTotals (emissionsSources) {
      const filteredEmissionsSources = emissionsSources.filter(
        (es) => es.rule !== 'hide-in-report' && es.rule !== 'hide'
      )
      const totals = this.getTotalsFromEmissionsSources(
        filteredEmissionsSources
      )
      const ScopeOneTwoBaseYearEmissions = filteredEmissionsSources.filter(
        (es) => es.GHGScope === 1 || es.GHGScope === 2
      )
      const totalScopeOneTwoEmissions = this.getTotalsFromEmissionsSources(
        ScopeOneTwoBaseYearEmissions
      )
      const baseYearMarketRateExists =
        this.checkEmissionsSourcesForMarketRate(emissionsSources)
      const totalEmissions = baseYearMarketRateExists
        ? totals.emissionsMR
        : totals.emissions
      const combinedScopeOneTwoEmissions = baseYearMarketRateExists
        ? totalScopeOneTwoEmissions.emissions
        : totalScopeOneTwoEmissions.emissionsMR
      return { totalEmissions, combinedScopeOneTwoEmissions }
    }
  },
  computed: {
    targets () {
      const baseYearTotals = this.getTargetsTotals(
        this.$store.state.app.client.baseYear.emissionsSources.nodes
      )
      const currentYearTotals = this.getTargetsTotals(
        this.$store.state.app.client.period.nodes[0].emissionsSources.nodes
      )
      const emissionsIntensityMetrics =
        this.$store.state.app.report.emissionIntensityMetricsGeneral.nodes.concat(
          this.$store.state.app.report.emissionIntensityMetricsOther.nodes
        )
      // search for this.$store.state.app.reductionPlan.reductionTarget.organisationSpecificMetric
      const currentYearIntensityMetric = emissionsIntensityMetrics.find(
        (metric) =>
          metric.title ===
          this.$store.state.app.reductionPlan.reductionTarget
            .organisationSpecificMetric
      )?.value
      const metricData = {
        absolute: {
          baseYear: {
            emissions: baseYearTotals.totalEmissions,
            year: this.$store.state.app.client.baseYear.year
          },
          currentYear: {
            emissions: currentYearTotals.totalEmissions,
            year: this.$store.state.app.client.period.nodes[0].year
          }
        },
        intensity: {
          baseYear: {
            emissions:
              baseYearTotals.totalEmissions / this.baseYearIntensityMetric,
            year: this.$store.state.app.client.baseYear.year
          },
          currentYear: {
            emissions:
              currentYearTotals.totalEmissions / currentYearIntensityMetric,
            year: this.$store.state.app.client.period.nodes[0].year
          }
        },
        scopeOneTwo: {
          baseYear: {
            emissions: baseYearTotals.combinedScopeOneTwoEmissions,
            year: this.$store.state.app.client.baseYear.year
          },
          currentYear: {
            emissions: currentYearTotals.combinedScopeOneTwoEmissions,
            year: this.$store.state.app.client.period.nodes[0].year
          }
        }
      }
      return renderTargetsTable(
        this.$store.state.app.reductionPlan.reductionTarget,
        metricData,
        this.$store.state.app.reductionPlan.reductionTarget
          .combinedScopeOneTwoTarget
      )
    },
    performanceGraph () {
      return `<div class="reduction-performance-graph"></div>`
    },
    performanceTable () {
      // getEmissionsByActivity for all completedAndCurrentInventories
      let performanceData = []
      this.completedAndCurrentInventories.forEach((inventory) => {
        const emissionsSources = inventory.emissionsSources.nodes
        const filteredEmissionsSources = emissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
        inventory.emissionsByActivity = this.getEmissionsByActivity(
          filteredEmissionsSources
        )
        if (inventory.year === this.$store.state.app.client.baseYear.year) {
          inventory.isBaseYear = true
        }
        inventory.totals = this.getTotalsFromEmissionsSources(
          filteredEmissionsSources
        )
        performanceData.push(inventory)
      })
      console.log('performanceData', performanceData)
      return renderPerformanceTable(
        performanceData,
        this.$store.state.app.selectedYear
      )
    },
    actionPlan () {
      const currentYearIsBaseYear =
        this.$store.state.app.client.period.nodes[0].year ===
        this.$store.state.app.client.baseYear.year
      return renderActionPlanTable(
        this.$store.state.app.reductionPlan.reductionEmissionSources.nodes,
        currentYearIsBaseYear
      )
    },
    companyCommitment () {
      return renderMonitoringTable(
        this.$store.state.app.reductionPlan.reductionMonitoring
      )
    }
  }
}
</script>
