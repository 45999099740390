<template>
  <div class="main-nav">
    <div v-if="$store.state.user.clientID">
      <router-link class="navlink" to="/dashboard">
        <div class="navtext">
          <i class="fad fa-tachometer-alt-fast"></i>
          <p>Dashboard</p>
        </div>
      </router-link>
      <div>
        <div v-for="nav in mainNavigationData" :key="nav.path">
          <router-link
          v-if="getRouteInfo(nav.object).visible"
          class="navlink"
          :class="
            mainPath === nav.path ? 'router-link-active' : ''
          "
          :to="{
            path: `${nav.path}/${getRouteInfo(nav.object).firstRoute}`,
            params: {
              sections: $root[nav.object].sections,
              schemas: $root[nav.object].schemas
            }
          }"
          ><div class="navtext">
            <i :class="nav.icon"></i>
            <p>{{ nav.title }}</p>
          </div></router-link
        >
        </div>
    </div>

  </div>
    <router-link v-if="$store.state.user.groups.includes('administrators')" class="navlink" to="/admin">
      <div class="navtext">
        <i class="fad fa-user-cog"></i>
        <p>Admin</p>
      </div>
    </router-link>
    <router-link v-if="$store.state.app.organisations.length > 1 && !$store.state.user.groups.includes('administrators')" class="navlink" to="/organisations">
      <div class="navtext">
        <i class="fad fa-sitemap"></i>
        <p>Business Units</p>
      </div>
    </router-link>
  </div>
</template>

<script>
import mainNavigationData from './mainNavigationData'
export default {
  data () {
    return {
      mainNavigationData: mainNavigationData
    }
  },
  methods: {
    getRouteInfo (route) {
      let visible = false
      let firstRoute = ''
      this.$root[route].sections.forEach(section => {
        if (this.$root.visibleSections.find(vs => vs.id === section)) {
          visible = true
          if (firstRoute === '') {
            firstRoute = section
          }
        }
      })
      return {
        firstRoute: firstRoute,
        visible: visible
      }
    }
  },
  computed: {
    mainPath () {
      return this.$route.path.split('/')[1]
    }
  }
}
</script>

<style></style>
