<template>
  <div>
    <Performance />
    <ReductionMonitoring />
    <OverallReductionTargets />
  </div>
</template>

<script>
import OverallReductionTargets from './OverallReductionTargets.vue'
import ReductionMonitoring from './ReductionMonitoring.vue'
import Performance from './Performance.vue'

export default {
  components: {
    ReductionMonitoring,
    Performance,
    OverallReductionTargets
  }
}
</script>

<style></style>
