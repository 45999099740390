<template>
  <div class="modal">
    <div class="container" :class="value ? 'active' : ''">
      <!-- <div class="modal__title">Help</div> -->
      <div @click.prevent="close" class="modal-close">
        <i class="fas fa-times"></i>
      </div>
      <div class="modal-content" v-html="tooltip">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    tooltip: {
      required: true
    },
    value: {
      required: true
    }
  },
  methods: {
    close () {
      this.$emit('input', !this.value)
    }
  }
}
</script>
