<template>
  <div class="emissions-inventory-report-container emissions-report">
    <div class="cover-page-line"></div>
    <div class="page-header">
      investing in nature
      <div class="page-header-ekos">ekos</div>
    </div>

    <div class="emissions-inventory-report-sections">
      <div class="report-page" v-for="(page, index) in pages" :key="index" :class="index+1 === pages.length ? 'last' : ''">
      <div v-if="$root.userDetails.clientStatus === 'Active'" class="watermark1">WORKING VERSION</div>
        <div v-if="$root.userDetails.clientStatus === 'Active' && index !== pages.length - 1" class="watermark2">WORKING VERSION</div>
        <div class="page-line"></div>
        <div class="page-header">
          investing in nature
          <div class="page-header-ekos">ekos</div>
        </div>
        <div v-if="page.content" v-html="parseContent(page.content)"></div>
        <div
          class="heading"
          v-for="heading in page.headings"
          :key="heading.number"
        >
          <h1 :class="heading.type === 'Heading' ? 'heading' : 'subheading'">
            {{ heading.headingNumber }} {{ heading.title }}
          </h1>
          <div
            v-if="heading.content"
            v-html="parseContent(heading.content)"
          ></div>
        </div>
        <!--  Shows on all pages in report and print version -->
        <div class="page-footer">Emissions Inventory Report</div>
        <div class="page-number">Page {{ index + 3 }}</div>
      </div>
      <EmissionsByActivity
        v-if="$store.state.user.clientID && !this.$root.refreshReports"
        :emissionsByScope="emissionsByScope"
      />
      <EmissionsByScope
        v-if="$store.state.user.clientID && !this.$root.refreshReports"
        :emissionsByScope="emissionsByScope"
      />
      <PerformanceComparison
        v-if="$store.state.user.clientID && !this.$root.refreshReports"
        :emissionActivities="emissionsByScope.activities"
        :baseYearActivities="matchingActivitiesInBaseYear"
        :previousYearActivities="matchingActivitiesInPreviousYear"
        :outstandingBaseYearActivities="outstandingActivitiesFromBaseYear"
        :outstandingPreviousYearActivities="outstandingActivitiesFromPreviousYear"
        :onlyPreviousYearActivities="activitiesExclusiveToPreviousYear"
      />
      <RankedEmissionsByActivity
        v-if="$store.state.user.clientID && !this.$root.refreshReports"
        :emissionSources="emissionSources"
        :marketRateExists="marketRateExists"
      />
    </div>
  </div>
</template>

<script>
import EmissionsByActivity from '../graphs/EmissionsByActivity.vue'
import EmissionsByScope from '../graphs/EmissionsByScope.vue'
import PerformanceComparison from '../graphs/PerformanceComparison.vue'
import RankedEmissionsByActivity from '../graphs/RankedEmissionsByActivity.vue'
import emissionsMixin from '../../mixins/emissionsMixin.vue'
import dataMixin from '../../mixins/dataMixin.vue'
import yearComparisonMixin from '../../mixins/yearComparisonMixin.vue'
import { renderBusinessUnitBreakdownTable } from '../../snippets/businessUnitBreakdown'

// import axios from '@/axios'
export default {
  mixins: [emissionsMixin, dataMixin, yearComparisonMixin],
  props: {
    pages: { type: Array, required: true },
    methodologyDiagram: { type: String },
    reportData: { type: Object },
    emissionsByScope: Object,
    emissionSources: Array,
    carbonPrice: Number,
    overflowData: Object
  },
  components: {
    EmissionsByActivity,
    EmissionsByScope,
    PerformanceComparison,
    RankedEmissionsByActivity
  },
  mounted () {
    this.getElectricityOffset(this.$root.masterSchema)
  },
  data () {
    return {
      graphsReady: false,
      creditTypes: [],
      reportOutputSchema: {
        clientName: this.$store.state.app.client.title,
        tradingName: this.$store.state.app.client.tradingName ? this.$store.state.app.client.tradingName : ' ',
        tradingAs: this.$store.state.app.client.tradingName ? `, trading as ${this.$store.state.app.client.tradingName}` : null,
        preferredName: this.$store.state.app.client.preferredName ? this.$store.state.app.client.preferredName : this.$store.state.app.organisation.title,
        preferredNameNote: `Thereafter in the report, the organisation will be referred to as ${this.$store.state.app.client.preferredName}.`,
        reportPeriod: this.$store.state.app.report.period,
        baseYearDescription: this.$store.state.app.client.baseYearDescription ? this.$store.state.app.client.baseYearDescription : ' ',
        organisationInformation: this.$store.state.app.client.description,
        statementOfIntent: this.$store.state.app.client.statementOfIntent,
        intendedUse: this.$store.state.app.client.intendedUse,
        organisationName: this.$store.state.app.organisation.title,
        methodologyDiagram: `<img class="diagram" src="${process.env.VUE_APP_APIURL}${this.methodologyDiagram}"`,
        methodologyChanges: this.$store.state.app.report
          .methodologyChanges ? this.addLinebreaks(this.$store.state.app.report
            .methodologyChanges) : ' ',
        organisationChartNotes: this.$store.state.app.report
          .organisationChartNotes ? this.addLinebreaks(this.$store.state.app.report
            .organisationChartNotes) : ' ',
        reportCount: this.getReportCount(),
        consolidationApproach: this.$store.state.app.report.consolidationApproach,
        consolidationReason: this.$store.state.app.report
          .consolidationReason ? this.addLinebreaks(this.$store.state.app.report
            .consolidationReason) : ' ',
        doubleCountingPreOffsetExtra: this.$store.state.app.report
          .doubleCountingPreOffsetExtra ? this.addLinebreaks(this.$store.state.app.report
            .doubleCountingPreOffsetExtra) : ' ',
        emissionsAgainstPreviousYearsNotes: this.$store.state.app.report
          .emissionsAgainstPreviousYearsNotes ? this.addLinebreaks(this.$store.state.app.report
            .emissionsAgainstPreviousYearsNotes) : ' ',
        emissionReductionNotes: this.$store.state.app.report
          .emissionReductionNotes ? this.addLinebreaks(this.$store.state.app.report
            .emissionReductionNotes) : ' ',
        offsetExplanation: this.$store.state.app.report.offsetExplanation ? this.addLinebreaks(this.$store.state.app.report.offsetExplanation) : ' ',
        recognisedOffsetDeduction: this.$store.state.app.report.recognisedOffsetDeduction
          ? 'There were instances of recognised offset deductions in this inventory, and these are described below:'
          : 'There were no known instances of recognised offset deductions relevant for this inventory.',
        instancesOfDoubleCountingNotes: this.$store.state.app.report.instancesOfDoubleCountingNotes && !this.$store.state.app.report.noKnownInstancesOfDoubleCounting
          ? this.$store.state.app.report.instancesOfDoubleCountingNotes
          : 'There were no known instances of double counting of emissions within this inventory.',
        organisationalBoundary: this.$store.state.app.client.organisationalChartThumbURL
          ? `<img class="diagram" src="${this.$store.state.app.client.organisationalChartURL}"`
          : 'None provided',
        certificationType: `${this.$store.state.app.report.certificationType.title ? this.$store.state.app.report.certificationType.title : 'TBC'}`,
        carbonCreditType: this.$store.state.app.report.carbonCreditType.title,
        carbonCreditDescription: this.$store.state.app.report.carbonCreditType.description,
        projectDetails: this.$store.state.app.report.carbonCreditType.title === 'Any Allocation' && this.$store.state.app.report.projectDetails ? this.addLinebreaks(this.$store.state.app.report.projectDetails) : ' ',
        notes: this.$store.state.app.report
          .notes ? this.addLinebreaks(this.$store.state.app.report
            .notes) : ' ',
        'emissions-by-scope-graph': `<div class="emissions-by-scope-graph"></div>`,
        'emissions-by-activity-graph': `<div class="emissions-by-activity-graph"></div>`,
        'emissions-by-sources-ranked-graph': `<ul class="ct-legend" style="margin: 0px auto;"><li class="ct-series-0" data-legend="0">GHG tCO<sub>2</sub>e</li></ul>
        <div class="emissions-by-sources-ranked-graph"></div>`,
        'fugitive-emission-graph': `<div class="fugitive-emission-graph"></div>`,
        'biogenic-graph': `<div class="biogenic-graph"></div>`
        // 'performance-against-previous-years-graph': `${this.performanceComparisonLegend ? this.performanceComparisonLegend : ''}<div class="performance-against-previous-years-graph"></div>`
      }
    }
  },
  async beforeMount () {
    await this.$store.dispatch('loadCarbonCreditTypes')
    this.creditTypes = this.$store.state.app.carbonCreditTypes
  },
  methods: {
    parseContent (content) {
      const self = this
      const regex = /\[(.*?)\]/g
      let result = content.replace(regex, function (x) {
        // console.log(x) // all liquid variables
        let noBrackets = x.replace('[', '').replace(']', '')
        if (self.overflowData[noBrackets]) {
          return self.overflowData[noBrackets]
        } else {
          let replacement = self.reportOutputSchema[noBrackets]
            ? self.reportOutputSchema[noBrackets]
            : self[noBrackets]
          if (replacement !== undefined) {
            return replacement
          } else {
            return ''
          }
        }
      })
      return result
    },
    getReportCount () {
      // format number with st nd rd
      let n = this.$store.state.app.client.completedInventories.nodes.length + 1
      return n + ['st', 'nd', 'rd'][((n + 90) % 100 - 10) % 10 - 1] || n + 'th'
    },
    getCells (data) {
      let cells = []
      data.forEach(element => {
        let cell = {
          title: element.title,
          value: element.value
        }
        cells.push(cell)
      })
      return cells
    },
    getCellsAdvanced (data) {
      let cells = []
      data.forEach((element, index) => {
        let cell = {
          title: `tCO2e/${element.title}`,
          value: this.$root.formatNumber(
            this.emissionsByScope.totalEmissions / element.value
          )
        }
        cells.push(cell)
      })
      return cells
    },
    renderCells (cells, cellCount = 0, heading) {
      let cellData = ''
      if (cellCount > 0) {
        // generate number of cells based on cellCount per row
        var count = 1
        cells.forEach((cell, index) => {
          if (count === 1) {
            cellData += `<tr ${heading ? "class='heading'" : ''}>`
          }
          cell = cell === Infinity || cell === 0 ? '0.00' : cell
          cellData += `<td>${
            cell !== null
              ? isNaN(cell) || cell === '' || typeof cell === 'string'
                ? cell
                : this.$root.formatNumber(cell)
              : cell !== undefined
                ? ''
                : '0.00'
          }</td>`
          if (count === cellCount) {
            cellData += '</tr>'
            count = 1
          } else {
            count++
          }
        })
      } else {
        // basic two column row generation
        cells.forEach(cell => {
          cellData += '<tr>'
          cellData += `<td>${cell.title}</td>`
          cellData += `<td>${typeof cell.value === 'string' ? cell.value : this.$root.formatNumber(cell.value)}</td>`
          cellData += '</tr>'
        })
      }
      return cellData
    },
    getCategoryTotal (ISOCategory) {
      // console.log(name)
      var total = {
        tCO2e: 0,
        tCO2eMarketRate: 0
      }
      // let trimmedCat =
      let filteredSources = this.$root.allEmissionSources.filter(es =>
        es.ISOCategory === ISOCategory
      )
      filteredSources.forEach(fs => {
        if (fs.rule !== 'hide-in-report' && fs.rule !== 'hide') {
          // console.log(`${fs.name} --- ${fs.tCO2e}`)
          total.tCO2e += fs.tCO2e
          total.tCO2eMarketRate += fs.tCO2eMarketRate
        }
      })
      // console.log(total)
      return total
    },
    getEmissionIntensityCells () {
      let emissionsIntensityCells = []
      this.$store.state.app.report.emissionIntensityMetricsGeneral.nodes.forEach(
        metric => {
          emissionsIntensityCells.push(metric.title)
          emissionsIntensityCells.push(metric.value)
          // extrapolate location and market based if this.marketRateExists
          emissionsIntensityCells.push(this.emissionsByScope.totalEmissions / metric.value)
          if (this.marketRateExists) {
            emissionsIntensityCells.push(this.emissionsByScope.totalEmissionsMarketRate / metric.value)
          }
        }
      )
      if (this.$store.state.app.report.emissionIntensityMetricsOther.nodes.length > 0) {
        this.$store.state.app.report.emissionIntensityMetricsOther.nodes.forEach(
          metric => {
            emissionsIntensityCells.push(metric.title)
            emissionsIntensityCells.push(metric.value)
            // extrapolate location and market based if this.marketRateExists
            emissionsIntensityCells.push(this.emissionsByScope.totalEmissions / metric.value)
            if (this.marketRateExists) {
              emissionsIntensityCells.push(this.emissionsByScope.totalEmissionsMarketRate / metric.value)
            }
          }
        )
      }
      return emissionsIntensityCells
    },
    getMarketBasedElectricity () {
      let total = 0
      let emissionSources = this.$root.allEmissionSources.filter(
        es => es.GHGScope === 2
      )
      emissionSources.forEach(es => {
        total += es.tCO2eMarketRate !== undefined ? es.tCO2eMarketRate : es.tCO2e
      })
      return total
    },
    addLinebreaks (str) {
      return str.replace(/\n/g, '<br />')
    },
    getCredit (id) {
      let credit = this.creditTypes.find(
        type => type.id === id
      )
      return credit || ''
    },
    getOffsetTableData () {
      const emissionsSources = this.$store.state.app.client.period.nodes[0].emissionsSources.nodes
      const filteredEmissionsSources = emissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
      let data = []
      // market or location based
      const totalGHG = this.marketRateExists ? this.emissionsByScope.totalEmissionsMarketRate : this.emissionsByScope.totalEmissions
      if (this.$store.state.app.report.certificationType.title === 'Net Zero Carbon' || this.$store.state.app.report.certificationType.title === 'TBC' || this.$store.state.app.report.certificationType.title === null) {
        data = [
          totalGHG,
          'Zero Carbon Option (100%)',
          totalGHG,
          this.preOffsetTotal,
          totalGHG - this.preOffsetTotal,
          this.getCreditsToOffset(filteredEmissionsSources, 1)
        ]
      } else if (this.$store.state.app.report.certificationType.title === 'Climate Positive') {
        const netOffset = totalGHG * 1.2 - this.preOffsetTotal
        const creditsToOffset = this.getCreditsToOffset(filteredEmissionsSources, 1.2)

        const customAmount = this.$store.state.app.report.customCreditOffsetAmount
        data = [
          totalGHG,
          customAmount === creditsToOffset ? 'Climate Positive Option (120%)' : 'Climate Positive (User Selected)',
          customAmount === creditsToOffset ? totalGHG * 1.2 : 0,
          customAmount === creditsToOffset ? this.preOffsetTotal : '',
          customAmount === creditsToOffset ? netOffset : 0,
          customAmount === creditsToOffset ? creditsToOffset : customAmount
        ]
      } else if (this.$store.state.app.report.certificationType.title === 'Carbon Conscious') {
        const customAmount = this.$store.state.app.report.customCreditOffsetAmount
        data = [
          totalGHG,
          'Carbon Conscious (User Selected)',
          0,
          '',
          0,
          customAmount
        ]
      } else if (this.$store.state.app.report.certificationType.title === 'Carbon Footprint') {
        data = [
          totalGHG,
          'Carbon Footprint (0%)',
          0,
          '',
          0,
          0
        ]
      }
      return data
    }
  },
  computed: {
    isBaseYear () {
      if (
        !this.$store.state.app.client.baseYear.year ||
        this.$store.state.app.client.baseYear.year === this.$store.state.app.report.year
      ) {
        return `<p>In subsequent inventories, comparisons will be made to the base year.</p>`
      } else {
        return ''
      }
    },
    baseYear () {
      if (
        !this.$store.state.app.client.baseYear.year ||
        this.$store.state.app.client.baseYear.year === this.$store.state.app.report.year
      ) {
        return 'This is the base year inventory therefore no comparisons can be made.'
      } else {
        let basePeriod = this.getReportingPeriod(
          parseInt(this.$store.state.app.client.baseYear.year),
          this.$store.state.app.client.baseYearReportingMonthStart
        )
        return basePeriod
      }
    },
    businessUnits () {
      // add table headings
      let tableHeadings =
        '<tr><th>Legal entities (Include any subsidaries)</th><th>Business unit / Location</th><th>Included / excluded</th><th>Reason for exclusion</th></tr>'
      let tableData = ''
      // loop through entities and add to table row
      this.$store.state.app.report.legalEntities.nodes.forEach(entity => {
        tableData += '<tr>'
        tableData += `<td>${entity.title ? entity.title : ''}</td>`
        tableData += `<td>${entity.location ? entity.location : ''}</td>`
        tableData += `<td>${entity.status}</td>`
        tableData += `<td>${
          entity.reasonForExclusion ? entity.reasonForExclusion : ''
        }</td>`
        tableData += '</tr>'
      })
      // piece table together
      return `<table>${tableHeadings}${tableData}</table`
    },
    businessUnitsBreakdown () {
      const organisations = JSON.parse(JSON.stringify(this.$store.state.app.client.allBusinessUnits.nodes))
      return renderBusinessUnitBreakdownTable(organisations, this.marketRateExists)
    },
    excludedEmissionsSources () {
      // add table headings
      let tableHeadings =
        '<tr><th>Emissions source excluded</th><th>Scope</th><th>Reason for exclusion</th></tr>'
      let tableData = ''
      // loop through entities and add to table row
      this.$store.state.app.report.excludedEmissionsSources.nodes.forEach(entity => {
        tableData += '<tr>'
        tableData += `<td>${entity.title ? entity.title : ''}</td>`
        tableData += `<td>${entity.scope ? entity.scope : ''}</td>`
        tableData += `<td>${
          entity.reasonForExclusion ? entity.reasonForExclusion : ''
        }</td>`
        tableData += '</tr>'
      })
      // piece table together
      return `<table>${tableHeadings}${tableData}</table`
    },
    // 5 Reporting Scopes
    emissionsCategories () {
      let categories = this.$store.state.app.report.emissionCategoryGroups.nodes.slice(0, 3)
      let cellCount = 6
      let headingData = [
        'ISO & GHG Protocol Categories',
        'Example of Emissions Sources',
        "Ekos' Position",
        'Include/ Exclude',
        'Exclusion Criteria',
        'Notes'
      ]
      let heading = this.renderCells(headingData, cellCount, true)

      let main = ''
      categories.forEach(group => {
        let data = []
        main += `<tr><td style="text-align: left !important" colspan=${cellCount}><strong>${group.title}</strong></td></tr>`
        group.emissionCategories.nodes.forEach(cat => {
          data.push(cat.title)
          data.push(cat.description)
          data.push(cat.ekosPosition)
          data.push(cat.status)
          data.push(cat.exclusionCriteria)
          data.push(cat.notes)
        })
        let mainTableData = `${this.renderCells(data, cellCount, false)}`
        main += mainTableData
      })

      return `<table class="emission-categories">${heading}${main}</table>`
    },
    emissionsCategories2 () {
      let categories = this.$store.state.app.report.emissionCategoryGroups.nodes.slice(3)
      let cellCount = 6
      let headingData = [
        'ISO & GHG Protocol Categories',
        'Example of Emissions Sources',
        "Ekos' Position",
        'Include/ Exclude',
        'Exclusion Criteria',
        'Notes'
      ]
      let heading = this.renderCells(headingData, cellCount, true)

      let main = ''
      categories.forEach(group => {
        let data = []
        main += `<tr><td style="text-align: left !important" colspan=${cellCount}><strong>${group.title}</strong></td></tr>`
        group.emissionCategories.nodes.forEach(cat => {
          data.push(cat.title)
          data.push(cat.description)
          data.push(cat.ekosPosition)
          data.push(cat.status)
          data.push(cat.exclusionCriteria)
          data.push(cat.notes)
        })
        let mainTableData = `${this.renderCells(data, cellCount, false)}`
        main += mainTableData
      })

      return `<table class="emission-categories">${heading}${main}</table>`
    },
    // 6.1
    emissionsScopeTableData () {
      let scopeTableHeadings =
        '<tr><th>Scope</th><th>Emissions Category</th><th>tCO<sub>2</sub>e (location-based)</th>'
        // market or location based
      scopeTableHeadings += this.marketRateExists ? `<th>tCO<sub>2</sub>e (market-based)</th></tr>` : `</tr>`
      let scopeTableData = ''
      this.emissionsSummaryTableData.scopeData.forEach(data => {
        scopeTableData += '<tr>'
        scopeTableData += `<td>${data.scopeNumber}</td>`
        scopeTableData += `<td>${data.emissionsCategory}</td>`
        scopeTableData += `<td>${this.$root.formatNumber(data.tCO2e)}</td>`
        // market or location based
        scopeTableData += this.marketRateExists
          ? `<td>${this.$root.formatNumber(data.tCO2eMarketRate)}</td>`
          : '</tr>'
      })
      let scopeTable = scopeTableHeadings + scopeTableData
      return scopeTable
    },
    // 6.1 Table 3
    emissionsISOTableData () {
      const grossGHGEmissions = this.emissionsSummaryTableData
        .totalGrossGHGEmissions
      let totalGrossGHGEmissionsHeading = `<tr class='bold'><td colspan='2'>Total Gross GHG Emissions</td><td>${this.$root.formatNumber(
        grossGHGEmissions.total
      )}</td>`
      // market or location based
      totalGrossGHGEmissionsHeading += this.marketRateExists
        ? `<td>${this.$root.formatNumber(grossGHGEmissions.totalMR)}</td></tr>`
        : `</tr>`
      // let grossEmissionCells = this.renderCells(grossGHGEmissions.data.cells, grossGHGEmissions.data.count)
      let grossData = totalGrossGHGEmissionsHeading + grossGHGEmissions.data
      return grossData
    },
    emissionsIntensityTableData () {
      const cellCount = this.marketRateExists ? 4 : 3
      let headingData = [
        'Emission Intensity Metrics',
        'Input',
        'tCO2e per Intensity Metric (Location based)'
      ]
      if (this.marketRateExists) {
        headingData.push('tCO<sub>2</sub>e Intensity Metric (market-based)')
      }
      let headingRow = this.renderCells(headingData, cellCount, true)

      const emissionIntensity = this.renderCells(
        this.emissionsSummaryTableData.emissionIntensity,
        cellCount
      )

      return headingRow + emissionIntensity
    },
    marketRateExists () {
      const emissionsSources = this.$store.state.app.client.period.nodes[0].emissionsSources.nodes
      return this.checkEmissionsSourcesForMarketRate(emissionsSources)
    },
    emissionsSummaryTotals () {
      // Based on ISO Category
      // Scope 1 - Category 1: Direct GHG emissions and removals
      let categoryOneTotal = this.getCategoryTotal(1)
      // Scope 2 - Category 2: Indirect GHG emissions from imported energy
      let categoryTwoTotal = this.getCategoryTotal(2)
      // Scope 3 - Category 3: Indirect GHG emissions from transportation
      let categoryThreeTotal = this.getCategoryTotal(3)
      // Scope 3 - Category 4: Indirect GHG emissions from products used by organization
      let categoryFourTotal = this.getCategoryTotal(4)
      // Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization
      let categoryFiveTotal = this.getCategoryTotal(5)
      // Scope 3 - Category 6: Indirect GHG emissions from other sources
      let categorySixTotal = this.getCategoryTotal(6)

      return {
        categoryOneTotal: categoryOneTotal,
        categoryTwoTotal: categoryTwoTotal,
        categoryThreeTotal: categoryThreeTotal,
        categoryFourTotal: categoryFourTotal,
        categoryFiveTotal: categoryFiveTotal,
        categorySixTotal: categorySixTotal
      }
    },
    // 6.1
    emissionsSummaryTableData () {
      let marketBasedElectricity = this.getMarketBasedElectricity()

      let scopeData = [
        {
          scopeNumber: '1',
          emissionsCategory: '(1) Direct GHG Emissions',
          tCO2e: this.emissionsSummaryTotals.categoryOneTotal.tCO2e,
          tCO2eMarketRate: this.emissionsSummaryTotals.categoryOneTotal.tCO2eMarketRate
        },
        {
          scopeNumber: '2',
          emissionsCategory: '(2) Indirect GHG Emissions From Imported Energy',
          tCO2e: this.emissionsSummaryTotals.categoryTwoTotal.tCO2e,
          tCO2eMarketRate: this.emissionsSummaryTotals.categoryTwoTotal.tCO2eMarketRate
        },
        {
          scopeNumber: '3',
          emissionsCategory:
            '(3) Indirect GHG Emissions From Transportation & Distribution',
          tCO2e: this.emissionsSummaryTotals.categoryThreeTotal.tCO2e,
          tCO2eMarketRate: this.emissionsSummaryTotals.categoryThreeTotal.tCO2eMarketRate
        },
        {
          scopeNumber: '3',
          emissionsCategory:
            '(4) Indirect GHG Emissions From Products & Services Used By The Organisation',
          tCO2e: this.emissionsSummaryTotals.categoryFourTotal.tCO2e,
          tCO2eMarketRate: this.emissionsSummaryTotals.categoryFourTotal.tCO2eMarketRate
        },
        {
          scopeNumber: '3',
          emissionsCategory:
            "(5) Indirect GHG Emissions From The Use Of The Organisation's Products",
          tCO2e: this.emissionsSummaryTotals.categoryFiveTotal.tCO2e,
          tCO2eMarketRate: this.emissionsSummaryTotals.categoryFiveTotal.tCO2eMarketRate
        },
        {
          scopeNumber: '3',
          emissionsCategory: '(6) Indirect GHG Emissions From Other Sources',
          tCO2e: this.emissionsSummaryTotals.categorySixTotal.tCO2e,
          tCO2eMarketRate: this.emissionsSummaryTotals.categorySixTotal.tCO2eMarketRate
        }
      ]

      let html = `<tr><td colspan='2'>GHG Removals/ Sinks</td><td>${this.$store.state.app.report.GHGRemoval === 0 ? 'NR' : this.$root.formatNumber(this.$store.state.app.report.GHGRemoval)}</td>`
      // market or location based
      html += this.marketRateExists ? `<td>${this.$store.state.app.report.GHGRemoval === 0 ? 'NR' : this.$root.formatNumber(this.$store.state.app.report.GHGRemoval)}</td></tr>` : '</tr>'

      let totalGrossGHGEmissions = {
        data: html,
        total: this.emissionsByScope.totalEmissions,
        totalMR: this.emissionsByScope.totalEmissionsMarketRate
      }

      let totalNetGHGEmissions = {
        total: this.totalNetGHGEmissions,
        totalMR: this.totalNetGHGEmissionsMR
      }
      let emissionsIntensityCells = this.getEmissionIntensityCells()

      return {
        scopeData: scopeData,
        totalGrossGHGEmissions: totalGrossGHGEmissions,
        totalNetGHGEmissions: totalNetGHGEmissions,
        emissionIntensity: emissionsIntensityCells,
        marketBasedElectricity: marketBasedElectricity
      }
    },
    totalNetGHGEmissions () {
      return (
        this.emissionsByScope.totalEmissions - this.totalOffset
      )
    },
    totalNetGHGEmissionsMR () {
      return this.emissionsByScope.totalEmissionsMarketRate - this.totalOffset
    },
    totalOffset () {
      return this.getTotalOffset()
    },
    // 6.1 liquid value
    emissionsSummary () {
      let emissionsSummary = `<div class="emissions-summary">`
      emissionsSummary += `<table class="emissions-summary-scope" id="emissions-summary-scope">${this.emissionsScopeTableData}${this.emissionsISOTableData}</table>`
      // emissionsSummary += `<table class="emissions-intensity">${this.emissionsIntensityTableData}</table>`
      emissionsSummary += '</div>'
      return emissionsSummary
    },
    // emissions intensity
    emissionsIntensityTable () {
      return `<table class="emissions-intensity">${this.emissionsIntensityTableData}</table>`
    },
    // 6.2 Emissions by Activities
    emissionsByActivityScopes () {
      let cellCount = 4
      let headingData = [
        'GHG scope',
        'Factor Groups',
        'Sum of tCO<sub>2</sub>e',
        '% of Inventory'
      ]
      let heading = this.renderCells(headingData, cellCount, true)

      let mainData = []
      let scopeOneEmissions = this.emissionsByScope.activities.filter(
        source => source.scope === 1
      )
      let scopeTwoEmissions = this.emissionsByScope.activities.filter(
        source => source.scope === 2
      )
      let scopeThreeEmissions = this.emissionsByScope.activities.filter(
        source => source.scope === 3
      )

      scopeOneEmissions.forEach(es => {
        mainData.push(es.scope.toString())
        mainData.push(es.name)
        mainData.push(es.tCO2e)
        mainData.push(es.percentageOfInventory)
      })
      scopeTwoEmissions.forEach(es => {
        mainData.push(es.scope.toString())
        mainData.push(es.name)
        mainData.push(es.tCO2e)
        mainData.push(es.percentageOfInventory)
      })
      scopeThreeEmissions.forEach(es => {
        mainData.push(es.scope.toString())
        mainData.push(es.name)
        mainData.push(es.tCO2e)
        mainData.push(es.percentageOfInventory)
      })

      let main = this.renderCells(mainData, cellCount, false)
      let totalRowData = [
        'Grand Total',
        '',
        // market or location based
        this.marketRateExists ? this.emissionsByScope.totalEmissionsMarketRate : this.emissionsByScope.totalEmissions,
        '100.00%'
      ]
      let total = this.renderCells(totalRowData, cellCount, true)
      return `<table>${heading}${main}${total}</table>`
    },
    emissionsByActivitiesTable () {
      // table 5
      let cellCount = 3
      let headingData = ['Emission Sources', 'GHG tCO<sub>2</sub>e', '% of Inventory']
      let heading = this.renderCells(headingData, cellCount, true)

      let mainData = []

      this.emissionSources.forEach(es => {
        mainData.push(es['Emissions source'])
        // market or location based
        mainData.push(this.marketRateExists && es['tCO2e (Market Rate)'] !== 'N/A' ? es['tCO2e (Market Rate)'] : es['tCO2e (Output)'])
        mainData.push(this.marketRateExists && es.percentageOfInventoryMR !== 'N/A' ? es.percentageOfInventoryMR : es.percentageOfInventory)
      })
      let main = this.renderCells(mainData, cellCount, false)
      let totalRowData = [
        'Grand Total',
        this.marketRateExists && this.emissionsByScope.totalEmissionsMarketRate ? this.emissionsByScope.totalEmissionsMarketRate : this.emissionsByScope.totalEmissions,
        '100.00%'
      ]
      let total = this.renderCells(totalRowData, cellCount, true)
      return `<table class='emissions-by-activity-table'>${heading}${main}${total}</table>`
    },
    // 6.3 liquid value
    emissionsByGasType () {
      let titleRow =
        '<table><tr class="heading"><td>GHG scope</td><td>1</td></tr></table>'
      let cellCount = 5
      let headingData = ['Emission Sources', 'tCO<sub>2</sub>e', 'tCO2', 'tCH4', 'tN2O']
      let heading = this.renderCells(headingData, cellCount, true)

      let mainData = []
      let scopeOneEmissions = this.$root.allEmissionSources.filter(
        es => es.GHGScope === 1 && es.rule !== 'hide-in-report' && es.rule !== 'hide'
      )
      let tCO2eTotal = 0
      let CO2Total = 0
      let CH4Total = 0
      let N2OTotal = 0
      scopeOneEmissions.forEach(es => {
        // add to totals
        tCO2eTotal += es.tCO2e
        CO2Total += es.CO2
        CH4Total += es.CH4
        N2OTotal += es.N2O
        // scope 1 emissions by gas type
        mainData.push(es.name)
        mainData.push(es.tCO2e)
        mainData.push(es.CO2)
        mainData.push(es.CH4)
        mainData.push(es.N2O)
      })
      let main = this.renderCells(mainData, cellCount, false)
      let totalRowData = [
        'Grand Total',
        tCO2eTotal,
        CO2Total,
        CH4Total,
        N2OTotal
      ]
      let total = this.renderCells(totalRowData, cellCount, true)
      return `${titleRow}<table>${heading}${main}${total}</table>`
    },
    // 6.4 Other emissions
    fugitiveEmissions () {
      let html = ''
      // console.log(this.totalFugitiveEmissions)
      if (this.totalFugitiveEmissions.total !== 0) {
        let cellCount = 4
        let headingData = ['Emission Sources', 'tPFC', 'tHFC', 'tSF6']
        let heading = this.renderCells(headingData, cellCount, true)
        let mainData = [
          this.totalFugitiveEmissions.name,
          this.totalFugitiveEmissions.PFC,
          this.totalFugitiveEmissions.HFC,
          this.totalFugitiveEmissions.SF6
        ]
        let main = this.renderCells(mainData, cellCount, false)
        let totalRowData = [
          'Grand Total',
          this.totalFugitiveEmissions.PFC,
          this.totalFugitiveEmissions.HFC,
          this.totalFugitiveEmissions.SF6
        ]
        let total = this.renderCells(totalRowData, cellCount, true)
        html = `<em><h4>Table 8.1: Fugitive emissions breakdown by gas types</h4></em><table>${heading}${main}${total}</table>`
        let fugitive = this.$store.state.app.report.otherEmissions.nodes.find(
          emission => emission.identifier === 'fugitive'
        )
        if (fugitive.notes) html += `<p>${fugitive.notes}</p>`
      } else {
        html =
          '<p>No sites have reported any top-ups of gas for this reporting period. Air conditioning is excluded from the inventory where offices are leased.</p><p>There are no operations that use PFC, NF3 or SF6.</p>'
      }
      return html
    },
    combustionOfBiomass () {
      let html = ''
      if (this.biogenicEmissions.totalBiogenicEmissions !== 0) {
        let cellCount = 2
        let headingData = ['Biogenic Source', 'Total tCO2']
        let heading = this.renderCells(headingData, cellCount, true)
        let mainData = []
        this.biogenicEmissions.biogenicData.forEach(bd => {
          mainData.push(bd.name)
          mainData.push(bd.tCO2)
        })
        // let mainData = [
        //   'Wood Industrial (Biofuel)',
        //   this.totalBiogenicEmissions
        // ]
        let main = this.renderCells(mainData, cellCount, false)
        let totalRowData = ['Grand Total', this.biogenicEmissions.totalBiogenicEmissions]
        let total = this.renderCells(totalRowData, cellCount, true)
        html = `
        <p>${this.biogenicEmissions.consumptionMessage} consumed during the measure period. The CH4 and N2O emissions has been included in the inventory and the breakdown is available in Table 8. The biogenic carbon associated with the combustion of biomass is shown in Table 8.2 below.</p>
        <em><h4>Table 8.2: Biogenic carbon</h4></em>
        <table>${heading}${main}${total}</table>`
        let biomass = this.$store.state.app.report.otherEmissions.nodes.find(
          emission => emission.identifier === 'biomass'
        )
        if (biomass.notes) html += `<p>${biomass.notes}</p>`
      } else {
        html =
          '<p>No known combustion of biomass occurred from the operation during this measure period and therefore no emissions from the combustion of biomass are included in this inventory.</p>'
      }
      return html
    },
    landUse () {
      let landuse = this.$store.state.app.report.otherEmissions.nodes.find(
        emission => emission.identifier === 'landuse'
      )
      if (landuse && landuse.status === 'Has Occured') {
        const notes = landuse.notes
          ? landuse.notes
          : 'Land use and Land use change has occured in this inventory but no explanation has been given.'
        return `<p>${notes}</p>`
      } else {
        return '<p>No deforestation has been undertaken by the organisation on land it owns during this measurement period. Therefore no emissions from deforestation are included in this inventory.</p>'
      }
    },
    preVerifiedData () {
      let data = this.$store.state.app.report.otherEmissions.nodes.find(
        emission => emission.identifier === 'pre-verified'
      )
      if (data && data.status === 'Has Occured') {
        const notes = data.notes
          ? data.notes
          : 'Pre-verified data has occured in this inventory but no explanation has been given.'
        return `<p>${notes}</p>`
      } else {
        return '<p>No pre-verified data is included within the inventory.</p>'
      }
    },
    // 7 Data Quality, Uncertainties and Assumptions
    activityDataCollection () {
      let cellCount = 6
      let headingData = [
        'Emissions source',
        'Scope',
        'Unit',
        'Data source',
        'Data quality',
        'Any assumptions made'
      ]
      let activityData = []

      this.$root.allActiveSchemas.forEach(schema => {
        if (schema.name !== 'Additional Sources') {
          activityData.push(schema.title)
          activityData.push('1')
          activityData.push(schema.name === 'Contracted Services and Providers' ? 'tCO2e' : schema.emissionSources[0].unit) // tCO2e CS instead of unit of first input box
          activityData.push(schema.dataSource)
          activityData.push(schema.dataQuality)
          activityData.push(schema.assumptions)
        } else {
          schema.emissionSources.forEach(source => {
            activityData.push(source.name)
            activityData.push(source.GHGScope.toString())
            activityData.push(source.unit)
            activityData.push(source.emissionSourceInfo.dataSource)
            activityData.push(source.emissionSourceInfo.dataQuality)
            activityData.push(source.emissionSourceInfo.assumptions)
          })
        }
      })

      let heading = this.renderCells(headingData, cellCount, true)

      let activityDataTable = this.renderCells(activityData, cellCount, false)

      return `<table class="data-quality">${heading}${activityDataTable}</table>`
    },
    // 9 Reduction Recommendations
    reductionRecommendations () {
      let activities = [...this.emissionSources]
      const topActivities = activities.slice(0, 3)
      let html = ''
      topActivities.forEach(activity => {
        html += `<p><strong>${activity['Emissions source']}</strong></p><p>Analyst add recommendation</p>`
      })
      return html
    },
    // Double Counting
    recognisedOffsetDeductionTable () {
      const offsets = this.$store.state.app.report.recognisedOffsetDeductions.nodes
      if (!offsets.length) {
        return ''
      }
      const cellCount = 3
      let heading = "<tr class='heading'><td  colspan='3' style='text-align: center !important;'>Recognised Offset Deductions</td></tr>"
      let subheading = "<tr class='subheading'><td>Emissions Source</td><td>Additional Notes</td><td>tCO<sub>2</sub>e deducted</td></tr>"
      let mainData = []
      offsets.forEach(offset => {
        mainData.push(offset.title)
        mainData.push(offset.notes)
        mainData.push(offset.amount)
      })
      let main = this.renderCells(mainData, cellCount)
      let total = `<tr class='subheading'><td colspan='2'>Total Recognised Offset Deductions</td><td>${this.$root.formatNumber(this.preOffsetTotal)}</td></tr>`
      return `<em><h4>Table 10: Recognised offset deductions</h4></em><table>${heading}${subheading}${main}${total}<table>`
    },
    // Offset Amount
    offset () {
      // const totalGHG = this.emissionsByScope.totalEmissions
      let cellCount = 6
      let headingData = [
        'Total Gross GHG Emissions',
        'Offset requirement',
        '',
        'Purchased credits/ Pre-offset',
        'Net offset requirement',
        'Total Credits to offset'
      ]
      let heading = this.renderCells(headingData, cellCount, true)
      let mainData = this.getOffsetTableData()
      let main = this.renderCells(mainData, cellCount, false)
      const mainTable = `<table>${heading}${main}</table>`

      const totalTable = this.carbonOffsetTable

      return `${mainTable}${totalTable}`
    },
    carbonOffsetTable () {
      return ''
    },
    // 11.3 Carbon Credits
    cancelledCarbonCredits () {
      let cellCount = 3
      let headingData = ['Offset Type', 'Description', '# Units Cancelled']
      let heading = this.renderCells(headingData, cellCount, true)
      let mainData = []
      this.$store.state.app.report.cancelledCarbonCredits.nodes.forEach(cancelledCredit => {
        let credit = this.getCredit(cancelledCredit.carbonCreditTypeID)
        mainData.push(credit.title)
        mainData.push(credit.description ? credit.description : cancelledCredit.customDescription)
        mainData.push(cancelledCredit.amount)
      })
      let main = this.renderCells(mainData, cellCount)
      let openingMessage = ''
      if (mainData[0] === 'None') {
        openingMessage = mainData[1]
      } else {
        openingMessage = `${this.$store.state.app.client.preferredName} has elected to cancel the following carbon credits:`
      }
      if (this.$store.state.app.report.cancelledCarbonCredits.nodes.length && mainData[0] === 'None') {
        return `<p>${openingMessage}</p>`
      } else if (this.$store.state.app.report.cancelledCarbonCredits.nodes.length) {
        return `<p>${openingMessage}</p><em><h4>Table 11.1: Carbon credits</h4></em><table>${heading}${main}</table>`
      } else {
        return `<p>No offset required for this inventory.</p>`
      }
    },
    electricityOffset () {
      return this.getElectricityOffset(this.$root.masterSchema)
    },
    preOffsetTotal () {
      return this.getPreOffsetTotal()
    },
    biogenicEmissions () {
      let consumptionMessage = ''
      let biogenicData = []
      let biogenicEmissionSources = [
        { id: 'wood-pallet', name: 'Wood pellets' },
        { id: 'bioethanol', name: 'Bioethanol' },
        { id: 'bioethanol-mc', name: 'Bioethanol' },
        { id: 'biodiesel', name: 'Biodiesel' },
        { id: 'biodiesel-mc', name: 'Biodiesel' }
      ]
      let totalBiogenicEmissions = 0
      biogenicEmissionSources.forEach((biogenicSource, index) => {
        let source = this.$root.allEmissionSources.find(
          emissionSource => emissionSource.id === biogenicSource.id
        )
        if (source) {
          totalBiogenicEmissions += source.CO2
          biogenicData.push({ tCO2: source.CO2, name: source.name, messageName: biogenicSource.name })
        }
        let messageInfo = biogenicData.filter((value, index, self) => {
          return self.findIndex(v => v.messageName === value.messageName) === index
        })
        // build consumption message
        if (messageInfo.length) {
          consumptionMessage = messageInfo.map((info, index) => {
            if (index === messageInfo.length - 1 && messageInfo.length > 1) {
              return ` and ${info.messageName.toLowerCase()}`
            } else {
              return index > 0 ? `, ${info.messageName.toLowerCase()}` : info.messageName
            }
          }).join('')
          consumptionMessage += biogenicData.find(bd => bd.messageName === 'Wood pellets') ? ' were' : ' was'
        }
      })
      return {
        totalBiogenicEmissions,
        biogenicData,
        consumptionMessage
      }
    },
    totalFugitiveEmissions () {
      const extraGasSubcategories = ['Chemical & Industrial Processes', 'Land use and Land Use changes', 'Mobile Combustion', 'Stationary Combustion']
      let fugitiveEmissionsSources = this.$store.state.app.client.period.nodes[0].emissionsSources.nodes.filter(
        es => extraGasSubcategories.includes(es.subcategory)
      )
      // console.log(fugitiveEmissionsSources)
      var PFC = 0
      var HFC = 0
      var SF6 = 0
      fugitiveEmissionsSources.forEach(es => {
        PFC += es.PFC
        HFC += es.HFC
        SF6 += es.SF6
      })
      return {
        name: 'Air Conditioning/Refrigerants',
        PFC: PFC,
        HFC: HFC,
        SF6: SF6,
        total: PFC + HFC + SF6
      }
    }
  }
}
</script>
