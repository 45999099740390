var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"waste-water-treatment"},[_c('div',{staticClass:"tooltip"},[_c('h1',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"modal-btn",on:{"click":_vm.openModal}},[_c('i',{staticClass:"fas fa-question-circle"})])]),_c('div',{staticClass:"modal-wrapper",class:_vm.modalOpen ? 'active' : ''},[_c('Modal',{attrs:{"tooltip":_vm.tooltip},model:{value:(_vm.modalOpen),callback:function ($$v) {_vm.modalOpen=$$v},expression:"modalOpen"}})],1),_c('h2',{staticClass:"secondary-title"},[_vm._v("Unit: "+_vm._s(this.unit))]),_c('div',{staticClass:"controls"},[(_vm.$root.userDetails.organisationStatus !== 'Completed')?_c('Controls',{directives:[{name:"show",rawName:"v-show",value:(!_vm.schema.annually),expression:"!schema.annually"}],on:{"add":function($event){return _vm.addUnit()},"remove":function($event){return _vm.removeUnit()},"update":function($event){return _vm.calculateCarbonEmissions()}}}):_vm._e()],1),(!_vm.schema.annually)?_c('div',[(_vm.type !== 'product')?_c('div',_vm._l((_vm.schema.units),function(unit,i){return _c('div',{key:("unit-" + i),staticClass:"input-section"},[_c('h2',{staticClass:"secondary-title"},[_vm._v("Unit "+_vm._s(i + 1))]),_c('div',{staticClass:"input-container-grid"},_vm._l((unit.months),function(month,index){return _c('div',{key:("month-" + (index - 1)),staticClass:"input-item stacked"},[_c('label',{staticClass:"month"},[_vm._v(" "+_vm._s(month.name)+" "),(_vm.type === 'product')?_c('span',[_vm._v(_vm._s(index + 1))]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(month.measurement),expression:"month.measurement",modifiers:{"number":true}}],staticClass:"unit-text-box",attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"type":"number","step":"0.01","min":"0"},domProps:{"value":(month.measurement)},on:{"change":function () {
                month.measurement = month.measurement || 0;
                _vm.calculateCarbonEmissions()
              },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(month, "measurement", _vm._n($event.target.value))}}})])}),0)])}),0):_c('div',_vm._l((_vm.schema.units),function(unit,i){return _c('div',{key:("unit-" + i),staticClass:"input-section"},[_c('div',{staticClass:"input-container-column"},_vm._l((unit.months),function(month,index){return _c('div',{key:("month-" + (index - 1)),staticClass:"input-item stacked"},[_c('label',{staticClass:"month"},[_vm._v(" "+_vm._s(month.name)+" "),(_vm.type === 'product')?_c('span',[_vm._v(_vm._s(index + 1))]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(month.measurement),expression:"month.measurement",modifiers:{"number":true}}],staticClass:"unit-text-box",attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"type":"number","step":"0.01","min":"0"},domProps:{"value":(month.measurement)},on:{"change":function () {
                month.measurement = month.measurement || 0;
                _vm.calculateCarbonEmissions()
              },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(month, "measurement", _vm._n($event.target.value))}}})])}),0)])}),0)]):_vm._e(),_c('div',{staticClass:"input-section"},[(!_vm.schema.annually)?_c('div',{staticClass:"input-container-row"},[_c('h2',{staticClass:"secondary-title"},[_vm._v(" Grand Total: ")]),_c('h2',{staticClass:"secondary-title"},[_vm._v(" "+_vm._s(_vm.schema.emissionSources[0].annualTotal)+" ")])]):_c('div',{staticClass:"input-container-row",staticStyle:{"margin-top":"10px"}},[_c('h2',{staticClass:"secondary-title"},[_vm._v(" Grand Total: ")]),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.schema.annualGrandTotal),expression:"schema.annualGrandTotal",modifiers:{"number":true}}],attrs:{"disabled":_vm.$root.userDetails.organisationStatus === 'Completed',"type":"number","step":"0.01","min":"0"},domProps:{"value":(_vm.schema.annualGrandTotal)},on:{"change":function () {
            _vm.schema.annualGrandTotal = _vm.schema.annualGrandTotal || 0;
            _vm.calculateCarbonEmissions()
          },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.schema, "annualGrandTotal", _vm._n($event.target.value))}}})])]),_c('div',{staticClass:"input-container-row"},[_c('h2',{staticClass:"secondary-title"},[_vm._v(" Total Carbon Emissions: ")]),_c('h2',{staticClass:"secondary-title"},[_vm._v(" "+_vm._s(_vm.$root.formatNumber(_vm.schema.emissionSources[0].tCO2e))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }