<template>
  <div class="input-section">
    <!-- Modal start -->
    <div class="tooltip">
      <h1 class="section-title">Fuels</h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <!-- Modal end -->
    <div>
      <h3 class="secondary-title">Volume consumed (litres)</h3>
      <div class="input-container-column" style="max-width: 300px;">
        <div class="input-item long">
          <label for="petrol">Petrol (regular)</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-model.number="getEmissionSource('Mobile Combustion - Petrol (regular)').annualTotal"
            v-on:change="() => {
              getEmissionSource('Mobile Combustion - Petrol (regular)').annualTotal = getEmissionSource('Mobile Combustion - Petrol (regular)').annualTotal || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            min="0"
            type="number" step="0.01"
            name="petrol-regular"
          />
        </div>
        <div class="input-item long">
          <label for="petrol">Petrol (premium)</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-model.number="getEmissionSource('Mobile Combustion - Petrol (premium)').annualTotal"
            v-on:change="() => {
              getEmissionSource('Mobile Combustion - Petrol (premium)').annualTotal = getEmissionSource('Mobile Combustion - Petrol (premium)').annualTotal || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            min="0"
            type="number" step="0.01"
            name="petrol-premium"
          />
        </div>
        <div class="input-item long">
          <label for="diesel">Diesel</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-model.number="getEmissionSource('Mobile Combustion - Diesel').annualTotal"
            v-on:change="() => {
              getEmissionSource('Mobile Combustion - Diesel').annualTotal = getEmissionSource('Mobile Combustion - Diesel').annualTotal || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            min="0"
            type="number" step="0.01"
            name="diesel"
          />
        </div>
        <div class="input-item long">
          <label for="lpg">LPG</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-model.number="getEmissionSource('Mobile Combustion - LPG').annualTotal"
            v-on:change="() => {
              getEmissionSource('Mobile Combustion - LPG').annualTotal = getEmissionSource('Mobile Combustion - LPG').annualTotal || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            min="0"
            type="number" step="0.01"
            name="lpg"
          />
        </div>
        <div class="input-item long">
          <label for="aviationGas">Aviation Gas</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-model.number="getEmissionSource('Mobile Combustion - Aviation Gas').annualTotal"
            v-on:change="() => {
              getEmissionSource('Mobile Combustion - Aviation Gas').annualTotal = getEmissionSource('Mobile Combustion - Aviation Gas').annualTotal || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            min="0"
            type="number"
            name="aviationGas"
          />
        </div>
        <div class="input-item long">
          <label for="aviationFuelKerosene">Aviation Fuel Kerosene</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-model.number="getEmissionSource('Mobile Combustion - Aviation Fuel Kerosene').annualTotal"
            v-on:change="() => {
              getEmissionSource('Mobile Combustion - Aviation Fuel Kerosene').annualTotal = getEmissionSource('Mobile Combustion - Aviation Fuel Kerosene').annualTotal || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            min="0"
            type="number"
            name="aviationFuelKerosene"
          />
        </div>
        <div class="input-item long">
          <label for="bioethanol">Bioethanol</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-model.number="getEmissionSource('Mobile Combustion - Bioethanol').annualTotal"
            v-on:change="() => {
              getEmissionSource('Mobile Combustion - Bioethanol').annualTotal = getEmissionSource('Mobile Combustion - Bioethanol').annualTotal || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            min="0"
            type="number"
            name="bioethanol"
          />
        </div>
        <div class="input-item long">
          <label for="biodiesel">Biodiesel</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-model.number="getEmissionSource('Mobile Combustion - Biodiesel').annualTotal"
            v-on:change="() => {
              getEmissionSource('Mobile Combustion - Biodiesel').annualTotal = getEmissionSource('Mobile Combustion - Biodiesel').annualTotal || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            min="0"
            type="number"
            name="biodiesel"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import Modal from '../../../modal/Modal.vue'
// import calculationData from '../../../../utils/calculationData'
export default {
  components: {
    Modal
  },
  name: 'fuels-component',
  props: {
    initialSchema: Object,
    activityID: String,
    schemaName: String
  },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },
  methods: {
    getEmissionSource (name) {
      return this.schema.emissionSources.find(s => s.name === name)
    },
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions () {
      var self = this
      // if (self.schema.petrol && self.schema.diesel) {
      return axios
        .post(
          '' +
            process.env.VUE_APP_APIURL +
            'api/calculateScopeOneCompanyVehicles',
          {
            key: 'fuel',
            input: self.schema
            // calculationData: calculationData.scopeOneCompanyVehiclesFuels
          }
        )
        .then(function (response) {
          // handle success
          // console.log(response)
          // let total = response.data.result
          self.$emit('calculateTotal', response.data.result, self.schemaName)
          self.$root.saveData()
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
      // }
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.$options.name}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
