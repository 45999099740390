<template>
  <div v-if="$store.state.app.client" class="company-information-container">
    <h1 class="main-heading">Company Information</h1>
    <div class="trading-name">
      <label>Trading As:</label>
      <input
        placeholder="Trading As Name"
        v-model="$store.state.app.client.tradingName"
        v-on:change="updateClient('tradingName')"
        v-on:focus="$root.setEditing()"
        v-on:blur="$root.unsetEditing()" />
    </div>
    <div class="trading-name">
      <label>Preferred Name:</label>
      <input
        placeholder="Preferred Name"
        v-model="$store.state.app.client.preferredName"
        v-on:change="updateClient('preferredName')"
        v-on:focus="$root.setEditing()"
        v-on:blur="$root.unsetEditing()" />
    </div>
    <div class="description">
      Thereafter in the report, the organisation will be referred to as this
      name.
    </div>
    <h1 class="section-title">
      Please describe your organisation and what it does
    </h1>
    <div class="description">
      Please, briefly, describe what your business does, who for, where from and
      if you have more than one office or facility (details, such as location,
      can be given below) feel free to insert an organisation structure diagram
      if relevant
    </div>
    <div class="label">Organisational description</div>
    <div class="textarea">
      <textarea
        rows="8"
        cols="75"
        v-model.lazy.trim="$store.state.app.client.description"
        v-on:change="updateClient('description')"
        v-on:focus="$root.setEditing()"
        v-on:blur="$root.unsetEditing()"></textarea>
    </div>
    <div class="spacer"></div>
    <h1 class="section-title activity-title">
      Please describe why your organisation is undertaking this measurement
    </h1>
    <div class="label">Organisational Statement of Intent</div>
    <div class="textarea">
      <textarea
        rows="8"
        cols="75"
        v-model.lazy.trim="$store.state.app.client.statementOfIntent"
        v-on:change="updateClient('statementOfIntent')"
        v-on:focus="$root.setEditing()"
        v-on:blur="$root.unsetEditing()"></textarea>
    </div>
    <br><br>
    <h1 class="section-title activity-title">
      Please describe your organisations intended use of this measurement
    </h1>
    <div class="label">Organisational communication and dissemination</div>
    <RichTextEditor
      :value="$store.state.app.client.intendedUse"
      @focus="$root.setEditing()"
      @onBlurUpdate="change"
    />
    <div class="spacer"></div>
    <BaseYear />
    <div class="spacer"></div>
    <ReportNotes
      @updateReportData="updateReport"
      property="notes"
      title="Measurement period and base year selection notes, if applicable"
      placeholder="Measurement period and base year selection notes, if applicable" />
    <div class="spacer"></div>
    <h1 class="section-title activity-title">
      Please insert your organisational chart below if possible or email to us
    </h1>
    <div class="organisational-chart">
      <img
        v-if="$store.state.app.client.organisationalChartThumbURL"
        :src="$store.state.app.client.organisationalChartThumbURL"
        alt="organisationalChartThumbURL"
        style="width: 100px; margin-right: 20px" />

      <FileInput
        id="organisational-chart"
        :upload-url="`client/update/${$store.state.app.client.id}`"
        name="OrganisationalChart"
        accept="image/*" />
    </div>
    <ReportNotes
      @updateReportData="updateReport"
      property="organisationChartNotes"
      title="Organisation Chart Notes"
      placeholder="Notes" />
  </div>
</template>

<script>
import FileInput from '../FileInput.vue'
import BaseYear from '../companyInformation/BaseYear.vue'
import ReportNotes from './ReportNotes.vue'
import RichTextEditor from '../RichTextEditor.vue'
export default {
  methods: {
    change (value) {
      // console.log('change', value)
      this.$store.state.app.client.intendedUse = value
      this.updateClient('intendedUse')
      this.$root.unsetEditing()
    },
    async updateClient (field) {
      const data = { client: { id: this.$store.state.app.client.id } }
      data.client[field] = this.$store.state.app.client[field]
      this.$root.saving = true
      await this.$store.dispatch('updateClient', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    async updateReport (field, loadAfterSaving = false) {
      const data = { report: { id: this.$store.state.app.report.id } }
      data.report[field] = this.$store.state.app.report[field]
      data.load = loadAfterSaving
      // console.log(data.report, 'updating report data')
      this.$root.saving = true
      await this.$store.dispatch('updateReport', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    }
  },
  components: { FileInput, BaseYear, ReportNotes, RichTextEditor }
}
</script>
