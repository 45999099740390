<template>
  <div class="cancelled-credit">
      <div class="input-container-row">
        <label>Offset Type</label>
        <select
          v-model.lazy="cancelledCredit.carbonCreditTypeID"
          v-on:change="updateCancelledCarbonCredit('carbonCreditTypeID', cancelledCredit)"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        >
          <option v-for="type in creditTypes" :key="type.id" :value="type.id">{{
            type.title
          }}</option>
        </select>
      </div>
      <div class="input-container-row">
        <label>Number of units to be cancelled</label>
        <input
          type="number"
          v-model.number="cancelledCredit.amount"
          v-on:change="updateCancelledCarbonCredit('amount', cancelledCredit)"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
      </div>
      <label v-if="currentCreditType.description" style="display: flex; margin: 10px 0 0 0">
        {{ creditDescription }}
      </label>
      <div v-else>
        <div class="report-input-section">
          <div class="report-input-container">
            <div class="report-input-item">
              <label>Description:</label>
              <textarea
                rows="8"
                cols="75"
                v-model="cancelledCredit.customDescription"
                v-on:change="updateCancelledCarbonCredit('customDescription', cancelledCredit)"
                v-on:focus="$root.setEditing()"
                v-on:blur="$root.unsetEditing()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  props: { cancelledCredit: Object },
  data () {
    return {
      creditTypes: []
    }
  },
  async beforeMount () {
    await this.$store.dispatch('loadCarbonCreditTypes')
    this.creditTypes = this.$store.state.app.carbonCreditTypes
  },
  methods: {
    async updateCancelledCarbonCredit (fieldName, cancelledCredit) {
      let timer = setInterval(async () => {
        if (!this.$root.editing) {
          clearInterval(timer)
          const data = { cancelledCarbonCredit: { id: cancelledCredit.id } }
          data.cancelledCarbonCredit[fieldName] = cancelledCredit[fieldName]
          // console.log(data.emissionIntensityMetric, 'updating emissionIntensityMetric data')
          this.$root.saving = true
          await this.$store.dispatch('updateCancelledCarbonCredit', data)
          this.$root.saving = false
        }
      }, 1000)
    },
    updateReportData (fieldName) {
      this.$emit('updateReportData', fieldName, true)
    }
  },
  computed: {
    creditDescription () {
      let credit = this.creditTypes.find(
        type => type.id === this.cancelledCredit.carbonCreditTypeID
      )
      return credit?.description || ''
    },
    currentCreditType () {
      let currentType = this.creditTypes.find(
        type => type.id === this.cancelledCredit.carbonCreditTypeID
      )
      return currentType || ''
    }
  }
}
</script>

<style></style>
