import { apolloClient } from '../../vue-apollo.js'
import gql from 'graphql-tag'
import * as Sentry from '@sentry/vue'

// import store from '../../store'

const initState = () => {
  return {
    id: null,
    firstName: null,
    surname: null,
    email: null,
    authenticated: false,
    authAttempted: false,
    securityTokenID: false,
    groups: [],
    clientID: 0,
    impersonateClientID: 0,
    organisationID: 0
  }
}

export default {
  state: initState(),
  mutations: {
    SET_USER: (state, data) => {
      if (!data) return
      // console.log(data)
      if ('id' in data && data.id !== '0' && data.id !== 0) {
        state.id = data.id
        if (data.groupsString) state.groups = data.groupsString.split(', ')
        state.authenticated = true
      }

      // Sets all the state properties that are in the data object
      Object.keys(data).forEach(prop => {
        if (prop in state) {
          state[prop] = data[prop]
        }
      })

      if ('impersonateClientID' in data) {
        state.clientID = data.impersonateClientID
      }

      // if they are strings make them numbers
      state.clientID = parseInt(state.clientID)
      state.organisationID = parseInt(state.organisationID)
      window.localStorage.setItem('organisationID', state.organisationID)

      Sentry.setUser({ id: state.id, username: `${state.firstName} ${state.surname}`, email: state.email, organisationID: state.organisationID, impersonateClientID: state.impersonateClientID ? state.impersonateClientID : null, groups: state.groups.toString() })
    }
  },
  actions: {
    async logout ({ commit, state }, data) {
      Sentry.setUser(null)
      window.localStorage.removeItem('organisationID')
      window.location = `${process.env.VUE_APP_APIURL}Security/logout?securityTokenID=${state.securityTokenID}`
    },

    async updateUser ({ commit }, data) {
      if (data.impersonateClientID) window.localStorage.setItem('impersonateClientID', data.impersonateClientID)
      commit('SET_USER', data)
    },

    async getUser ({ commit, state }) {
      const query = gql`{
        readCurrentMember {
          id
          firstName
          surname
          email
          securityTokenID
          groupsString
          clientID
        }
      }`

      const { data } = await apolloClient.query({
        query,
        fetchPolicy: 'no-cache'
      })

      data.readCurrentMember.authAttempted = true

      const res = data.readCurrentMember
      const groups = res.groupsString.split(', ')

      let impersonate = window.localStorage.getItem('impersonateClientID')
      if (groups.includes('administrators') && impersonate) {
        let impersonateRes = await this.dispatch('getClient', impersonate)
        impersonate = impersonateRes ? impersonate : null

        if (impersonate) {
          res.impersonateClientID = impersonate
        }
      }

      // groups.includes('administrators') && impersonate && no organisationID in local storage fetch one
      if (groups.includes('administrators') && impersonate && !window.localStorage.getItem('organisationID')) {
        console.log(impersonate)
        let organisationRes = await this.dispatch('getClientOrganisation', impersonate)
        if (organisationRes && organisationRes.businessUnits && organisationRes.businessUnits.nodes.length > 0) {
          window.localStorage.setItem('organisationID', organisationRes.businessUnits.nodes[0].id)
        }
      }

      if (window.localStorage.getItem('organisationID')) res.organisationID = window.localStorage.getItem('organisationID')

      commit('SET_USER', res)
    },
    async getClient ({ commit, state }, clientID) {
      const res = await apolloClient.query({
        query: gql`
          query($filter: ClientFilterFields!) {
            readOneClient(filter: $filter) {
              id
            }
          }
        `,
        variables: {
          filter: { id: { eq: clientID } }
        },
        fetchPolicy: 'no-cache'
      })
      return res.data.readOneClient
    },
    async getClientOrganisation ({ commit, state }, clientID) {
      const res = await apolloClient.query({
        query: gql`
          query($filter: ClientFilterFields!) {
            readOneClient(filter: $filter) {
              businessUnits {
                nodes {
                  id
                }
              }
            }
          }
        `,
        variables: {
          filter: { id: { eq: clientID } }
        },
        fetchPolicy: 'no-cache'
      })
      return res.data.readOneClient
    }
  },
  getters: {
    clientID (state) {
      return parseInt(state.clientID) ? parseInt(state.clientID) : parseInt(state.impersonateClientID)
    }
  }
}
