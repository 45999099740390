<template>
  <div class="reports"></div>
</template>

<script>
import Chartist from 'chartist'
// import legend from 'chartist-plugin-legend'

export default {
  data () {
    return {}
  },
  name: 'perfomance-comparison-graph',
  props: {
    emissionActivities: Array,
    baseYearActivities: Array,
    previousYearActivities: Array,
    outstandingBaseYearActivities: Array,
    outstandingPreviousYearActivities: Array,
    onlyPreviousYearActivities: Array
  },
  mounted () {
    this.renderGraph()
  },
  methods: {
    renderGraph () {
      var options = {
        seriesBarDistance: 10,
        axisX: {
          offset: 50,
          scaleMaxSpace: 10
        },
        axisY: {
          offset: 20,
          labelInterpolationFnc: function (value) {
            return value
          },
          scaleMinSpace: 15
        },
        // plugins: [
        //   legend({
        //     legendNames: [
        //       'Base year tCO2e',
        //       'Previous year tCO2e',
        //       'Current year tCO2e'
        //     ],
        //     clickable: false
        //   })
        // ],
        width: '21cm',
        height: '400px'
      }

      var data = {
        labels: this.activityNames,
        series: this.comparisonSeries
      }

      return Chartist.Bar(
        '.performance-against-previous-years-graph',
        data,
        options
      )
    },
    addActivityNames (sourcesArray) {
      let names = []
      sourcesArray.forEach(es => {
        names.push(es.name)
      })
      return names
    }
  },
  computed: {
    activityNames () {
      var names = []
      // add current activity names
      names = names.concat(this.addActivityNames(this.emissionActivities))
      // add outstanding activity names found in base year
      names = names.concat(
        this.addActivityNames(this.outstandingBaseYearActivities)
      )
      // add activity names not in current or base year
      names = names.concat(
        this.addActivityNames(this.onlyPreviousYearActivities)
      )

      return names
    },
    comparisonSeries () {
      var series = []
      let baseYear = []
      let prevYear = []
      let currentYear = []

      this.emissionActivities.forEach(es => {
        let baseYearActivity = this.baseYearActivities.find(
          bs => bs.name === es.name
        )
        let previousYearActivity = this.previousYearActivities.find(
          previous => previous.name === es.name
        )

        currentYear.push(es.tCO2e)
        baseYearActivity
          ? baseYear.push(baseYearActivity.emissions)
          : baseYear.push(0)
        previousYearActivity
          ? prevYear.push(previousYearActivity.emissions)
          : prevYear.push(0)
      })

      // add outstanding base year activities with base year and previous year values
      this.outstandingBaseYearActivities.forEach(activity => {
        let previousYearActivity = this.previousYearActivities.find(
          previous => previous.name === activity.name
        )
        currentYear.push(0)
        baseYear.push(activity.tCO2e)
        previousYearActivity
          ? prevYear.push(previousYearActivity.emissions)
          : prevYear.push(0)
      })

      // add final activities only found in previous year
      this.onlyPreviousYearActivities.forEach(activity => {
        currentYear.push(0)
        baseYear.push(0)
        prevYear.push(activity.tCO2e)
      })

      // push stats from each year
      series.push(baseYear)
      series.push(prevYear)
      series.push(currentYear)
      return series
    }
  }
}
</script>

<style></style>
