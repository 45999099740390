<template>
  <div class="input-section">
    <!-- Modal start -->
    <div class="tooltip">
      <h1 class="section-title">{{ title }}</h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <!-- Modal end -->
    <div>
      <h3 class="secondary-title">Volume Consumed</h3>
      <template v-for="emissionSource in schema.emissionSources" >
      <div class="input-container-column" :key="emissionSource.id">
        <div v-if="emissionSource.type === 'default'" class="input-item stacked">
          <label for="petrol">{{ parseNameForLabel(emissionSource.name) }} ({{emissionSource.unit}})</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-on:change="() => {
              emissionSource.annualTotal = emissionSource.annualTotal || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model.number="emissionSource.annualTotal"
            type="number" step="0.01"
            :name="emissionSource.id"
          />
        </div>
        <!-- </div> -->
      </div>
      </template>
    </div>
    <h3 class="secondary-title">
      <!-- Carbon Emissions: {{ $root.formatNumber(schema.carbonEmissions) }} -->
      Carbon Emissions: {{ $root.formatNumber(carbonEmissions) }}
    </h3>
  </div>
</template>

<script>
import axios from '@/axios'
import Modal from '../../../modal/Modal.vue'
export default {
  components: { Modal },
  name: 'stationary-fuels-component',
  props: {
    title: String,
    schemaName: String,
    initialSchema: Object,
    measurement: String,
    activityID: String,
    identifier: String
  },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },
  computed: {
    carbonEmissions () {
      let totalCarbonEmissions = 0
      this.schema.emissionSources.forEach(emissionSource => {
        totalCarbonEmissions += emissionSource.tCO2e
      })
      return totalCarbonEmissions
    }
  },
  methods: {
    parseNameForLabel (name) {
      const i = name.indexOf('-') + 1
      return name.substring(i).replace('Stationary Fuels', '').replace('Mobile Fuels', '').replace('Mobile', '')
    },
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions () {
      var self = this
      return axios
        .post(
          '' +
            process.env.VUE_APP_APIURL +
            'api/calculateScopeThreeUpstreamLeasedAssetsFuels/',
          {
            schema: this.schema
            // calculationData: calculationData.scopeOneCompanyVehiclesFuels
          }
        )
        .then(function (response) {
          self.schema = response.data.result
          self.$emit('calculateTotal', response.data.result, self.schemaName)
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          self.$root.saveData()
        })
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.identifier}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
