<template>
  <div class="reduction-targets">
    <h1 class="section-title activity-title">Overall Reduction Targets</h1>
    <div class="secondary-title">Enter your reduction targets for your whole organisation.</div>
    <div class="input-section" style="max-width: 800px">
      <div class="input-container-row">
        <label>Do you want to set a combined scope 1 & 2 target?</label>
        <select v-model="$store.state.app.reductionPlan.reductionTarget.combinedScopeOneTwoTarget"
          @change="updateReductionTarget('combinedScopeOneTwoTarget', $store.state.app.reductionPlan.reductionTarget.combinedScopeOneTwoTarget)"
          v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()">
          <option :value="true">Yes</option>
          <option :value="false">No</option>
        </select>
      </div>
    </div>
    <ReductionTargets />
    <TimeFrameMetric style="max-width: 800px;" />
  </div>
</template>

<script>
import TimeFrameMetric from './reductionTarget/TimeFrameMetric.vue'
import ReductionTargets from './reductionTarget/ReductionTargets.vue'
export default {
  components: { TimeFrameMetric, ReductionTargets },
  name: 'OverallReductionTargets',
  methods: {
    async updateReductionTarget (field, value) {
      const data = { reductionTarget: { id: this.$store.state.app.reductionPlan.reductionTarget.id } }
      data.reductionTarget[field] = value
      // console.log(data.otherEmissions, 'updating otherEmissions data')
      this.$root.saving = true
      const res = await this.$store.dispatch('updateReductionTarget', data)
      this.$store.state.app.reductionPlan.reductionTarget = res.data.updateReductionTarget
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    }
  }
}
</script>
