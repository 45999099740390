import './assets/scss/_main.scss'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import titleMixin from './mixins/titleMixin'

import appData from './appData'
import periodQueries from './queries/period.js'
import axios from '@/axios'

import { ToggleButton } from 'vue-js-toggle-button'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import { apolloProvider } from './vue-apollo'
import store from './store'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import performUpdate from './utils/updater/updater.js'

Vue.use(Autocomplete)
Vue.mixin(titleMixin)
Vue.component('ToggleButton', ToggleButton)

Vue.config.productionTip = false

export const bus = new Vue()

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  // set environment
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  // release: process.env.VUE_APP_SENTRY_RELEASE,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [process.env.VUE_APP_URL]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true
})

Vue.use(VueToast, {
  // One of the options
  position: 'bottom-right'
})

new Vue({
  router,

  data: function () {
    return appData
  },

  async beforeMount () {
    this.masterSchema = this.defaultSchema
    await this.$store.dispatch('getUser')
    if (this.$store.state.user.authenticated) {
      await this.onLogin()
      // refresh user ever 30 mins to get new SecurityToken
      setInterval(() => this.$store.dispatch('getUser'), 1000 * 60 * 30)
    } else {
      window.location = process.env.VUE_APP_APIURL + 'Security/login'
    }
    this.getSiteContent()
  },

  methods: {
    getSchema (scope = false) {
      // console.log('getting schema' + scope)
      var index = -1
      scope = scope || this.$router.currentRoute.path.split('/')[1]
      if (scope === 'scope-three') {
        index = this.currentSectionIndex('scopeThreeSections')
        return this.masterSchema.scopeThreeSchemas[index]
      } else if (scope === 'scope-one') {
        index = this.currentSectionIndex('scopeOneSections')
        return this.masterSchema.scopeOneSchemas[index]
      } else if (scope === 'scope-two') {
        index = this.currentSectionIndex('scopeTwoSections')
        // console.log(this.masterSchema.scopeTwoSchemas[index], 'here')
        return this.masterSchema.scopeTwoSchemas[index]
      } else if (scope === 'no-scope') {
        // console.log('getting no scope')
        // console.log(this.masterSchema.additionalSources, 'no-scope')
        return this.masterSchema.additionalSources
      } else {
        // console.log('schema not found')
      }
    },
    getDefaultSchema (scope) {
      // console.log('getting schema')
      var index = -1
      scope = scope || this.$router.currentRoute.path.split('/')[1]
      if (scope === 'scope-three') {
        index = this.currentSectionIndex('scopeThreeSections')
        return this.defaultSchema.scopeThreeSchemas[index]
      } else if (scope === 'scope-one') {
        index = this.currentSectionIndex('scopeOneSections')
        return this.defaultSchema.scopeOneSchemas[index]
      } else if (scope === 'scope-two') {
        index = this.currentSectionIndex('scopeTwoSections')
        return this.defaultSchema.scopeTwoSchemas[index]
      } else {
        // console.log('schema not found')
      }
    },
    getScopeData () {
      var path = this.$router.currentRoute.path.split('/')[1]
      if (path === 'scope-three') {
        return {
          schemas: this.masterSchema.scopeThreeSchemas,
          sections: this.masterSchema.scopeThreeSections
        }
      } else if (path === 'scope-one') {
        return {
          schemas: this.masterSchema.scopeOneSchemas,
          sections: this.masterSchema.scopeOneSections
        }
      } else if (path === 'scope-two') {
        return {
          schemas: this.masterSchema.scopeTwoSchemas,
          sections: this.masterSchema.scopeTwoSections
        }
      }
    },
    updateMasterSchema (schema, scope = false) {
      // console.log('updating master schema')
      var index = -1
      scope = scope || this.$router.currentRoute.path.split('/')[1]
      if (scope === 'scope-one') {
        index = this.currentSectionIndex('scopeOneSections')
        Vue.set(this.masterSchema.scopeOneSchemas, index, schema)
      } else if (scope === 'scope-two') {
        index = this.currentSectionIndex('scopeTwoSections')
        Vue.set(this.masterSchema.scopeTwoSchemas, index, schema)
      } else if (scope === 'scope-three') {
        index = this.currentSectionIndex('scopeThreeSections')
        Vue.set(this.masterSchema.scopeThreeSchemas, index, schema)
      } else if (scope === 'no-scope') {
        // console.log('no scoping')
        Vue.set(this.masterSchema.additionalSources, 'emissionSources', schema)
      } else {
        // console.log('nothing changed')
      }
    },
    currentSectionIndex (scope) {
      return this.masterSchema[scope].findIndex(
        section => section === this.$router.currentRoute.name
      )
    },
    defaultSectionIndex (scope) {
      return this.defaultSchema[scope].findIndex(
        section => section === this.$router.currentRoute.name
      )
    },
    submitResults (reportingPeriod) {
      var self = this
      return axios
        .post('' + process.env.VUE_APP_APIURL + 'api/submitResults', {
          userDetails: {
            clientID: this.$store.state.user.clientID,
            year: this.$store.state.app.selectedYear,
            periodID: this.$store.state.app.client.period.nodes[0].id
          },
          reportingPeriod: reportingPeriod
        })
        .then(function (response) {
          // handle success
          // console.log(response.data.status)
          Vue.set(self.userDetails, 'organisationStatus', response.data.status)
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    },
    getSiteContent () {
      var self = this
      return axios
        .get(`${process.env.VUE_APP_APIURL}api/getSiteContent/`)
        .then(function (response) {
          // console.log(response)
          self.siteContent = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {})
    },
    async saveData () {
      this.saving = true
      var self = this
      await this.$apollo.mutate({
        mutation: periodQueries.SAVE_PERIOD,
        variables: {
          organisationID: this.$store.state.user.organisationID,
          year: this.$store.state.app.selectedYear,
          month: this.$store.state.app.selectedMonth,
          masterSchema: JSON.stringify(self.masterSchema)
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        Vue.set(
          self.userDetails,
          'periodID',
          response.data.savePeriod.id
        )
        Vue.set(self.userDetails, 'organisationStatus', response.data.savePeriod.status)
        setTimeout(() => {
          self.saving = false
        }, 2000)
        // loadAppData
        this.$store.dispatch('loadAppData')
      })
    },
    async resetSchema () {
      Vue.set(this.masterSchema, 'scopeOneSchemas', JSON.parse(JSON.stringify(this.defaultSchema.scopeOneSchemas)))
      Vue.set(this.masterSchema, 'scopeOneSections', JSON.parse(JSON.stringify(this.defaultSchema.scopeOneSections)))

      Vue.set(this.masterSchema, 'scopeTwoSchemas', JSON.parse(JSON.stringify(this.defaultSchema.scopeTwoSchemas)))
      Vue.set(this.masterSchema, 'scopeTwoSections', JSON.parse(JSON.stringify(this.defaultSchema.scopeTwoSections)))

      Vue.set(this.masterSchema, 'scopeThreeSchemas', JSON.parse(JSON.stringify(this.defaultSchema.scopeThreeSchemas)))
      Vue.set(this.masterSchema, 'scopeThreeSections', JSON.parse(JSON.stringify(this.defaultSchema.scopeThreeSections)))

      Vue.set(this.masterSchema, 'additionalSources', JSON.parse(JSON.stringify(this.defaultSchema.additionalSources)))

      await this.saveData()
    },
    async loadData () {
      await this.loadClientData()
      await this.loadOrganisationData()
    },
    async loadClientData () {
      if (!this.$store.state.user.clientID) return
      if (!this.$store.state.user.organisationID) return
      var self = this
      let status
      try {
        await this.$apollo.query({
          query: periodQueries.READ_ONE_CLIENT_PERIOD_QUERY,
          variables: {
            clientID: this.$store.state.user.clientID,
            year: this.$store.state.app.selectedYear,
            status: 'Inactive' // not inactive
          },
          fetchPolicy: 'no-cache'
        }).then(async response => {
          if (response.data.readOnePeriod !== null) {
            status = response.data.readOnePeriod.status
          // couldnt find a matching period. Making a new period
          } else {
            // create client period
            await this.$apollo.mutate({
              mutation: periodQueries.CREATE_PERIOD,
              variables: {
                input: {
                  clientID: this.$store.state.user.clientID,
                  year: this.$store.state.app.selectedYear
                }
              },
              fetchPolicy: 'no-cache'
            }).then(response => {
              this.$store.state.app.client.period.nodes[0] = response.data.createPeriod
            })
          }
          Vue.set(self.userDetails, 'clientStatus', status)
        })
      } catch (e) {
        this.error = e
        console.error(e)
      }
    },
    async loadOrganisationData () {
      if (!this.$store.state.user.clientID) return
      if (!this.$store.state.user.organisationID) return

      var self = this
      let id, status

      try {
        await this.$apollo.query({
          query: periodQueries.READ_ONE_PERIOD_QUERY,
          variables: {
            organisationID: this.$store.state.user.organisationID,
            year: this.$store.state.app.selectedYear,
            month: this.$store.state.app.selectedMonth,
            status: 'Inactive' // not inactive
          },
          fetchPolicy: 'no-cache'
        }).then(async response => {
          // set and update schema if active
          if (response.data.readOnePeriod !== null) {
            self.masterSchema = JSON.parse(response.data.readOnePeriod.masterSchema)
            if (self.masterSchema === null) {
              self.masterSchema = Object.assign(
                {},
                self.masterSchema,
                JSON.parse(JSON.stringify(self.defaultSchema))
              )
            }
            if (response.data.readOnePeriod.status !== 'Completed') self.checkSchema(JSON.parse(response.data.readOnePeriod.masterSchema))
            id = response.data.readOnePeriod.id
            status = response.data.readOnePeriod.status
          // couldnt find a matching period. Making a new period
          } else {
            // create organisation period
            await this.$apollo.mutate({
              mutation: periodQueries.CREATE_PERIOD,
              variables: {
                input: {
                  organisationID: this.$store.state.user.organisationID,
                  year: this.$store.state.app.selectedYear,
                  month: this.$store.state.app.selectedMonth
                }
              },
              fetchPolicy: 'no-cache'
            }).then(response => {
              id = response.data.createPeriod.id
              status = response.data.createPeriod.status
              self.masterSchema = Object.assign(
                {},
                self.masterSchema,
                JSON.parse(JSON.stringify(self.defaultSchema))
              )
            })
          }
          Vue.set(self.userDetails, 'periodID', id)
          Sentry.setTag('clientPeriodID', this.$store.state.app.client.period.nodes[0].id)
          Sentry.setTag('organisationPeriodID', id)
          Sentry.setTag('organisationID', this.$store.state.user.organisationID)
          Sentry.setTag('impersonateClientID', this.$store.state.user.impersonateClientID)
          Vue.set(self.userDetails, 'organisationStatus', status)
        })
      } catch (e) {
        this.error = e
        console.error(e)
      } finally {
        self.time = Date.now()
        self.loaded = true
      }
    },

    async setActiveData () {
      this.saving = true
      await this.$apollo.mutate({
        mutation: periodQueries.SET_ACTIVE_PERIOD,
        variables: {
          organisationID: this.$store.state.user.organisationID,
          year: this.$store.state.app.selectedYear,
          month: this.$store.state.app.selectedMonth
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        setTimeout(() => {
          self.saving = false
        }, 2000)
      })
    },
    async onLogin () {
      await this.$store.dispatch('fetchOrganisations')
      if (!this.$store.state.user.groups.includes('administrators')) {
        if (this.$store.state.app.organisations.some(org => org.id === this.$store.state.user.organisationID.toString())) {
          // do nothing if the current organisation in storage still exists
        } else if (this.$store.state.app.organisations.length > 1) {
          if (this.$router.currentRoute.path !== '/organisations') this.$router.push('/organisations')
        } else {
          if (this.$store.state.app.organisations.length) {
            await this.$store.dispatch('updateUser', { organisationID: this.$store.state.app.organisations[0].id })
          }
        }
      }
      await this.$store.dispatch('loadAppData')
      await this.updateSessionLocale(this.$store.state.app.selectedYear)
      await this.loadData()
      // session logging
      if (this.$store.state.app.client && this.$store.state.app.client.period.nodes.length) await this.$store.dispatch('saveMemberSession', this.$store.state.app.client.period.nodes[0].id)
      this.loadDataInterval = setInterval(async () => {
        // session logging
        if (this.$store.state.app.client && this.$store.state.app.client.period.nodes.length) await this.$store.dispatch('saveMemberSession', this.$store.state.app.client.period.nodes[0].id)
        // load data every xx seconds if other users are logged in and user is not editing
        if (
          this.$store.state.user.authenticated &&
            this.$store.state.memberSessions.otherEditors.length > 0 && !this.editing
        ) {
          this.refreshReports = true
          await this.$store.dispatch('loadAppData')
          this.refreshReports = false
          await this.loadData()
        }
      }, 15000)
    },
    async checkSchema (masterSchema) {
      // if master schema is null do nothing
      if (!masterSchema) return
      this.resetNullSchemas()
      // check versioning
      if (this.defaultSchema.version > masterSchema.version) {
        if (this.defaultSchema.version > 3.01) {
          // console.log('performing update')
          performUpdate(this.masterSchema)
          this.masterSchema.version = this.defaultSchema.version
        }
        await this.setActiveData()
        await this.saveData()
      }
    },
    resetNullSchemas () {
      let scopes = ['scopeOneSchemas', 'scopeTwoSchemas', 'scopeThreeSchemas']
      scopes.forEach(scope => {
        this.masterSchema[scope].forEach((schema, index) => {
          if (Array.isArray(schema)) {
            schema.forEach((section, i) => {
              if (section === null) {
                console.log(`removed null section at schema ${index}, section ${i}`)
                schema.splice(i, 1)
              }
            })
          }
        })
      })
    },
    formatNumber (amount, decimalCount = 2, decimal = '.', thousands = ',') {
      try {
        if (!isFinite(amount)) {
          return 0
        }
        decimalCount = Math.abs(decimalCount)
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount

        const negativeSign = amount < 0 ? '-' : ''

        let i = parseInt(
          (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString()
        let j = i.length > 3 ? i.length % 3 : 0

        return (
          negativeSign +
          (j ? i.substr(0, j) + thousands : '') +
          i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
          (decimalCount
            ? decimal +
              Math.abs(amount - i)
                .toFixed(decimalCount)
                .slice(2)
            : '')
        )
      } catch (e) {
        console.log(e)
      }
    },
    setEditing () {
      // console.log('setEditing')
      this.editing = true
      this.editingTimeout = setTimeout(() => {
        // console.log('timeout fired')
        this.editing = false
      }, 30000)
    },
    unsetEditing () {
      // console.log('unsetEditing')
      this.editing = false
      clearTimeout(this.editingTimeout)
    },
    async updateSessionLocale (year) {
    // Set default locale to 'ef_2021'. Update to 'ef_2022' for years > 2019, non calander year 2019 and calender year 2022.
    // Upgrade to 'ef_2023' for years >= 2023 or for non-calendar year 2022
      const startMonth = this.$store.state.app.client.reportingMonthStart
      let locale = 'ef_2021'
      if (parseInt(year) > 2019 || (parseInt(year) === 2019 && startMonth !== 'January') || ((parseInt(year) === 2022) && startMonth === 'January')) {
        locale = 'ef_2022'
      }
      if (parseInt(year) >= 2023 || ((parseInt(year) === 2022) && startMonth !== 'January')) {
        locale = 'ef_2023'
      }
      if (parseInt(year) >= 2024 || ((parseInt(year) === 2023) && startMonth !== 'January')) {
        locale = 'ef_2024'
      }
      await (this.setSessionLocale(locale))
      console.log('locale set to ' + locale)
    },
    async setSessionLocale (locale) {
      return axios
        .post(process.env.VUE_APP_APIURL + `api/setLocale/${locale}`
        )
        .then(response => {
          // handle success
          // this.locale = response.data.locale
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    }
  },

  computed: {
    allSchemas () {
      // put all schemas into an array for easy looping
      let allSchemas = []
      this.masterSchema.scopeOneSchemas.forEach(scopeSchema => {
        if (scopeSchema.length > 0) {
          scopeSchema.forEach(schema => {
            // console.log(schema.title)
            // console.log(schema)
            allSchemas.push(schema)
          })
        }
      })
      this.masterSchema.scopeTwoSchemas.forEach(scopeSchema => {
        if (scopeSchema.length > 0) {
          scopeSchema.forEach(schema => {
            // console.log(schema.title)
            // console.log(schema)
            allSchemas.push(schema)
          })
        }
      })
      this.masterSchema.scopeThreeSchemas.forEach(scopeSchema => {
        if (scopeSchema.length > 0) {
          scopeSchema.forEach(schema => {
            // console.log(schema.title)
            // console.log(schema)
            allSchemas.push(schema)
          })
        }
      })
      // additionalSources
      if (this.masterSchema.additionalSources) {
        allSchemas.push(this.masterSchema.additionalSources)
      }
      return allSchemas
    },
    allActiveSchemas () {
      let activeSchemas = []
      this.allSchemas.forEach((schema, index) => {
        let active = false
        schema.emissionSources.forEach(es => {
          if (es.tCO2e !== 0 && (es.annualTotal !== 0 || es.type === 'custom')) {
            active = true
          }
        })
        if (active) {
          activeSchemas.push(schema)
        }
      })
      return activeSchemas
    },
    // all emissions sources that are available in the report
    allEmissionSources () {
      if (!this.$store.state.app.client.period.nodes[0]) return []
      const emissionsSources = this.$store.state.app.client.period.nodes[0].emissionsSources.nodes
      return emissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
    },
    visibleSections () {
      const visibleSections = []
      const organisation = this.$store.state.app.organisation
      if (organisation) {
        organisation.activities.nodes.forEach(activity => {
          visibleSections.push({ id: activity.identifier.replace('activity-', ''), title: activity.title })
        })
      }

      return visibleSections
    }
  },

  apolloProvider: apolloProvider,
  store,
  render: h => h(App)
}).$mount('#app')
