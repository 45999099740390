const variables = {
  electricity: {
    tCO2e: 'electricityNewZealandKgCO2e',
    CO2: 'electricityNewZealandKgCO2',
    N2O: 'electricityNewZealandKgN2O',
    CH4: 'electricityNewZealandKgCH4',
    unit: 'kWh',
    label: 'Electricity'
  },
  naturalGas: {
    tCO2e: 'kgCO2eNaturalGas',
    N2O: 'kgN2OPerUnitNaturalGas',
    CH4: 'kgCH4PerUnitNaturalGas',
    CO2: 'kgCO2PerUnitNaturalGas',
    unit: 'kWh',
    label: 'Natural Gas'
  },
  diesel: {
    tCO2e: 'kgCO2eDieselCompanyVehicles',
    N2O: 'kgn2OPerUnitDieselCompanyVehicles',
    CH4: 'kgCH4PerUnitDieselCompanyVehicles',
    CO2: 'kgCO2PerUnitDieselCompanyVehicles',
    unit: 'L',
    label: 'Diesel'
  },
  petrol: {
    tCO2e: 'kgCO2ePetrol',
    N2O: 'kgN2OPerUnitPetrol',
    CH4: 'kgCH4PerUnitPetrol',
    CO2: 'kgCO2PerUnitPetrol',
    unit: 'L',
    label: 'Petrol'
  }
}

const mobileExtraCalculations = [
  {
    type: 'petrol',
    tCO2eVariableName: 'kgCO2ePetrol',
    CO2VariableName: 'kgCO2PerUnitPetrol',
    N2OVariableName: 'kgN2OPerUnitPetrol',
    CH4VariableName: 'kgCH4PerUnitPetrol'
  },
  {
    type: 'diesel',
    tCO2eVariableName: 'kgCO2eDieselCompanyVehicles',
    CO2VariableName: 'kgCO2PerUnitDieselCompanyVehicles',
    N2OVariableName: 'kgn2OPerUnitDieselCompanyVehicles',
    CH4VariableName: 'kgCH4PerUnitDieselCompanyVehicles'
  }
]

const stationaryExtraCalculations = [
  {
    type: 'petrol',
    tCO2eVariableName: 'kgCO2ePetrol',
    CO2VariableName: 'kgCO2PerUnitPetrol',
    N2OVariableName: 'kgN2OPerUnitPetrol',
    CH4VariableName: 'kgCH4PerUnitPetrol'
  },
  {
    type: 'diesel',
    tCO2eVariableName: 'kgCO2eFuelsDiesel',
    CO2VariableName: 'kgCO2PerUnitPetrol',
    N2OVariableName: 'kgN2OPerUnitPetrol',
    CH4VariableName: 'kgCH4PerUnitPetrol'
  },
  {
    type: 'natural-gas',
    tCO2eVariableName: 'kgCO2eNaturalGas',
    CO2VariableName: 'kgCO2PerUnitNaturalGas',
    N2OVariableName: 'kgN2OPerUnitNaturalGas',
    CH4VariableName: 'kgCH4PerUnitNaturalGas'
  },
  {
    type: 'lpg',
    tCO2eVariableName: 'kgCO2eLPG',
    CO2VariableName: 'kgCO2PerUnitLPG',
    N2OVariableName: 'kgN2OPerUnitLPG',
    CH4VariableName: 'kgCH4PerUnitLPG'
  }
]

const extraCalculationsIDAdjustment = [
  {
    emissionSourceID: 'Diesel',
    type: 'Diesel WTT',
    variableName: 'dieselWTT'
  },
  {
    emissionSourceID: 'LPG',
    type: 'LPG WTT',
    variableName: 'LPGWTT'
  },
  {
    emissionSourceID: 'aviation-gas',
    type: 'Aviation Gas WTT',
    variableName: 'aviationGasWTT'
  },
  {
    emissionSourceID: 'aviation-fuel-kerosene',
    type: 'Aviation Fuel Kerosene WTT',
    variableName: 'aviationFuelKeroseneWTT'
  },
  {
    emissionSourceID: 'bioethanol-mc',
    type: 'Bioethanol WTT',
    variableName: 'bioethanolWTT'
  },
  {
    emissionSourceID: 'biodiesel-mc',
    type: 'Biodiesel WTT',
    variableName: 'biodieselWTT'
  },
  {
    emissionSourceID: 'petrol-regular',
    type: 'Petrol Regular WTT',
    variableName: 'petrolRegularWTT'
  },
  {
    emissionSourceID: 'petrol-premium',
    type: 'Petrol WTT',
    variableName: 'petrolWTT'
  }
]

export default { variables, mobileExtraCalculations, stationaryExtraCalculations, extraCalculationsIDAdjustment }
