<template>
  <div class="section electricity">
    <div class="activity-title-row">
      <h1 class="activity-title">Electricity</h1>
      <i class="title-icon fad fad fa-gas-pump"></i>
    </div>
      <ElectricityWTT
        v-if="
          $store.state.app.report &&
          $root.userDetails.organisationStatus !== 'Completed' &&
          $store.state.app.client.previousYear
        "
      />
    <ElectricityComponent
      title="Electricity Consumption"
      v-bind:initialSchema="schema[getSchemaIndex('Electricity Consumption')]"
      schemaName="Electricity Consumption"
      measurement="kWh"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="electricity"
    />
    <Notes
      :schemaIndex="0"
      :key="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
  </div>
</template>

<script>
import Notes from '../../../Notes.vue'
import ElectricityComponent from './ElectricityComponent'

export default {
  metaData () {
    return {
      title: `dCarbon — Electricity`,
      description: ''
    }
  },
  name: 'activity-electricity',
  components: {
    ElectricityComponent,
    Notes,
    ElectricityWTT: () => import('../../../admin/ElectricityWTT.vue')
  },
  props: { initialSchema: Object, scope: String },

  data () {
    return {
      schema: {},
      active: false
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    calculateTotal (schema, schemaName) {
      // console.log(schemaName)
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getSchemaIndex (name) {
      return this.schema.findIndex((s) => s.name === name)
    }
  }
}
</script>
