<template>
  <div class="privacy-policy">
    <div class="home-logo">
      <a href="#/">
      <img src="../../src/assets/images/dCarbonLogo.png" />
      </a>
    </div>
    <div class="title">Privacy Policy</div>
    <div class="content">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Pellentesque sit amet
      porttitor eget dolor morbi non arcu risus. Cursus turpis massa tincidunt
      dui ut ornare lectus. Tristique risus nec feugiat in fermentum posuere.
      Dolor sit amet consectetur adipiscing elit ut. Ac tortor vitae purus
      faucibus. Eget nunc lobortis mattis aliquam faucibus. In hac habitasse
      platea dictumst vestibulum rhoncus. Placerat vestibulum lectus mauris
      ultrices eros. Vel risus commodo viverra maecenas accumsan lacus vel
      facilisis. Elit duis tristique sollicitudin nibh sit amet commodo nulla.
      Accumsan lacus vel facilisis volutpat est velit egestas. Porta nibh
      venenatis cras sed felis. Turpis egestas sed tempus urna et pharetra.
      Tincidunt vitae semper quis lectus nulla. Diam vel quam elementum pulvinar
      etiam non quam. Quis risus sed vulputate odio ut enim blandit. Libero
      justo laoreet sit amet cursus sit amet. Porttitor massa id neque aliquam
      vestibulum. Adipiscing bibendum est ultricies integer quis auctor elit.
      Dui ut ornare lectus sit amet est placerat. Purus sit amet volutpat
      consequat mauris. Aliquam eleifend mi in nulla posuere sollicitudin
      aliquam ultrices sagittis. Sit amet porttitor eget dolor morbi non.
      Egestas erat imperdiet sed euismod nisi porta lorem mollis aliquam. Ligula
      ullamcorper malesuada proin libero nunc. Mauris pellentesque pulvinar
      pellentesque habitant morbi tristique senectus. Ut diam quam nulla
      porttitor massa id neque. Sed odio morbi quis commodo odio aenean sed
      adipiscing diam. Tincidunt tortor aliquam nulla facilisi cras fermentum
      odio eu feugiat. Commodo odio aenean sed adipiscing diam donec adipiscing.
      Auctor eu augue ut lectus arcu bibendum at varius vel. Viverra accumsan in
      nisl nisi scelerisque. Facilisis sed odio morbi quis commodo odio aenean.
      Pellentesque eu tincidunt tortor aliquam nulla. Ultrices vitae auctor eu
      augue ut lectus arcu bibendum. Porttitor massa id neque aliquam. Sit amet
      consectetur adipiscing elit duis tristique sollicitudin nibh. Duis ut diam
      quam nulla porttitor massa. Arcu non sodales neque sodales. Nunc mattis
      enim ut tellus elementum sagittis. At augue eget arcu dictum varius duis
      at consectetur lorem. Laoreet sit amet cursus sit amet. Mauris ultrices
      eros in cursus turpis massa tincidunt. Semper eget duis at tellus. Et
      malesuada fames ac turpis. Tempor commodo ullamcorper a lacus. Natoque
      penatibus et magnis dis parturient montes. Lobortis mattis aliquam
      faucibus purus. Nec ultrices dui sapien eget mi proin.
    </div>
    <router-link
      v-if="$store.state.user.groups.includes('administrators')"
      class="square-button blue bold"
      to="/admin"
      >Back to Admin Panel</router-link
    >

    <router-link class="square-button blue bold bottom-btn" to="/terms-of-use"
      >Terms of use</router-link
    >
  </div>
</template>

<script>
export default {
  metaData () {
    return {
      title: `dCarbon — Privacy policy`,
      description: ''
    }
  }
}
</script>
