<template>
  <div class="dashboard" v-if="$store.state.app.clientDataLoaded && $root.loaded"> <!-- :key="$root.time" -->
    <div class="title-bar">
      <h1 class="main-heading">Dashboard</h1>
      <div class="left">
        <!-- for devs -->
        <!-- <div class="populate-inputs" @click="populateInputs">
          Populate Inputs
        </div> -->
        <CarbonDataStatusSelect v-if="this.$store.state.user.groups.includes('administrators')" />
        <VueJsonToCsv
          :json-data="emissionsBreakdown"
          :csv-title="`dCarbon Emissions Breakdown for ${$store.state.app.client.title} ${reportingPeriod}`"
        >
          <div class="square-button blue">
            <div class="btn-text-icon">
              <div>Download Emissions Breakdown</div>
              <i class="fad fa-download"></i>
            </div>
          </div>
        </VueJsonToCsv>

      </div>
    </div>
    <div class="row">
      <div class="layout-settings" @click="showSettings=true">
        <div class="title">Layout Settings</div>
        <i class="fas fa-cog"></i>
      </div>
    </div>
        <ReportSettings v-if="$store.state.app.report" :settings="$store.state.app.report.settings" :show="showSettings" @hide="showSettings = false" />
    <div class="section">
      <div class="dashboard-bar">
        <h1 class="activity-title">
          {{
            tab === 'summary'
              ? 'Summary'
              : tab === 'generate'
              ? 'Generate Emissions Inventory Report'
              : tab === 'company-information'
              ? 'Company Information'
              : 'EIR Information'
          }}
        </h1>
        <div class="toggle-btn-container">
          <div
            v-for="tab in tabs"
            :key="tab.id"
            class="toggle-btn"
            :class="{
              active: tabIsActive(tab.id),
              inactive: !tabIsActive(tab.id)
            }"
            @click="selectTab(tab.id)"
          >
            <p class="btn-text">{{ tab.title }}</p>
          </div>
          <div class="toggle-btn" @click="$router.push('/reduction-plan')">
            <p class="btn-text">Reduction Plan</p>
          </div>
        </div>
      </div>
      <DashboardTabs v-if="this.$store.state.app.report" :tab="tab" :carbonPrice="carbonPrice" :reportingPeriod="reportingPeriod"/>
      <div v-else class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import VueJsonToCsv from 'vue-json-to-csv'
import CarbonDataStatusSelect from './CarbonDataStatusSelect.vue'
import emissionsMixin from '../../mixins/emissionsMixin.vue'
import DashboardTabs from './DashboardTabs.vue'
import ReportSettings from '../emissionsInventoryReport/reportSettings.vue'
import { reportingPeriod } from '../../utils/utils'

// import Compare from './Compare'

export default {
  mixins: [emissionsMixin],
  metaData () {
    return {
      title: `dCarbon — Dashboard`,
      description: ''
    }
  },
  name: 'dashboard',
  components: {
    VueJsonToCsv,
    CarbonDataStatusSelect,
    DashboardTabs,
    ReportSettings
  },
  data () {
    return {
      carbonPrice: 0,
      tab: 'summary',
      messageVisible: false,
      showSettings: false,
      tabs: [
        {
          id: 'company-information',
          title: 'Company Information'
        },
        {
          id: 'report-information',
          title: 'EIR Information'
        },
        {
          id: 'summary',
          title: 'Summary'
        },
        {
          id: 'generate',
          title: 'Generate EIR'
        }
      ]
    }
  },
  async beforeMount () {
    if (window.localStorage.getItem('impersonateClientID') === 0 && this.$store.state.user.groups.includes('administrators')) {
      this.$router.push('/admin')
    }
    if (!window.location.hash) {
      this.selectTab(this.tabs[0].id)
    } else {
      this.selectTab(window.location.hash.replace('#', ''))
    }

    this.getCarbonPrice()
  },
  methods: {
    selectTab (id) {
      if (this.tabs.find(x => x.id === id)) {
        window.location.hash = id
        this.tab = id
      }
    },
    tabIsActive (id) {
      return window.location.hash.replace('#', '') === id
    },
    submit () {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 300)
    },
    populateInputs () {
      this.$root.allSchemas.forEach(schema => {
        schema.emissionSources.forEach(es => {
          es.annualTotal = 100000
        })
      })
    },
    getCarbonPrice () {
      var self = this
      return axios
        .get(`${process.env.VUE_APP_APIURL}api/getCarbonPrice`)
        .then(function (response) {
          self.carbonPrice = response.data.carbonPrice
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {})
    }
  },
  computed: {
    reportingPeriod () {
      return reportingPeriod(this.$store.state.app.selectedYear, this.$store.state.app.client.reportingMonthStart)
    },
    // seperate WTT and TDL emissions
    emissionsBreakdown () {
      const organisations = JSON.parse(JSON.stringify(this.$store.state.app.client.allBusinessUnits.nodes))
      let emissionsBreakdown = []
      //  loop through organisation.periods.nodes[0].emissionsSources.nodes and add business unit title to each emissions source
      organisations.forEach(org => {
        if (org.periods.nodes.length === 0) return
        const periods = org.periods.nodes
        periods.forEach(period => {
          const emissionsSources = period.emissionsSources.nodes
          emissionsSources.forEach(es => {
            es.businessUnit = org.title
          })
          let filteredEmissionsSources = emissionsSources.filter(es => es.rule !== 'hide-in-breakdown' && es.rule !== 'hide')
          emissionsBreakdown.push(...filteredEmissionsSources)
        })
      })
      // const emissionsSources = this.$store.state.app.organisation.period.nodes[0].emissionsSources.nodes
      //  if (es.rule !== 'hide-in-breakdown' && es.rule !== 'hide') {
      emissionsBreakdown = this.getRawEmissionsSources(emissionsBreakdown)
      return this.sanatiseEmissionsSourcesForBreakdown(emissionsBreakdown)
    },
    electricityOffset () {
      return this.getElectricityOffset(this.$root.masterSchema)
    },
    preOffsetTotal () {
      return this.getPreOffsetTotal()
    }
  }
}
</script>

<style></style>
