const fertiliserSchema = {
  dataSource: '',
  dataQuality: '',
  assumptions: '',
  name: 'Fertiliser Use', // Title of Section on front end
  title: 'Fertiliser Use', // Title of Section on front end
  scope: 1, // scope number
  sectionID: 'agriculture', // Default Schema Scope Section Name
  identifier: 'agriculture-component', // Main Section component identifier for tooltips
  version: 2.75,
  active: true,
  toggleName: 'enterBy',
  emissionSources: [
    {
      category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'Land use and Land Use changes', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
      name: '', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'default', // default, naturalGasTDL, electricityTDL, WTT
      id: 'fertiliser',
      unit: 'KG',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    }
  ],
  agricultureCategories: [],
  totalEmissions: {
    totalCarbonDioxideEquivalentEmissions: 0,
    totalCarbonDioxideEmissions: 0,
    totalNitrosOxideEmissions: 0,
    totalMethaneEmissions: 0
  }
}

export default { fertiliserSchema }
