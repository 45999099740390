import gql from 'graphql-tag'
import store from '..'
import { apolloClient } from '../../vue-apollo.js'
import appDataQuery from '../../queries/appDataQuery.js'
import reportSettings from '../../utils/reportSettings.json'
import clientQueries from '../../queries/client.js'
import periodQueries from '../../queries/period.js'

const getPeriod = state => {
  if (state.yearType === 'Financial') {
    let year = parseInt(state.financialYearEnd.split('-')[0])
    return `${year - 1} - ${year} Financial Year`
  } else {
    let year = this.calendarYearEnd
    return `${year - 1} - ${year} Calendar Year`
  }
}

const getOrganisation = state => {
  if (state.client.id && state.client.businessUnits.nodes.length) {
    return state.client.businessUnits.nodes.find(organisation => parseInt(organisation.id) === parseInt(store.state.user.organisationID))
  } else {
    return null
  }
}

const getClientDataLoaded = state => {
  if (state.client && state.client.id) {
    return true
  } else {
    return false
  }
}

const getPreviousYear = state => {
  // const organisation = getOrganisation(state)
  // // console.log(organisation, 'state organisation')
  // // console.log(client.previousYear, 'org here')
  // if (
  //   organisation &&
  //   client.previousYear && // this fixed console error
  //   client.previousYear.nodes &&
  //   client.previousYear.nodes.length
  // ) {
  //   return client.previousYear.nodes[0]
  // } else {
  //   return null
  // }
  return state.client.previousYear && state.client.previousYear.nodes[0]
}

const getReportingPeriod = state => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const startYear = parseInt(state.selectedYear)
  const month = state.client.reportingMonthStart
  const endYear =
    months.findIndex(el => el === month) !== 0 ? startYear + 1 : startYear
  const startMonth = month.substring(0, 3)
  const endMonth =
    months.findIndex(el => el === month) !== 0
      ? months[months.findIndex(el => el === month) - 1].substring(0, 3)
      : months[11].substring(0, 3)
  return `1 ${startMonth} ${startYear} - ${getLastDayOfMonth(months.findIndex(el => el === month) - 1)} ${endMonth} ${endYear}`
}

const getLastDayOfMonth = (monthIndex) => {
  var today = new Date()
  var lastDayOfMonth = new Date(today.getFullYear(), monthIndex + 1, 0)
  return lastDayOfMonth.getDate()
}

const calcComputedStates = state => {
  state.period = getPeriod(state)
  state.organisation = getOrganisation(state)
  state.client.previousYear = getPreviousYear(state)
  state.clientDataLoaded = getClientDataLoaded(state)
}

const setReport = async (state, data) => {
  if (data.report) {
    if (data.report.period !== getReportingPeriod(state)) {
      // console.log('updating report period')
      data.report.period = getReportingPeriod(state)
    }
    state.report = data.report
  } else {
    let certID = 0
    if (data.certificationTypes && data.certificationTypes.nodes.length) {
      const certificationType = data.certificationTypes.nodes.find(cert => cert.title === 'TBC')
      if (certificationType) {
        certID = certificationType.id
      }
    }
    const reportData = {
      clientID: parseInt(state.client.id),
      year: state.selectedYear,
      period: getReportingPeriod(state),
      assuranceType: 'N/A',
      status: 'Unverified Inventory',
      consolidationApproach: 'TBC',
      customCreditOffsetAmount: 0,
      certificationTypeID: certID
    }
    await store.dispatch('createReport', reportData)
  }
  if (
    data.report &&
    data.certificationTypes &&
    data.certificationTypes.nodes.length
  ) {
    data.report.certificationTypes = data.certificationTypes.nodes
  }
  if (data.report && !data.report.settings) {
    data.report.settings = reportSettings
  } else if (data.report && data.report.settings && typeof data.report.settings === 'string') {
    data.report.settings = JSON.parse(data.report.settings)
  }
}

export default {
  state: {
    selectedYear: window.localStorage.getItem('selectedYear') || new Date().toISOString().split('-')[0],
    selectedMonth: window.localStorage.getItem('selectedMonth') || 0,
    period: null,
    clientDataLoaded: false,
    yearType: 'Financial',
    financialYearEnd: '2021-03-31',
    client: null,
    organisation: null,
    report: null,
    reductionPlan: null,
    carbonCreditTypes: null,
    updatedSections: [],
    organisations: [],
    completedInventories: null,
    loading: false
    // period: null
  },
  mutations: {
    SET_APP_DATA: async (state, data) => {
      Object.keys(data).forEach(prop => {
        if (prop in state) {
          state[prop] = data[prop]
        }
      })

      if (typeof state.selectedYear === 'number') state.selectedYear = state.selectedYear.toString()
      if (typeof state.selectedMonth === 'number') state.selectedMonth = state.selectedMonth.toString()
      if (state.selectedYear) window.localStorage.setItem('selectedYear', state.selectedYear)
      if (state.selectedMonth) window.localStorage.setItem('selectedMonth', state.selectedMonth)

      calcComputedStates(state)
      await setReport(state, data)
    },
    SET_CARBON_CREDIT_TYPES_DATA: (state, data) => {
      state.carbonCreditTypes = data.carbonCreditTypes.nodes
    },
    SET_RECOGNISED_OFFSET_DEDUCTION_DATA: (state, data) => {
      state.report.recognisedOffsetDeductions = data.readRecognisedOffsetDeductions
    },
    SET_COMPLETED_INVENTORIES: (state, data) => {
      state.completedInventories = data
    },
    SET_REDUCTION_PLAN: (state, reductionPlan) => {
      state.reductionPlan = reductionPlan
    },
    SET_REDUCTION_PLAN_EMISSION_SOURCES: (state, data) => {
      state.reductionPlan.reductionEmissionSources = data
    },
    SET_ORGANISATIONS: (state, data) => {
      state.organisations = data
    }
  },
  actions: {
    resetReportSettings ({ commit, state }) {
      // console.log('resetting report settings')
      state.report.settings = JSON.parse(JSON.stringify(reportSettings))
    },
    async getMarketBasedEFs ({ commit, state }) {
      const { data } = await apolloClient.query({
        query: gql`
          query {
            readMarketBasedFactors {
              nodes {
                id
                title
                variable {
                  variableName
                }
              }
            }
          }
            `,
        fetchPolicy: 'no-cache'
      })
      return data.readMarketBasedFactors
    },
    async loadAppData ({ commit, state }) {
      if (store.state.user.clientID) {
        // console.log(state.selectedYear, 'selected year')
        const { data } = await apolloClient.query({
          query: appDataQuery.query,
          variables: {
            filter: { id: { eq: parseInt(store.state.user.clientID) } },
            reportFilter: {
              year: { eq: state.selectedYear },
              clientID: { eq: parseInt(store.state.user.clientID) }
            },
            previousYearFilter: {
              year: { eq: (parseInt(state.selectedYear) - 1).toString() },
              status: { ne: 'Inactive' }
            },
            generalMetricsFilter: { type: { eq: 'General' } },
            otherMetricsFilter: { type: { eq: 'Other' } },
            completedInventoriesFilter: {
              status: { eq: 'Completed' },
              year: { lt: state.selectedYear }
            },
            activeClientPeriodFilter: {
              year: { eq: state.selectedYear },
              status: { ne: 'Inactive' },
              clientID: { eq: parseInt(store.state.user.clientID) }
            },
            activeOrganisationPeriodFilter: {
              year: { eq: state.selectedYear },
              status: { ne: 'Inactive' },
              organisationID: { eq: parseInt(store.state.user.organisationID) }
            },
            businessUnitsFilter: {
              id: { eq: parseInt(store.state.user.organisationID) }
            },
            allBusinessUnitsFilter: {
              clientID: { eq: parseInt(store.state.user.clientID) }
            },
            activePeriodFilter: {
              year: { eq: state.selectedYear },
              status: { ne: 'Inactive' }
            }
          },
          fetchPolicy: 'no-cache'
        })

        await commit('SET_APP_DATA', {
          client: data.readOneClient,
          report: data.readOneReport,
          certificationTypes: data.readCertificationTypes,
          selectedYear: state.selectedYear
        })
      }
    },
    setAppData ({ commit, state }, data) {
      commit('SET_APP_DATA', data)
    },
    async loadCarbonCreditTypes ({ commit, state }) {
      const { data } = await apolloClient.query({
        query: gql`
          query {
            readCarbonCreditTypes {
              nodes {
                id
                title
                description
              }
            }
          }
        `,
        fetchPolicy: 'no-cache'
      })
      await commit('SET_CARBON_CREDIT_TYPES_DATA', {
        carbonCreditTypes: data.readCarbonCreditTypes
      })
    },
    async updateClient ({ commit, state, dispatch }, data) {
      apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: UpdateClientInput!) {
            updateClient(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: data.client
        },
        fetchPolicy: 'no-cache'
      })
      await dispatch('loadAppData')
    },
    async updateReport ({ commit, state, dispatch }, data) {
      // console.log('therer')
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: UpdateReportInput!) {
            updateReport(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: data.report
        },
        fetchPolicy: 'no-cache'
      })
      if (data.load) {
        await dispatch('loadAppData')
      }
    },
    async updateOtherEmissions ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: UpdateOtherEmissionsInput!) {
            updateOtherEmissions(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: data.otherEmissions
        },
        fetchPolicy: 'no-cache'
      })
      // await dispatch('loadAppData')
    },
    async updateEmissionCategory ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: UpdateEmissionCategoryInput!) {
            updateEmissionCategory(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: data.emissionCategory
        },
        fetchPolicy: 'no-cache'
      })
      // await dispatch('loadAppData')
    },
    async updateEmissionIntensityMetric ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: UpdateEmissionIntensityMetricInput!) {
            updateEmissionIntensityMetric(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: data.emissionIntensityMetric
        },
        fetchPolicy: 'no-cache'
      })
      // await dispatch('loadAppData')
    },
    async createEmissionIntensityMetric ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: CreateEmissionIntensityMetricInput!) {
            createEmissionIntensityMetric(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: data.emissionIntensityMetric
        },
        fetchPolicy: 'no-cache'
      })
      await dispatch('loadAppData')
    },
    async deleteEmissionIntensityMetrics ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($ids: [ID]!) {
            deleteEmissionIntensityMetrics(ids: $ids)
          }
        `,
        variables: {
          ids: data
        },
        fetchPolicy: 'no-cache'
      })
      await dispatch('loadAppData')
    },
    async updateLegalEntity ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: UpdateLegalEntityInput!) {
            updateLegalEntity(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: data.legalEntity
        },
        fetchPolicy: 'no-cache'
      })
      // await dispatch('loadAppData')
    },
    async createLegalEntity ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: CreateLegalEntityInput!) {
            createLegalEntity(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: data.legalEntity
        },
        fetchPolicy: 'no-cache'
      })
      await dispatch('loadAppData')
    },
    async deleteLegalEntities ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($ids: [ID]!) {
            deleteLegalEntities(ids: $ids)
          }
        `,
        variables: {
          ids: data
        },
        fetchPolicy: 'no-cache'
      })
      await dispatch('loadAppData')
    },
    async updateExcludedEmissionsSource ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: UpdateExcludedEmissionsSourceInput!) {
            updateExcludedEmissionsSource(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: data.excludedEmissionsSource
        },
        fetchPolicy: 'no-cache'
      })
      // await dispatch('loadAppData')
    },
    async createExcludedEmissionsSource ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: CreateExcludedEmissionsSourceInput!) {
            createExcludedEmissionsSource(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: data.excludedEmissionsSource
        },
        fetchPolicy: 'no-cache'
      })
      await dispatch('loadAppData')
    },
    async deleteExcludedEmissionsSources ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($ids: [ID]!) {
            deleteExcludedEmissionsSources(ids: $ids)
          }
        `,
        variables: {
          ids: data
        },
        fetchPolicy: 'no-cache'
      })
      await dispatch('loadAppData')
    },
    async createReport ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: CreateReportInput!) {
            createReport(input: $input) {
              id
              year
              period
            }
          }
        `,
        variables: {
          input: data
        },
        fetchPolicy: 'no-cache'
      })
      await dispatch('loadAppData')
    },
    async updatePeriod ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: UpdatePeriodInput!) {
            updatePeriod(input: $input) {
              status
            }
          }
        `,
        variables: {
          input: data.period
        },
        fetchPolicy: 'no-cache'
      })
      // await dispatch('loadAppData')
    },
    async createCancelledCarbonCredit ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: CreateCancelledCarbonCreditInput!) {
            createCancelledCarbonCredit(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: data.cancelledCarbonCredit
        },
        fetchPolicy: 'no-cache'
      })
      await dispatch('loadAppData')
    },
    async updateCancelledCarbonCredit ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: UpdateCancelledCarbonCreditInput!) {
            updateCancelledCarbonCredit(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: data.cancelledCarbonCredit
        },
        fetchPolicy: 'no-cache'
      })
      // await dispatch('loadAppData')
    },
    async deleteCancelledCarbonCredit ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($ids: [ID]!) {
            deleteCancelledCarbonCredits(ids: $ids)
          }
        `,
        variables: {
          ids: data
        },
        fetchPolicy: 'no-cache'
      })
      await dispatch('loadAppData')
    },
    async createRecognisedOffsetDeduction ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: CreateRecognisedOffsetDeductionInput!) {
            createRecognisedOffsetDeduction(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: data.recognisedOffsetDeduction
        },
        fetchPolicy: 'no-cache'
      })
      // await dispatch('loadAppData')
      await dispatch('loadRecognisedOffsetDeduction')
    },
    async updateRecognisedOffsetDeduction ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: UpdateRecognisedOffsetDeductionInput!) {
            updateRecognisedOffsetDeduction(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: data.recognisedOffsetDeduction
        },
        fetchPolicy: 'no-cache'
      })
      // await dispatch('loadAppData')
      await dispatch('loadRecognisedOffsetDeduction')
    },
    async deleteRecognisedOffsetDeduction ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($ids: [ID]!) {
            deleteRecognisedOffsetDeductions(ids: $ids)
          }
        `,
        variables: {
          ids: data
        },
        fetchPolicy: 'no-cache'
      })
      // await dispatch('loadAppData')
      await dispatch('loadRecognisedOffsetDeduction')
    },
    async loadRecognisedOffsetDeduction ({ commit, state }) {
      const { data } = await apolloClient.query({
        query: gql`
          query(
            $filter: RecognisedOffsetDeductionFilterFields!
          ) {
            readRecognisedOffsetDeductions(filter: $filter) {
              nodes {
                id
                hash
                title
                notes
                amount
                type
              }
            }
          }
            `,
        variables: {
          filter: { reportID: { eq: store.state.app.report.id } }
        },
        fetchPolicy: 'no-cache'
      })
      await commit('SET_RECOGNISED_OFFSET_DEDUCTION_DATA', data)
      // return data
    },
    async loadCompletedInventories ({ commit, state }) {
      const { data } = await apolloClient.query({
        query: periodQueries.READ_PERIODS,
        variables: {
          clientID: store.state.app.client.id,
          status: 'Completed'
        },
        fetchPolicy: 'no-cache'
      })
      await commit('SET_COMPLETED_INVENTORIES', data.readPeriods)
    },
    async loadReductionPlan ({ commit, state }) {
      const { data } = await apolloClient.query({
        query: gql`
          query(
            $filter: ReductionPlanFilterFields!
          ) {
            readOneReductionPlan(filter: $filter) {
              id
              year
              settings
              lastEdited
              reductionEmissionSources {
                nodes {
                  id
                  term
                  initiative
                  responsibility
                  previousPerformanceComment
                  addedByAnalyst
                  emissionsSource {
                    id
                    name
                    tCO2e
                    tCO2eMarketRate
                    subcategory
                    period {
                      id
                      year
                    }
                  }
                  baseYearEmissionsSource {
                    id
                    name
                    tCO2e
                    tCO2eMarketRate
                    subcategory
                    period {
                      id
                      year
                    }
                  }
                }
              }
              reductionTarget {
                id
                organisationSpecificMetric
                combinedScopeOneTwoTarget
                shortTermTimeFrame
                mediumTermTimeFrame
                longTermTimeFrame
                absoluteShortTermPercentage
                absoluteMediumTermPercentage
                absoluteLongTermPercentage
                intensityShortTermPercentage
                intensityMediumTermPercentage
                intensityLongTermPercentage
                scopeOneTwoShortTermPercentage
                scopeOneTwoMediumTermPercentage
                scopeOneTwoLongTermPercentage
              }
              reductionMonitoring {
                nodes {
                  id
                  confirmedResponsibility
                  reviewed
                  signedOff
                  coveredRegularly
                  frequencyOfReview
                }
              }
            }
          }
        `,
        variables: {
          filter: {
            // year: { eq: state.selectedYear },
            clientID: { eq: parseInt(store.state.user.clientID) }
          }
        },
        fetchPolicy: 'no-cache'
      })
      await commit('SET_REDUCTION_PLAN', data.readOneReductionPlan)
      return data.readOneReductionPlan
    },
    async createReductionPlan ({ commit, state, dispatch }, data) {
      const res = await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation(
            $input: CreateReductionPlanInput!
          ) {
            createReductionPlan(input: $input) {
              id
              year
              settings
              lastEdited
              reductionEmissionSources {
                nodes {
                  id
                  term
                  initiative
                  responsibility
                  previousPerformanceComment
                  addedByAnalyst
                  emissionsSource {
                    id
                    name
                    tCO2e
                    tCO2eMarketRate
                    subcategory
                    period {
                      id
                      year
                    }
                  }
                  baseYearEmissionsSource {
                    id
                    name
                    tCO2e
                    tCO2eMarketRate
                    subcategory
                    period {
                      id
                      year
                    }
                  }
                }
              }
              reductionTarget {
                id
                organisationSpecificMetric
                combinedScopeOneTwoTarget
                shortTermTimeFrame
                mediumTermTimeFrame
                longTermTimeFrame
                absoluteShortTermPercentage
                absoluteMediumTermPercentage
                absoluteLongTermPercentage
                intensityShortTermPercentage
                intensityMediumTermPercentage
                intensityLongTermPercentage
                scopeOneTwoShortTermPercentage
                scopeOneTwoMediumTermPercentage
                scopeOneTwoLongTermPercentage
              }
            }
          }
        `,
        variables: {
          input: data
        },
        fetchPolicy: 'no-cache'
      })
      return res
      // await dispatch('loadReductionPlan')
    },
    async loadReductionPlanEmissionSources ({ commit, state }) {
      const { data } = await apolloClient.query({
        query: gql`
          query(
            $filter: ReductionPlanFilterFields!
          ) {
            readOneReductionPlan(filter: $filter) {
              reductionEmissionSources {
                  nodes {
                    id
                    term
                    initiative
                    responsibility
                    previousPerformanceComment
                    addedByAnalyst
                    emissionsSource {
                      id
                      name
                      tCO2e
                      tCO2eMarketRate
                      subcategory
                      period {
                        id
                        year
                      }
                    }
                    baseYearEmissionsSource {
                      id
                      name
                      tCO2e
                      tCO2eMarketRate
                      subcategory
                      period {
                        id
                        year
                      }
                    }
                  }
                }
            }
          }
            `,
        variables: {
          filter: {
            year: { eq: state.selectedYear },
            clientID: { eq: parseInt(store.state.user.clientID) }
          }
        },
        fetchPolicy: 'no-cache'
      })
      await commit('SET_REDUCTION_PLAN_EMISSION_SOURCES', data.readOneReductionPlan.reductionEmissionSources)
    },
    async createReductionEmissionSources ({ commit, state, dispatch }, data) {
      const promises = data.map((item) => {
        return apolloClient.mutate({
          // Query
          mutation: gql`
            mutation($input: CreateReductionEmissionSourceInput!) {
              createReductionEmissionSource(input: $input) {
                id
                term
                initiative
                responsibility
                previousPerformanceComment
                addedByAnalyst
                emissionsSource {
                  id
                  name
                  tCO2e
                  tCO2eMarketRate
                  subcategory
                  period {
                    id
                    year
                  }
                }
                baseYearEmissionsSource {
                  id
                  name
                  tCO2e
                  tCO2eMarketRate
                  subcategory
                  period {
                    id
                    year
                  }
                }
              }
            }
          `,
          variables: {
            input: item
          },
          fetchPolicy: 'no-cache'
        })
      })
      const res = await Promise.all(promises)
      return res
    },
    async updateReductionEmissionSources ({ commit, state, dispatch }, data) {
      const promises = data.map((item) => {
        return apolloClient.mutate({
          // Query
          mutation: gql`
            mutation($input: UpdateReductionEmissionSourceInput!) {
              updateReductionEmissionSource(input: $input) {
                id
                term
                initiative
                responsibility
                previousPerformanceComment
                addedByAnalyst
                emissionsSource {
                  id
                  name
                  tCO2e
                  tCO2eMarketRate
                  subcategory
                  period {
                    id
                    year
                  }
                }
                baseYearEmissionsSource {
                  id
                  name
                  tCO2e
                  tCO2eMarketRate
                  subcategory
                  period {
                    id
                    year
                  }
                }
              }
            }
          `,
          variables: {
            input: item
          },
          fetchPolicy: 'no-cache'
        })
      })
      const res = await Promise.all(promises)
      return res
    },
    async deleteReductionEmissionSources ({ commit, state, dispatch }, data) {
      const promises = data.map((item) => {
        return apolloClient.mutate({
          // Query
          mutation: gql`
            mutation($ids: [ID]!) {
              deleteReductionEmissionSources(ids: $ids)
            }
          `,
          variables: {
            ids: item
          },
          fetchPolicy: 'no-cache'
        })
      })
      const res = await Promise.all(promises)
      return res
    },
    async updateReductionEmissionSource ({ commit, state, dispatch }, data) {
      await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: UpdateReductionEmissionSourceInput!) {
            updateReductionEmissionSource(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: data.reductionEmissionSource
        },
        fetchPolicy: 'no-cache'
      })
      // await dispatch('loadAppData')
    },
    async updateReductionTarget ({ commit, state, dispatch }, data) {
      const res = await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: UpdateReductionTargetInput!) {
            updateReductionTarget(input: $input) {
              id
              organisationSpecificMetric
              combinedScopeOneTwoTarget
              shortTermTimeFrame
              mediumTermTimeFrame
              longTermTimeFrame
              absoluteShortTermPercentage
              absoluteMediumTermPercentage
              absoluteLongTermPercentage
              intensityShortTermPercentage
              intensityMediumTermPercentage
              intensityLongTermPercentage
              scopeOneTwoShortTermPercentage
              scopeOneTwoMediumTermPercentage
              scopeOneTwoLongTermPercentage
            }
          }
        `,
        variables: {
          input: data.reductionTarget
        },
        fetchPolicy: 'no-cache'
      })
      return res
      // await dispatch('loadAppData')
    },
    async createReductionMonitoring ({ commit, state, dispatch }, data) {
      const res = await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: CreateReductionMonitoringInput!) {
            createReductionMonitoring(input: $input) {
              id
              confirmedResponsibility
              reviewed
              signedOff
              coveredRegularly
              frequencyOfReview
              year
            }
          }
        `,
        variables: {
          input: data.reductionMonitoring
        },
        fetchPolicy: 'no-cache'
      })
      return res
      // await dispatch('loadAppData')
    },
    async updateReductionMonitoring ({ commit, state, dispatch }, data) {
      const res = await apolloClient.mutate({
        // Query
        mutation: gql`
          mutation($input: UpdateReductionMonitoringInput!) {
            updateReductionMonitoring(input: $input) {
              id
              confirmedResponsibility
              reviewed
              signedOff
              coveredRegularly
              frequencyOfReview
              year
            }
          }
        `,
        variables: {
          input: data.reductionMonitoring
        },
        fetchPolicy: 'no-cache'
      })
      return res
      // await dispatch('loadAppData')
    },
    async loadReductionMonitoring ({ commit, state }, input) {
      const { data } = await apolloClient.query({
        query: gql`
          query(
            $filter: ReductionMonitoringFilterFields!
          ) {
            readOneReductionMonitoring(filter: $filter) {
              id
              confirmedResponsibility
              reviewed
              signedOff
              coveredRegularly
              frequencyOfReview
              year
            }
          }
            `,
        variables: {
          filter: { year: { eq: state.selectedYear }, reductionPlanID: { eq: input.id } }
        },
        fetchPolicy: 'no-cache'
      })
      return data.readOneReductionMonitoring
    },
    async getReportFTE ({ commit, state }, info) {
      const { data } = await apolloClient.query({
        query: gql`
          query(
            $filter: ReportFilterFields!
            $FTEFilter: EmissionIntensityMetricFilterFields!
          ) {
            readOneReport(filter: $filter) {
              emissionIntensityMetrics(filter: $FTEFilter) {
                  nodes {
                    id
                    title
                    value
                  }
              }
            }
          }
            `,
        variables: {
          filter: {
            year: { eq: info.year },
            clientID: { eq: parseInt(store.state.user.clientID) }
          },
          FTEFilter: { title: { eq: info.metric } }
        },
        fetchPolicy: 'no-cache'
      })
      return data
    },
    async getEmissionIntensityMetrics ({ commit, state }, year) {
      const { data } = await apolloClient.query({
        query: gql`
          query(
            $filter: ReportFilterFields!
          ) {
            readOneReport(filter: $filter) {
              emissionIntensityMetrics {
                  nodes {
                    id
                    title
                    value
                  }
              }
            }
          }
            `,
        variables: {
          filter: {
            year: { eq: year },
            clientID: { eq: parseInt(store.state.user.clientID) }
          }
        },
        fetchPolicy: 'no-cache'
      })
      return data
    },
    async getReductionRecommendations ({ commit, state }) {
      const { data } = await apolloClient.query({
        query: gql`
          query {
            readReductionRecommendationCategories {
              nodes {
                id
                title
                recommendations {
                  nodes {
                    id
                    type
                    content
                  }
                }
              }
            }
          }
            `
      })
      return data
    },
    async fetchOrganisations ({ commit, state, dispatch }, input) {
      const response = await apolloClient.query({
        query: clientQueries.READ_CLIENT_ORGANISATIONS,
        variables: {
          clientID: store.state.user.clientID
        }
      })
      commit('SET_ORGANISATIONS', response.data.readOrganisations.nodes)
    }
  }
}
