<template>
  <div>
    <table class="absolute-intensity-table">
      <tr>
        <th></th>
        <th>Base Year Emissions</th>
        <th>Short Term Reduction Target %</th>
        <th>Projected Total</th>
        <th>Medium Term Reduction Target %</th>
        <th>Projected Total</th>
        <th>Long Term Reduction Target %</th>
        <th>Projected Total</th>
      </tr>
      <tr>
        <td class="bold">Absolute</td>
        <td class="bold">{{ $root.formatNumber(reductionTargets.absolute.base) }}</td>
        <td><input type="number"
            v-model.number="$store.state.app.reductionPlan.reductionTarget.absoluteShortTermPercentage"
            @change="updateReductionTarget('absoluteShortTermPercentage', $store.state.app.reductionPlan.reductionTarget.absoluteShortTermPercentage)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()" /></td>
        <td>{{ $root.formatNumber(reductionTargets.absolute.shortTerm) }}</td>
        <td><input type="number"
            v-model.number="$store.state.app.reductionPlan.reductionTarget.absoluteMediumTermPercentage"
            @change="updateReductionTarget('absoluteMediumTermPercentage', $store.state.app.reductionPlan.reductionTarget.absoluteMediumTermPercentage)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()" /></td>
            <td>{{ $root.formatNumber(reductionTargets.absolute.mediumTerm) }}</td>
        <td><input type="number"
            v-model.number="$store.state.app.reductionPlan.reductionTarget.absoluteLongTermPercentage"
            @change="updateReductionTarget('absoluteLongTermPercentage', $store.state.app.reductionPlan.reductionTarget.absoluteLongTermPercentage)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()" /></td>
            <td>{{ $root.formatNumber(reductionTargets.absolute.longTerm) }}</td>
      </tr>
      <tr>
        <td class="bold">Intensity</td>
        <td class="bold">{{ $root.formatNumber(reductionTargets.intensity.base) }}</td>
        <td><input type="number"
            v-model.number="$store.state.app.reductionPlan.reductionTarget.intensityShortTermPercentage"
            @change="updateReductionTarget('intensityShortTermPercentage', $store.state.app.reductionPlan.reductionTarget.intensityShortTermPercentage)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()" /></td>
        <td>{{ $root.formatNumber(reductionTargets.intensity.shortTerm) }}</td>
        <td><input type="number"
            v-model.number="$store.state.app.reductionPlan.reductionTarget.intensityMediumTermPercentage"
            @change="updateReductionTarget('intensityMediumTermPercentage', $store.state.app.reductionPlan.reductionTarget.intensityMediumTermPercentage)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()" /></td>
            <td>{{ $root.formatNumber(reductionTargets.intensity.mediumTerm) }}</td>
        <td><input type="number"
            v-model.number="$store.state.app.reductionPlan.reductionTarget.intensityLongTermPercentage"
            @change="updateReductionTarget('intensityLongTermPercentage', $store.state.app.reductionPlan.reductionTarget.intensityLongTermPercentage)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()" /></td>
            <td>{{ $root.formatNumber(reductionTargets.intensity.longTerm) }}</td>
      </tr>
      <tr v-if="$store.state.app.reductionPlan.reductionTarget.combinedScopeOneTwoTarget">
        <td class="bold">
          Combined Scope 1 & 2 Target
        </td>
        <td class="bold">
          {{ $root.formatNumber(reductionTargets.scopeOneTwo.base) }}
        </td>
        <td><input type="number"
            v-model.number="$store.state.app.reductionPlan.reductionTarget.scopeOneTwoShortTermPercentage"
            @change="updateReductionTarget('scopeOneTwoShortTermPercentage', $store.state.app.reductionPlan.reductionTarget.scopeOneTwoShortTermPercentage)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()" /></td>
        <td>{{ $root.formatNumber(reductionTargets.scopeOneTwo.shortTerm) }}</td>
        <td><input type="number"
            v-model.number="$store.state.app.reductionPlan.reductionTarget.scopeOneTwoMediumTermPercentage"
            @change="updateReductionTarget('scopeOneTwoMediumTermPercentage', $store.state.app.reductionPlan.reductionTarget.scopeOneTwoMediumTermPercentage)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()" /></td>
            <td>{{ $root.formatNumber(reductionTargets.scopeOneTwo.mediumTerm) }}</td>
        <td><input type="number"
            v-model.number="$store.state.app.reductionPlan.reductionTarget.scopeOneTwoLongTermPercentage"
            @change="updateReductionTarget('scopeOneTwoLongTermPercentage', $store.state.app.reductionPlan.reductionTarget.scopeOneTwoLongTermPercentage)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()" /></td>
            <td>{{ $root.formatNumber(reductionTargets.scopeOneTwo.longTerm) }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import emissionsMixin from '../../../mixins/emissionsMixin.vue'
export default {
  mixins: [emissionsMixin],
  name: 'ReductionTargets',
  data () {
    return {
      baseYearIntensityMetric: 0,
      loaded: false
    }
  },
  async beforeMount () {
    this.baseYearIntensityMetric = await this.getBaseYearIntensityMetric()
  },
  methods: {
    async updateReductionTarget (field, value) {
      const data = { reductionTarget: { id: this.$store.state.app.reductionPlan.reductionTarget.id } }
      data.reductionTarget[field] = value
      // console.log(data.otherEmissions, 'updating otherEmissions data')
      this.$root.saving = true
      const res = await this.$store.dispatch('updateReductionTarget', data)
      this.$store.state.app.reductionPlan.reductionTarget = res.data.updateReductionTarget
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    async getBaseYearIntensityMetric () {
      const baseYear = this.$store.state.app.client.baseYear?.year
      const metric = this.$store.state.app.reductionPlan.reductionTarget.organisationSpecificMetric
      if (baseYear && metric) {
        const res = await this.$store.dispatch('getReportFTE', { year: baseYear, metric: metric })
        return res.readOneReport.emissionIntensityMetrics.nodes[0]?.value
      } else {
        return 0
      }
    }
  },
  computed: {
    baseYearEmissions () {
      const baseYearEmissionsSources = this.$store.state.app.client.baseYear.emissionsSources.nodes
      const filteredBaseYearEmissionsSources = baseYearEmissionsSources.filter(es => es.rule !== 'hide-in-report' &&
            es.rule !== 'hide')
      const totals = this.getTotalsFromEmissionsSources(filteredBaseYearEmissionsSources)
      const ScopeOneTwoBaseYearEmissions = filteredBaseYearEmissionsSources.filter(es => es.GHGScope === 1 || es.GHGScope === 2)
      const totalScopeOneTwoEmissions = this.getTotalsFromEmissionsSources(ScopeOneTwoBaseYearEmissions)
      return {
        totalEmissions: this.baseYearMarketRateExists ? totals.emissionsMR : totals.emissions,
        combinedScopeOneTwoEmissions: this.baseYearMarketRateExists ? totalScopeOneTwoEmissions.emissions : totalScopeOneTwoEmissions.emissionsMR
      }
    },
    baseYearMarketRateExists () {
      const baseYearEmissionsSources = this.$store.state.app.client.baseYear.emissionsSources.nodes
      return this.checkEmissionsSourcesForMarketRate(baseYearEmissionsSources)
    },
    reductionTargets () {
      const reductionTargets = {
        absolute: {
          base: this.baseYearEmissions.totalEmissions,
          shortTerm: this.baseYearEmissions.totalEmissions - (this.$store.state.app.reductionPlan.reductionTarget.absoluteShortTermPercentage / 100) * this.baseYearEmissions.totalEmissions,
          mediumTerm: this.baseYearEmissions.totalEmissions - (this.$store.state.app.reductionPlan.reductionTarget.absoluteMediumTermPercentage / 100) * this.baseYearEmissions.totalEmissions,
          longTerm: this.baseYearEmissions.totalEmissions - (this.$store.state.app.reductionPlan.reductionTarget.absoluteLongTermPercentage / 100) * this.baseYearEmissions.totalEmissions
        },
        intensity: {
          base: this.baseYearEmissions.totalEmissions / this.baseYearIntensityMetric,
          shortTerm: (this.baseYearEmissions.totalEmissions / this.baseYearIntensityMetric) - (this.$store.state.app.reductionPlan.reductionTarget.intensityShortTermPercentage / 100) * (this.baseYearEmissions.totalEmissions / this.baseYearIntensityMetric),
          mediumTerm: (this.baseYearEmissions.totalEmissions / this.baseYearIntensityMetric) - (this.$store.state.app.reductionPlan.reductionTarget.intensityMediumTermPercentage / 100) * (this.baseYearEmissions.totalEmissions / this.baseYearIntensityMetric),
          longTerm: (this.baseYearEmissions.totalEmissions / this.baseYearIntensityMetric) - (this.$store.state.app.reductionPlan.reductionTarget.intensityLongTermPercentage / 100) * (this.baseYearEmissions.totalEmissions / this.baseYearIntensityMetric)
        },
        scopeOneTwo: {
          base: this.baseYearEmissions.combinedScopeOneTwoEmissions,
          shortTerm: this.baseYearEmissions.combinedScopeOneTwoEmissions - (this.$store.state.app.reductionPlan.reductionTarget.scopeOneTwoShortTermPercentage / 100) * this.baseYearEmissions.combinedScopeOneTwoEmissions,
          mediumTerm: this.baseYearEmissions.combinedScopeOneTwoEmissions - (this.$store.state.app.reductionPlan.reductionTarget.scopeOneTwoMediumTermPercentage / 100) * this.baseYearEmissions.combinedScopeOneTwoEmissions,
          longTerm: this.baseYearEmissions.combinedScopeOneTwoEmissions - (this.$store.state.app.reductionPlan.reductionTarget.scopeOneTwoLongTermPercentage / 100) * this.baseYearEmissions.combinedScopeOneTwoEmissions
        }
      }
      return reductionTargets
    }
  },
  watch: {
    // when }
    async '$store.state.app.reductionPlan.reductionTarget.organisationSpecificMetric' () {
      this.baseYearIntensityMetric = await this.getBaseYearIntensityMetric()
    }
  }
}
</script>
