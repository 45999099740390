<template>
  <div class="recognised-offset-deductions">
    <div class="input-container-row">
    <h2 class="section-title activity-title">Recognised Offset Deductions</h2>
    <Controls
      class="controls"
      v-on:add="createDefaultRecognisedOffsetDeduction()"
      v-on:remove="() => {}"
      :showRemove="false"
    />
    </div>
    <div
      class="grid"
      v-for="offset in $store.state.app.report.recognisedOffsetDeductions.nodes"
      :key="offset.id"
    >
      <!-- <div class="grid"> -->
        <div class="grid-item">
          <label>Title:</label>
          <input
            type="text"
            v-model="offset.title"
            v-on:change="updateRecognisedOffsetDeduction('title', offset)"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          />
        </div>
        <div class="grid-item">
          <label>Notes:</label>
          <textarea
            rows="4"
            v-model="offset.notes"
            v-on:change="updateRecognisedOffsetDeduction('notes', offset)"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          />
        </div>
        <div class="grid-item">
          <label>Amount:</label>
          <input
            type="number" step="0.01"
            :disabled="offset.type === 'Calculated'"
            v-model.number="offset.amount"
            v-on:change="updateRecognisedOffsetDeduction('amount', offset)"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          />
        </div>
        <div class="grid-item">
          <Controls
            class="controls"
            v-on:add="() => {}"
            v-on:remove="removeRecognisedOffsetDeduction(offset)"
            :showAdd="false"
            />
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import emissionsMixin from '../../mixins/emissionsMixin.vue'
import Controls from '../../components/controls.vue'

export default {
  mixins: [emissionsMixin],
  components: { Controls },
  mounted () {
    this.getElectricityOffset(this.$root.masterSchema)
  },
  methods: {
    async createDefaultRecognisedOffsetDeduction (offset) {
      const data = {
        recognisedOffsetDeduction: {
          reportID: this.$store.state.app.report.id,
          title: 'Manual Emissions Source',
          notes: '',
          amount: 0,
          type: 'Manual'
        }
      }
      await this.$store.dispatch('createRecognisedOffsetDeduction', data)
      this.$root.saving = true
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    async updateRecognisedOffsetDeduction (field, offset) {
      const data = { recognisedOffsetDeduction: { id: offset.id } }
      data.recognisedOffsetDeduction[field] = offset[field]
      // console.log(data.otherEmissions, 'updating otherEmissions data')
      await this.$store.dispatch('updateRecognisedOffsetDeduction', data)
      this.$root.saving = true
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    async removeRecognisedOffsetDeduction (offset) {
      const data = [offset.id]
      await this.$store.dispatch('deleteRecognisedOffsetDeduction', data)
      this.$root.saving = true
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    }
  },
  computed: {
    electricityOffset () {
      return this.getElectricityOffset(this.$root.masterSchema)
    }
  }
}
</script>

<style></style>
