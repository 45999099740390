<template>
  <div class="business-units">
    <h3>Business Units</h3>
    <div class="business-units-table" v-html="businessUnitsBreakdown"/>
  </div>
  </template>

<script>
import { renderBusinessUnitBreakdownTable } from '../../../snippets/businessUnitBreakdown'
export default {
  computed: {
    businessUnitsBreakdown () {
      const organisations = JSON.parse(JSON.stringify(this.$store.state.app.client.allBusinessUnits.nodes))
      return renderBusinessUnitBreakdownTable(organisations, this.marketRateExists)
    }
  }
}
</script>
