<template>
  <div class="file-input">
    <input :id="id" class="file-input" type="file" @change="handleUpload" :accept="accept" />
  </div>
</template>

<script>

import axios from '@/axios'

export default {
  // props seem best defined in the Object API style as we're used to
  props: {
    id: { type: String, default: 'file-input' },
    name: { type: String, default: 'file ' },
    multiple: { type: Boolean, default: false },
    uploadUrl: { type: [String, Boolean], default: false },
    accept: { type: String, default: '*' }
  },
  components: {},
  data () {
    return {
      files: []
    }
  },
  computed: {
    uploadInfo () {
      return this.files.value.length === 1
        ? this.files.value[0].name
        : `${this.files.value.length} files selected`
    }
  },
  methods: {
    async handleUpload (e) {
      this.files = Array.from(e.target.files) || []
      if (this.uploadUrl) {
        let formData = new FormData()
        formData.append(this.name, this.files[0])

        const res = await axios.post(`${process.env.VUE_APP_APIURL}api/${this.uploadUrl}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        if (res.data.error) {
          alert(res.data.error)
        }
      }
      this.$emit('inputChange', this.files)
    }
  }
}
</script>
