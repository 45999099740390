<template>
  <div v-if="$store.state.user.clientID">
    <div
      v-if="$store.state.app.clientDataLoaded && this.$store.state.app.report"
      class="eir-information"
    >
      <!-- <CarbonCredits @updateReportData="updateReport" /> -->
      <EmissionsIntensityMetrics />
      <div class="spacer"></div>

      <ReportNotes
        @updateReportData="updateReport"
        property="methodologyChanges"
        title="Any Changes to Methodologies"
        label="Changes to Methodologies:"
        placeholder="Please detail any changes to your organisations methodologies"
      />
      <div class="spacer"></div>
      <EmissionConsolidationMethod @updateReportData="updateReport" />
      <div class="spacer"></div>
      <!-- v-if="devMode() === 'development' || $root.userDetails.admin" -->
      <InventoryStatus @updateReportData="updateReport" />
      <div class="spacer"></div>
      <PreparedReviewedBy @updateReportData="updateReport" />
      <div class="spacer"></div>
      <PartsofBusinessIncluded />
      <div class="spacer"></div>
      <EmissionCategories />
      <div class="spacer"></div>
      <ExcludedEmissionsSources/>
      <div class="spacer"></div>
      <!-- v-if="devMode() === 'development' || $root.userDetails.admin" -->
      <DoubleCountingPreOffset @updateReportData="updateReport" />
      <div class="spacer"></div>
      <PerformanceAgainstPreviousYears :emissionsByScope="emissionsByScope" :emissionSources="emissionSources" />
      <div class="spacer"></div>
      <ReportNotes
        @updateReportData="updateReport"
        property="emissionReductionNotes"
        title="Emission Reduction Recommendation Notes"
        placeholder="Please refer to a separate, detailed reduction plan prepared by the organisation which documents the targets, responsibilities, actions and top level management commitment."
      />
      <CertificationType
        @updateReportData="updateReport"
        :marketRateExists="marketRateExists"
      />
      <div class="spacer"></div>
      <OtherEmissions />
      <div class="btn-wrapper">
        <div class="submit-btn" @click="$emit('navigate')">
          <div class="btn-text">
            Preview Report
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtherEmissions from './OtherEmissions.vue'
import EmissionCategories from './EmissionCategories.vue'
import PartsofBusinessIncluded from './PartsofBusinessIncluded.vue'
import EmissionsIntensityMetrics from './EmissionsIntensityMetrics.vue'
import EmissionConsolidationMethod from './EmissionConsolidationMethod.vue'
import InventoryStatus from './InventoryStatus.vue'
import dataMixin from '../../mixins/dataMixin.vue'
import PreparedReviewedBy from './PreparedReviewedBy.vue'
import ReportNotes from './ReportNotes.vue'
import CertificationType from './CertificationType.vue'
import DoubleCountingPreOffset from './DoubleCountingPreOffset.vue'
import PerformanceAgainstPreviousYears from './PerformanceAgainstPreviousYears.vue'
import ExcludedEmissionsSources from './ExcludedEmissionsSources.vue'

export default {
  mixins: [dataMixin],
  props: { period: String, marketRateExists: Boolean, emissionsByScope: Object, emissionSources: Array },
  components: {
    OtherEmissions,
    EmissionCategories,
    PartsofBusinessIncluded,
    EmissionsIntensityMetrics,
    EmissionConsolidationMethod,
    InventoryStatus,
    PreparedReviewedBy,
    ReportNotes,
    CertificationType,
    DoubleCountingPreOffset,
    PerformanceAgainstPreviousYears,
    ExcludedEmissionsSources
  },
  metaData () {
    return {
      title: `dCarbon — Emissions Report`,
      description: ''
    }
  },
  methods: {
    async updateReport (field, loadAfterSaving = false) {
      const data = { report: { id: this.$store.state.app.report.id } }
      data.report[field] = this.$store.state.app.report[field]
      data.load = loadAfterSaving
      this.$root.saving = true
      await this.$store.dispatch('updateReport', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    }
  }
}
</script>
