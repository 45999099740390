import { apolloClient } from '../../vue-apollo.js'
import gql from 'graphql-tag'
import store from '../../store'

/**
 * get member sessions editing the current period
 * @param {any} state
 * @returns {MemberSession[]}
*/
const otherEditors = function (state) {
  const editors = []
  state.sessions.forEach(session => {
    const date = new Date()
    const time = Math.floor(date.getTime() / 1000)
    if (parseInt(time) < parseInt(session.timestamp) + 30 && store.state.user.id !== session.memberID) {
      editors.push(session)
    }
  })
  // console.log('e', editors)
  return editors
}

export default {
  state: {
    sessions: [],
    otherEditors: []
  },
  mutations: {
    SET_SESSIONS: (state, data) => {
      if (!data) return
      if (data.sessions) {
        state.sessions = data.sessions
      }

      // console.log('otherEditors', otherEditors(state))
      state.otherEditors = otherEditors(state)
      // console.log('state.otherEditors', state.otherEditors)
    }
  },
  actions: {
    async loadMemberSessions ({ commit }, { year, clientID }) {
      const { data } = await apolloClient.query({
        query: gql`query ($filter: MemberSessionFilterFields!) {
          readMemberSessions(filter: $filter) {
            nodes {
              id
              timestamp
              memberID
              member {
                firstName
                surname
              }
              clientID
              organisationID
              periodID
              period {
                year
              }
            }
          }
        }`,
        variables: {
          filter: {
            clientID: { eq: parseInt(clientID) || '' },
            period: { year: { eq: year.toString() || '' } }
          }
        },
        fetchPolicy: 'no-cache'
      })

      commit('SET_SESSIONS', { sessions: data.readMemberSessions.nodes })
    },

    async saveMemberSession ({ dispatch }, periodID) {
      if (periodID) {
        const input = {
          memberID: parseInt(store.state.user.id),
          clientID: parseInt(store.state.user.clientID),
          organisationID: parseInt(store.state.user.organisationID),
          periodID: parseInt(periodID)
        }

        if (!input.clientID) {
          return
        }

        await apolloClient.mutate({
          // Query
          mutation: gql`mutation ($memberID: ID!, $clientID: ID!, $organisationID: ID!, $periodID: ID!) {
            saveMemberSession(memberID: $memberID, clientID: $clientID, organisationID: $organisationID, periodID: $periodID) {
              id
              timestamp
            }
          }`,
          variables: input
        })
        await dispatch('loadMemberSessions', {
          year: store.state.app.selectedYear,
          clientID: store.state.user.clientID
        })
      }
    }
  }
}
