const businessAccommodationSchema = [
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Business Accommodation', // Title of Section on front end
    title: 'Business Accommodation', // Title of Section on front end
    scope: 3, // scope number
    sectionID: 'business-accommodation', // Default Schema Scope Section Name
    identifier: 'accommodation-component', // Main Section component identifier for tooltips
    version: 2.76,
    emissionSources: [
      {
        category: 'Scope 3 - Category 3: Indirect GHG emissions from transportation', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Business Travel', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 6, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Business Accommodation -', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        unit: 'Person nights',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    accommodation: [
      {
        country: '',
        rooms: 0,
        nights: 0,
        eFactor: 0,
        carbonEmissions: 0
      },
      { country: '', rooms: 0, nights: 0, eFactor: 0, carbonEmissions: 0 },
      { country: '', rooms: 0, nights: 0, eFactor: 0, carbonEmissions: 0 },
      { country: '', rooms: 0, nights: 0, eFactor: 0, carbonEmissions: 0 },
      { country: '', rooms: 0, nights: 0, eFactor: 0, carbonEmissions: 0 }
    ],
    countries: [
      { name: 'Argentina', variableName: 'hotelStayArgentinaKgCO2e' },
      { name: 'Australia', variableName: 'hotelStayAustraliaKgCO2e' },
      { name: 'Austria', variableName: 'hotelStayAustriaKgCO2e' },
      { name: 'Belgium', variableName: 'hotelStayBelgiumKgCO2e' },
      { name: 'Brazil', variableName: 'hotelStayBrazilKgCO2e' },
      { name: 'Canada', variableName: 'hotelStayCanadaKgCO2e' },
      {
        name: 'Caribbean Region',
        variableName: 'hotelStayCaribbeanRegionKgCO2e'
      },
      { name: 'Chile', variableName: 'hotelStayChileKgCO2e' },
      { name: 'China', variableName: 'hotelStayChinaKgCO2e' },
      {
        name: 'China (Hong Kong)',
        variableName: 'hotelStayChinaHongKongKgCO2e'
      },
      { name: 'Colombia', variableName: 'hotelStayColombiaKgCO2e' },
      { name: 'Costa Rica', variableName: 'hotelStayCostaRicaKgCO2e' },
      {
        name: 'Czech Republic',
        variableName: 'hotelStayCzechRepublicKgCO2e'
      },
      { name: 'Egypt', variableName: 'hotelStayEgyptKgCO2e' },
      { name: 'France', variableName: 'hotelStayFranceKgCO2e' },
      { name: 'French Polynesia', variableName: 'hotelStayFrenchPolynesiaKgCO2e' },
      { name: 'Germany', variableName: 'hotelStayGermanyKgCO2e' },
      { name: 'India', variableName: 'hotelStayIndiaKgCO2e' },
      { name: 'Indonesia', variableName: 'hotelStayIndonesiaKgCO2e' },
      { name: 'Ireland', variableName: 'hotelStayIrelandKgCO2e' },
      { name: 'Italy', variableName: 'hotelStayItalyKgCO2e' },
      { name: 'Japan', variableName: 'hotelStayJapanKgCO2e' },
      { name: 'Jordan', variableName: 'hotelStayJordanKgCO2e' },
      { name: 'Malaysia', variableName: 'hotelStayMalaysiaKgCO2e' },
      { name: 'Maldives', variableName: 'hotelStayMaldivesKgCO2e' },
      { name: 'Mexico', variableName: 'hotelStayMexicoKgCO2e' },
      { name: 'Netherlands', variableName: 'hotelStayNetherlandsKgCO2e' },
      { name: 'New Zealand', variableName: 'hotelStayNewZealandKgCO2e' },
      { name: 'Oman', variableName: 'hotelStayOmanKgCO2e' },
      { name: 'Panama', variableName: 'hotelStayPanamaKgCO2e' },
      { name: 'Philippines', variableName: 'hotelStayPhilippinesKgCO2e' },
      { name: 'Poland', variableName: 'hotelStayPolandKgCO2e' },
      { name: 'Portugal', variableName: 'hotelStayPortugalKgCO2e' },
      { name: 'Qatar', variableName: 'hotelStayQatarKgCO2e' },
      { name: 'Romania', variableName: 'hotelStayRomaniaKgCO2e' },
      {
        name: 'Russian Federation',
        variableName: 'hotelStayRussianFederationKgCO2e'
      },
      { name: 'Saudi Arabia', variableName: 'hotelStaySaudiArabiaKgCO2e' },
      { name: 'Singapore', variableName: 'hotelStaySingaporeKgCO2e' },
      { name: 'South Africa', variableName: 'hotelStaySouthAfricaKgCO2e' },
      { name: 'South Korea', variableName: 'hotelStaySouthKoreaKgCO2e' },
      { name: 'Spain', variableName: 'hotelStaySpainKgCO2e' },
      { name: 'Switzerland', variableName: 'hotelStaySwitzerlandKgCO2e' },
      { name: 'Taiwan', variableName: 'hotelStayTaiwanKgCO2e' },
      { name: 'Thailand', variableName: 'hotelStayThailandKgCO2e' },
      { name: 'Turkey', variableName: 'hotelStayTurkeyKgCO2e' },
      {
        name: 'United Arab Emirates',
        variableName: 'hotelStayUnitedArabEmiratesKgCO2e'
      },
      {
        name: 'United Kingdom',
        variableName: 'hotelStayUnitedKingdomKgCO2e'
      },
      {
        name: 'United States',
        variableName: 'hotelStayUnitedStatesKgCO2e'
      },
      { name: 'Fiji', variableName: 'hotelStayFijiKgCO2e' },
      { name: 'Greece', variableName: 'hotelStayGreeceKgCO2e' },
      { name: 'Hungary', variableName: 'hotelStayHungaryKgCO2e' },
      { name: 'Israel', variableName: 'hotelStayIsraelKgCO2e' },
      { name: 'Kazakhstan', variableName: 'hotelStayKazakhstanKgCO2e' },
      { name: 'Macau', variableName: 'hotelStayMacauKgCO2e' },
      { name: 'Peru', variableName: 'hotelStayPeruKgCO2e' },
      { name: 'Morocco', variableName: 'hotelStayMoroccoKgCO2e' },
      { name: 'Other', variableName: 'hotelStayOtherKgCO2e' }
    ]
  }
]
export default businessAccommodationSchema
