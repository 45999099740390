<template>
  <div class="section inward-freight">
    <div class="activity-title-row">
      <h1 class="activity-title">Freight not paid for by client</h1>
      <!-- <p>Freight not paid for by you - between your client and their clients</p> -->
      <i class="title-icon fad fa-truck-moving"></i>
    </div>
    <InwardFreightSeaFreightComponent
      title="Sea freight not paid for by client"
      v-bind:initialSchema="
        schema[this.getSchemaIndex('Sea freight not paid for by client')]
      "
      schemaName="Sea freight not paid for by client"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="sea-freight"
    />
    <Notes
      :schemaIndex="0"
      :key="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>

    <InwardFreightAirFreightComponent
      title="Air freight not paid for by client"
        v-bind:initialSchema="
        schema[this.getSchemaIndex('Air freight not paid for by client')]
      "
      schemaName="Air freight not paid for by client"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="air-freight"
    />
    <Notes
      :schemaIndex="1"
      :key="1"
      :dataSource="schema[1].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[1].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[1].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>

    <InwardFreightAirFreightComponent
      title="Other Freight not paid for by client"
      v-bind:initialSchema="
        schema[this.getSchemaIndex('Other Freight not paid for by client')]
      "
      schemaName="Other Freight not paid for by client"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="road-freight"
    />
    <Notes
      :schemaIndex="2"
      :key="2"
      :dataSource="schema[2].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[2].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[2].assumptions"
      @assumptionsChanged="updateAssumptions"
    />

    <NextSectionButton
      :nextRouteSchema="$root.freight.schemas[4]"
      :nextRouteActivity="$root.freight.sections[4]"
      sectionName="freight"
    />
  </div>
</template>

<script>
import InwardFreightSeaFreightComponent from '../inwardFreight/InwardFreightSeaFreightComponent'
import InwardFreightAirFreightComponent from '../inwardFreight/InwardFreightAirFreightComponent'
import Notes from '../../../Notes.vue'
import NextSectionButton from '../../../NextSectionButton.vue'

export default {
  metaData () {
    return {
      title: `dCarbon — Freight Not Paid For`,
      description: ''
    }
  },
  name: 'activity-freight-not-paid-for',
  components: {
    InwardFreightSeaFreightComponent,
    InwardFreightAirFreightComponent,
    Notes,
    NextSectionButton
  },
  props: { initialSchema: Object, scope: String },

  data () {
    return {
      schema: {}
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    calculateTotal (schema, schemaName) {
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    }
  }
}
</script>
