import { renderCells } from '../../utils/utils'

export function renderMonitoringTable (monitoring) {
  let cellCount = 2
  const headingData = ['Has the person responsible for each initiative confirmed their responsibility?', 'Has the plan and target been reviewed by senior management?', 'Has the CEO/Director (or equivalent) signed off on all the targets and initiatives?', 'Is the reduction plan and performance being covered in regular meetings?']
  const mainData = []
  mainData.push(monitoring.confirmedResponsibility)
  mainData.push(monitoring.reviewed)
  mainData.push(monitoring.signedOff)
  mainData.push(monitoring.coveredRegularly)
  if (monitoring.coveredRegularly === 'Yes') {
    headingData.push('If so, how frequently?')
    mainData.push(monitoring.frequencyOfReview)
  }
  let mergedArray = mergeArrays(headingData, mainData)
  let newMain = renderCells(mergedArray, cellCount)

  return `<table><tr><th></th><th></th></tr>${newMain}</table>`
}

function mergeArrays (array1, array2) {
  let mergedArray = []
  for (let i = 0; i < Math.max(array1.length, array2.length); i++) {
    if (i < array1.length) {
      mergedArray.push(array1[i])
    }
    if (i < array2.length) {
      mergedArray.push(array2[i])
    }
  }
  return mergedArray
}
