const airConSchema = [
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Air Conditioning/Refrigerants', // Title of Section on front end
    title: 'Refrigerants- Air con & chillers',
    scope: 1, // scope number
    sectionID: 'aircon', // Default Schema Scope Section Name
    identifier: 'chemicals-component', // Main Section component identifier for tooltips
    version: 2.76,
    active: true,
    toggleName: 'enterBy',
    emissionSources: [
      {
        category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Chemical & Industrial Processes', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Refrigerants ', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'CO2',
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    gasTypes: [
      {
        topUpDate: '',
        gasType: 'HFC-134a',
        topUpAmount: 0,
        units: 'kg',
        contractor: '',
        eFactor: 0.0,
        carbonEmissions: 0.0
      },
      {
        topUpDate: '',
        gasType: 'HFC-134a',
        topUpAmount: 0,
        units: 'kg',
        contractor: '',
        eFactor: 0.0,
        carbonEmissions: 0.0
      },
      {
        topUpDate: '',
        gasType: 'HFC-134a',
        topUpAmount: 0,
        units: 'kg',
        contractor: '',
        eFactor: 0.0,
        carbonEmissions: 0.0
      }
    ],
    gases: [
      { name: 'HFC-23', tCO2e: 'HFC-23KgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: 'HFC-32', tCO2e: 'HFC-32KgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: 'HFC-125', tCO2e: 'HFC-125KgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: 'HFC-134a', tCO2e: 'HFC-134aKgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: 'HFC-143a', tCO2e: 'HFC-143aKgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: 'HFC-152a', tCO2e: 'HFC-152aKgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      {
        name: 'HFC-227ea',
        tCO2e: 'HFC-227eaKgCO2e',
        CO2: null,
        N2O: null,
        CH4: null,
        emissionType: 'HFC',
        unit: 'KG'
      },
      {
        name: 'HFC-236fa',
        tCO2e: 'HFC-236faKgCO2e',
        CO2: null,
        N2O: null,
        CH4: null,
        emissionType: 'HFC',
        unit: 'KG'
      },
      {
        name: 'HFC-245fa',
        tCO2e: 'HFC-245faKgCO2e',
        CO2: null,
        N2O: null,
        CH4: null,
        emissionType: 'HFC',
        unit: 'KG'
      },
      {
        name: 'HFC-365mfc',
        tCO2e: 'HFC-365mfcKgCO2e',
        CO2: null,
        N2O: null,
        CH4: null,
        emissionType: 'HFC',
        unit: 'KG'
      },
      {
        name: 'HFC-43-10mee',
        tCO2e: 'HFC-43-10meeKgCO2e',
        CO2: null,
        N2O: null,
        CH4: null,
        emissionType: 'HFC',
        unit: 'KG'
      },
      {
        name: 'Sulphur Hexafluoride',
        tCO2e: 'SulphurHexafluorideKgCO2e',
        CO2: null,
        N2O: null,
        CH4: null,
        emissionType: 'SF6',
        unit: 'KG'
      },
      { name: 'PFC-14', tCO2e: 'PFC-14KgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'PFC', unit: 'KG' },
      { name: 'PFC-116', tCO2e: 'PFC-116KgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'PFC', unit: 'KG' },
      { name: 'PFC-218', tCO2e: 'PFC-218KgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'PFC', unit: 'KG' },
      { name: 'PFC-318', tCO2e: 'PFC-318KgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'PFC', unit: 'KG' },
      {
        name: 'PFC-3-1-10',
        tCO2e: 'PFC-3-1-10KgCO2e',
        CO2: null,
        N2O: null,
        CH4: null,
        emissionType: 'PFC',
        unit: 'KG'
      },
      {
        name: 'PFC-4-1-12',
        tCO2e: 'PFC-4-1-12KgCO2e',
        CO2: null,
        N2O: null,
        CH4: null,
        emissionType: 'PFC',
        unit: 'KG'
      },
      {
        name: 'PFC-5-1-14',
        tCO2e: 'PFC-5-1-14KgCO2e',
        CO2: null,
        N2O: null,
        CH4: null,
        emissionType: 'PFC',
        unit: 'KG'
      },
      {
        name: 'PFC-9-1-18',
        tCO2e: 'PFC-9-1-18KgCO2e',
        CO2: null,
        N2O: null,
        CH4: null,
        emissionType: 'PFC',
        unit: 'KG'
      },
      {
        name: 'Trifluoromethyl Sulphur Pentafluoride',
        tCO2e: 'TrifluoromethylSulphurPentafluorideKgCO2e',
        CO2: null,
        N2O: null,
        CH4: null,
        emissionType: 'PFC',
        unit: 'KG'
      },
      { name: '404A', tCO2e: '404AKgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: '406A', tCO2e: '406AKgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: '407C', tCO2e: '407CKgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: '407F', tCO2e: '407FKgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: '408A', tCO2e: '408AKgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: '409A', tCO2e: '409AKgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: '409B', tCO2e: '409BKgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: '410A', tCO2e: '410AKgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: '436A', tCO2e: '436AKgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: '436B', tCO2e: '436BKgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: '507A', tCO2e: '507AKgCO2e', CO2: null, N2O: null, CH4: null, emissionType: 'HFC', unit: 'KG' },
      { name: 'Aviation gas', tCO2e: 'aviationGasKgCO2e', CO2: 'aviationGasKgCO2', N2O: 'aviationGasKgN2O', CH4: 'aviationGasKgCH4', emissionType: 'NA', unit: 'L' },
      { name: 'Aviation fuel (Kerosene)', tCO2e: 'aviationFuelKeroseneKgCO2e', CO2: 'aviationFuelKeroseneKgCO2', N2O: 'aviationFuelKeroseneKgN2O', CH4: 'aviationFuelKeroseneKgCH4', emissionType: 'NA', unit: 'L' }
    ],
    numberOfGasType: 3
  }
]
export default airConSchema
