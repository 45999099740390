<template>
  <div class="reduction-monitoring" :style="'max-width: 800px'">
    <h1 class="section-title activity-title">Monitoring and Company Commitment</h1>
    <div class="input-container-column">
      <div class="input-section">
        <div class="input-container-row">
          <label>1. Has the person responsible for each initaitive confirmed their responsibility?</label>
          <select v-model="$store.state.app.reductionPlan.reductionMonitoring.confirmedResponsibility"
            @change="updateReductionMonitoring('confirmedResponsibility', $store.state.app.reductionPlan.reductionMonitoring.confirmedResponsibility)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()">
            <option :value="null">Please select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div class="input-container-row">
          <label>2. Has the plan and target been reviewed by senior management?</label>
          <select v-model="$store.state.app.reductionPlan.reductionMonitoring.reviewed"
            @change="updateReductionMonitoring('reviewed', $store.state.app.reductionPlan.reductionMonitoring.reviewed)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()">
            <option :value="null">Please select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div class="input-container-row">
          <label>3. Has the CEO/Director (or equivalent) signed off on all the targets and initiatives?</label>
          <select v-model="$store.state.app.reductionPlan.reductionMonitoring.signedOff"
            @change="updateReductionMonitoring('signedOff', $store.state.app.reductionPlan.reductionMonitoring.signedOff)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()">
            <option :value="null">Please select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div class="input-container-row">
          <label>4a. Is the reduction plan and performance being covered in regular meetings?</label>
          <select v-model="$store.state.app.reductionPlan.reductionMonitoring.coveredRegularly"
            @change="updateReductionMonitoring('coveredRegularly', $store.state.app.reductionPlan.reductionMonitoring.coveredRegularly)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()">
            <option :value="null">Please select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div class="input-container-row" v-if="$store.state.app.reductionPlan.reductionMonitoring.coveredRegularly === 'Yes'">
          <label>4b. How frequently?</label>
          <select v-model="$store.state.app.reductionPlan.reductionMonitoring.frequencyOfReview"
            @change="updateReductionMonitoring('frequencyOfReview', $store.state.app.reductionPlan.reductionMonitoring.frequencyOfReview)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()">
            <option :value="null">Please select</option>
            <option value="Monthly">Monthly</option>
            <option value="Bimonthly">Bimonthly</option>
            <option value="Quarterly">Quarterly</option>
            <option value="Annually">Annually</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async updateReductionMonitoring (field, value) {
      const data = { reductionMonitoring: { id: this.$store.state.app.reductionPlan.reductionMonitoring.id } }
      data.reductionMonitoring[field] = value
      // console.log(data.otherEmissions, 'updating otherEmissions data')
      this.$root.saving = true
      const res = await this.$store.dispatch('updateReductionMonitoring', data)
      this.$store.state.app.reductionPlan.reductionMonitoring = res.data.updateReductionMonitoring
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    }
  }
}
</script>

<style></style>
