<template>
  <div class="table-of-contents report-page">
    <div v-if="$root.userDetails.clientStatus === 'Active'" class="watermark1">WORKING VERSION</div>
    <div v-if="$root.userDetails.clientStatus === 'Active'" class="watermark2">WORKING VERSION</div>

    <div class="page-line"></div>
    <div class="page-header">
      investing in nature
      <div class="page-header-ekos">ekos</div>
    </div>

    <h1 class="title">Table of contents</h1>
    <div class="heading column">
      <div class="index" v-for="(page, index) in pages" :key="page.number">
        <div v-for="heading in page.headings" :key="heading.number">
          <div class="row">
            <div v-if="heading.title" :class="heading.type === 'Heading' ? 'heading-title' : 'sub-heading'">
              <span class="title-number">{{ heading.headingNumber }}</span>
              {{ heading.title }}
            </div>
            <div v-if="heading.type === 'Heading'" class="heading-number">
              Page {{ index + 3 }}
            </div>
          </div>
        </div>
      </div>
      <div class="page-footer">{{ footerText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pages: { type: Array, required: true },
    footerText: { type: String, default: 'Emissions Inventory Report' }
  },
  components: {},
  data () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>
