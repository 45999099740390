<template>
  <div class="reports">
  </div>
</template>

<script>
import Chartist from 'chartist'
import legend from 'chartist-plugin-legend'

export default {
  data () {
    return {}
  },
  name: 'emissions-by-scope-graph',
  props: { emissionsByScope: Object },
  mounted () {
    this.emissionsByScopeGraph()
  },
  methods: {
    emissionsByScopeGraph () {
      var series = [
        this.emissionsByScope.scopeOneEmissions,
        this.emissionsByScope.scopeTwoEmissions,
        this.emissionsByScope.scopeThreeEmissions
      ]
      var legendNames = ['Scope 1', 'Scope 2', 'Scope 3']
      series.forEach((item, index) => {
        if (item === 0) {
          series.splice(index, 1)
          legendNames.splice(index, 1)
        }
      })
      var data = {
        series: series
      }
      var sum = function (a, b) {
        return a + b
      }

      var options = {
        labelInterpolationFnc: function (value) {
          let percentage = Math.round((value / data.series.reduce(sum)) * 100)
          return percentage > 1 ? percentage + '%' : ''
        },
        width: '400px',
        height: '400px',
        plugins: [
          legend({
            legendNames: legendNames,
            clickable: false
          })
        ],
        donut: true
      }

      return Chartist.Pie('.emissions-by-scope-graph', data, options)
    }
  }
}
</script>

<style></style>
