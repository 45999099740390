<template>
  <div class="notes-row">
    <div class="input-section" style="width:100%">
      <div class="input-container-column">
        <div class="input-container-column">
          <div class="input-item long">
            <label>Data Source:</label>
            <input
            :disabled="$root.userDetails.organisationStatus === 'Completed'"
              placeholder="ie supplier invoice"
              type="text"
              @input="
                $emit('dataSourceChanged', {
                  val: $event.target.value,
                  schemaIndex: schemaIndex
                })
              "
              v-model="clientDataSource"
              v-on:change="$root.saveData()"
            />
          </div>
          <div class="input-item long">
            <label>Data Quality:</label>
            <select
            :disabled="$root.userDetails.organisationStatus === 'Completed'"
              @input="
                $emit('dataQualityChanged', {
                  val: $event.target.value,
                  schemaIndex: schemaIndex
                })
              "
              v-on:change="$root.saveData()"
              name="unit"
              id="unit-type"
              v-model="quality"
            >
              <option value="" >Please select</option>
              <option value="Poor">Poor</option>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="Good">Good</option>
            </select>
          </div>
        </div>
        <div class="input-container-row">
          <div class="input-item long">
            <label style="margin-right: 50px;">Assumptions:</label
            ><textarea
            :disabled="$root.userDetails.organisationStatus === 'Completed'"
            placeholder="Any assumptions made about this data"
              @input="
                $emit('assumptionsChanged', {
                  val: $event.target.value,
                  schemaIndex: schemaIndex
                })
              "
              rows="4"
              cols="50"
              v-model="userAssumptions"
              v-on:change="$root.saveData()"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      userAssumptions: '',
      clientDataSource: '',
      quality: ''
    }
  },
  props: {
    schemaIndex: Number,
    assumptions: String,
    dataSource: String,
    dataQuality: String
  },
  beforeMount () {
    this.userAssumptions = this.assumptions
    this.clientDataSource = this.dataSource
    this.quality = this.dataQuality
  }
}
</script>
