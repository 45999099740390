<template>
  <div v-if="$store.state.user.clientID" class="emissions-report-company-info excluded-emissions-sources">
    <div class="business-includes-container">
      <h1 class="section-title">
        Any specific emissions sources to be excluded that needs further explanation?
      </h1>
      <div class="description" style="text-align: left; max-width: 900px;">
        If you need to exclude sources or activities that is more complex than the standard category exclusions listed above, please detail them below.
      </div>
      <div >
        <div class="control-input-container" v-if="$store.state.app.report.excludedEmissionsSources.nodes.length === 0">
          <div class="title">Create excluded emissions source</div>
          <Controls
            class="controls"
            v-on:add="createExcludedEmissionsSource()"
            v-on:remove="$store.state.app.report.excludedEmissionsSources.nodes.length > 0 ? deleteExcludedEmissionsSource() : ''"
          />
        </div>
        </div>
        <div
          v-for="(source, i) in $store.state.app.report.excludedEmissionsSources.nodes"
          :key="`n-${i}`"
          class="column"
          style="display: flex;
                flex-direction: column;
                align-items: flex-start;"
        >
        <Controls
          v-on:add="createExcludedEmissionsSource()"
          v-on:remove="deleteExcludedEmissionsSource(source)"
        />
          <div class="business-includes row">
            <div class="business-includes-title">
              Emissions source excluded
            </div>
            <input
              type="text"
              id="title"
              placeholder="excluded source"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              v-model.lazy.trim="source.title"
              v-on:change="updateExcludedEmissionsSource('title', source)"
            />
          </div>

          <div class="business-includes row">
            <div class="business-includes-title">Scope</div>
            <select
              type="text"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              v-model.lazy.trim="source.scope"
              v-on:change="updateExcludedEmissionsSource('status', source)"
            >
              <option :value="null">Please select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>

          <div class="business-includes row">
            <div class="business-includes-title">Reason for exclusion</div>
            <textarea
              name=""
              id="reasonForExclusion"
              cols="30"
              rows="2"
              placeholder="reason for exclusion"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              v-model.lazy.trim="source.reasonForExclusion"
              v-on:change="updateExcludedEmissionsSource('reasonForExclusion', source)"
            ></textarea>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
import Controls from '../../components/controls.vue'

export default {
  components: { Controls },
  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    async createExcludedEmissionsSource () {
      const data = {
        excludedEmissionsSource: {
          reportID: this.$store.state.app.report.id,
          title: '',
          scope: '',
          reasonForExclusion: ''
        }
      }
      // console.log(data.excludedEmissionsSource, 'creating excludedEmissionsSource')
      this.$root.saving = true
      await this.$store.dispatch('createExcludedEmissionsSource', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    async deleteExcludedEmissionsSource (source) {
      // let source = this.$store.state.app.report.excludedEmissionsSources.nodes[this.$store.state.app.report.excludedEmissionsSources.nodes.length - 1]
      const data = [source.id]
      this.$root.saving = true
      await this.$store.dispatch('deleteExcludedEmissionsSources', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    async updateExcludedEmissionsSource (fieldName, source) {
      const data = { excludedEmissionsSource: { id: source.id } }
      data.excludedEmissionsSource[fieldName] = source[fieldName]
      // console.log(data.excludedEmissionsSource, 'updating excludedEmissionsSource data')
      this.$root.saving = true
      await this.$store.dispatch('updateExcludedEmissionsSource', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    }
  }
}
</script>

<style></style>
