<template>
  <div class="calculation-results">
    <h1 class="main-heading">Emissions/Consumption</h1>
    <div class="calculation-totals">
      <div class="total-section shadow">
        <p class="total-label">Total Location Based Emissions</p>
        <div class="total-content">
          <p class="total-big">
            {{ $root.formatNumber(totals.emissions) }}
          </p>
          <i class="fad fa-chart-bar green"></i>
        </div>
      </div>
      <div class="total-section shadow">
        <p class="total-label">Total Market Based Emissions</p>
        <div class="total-content">
          <p class="total-big">
            {{ $root.formatNumber(totals.emissionsMR) }}
          </p>
          <i class="fad fa-chart-bar blue"></i>
        </div>
      </div>
      <div class="total-section shadow">
        <p class="total-label">Total Fuel Emissions</p>
        <div class="total-content">
          <div class="total-list">
            <div class="list-row">
              <p class="list-text">CO2e</p>
              <p class="list-text">
                {{ $root.formatNumber(fuels.tCO2e) }}
              </p>
            </div>
            <div class="list-row">
              <p class="list-text">Carbon Dioxide</p>
              <p class="list-text">
                {{
                  $root.formatNumber(fuels.CO2)
                }}
              </p>
            </div>
            <div class="list-row">
              <p class="list-text">Nitros Oxide</p>
              <p class="list-text">
                {{
                  $root.formatNumber(fuels.N2O)
                }}
              </p>
            </div>
          </div>
          <div class="total-list">
            <div class="list-row">
              <p class="list-text">Methane</p>
              <p class="list-text">
                {{ $root.formatNumber(fuels.CH4) }}
              </p>
            </div>
            <div class="list-row">
              <p class="list-text">Biogenic</p>
              <p class="list-text">
                {{ $root.formatNumber(fuels.biogenic) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import EmissionsMixin from '../mixins/emissionsMixin.vue'

export default {
  metaData () {
    return {
      title: `dCarbon — Calculation Results`,
      description: ''
    }
  },
  mixins: [EmissionsMixin],
  computed: {
    totals () {
      if (!this.$store.state.app.client) return { emissions: 0, emissionsMR: 0 }
      const emissionsSources = this.$store.state.app.client.period.nodes[0].emissionsSources.nodes
      const filteredEmissionsSources = emissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
      return this.getTotalsFromEmissionsSources(filteredEmissionsSources)
    },
    electricityConsumption () {
      var total = 0
      this.$root.allActiveSchemas.forEach(schema => {
        schema.emissionSources.forEach(emissionSource => {
          if (emissionSource.name.includes('Electricity') && emissionSource.type === 'default') {
            total += emissionSource.annualTotal
          }
        })
      })
      return total
    },
    fuels () {
      var tCO2e = 0
      var CO2 = 0
      var CH4 = 0
      var N2O = 0
      var biogenic = 0
      this.$root.allActiveSchemas.forEach(schema => {
        schema.emissionSources.forEach(emissionSource => {
          if (emissionSource.GHGScope === 1 && emissionSource.subcategory === 'Stationary Combustion' && emissionSource.id !== 'wood-pallet') {
            tCO2e += emissionSource.tCO2e
            CO2 += emissionSource.CO2
            CH4 += emissionSource.CH4
            N2O += emissionSource.N2O
          } else if (emissionSource.id === 'wood-pallet') {
            biogenic += emissionSource.tCO2e
          }
        })
      })
      return {
        tCO2e: tCO2e,
        CO2: CO2,
        CH4: CH4,
        N2O: N2O,
        biogenic: biogenic
      }
    }
  }
}

</script>

<style></style>
