<template>
  <div>
    <div class="emissions-reduction-performance" v-if="loaded">
      <h1 class="section-title activity-title">Emissions Reduction Performance</h1>
      <div v-html="comparisonAgainstBaseYear" />
    </div>
    <div v-else class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import emissionsMixin from '../../mixins/emissionsMixin.vue'
import yearComparisonMixin from '../../mixins/yearComparisonMixin.vue'
export default {
  mixins: [emissionsMixin, yearComparisonMixin],
  data () {
    return {
      currentYearFTE: 0,
      baseYearFTE: 0,
      previousYearFTE: 0,
      showFTE: true,
      loaded: false
    }
  },
  async beforeMount () {
    await this.getFTEValues()
    this.loaded = true
  },
  methods: {
    async getFTEValues () {
      this.currentYearFTE = this.$store.state.app.report.emissionIntensityMetricsGeneral.nodes.find(
        node => node.title === this.$store.state.app.reductionPlan.reductionTarget.organisationSpecificMetric
      )?.value || 0
      this.baseYearFTE = await this.getBaseYearFTE()
      this.previousYearFTE = await this.getPreviousYearFTE()
    },
    async getBaseYearFTE () {
      const baseYear = this.$store.state.app.client.baseYear?.year
      if (baseYear && this.$store.state.app.reductionPlan.reductionTarget.organisationSpecificMetric) {
        const res = await this.$store.dispatch('getReportFTE', { year: baseYear, metric: this.$store.state.app.reductionPlan.reductionTarget.organisationSpecificMetric })
        return res.readOneReport.emissionIntensityMetrics.nodes[0]?.value || 0
      } else {
        return 0
      }
    },
    async getPreviousYearFTE () {
      const previousYear = this.$store.state.app.client.previousYear?.year
      if (previousYear && this.$store.state.app.reductionPlan.reductionTarget.organisationSpecificMetric) {
        const res = await this.$store.dispatch('getReportFTE', { year: previousYear, metric: this.$store.state.app.reductionPlan.reductionTarget.organisationSpecificMetric })
        return res.readOneReport.emissionIntensityMetrics.nodes[0]?.value || 0
      } else {
        return 0
      }
    }
  },
  computed: {
    emissionsByScope () {
      const emissionsSources = this.$store.state.app.client.period.nodes[0].emissionsSources.nodes
      const filteredEmissionsSources = emissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
      return this.getEmissionsByScopeNew(filteredEmissionsSources)
    },
    emissionSources () {
      const emissionsSources = this.$store.state.app.client.period.nodes[0].emissionsSources.nodes
      // filter by emissionSource.type !== 'WTT' && emissionSource.type !== 'naturalGasTDL' && emissionSource.type !== 'electricityTDL' && emissionSource.rule !== 'hide-in-report' && emissionSource.rule !== 'hide'
      const filteredEmissionsSources = emissionsSources.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
      return this.getAllEmissionSources(filteredEmissionsSources)
    },
    marketRateExists () {
      return this.checkEmissionsSourcesForMarketRate(this.$root.allEmissionSources)
    }
  }
}
</script>
