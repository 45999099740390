<template>
  <div class="input-section">
    <h1 class="section-title activity-title">Emissions intensity metrics</h1>
    <div class="input-container-column" style="max-width: 500px">
      <div class="input-item" style="justify-content: space-between;" v-for="(gm, i) in $store.state.app.report.emissionIntensityMetricsGeneral.nodes" :key="`gm-${i}`">
        <label>{{ gm.title }}</label>
        <input type="number" step="0.01" class="intensity-metric-textarea" v-model.number.lazy="gm.value" v-on:change="updateEmissionIntensityMetric('value', gm)" v-on:focus="$root.setEditing()"
                v-on:blur="$root.unsetEditing()" />
      </div>
      <Controls
        class="controls"
        v-on:add="createEmissionIntensityMetric()"
        v-on:remove="deleteEmissionIntensityMetric()"
      />
      <div class="input-item" v-for="(om, i) in $store.state.app.report.emissionIntensityMetricsOther.nodes" :key="`om-${i}`">
        <input placeholder="Other xx" class="intensity-metric-textarea" v-model.lazy.trim="om.title" v-on:change="updateEmissionIntensityMetric('title', om)" v-on:focus="$root.setEditing()"
                v-on:blur="$root.unsetEditing()"/>
        <input type="number" step="0.01" class="intensity-metric-textarea" v-model.number="om.value" v-on:change="updateEmissionIntensityMetric('value', om)" v-on:focus="$root.setEditing()"
                v-on:blur="$root.unsetEditing()"/>
      </div>
    </div>
  </div>
</template>

<script>
import Controls from '../../components/controls.vue'

export default {
  components: { Controls },
  methods: {
    async createEmissionIntensityMetric () {
      const data = { emissionIntensityMetric: { reportID: this.$store.state.app.report.id, value: 0, title: 'Other Metric', type: 'Other' } }
      // console.log(data.emissionIntensityMetric, 'creating emissionIntensityMetric')
      this.$root.saving = true
      await this.$store.dispatch('createEmissionIntensityMetric', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    async deleteEmissionIntensityMetric () {
      let metric = this.$store.state.app.report.emissionIntensityMetricsOther.nodes[this.$store.state.app.report.emissionIntensityMetricsOther.nodes.length - 1]
      const data = [metric.id]
      this.$root.saving = true
      await this.$store.dispatch('deleteEmissionIntensityMetrics', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    async updateEmissionIntensityMetric (fieldName, metric) {
      let timer = setInterval(async () => {
        if (!this.$root.editing) {
          clearInterval(timer)
          const data = { emissionIntensityMetric: { id: metric.id } }
          data.emissionIntensityMetric[fieldName] = metric[fieldName]
          // console.log(data.emissionIntensityMetric, 'updating emissionIntensityMetric data')
          this.$root.saving = true
          await this.$store.dispatch('updateEmissionIntensityMetric', data)
          this.$root.saving = false
        }
      }, 1000)
    }
  }
}
</script>
