import { percentageChanged, renderCells } from '../../utils/utils'

export function renderPerformanceTable (performanceData, currentYear = false) {
  let cellCount = currentYear ? performanceData.length + 2 : performanceData.length + 1
  let headingData = ['Activities']
  performanceData.forEach((period) => {
    const marketRateExists = checkEmissionsSourcesForMarketRate(period.emissionsSources.nodes)
    // const dualReporting = marketRateExists ? '(market-based)' : '(location-based)'
    headingData.push(`${period.year}${marketRateExists ? ' *' : ''}`)
    // push the percentage change heading if period year is the last year
    if (currentYear && period.year === currentYear) {
      headingData.push('% change against base year')
    }
  })
  let uniqueActivities = getAllUniqueActivities(performanceData)
  let mainData = []
  uniqueActivities.forEach(activity => {
    mainData.push(activity)
    performanceData.forEach(period => {
      let activityData = period.emissionsByActivity.find(a => a.name === activity)
      mainData.push(activityData ? activityData.tCO2eMarketRate : 0)
      // push the percentage change heading if period year is the last year
      if (currentYear && period.year === currentYear) {
        mainData.push(activityData ? percentageChanged(getBaseYearEmissionsSource(performanceData, activity), activityData.tCO2eMarketRate) : '-')
      }
    })
  })

  // push total row
  mainData.push('Total')
  performanceData.forEach(period => {
    mainData.push(period.totals.emissionsMR)
    // push the percentage change heading if period year is the last year
    if (currentYear && period.year === currentYear) {
      mainData.push(percentageChanged(getBaseYearTotalEmissions(performanceData), period.totals.emissionsMR))
    }
  })

  let heading = renderCells(headingData, cellCount, true)
  let main = renderCells(mainData, cellCount)
  return `<table style="margin-bottom: 10px;">${heading}${main}</table><p style="font-weight: 600;">* Market-based emissions</>`
}

function checkEmissionsSourcesForMarketRate (emissionsSources) {
  let marketRateExists = false
  emissionsSources.forEach(es => {
    if (es.tCO2eMarketRate !== es.tCO2e) {
      marketRateExists = true
    }
  })
  return marketRateExists
}

function getAllUniqueActivities (performanceData) {
  let activities = []
  performanceData.forEach(period => {
    period.emissionsByActivity.forEach(activity => {
      if (!activities.includes(activity.name)) {
        activities.push(activity.name)
      }
    })
  })
  // order by base year emissions
  return activities.sort((a, b) => {
    let aTotal = 0
    let bTotal = 0
    performanceData.forEach(period => {
      period.emissionsByActivity.forEach(activity => {
        if (activity.name === a) {
          aTotal += activity.tCO2eMarketRate
        }
        if (activity.name === b) {
          bTotal += activity.tCO2eMarketRate
        }
      })
    })
    return bTotal - aTotal
  })
}

function getBaseYearEmissionsSource (performanceData, activity) {
  // find the base year emissions for the activity
  let baseYearPeriod = performanceData.find(period => period.isBaseYear)
  if (!baseYearPeriod) {
    return 0
  } else {
    let baseYearActivity = baseYearPeriod.emissionsByActivity.find(a => a.name === activity)
    return baseYearActivity ? baseYearActivity.tCO2eMarketRate : 0
  }
}

function getBaseYearTotalEmissions (performanceData) {
  let baseYearPeriod = performanceData.find(period => period.isBaseYear)
  return baseYearPeriod ? baseYearPeriod.totals.emissionsMR : 0
}
