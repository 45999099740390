<template>
  <div>
    <h1 class="section-title activity-title">
      Offsets
    </h1>
    <div v-if="!loading" class="carbon-credits">
      <div v-if="offsetsRequired" class="offsets">
        <Controls
          class="controls"
          v-on:add="createCancelledCarbonCredit()"
          v-on:remove="deleteCancelledCarbonCredit()"
        />
        <div v-for="cancelledCredit in $store.state.app.report.cancelledCarbonCredits.nodes" :key="cancelledCredit.id"
          class="input-section"
        >
        <cancelled-credit :cancelledCredit="cancelledCredit" @updateReportData="updateReportData"/>
        </div>
      </div>
      <div class="row" v-else>
        <label style="display: flex; margin: 10px 0 0 0">
          No offsets required for this inventory.
        </label>
      </div>
    </div>
    <div v-else class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
</div>
</template>

<script>
import Controls from '../../components/controls.vue'
import CancelledCredit from './CancelledCredit.vue'

export default {
  components: { Controls, CancelledCredit },
  props: {
    certificationType: {
      type: String,
      required: true
    },
    offsetsRequired: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      creditTypes: [],
      loading: false
    }
  },
  async beforeMount () {
    await this.$store.dispatch('loadCarbonCreditTypes')
    this.creditTypes = this.$store.state.app.carbonCreditTypes
    if (this.$store.state.app.report.cancelledCarbonCredits.nodes.length === 0) {
      await this.createCancelledCarbonCredit()
    }
  },
  methods: {
    updateReportData (fieldName) {
      this.$emit('updateReportData', fieldName, true)
    },
    async createCancelledCarbonCredit () {
      let currentCreditType = this.creditTypes.find(carbonCredit => carbonCredit.id === this.$store.state.app.report.carbonCreditTypeID)
      if (!currentCreditType) {
        currentCreditType = this.creditTypes[0]
      }
      let data = {}
      if (this.$store.state.app.report.customCreditOffsetAmount > 0 && currentCreditType) {
        data = {
          cancelledCarbonCredit: {
            reportID: this.$store.state.app.report.id,
            carbonCreditTypeID: currentCreditType.id,
            amount: this.$store.state.app.report.customCreditOffsetAmount
          }
        }
      } else {
        let none = this.creditTypes.find(carbonCredit => carbonCredit.title === 'None')
        data = {
          cancelledCarbonCredit: {
            reportID: this.$store.state.app.report.id,
            carbonCreditTypeID: none ? none.id : this.creditTypes[0].id,
            amount: 0
          }
        }
      }
      this.$root.saving = true
      await this.$store.dispatch('createCancelledCarbonCredit', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    async deleteCancelledCarbonCredit (deleteAll = false) {
      if (deleteAll || this.$store.state.app.report.cancelledCarbonCredits.nodes.length > 1) {
        let credit = this.$store.state.app.report.cancelledCarbonCredits.nodes[this.$store.state.app.report.cancelledCarbonCredits.nodes.length - 1]
        const data = [credit.id]
        this.$root.saving = true
        await this.$store.dispatch('deleteCancelledCarbonCredit', data)
        setTimeout(() => {
          this.$root.saving = false
        }, 2000)
      }
    },
    async deleteAllCancelledCarbonCredits () {
      // for (let index = 0; index < this.$store.state.app.report.cancelledCarbonCredits.nodes.length; index++) {
      //   await this.deleteCancelledCarbonCredit(true)
      // }
      for (const credit of this.$store.state.app.report.cancelledCarbonCredits.nodes) {
        const data = [credit.id]
        this.$root.saving = true
        await this.$store.dispatch('deleteCancelledCarbonCredit', data)
        setTimeout(() => {
          this.$root.saving = false
        }, 2000)
      }
      // await this.createCancelledCarbonCredit()
    }
  },
  getCreditDescription (id) {
    let currentType = this.creditTypes.find(
      type => type.id === id
    )
    return currentType.description || ''
  },
  computed: {
    currentCreditType () {
      let currentType = this.creditTypes.find(
        type => type.id === this.$store.state.app.report.carbonCreditTypeID
      )
      return currentType || ''
    },
    customCreditOffsetAmount () {
      return this.$store.state.app.report.customCreditOffsetAmount
    }
  },
  watch: {
    async certificationType (newValue, oldValue) {
      this.loading = true
      await this.deleteAllCancelledCarbonCredits()
      await this.createCancelledCarbonCredit()
      this.loading = false
    },
    async customCreditOffsetAmount (newValue, oldValue) {
      if (oldValue === 0 && this.certificationType === 'Carbon Conscious') {
        this.loading = true
        await this.deleteAllCancelledCarbonCredits()
        await this.createCancelledCarbonCredit()
        this.loading = false
      }
    }
  }
}
</script>

<style></style>
