<template>
  <div class="section waste-water-waste">
    <div class="activity-title-row">
      <h1 class="activity-title">Waste & Wastewater</h1>
      <i class="title-icon fad fa-trash"></i>
    </div>
    <LandfillWasteComponent
      title="Landfill Waste"
      schemaName="Landfill Waste"
      v-bind:initialSchema="schema[this.getSchemaIndex('Landfill Waste')]"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="landfill-waste"
    />
    <Notes
      :schemaIndex="0"
      :key="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>

    <WasteWaterTreatmentComponent
      title="Wastewater Treatement"
      unit="m3"
      schemaName="Waste Water Treatement"
      v-bind:initialSchema="
        schema[this.getSchemaIndex('Waste Water Treatement')]
      "
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="waste-water-treatment"
    />
    <Notes
      :schemaIndex="1"
      :key="1"
      :dataSource="schema[1].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[1].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[1].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
      <WasteWaterTreatmentComponent
      title="Waste Composting"
      unit="KG"
      schemaName="Waste Composting"
      v-bind:initialSchema="
        schema[this.getSchemaIndex('Waste Composting')]
      "
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="waste-composting"
    />
        <Notes
      :schemaIndex="2"
      :key="2"
      :dataSource="schema[2].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[2].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[2].assumptions"
      @assumptionsChanged="updateAssumptions"
    />

    <NextSectionButton
      :nextRouteSchema="$root.waste.schemas[1]"
      :nextRouteActivity="$root.waste.sections[1]"
      sectionName="waste"
    />
  </div>
</template>

<script>
import NextSectionButton from '../../../NextSectionButton.vue'
import Notes from '../../../Notes.vue'
import LandfillWasteComponent from './LandfillWasteComponent'
import WasteWaterTreatmentComponent from './WasteWaterTreatmentComponent'

export default {
  metaData () {
    return {
      title: `dCarbon — Wastewater`,
      description: ''
    }
  },
  name: 'activity-waste-water-waste',
  components: { LandfillWasteComponent, WasteWaterTreatmentComponent, Notes, NextSectionButton },
  props: { initialSchema: Object, schemaName: String, scope: String },

  data () {
    return {
      schema: {}
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    calculateTotal (schema, schemaName) {
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    }
  }
}
</script>
