<template>
  <div class="section additional-sources">
    <div class="activity-title-row">
      <h1 class="activity-title">Additional Sources</h1>

      <i class="title-icon fad fa-file-plus"></i>
    </div>
            <!-- <h2 class="secondary-title">Such as company vehicles, lawn mowers, forklifts, boats, aircrafts etc.</h2> -->
    <AdditionalSourcesComponent :key="schema.emissionSources.length" :initialSchema="schema" :scope="scope"/>
  </div>

</template>

<script>

import AdditionalSourcesComponent from './AdditionalSourcesComponent.vue'

export default {
  metaData () {
    return {
      title: `dCarbon — Additional Sources`,
      description: ''
    }
  },
  name: 'activity-additional-sources',
  // props: { initialSchema: Object, scope: String },
  components: { AdditionalSourcesComponent },
  data () {
    return {
      // schema: {},
      scope: 'no-scope'
    }
  },
  computed: {
    schema () {
      return this.$root.masterSchema.additionalSources
    }
  }
  // beforeMount () {
  //   this.schema = this.$root.getSchema(this.scope)
  // }
}
</script>
