const downstreamLeasedAssetsSchema = [
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Electricity Consumption of items Leased to a Third Party', // Title of Section on front end
    title: 'Electricity Consumption of items Leased to a Third Party',
    scope: 3, // scope number
    sectionID: 'downstream-leased-assets', // Default Schema Scope Section Name
    identifier: 'electricity-component', // Main Section component identifier for tooltips
    version: 2.77,
    annually: true,
    active: true,
    toggleName: 'enterBy',
    emissionSources: [
      {
        category:
          'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Downstream Leased Assets', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 13, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Downstream Electricity -', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'electricity',
        unit: 'KWH',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    units: [
      {
        title: 'Unit 1',
        months: [
          { name: 'January', amount: 0 },
          { name: 'February', amount: 0 },
          { name: 'March', amount: 0 },
          { name: 'April', amount: 0 },
          { name: 'May', amount: 0 },
          { name: 'June', amount: 0 },
          { name: 'July', amount: 0 },
          { name: 'August', amount: 0 },
          { name: 'September', amount: 0 },
          { name: 'October', amount: 0 },
          { name: 'November', amount: 0 },
          { name: 'December', amount: 0 }
        ],
        selectedCountry: 'New Zealand',
        country: 'New Zealand',
        marketBasedRate: 0,
        carbonNeutral: false,
        certifiedRenewable: false,
        annualTotal: 0,
        buildingPercentage: 100,
        total: 0
      }
    ],
    countries: [
      { name: 'Other', CO2e: 'electricityAverageKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Antigua', CO2e: 'electricityAntiguaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Argentina', CO2e: 'electricityArgentinaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Australia', CO2e: 'electricityAustraliaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAustraliaWTT', TDL: 'electricityAustraliaTDL' },
      { name: 'Austria', CO2e: 'electricityAustriaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAustriaWTT', TDL: 'electricityAustriaTDL' },
      { name: 'Barbuda', CO2e: 'electricityBarbudaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Belgium', CO2e: 'electricityBelgiumKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Benin', CO2e: 'electricityBeninKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Brazil', CO2e: 'electricityBrazilKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityBrazilWTT', TDL: 'electricityBrazilTDL' },
      { name: 'Bulgaria', CO2e: 'electricityBulgriaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityBulgriaWTT', TDL: 'electricityBulgriaWTT' },
      { name: 'Burkina Faso', CO2e: 'electricityBurkinaFasoKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Canada', CO2e: 'electricityCanadaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityCanadaWTT', TDL: 'electricityCanadaTDL' },
      { name: 'China', CO2e: 'electricityChinaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityChinaWTT', TDL: 'electricityChinaTDL' },
      { name: 'Colombia', CO2e: 'electricityColombiaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Côte d’Ivoire', CO2e: 'electricityCotedIvoireKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Croatia', CO2e: 'electricityCroatiaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityCroatiaWTT', TDL: 'electricityCroatiaTDL' },
      { name: 'Cyprus', CO2e: 'electricityCyprusKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityCyprusWTT', TDL: 'electricityCyprusTDL' },
      { name: 'Czech Republic', CO2e: 'electricityCzechRepublicKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityCzechRepublicWTT', TDL: 'electricityCzechRepublicTDL' },
      { name: 'Denmark', CO2e: 'electricityDenmarkKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityDenmarkWTT', TDL: 'electricityDenmarkTDL' },
      { name: 'Ecuador', CO2e: 'electricityEcuadorKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Egypt', CO2e: 'electricityAverageKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityEgyptWTT', TDL: 'electricityEgyptTDL' },
      { name: 'Estonia', CO2e: 'electricityEstoniaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityEstoniaWTT', TDL: 'electricityEstoniaTDL' },
      { name: 'Finland', CO2e: 'electricityFinlandKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityFinlandWTT', TDL: 'electricityFinlandTDL' },
      { name: 'Germany', CO2e: 'electricityGermanyKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityGermanyWTT', TDL: 'electricityGermanyTDL' },
      { name: 'Ghana', CO2e: 'electricityGhanaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Gibraltar', CO2e: 'electricityAverageKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityGibraltarWTT', TDL: 'electricityGibraltarTDL' },
      { name: 'Greece', CO2e: 'electricityGreeceKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityGreeceWTT', TDL: 'electricityGreeceTDL' },
      { name: 'Hong Kong', CO2e: 'electricityHongKongKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityHongKongWTT', TDL: 'electricityHongKongTDL' },
      { name: 'Hungary', CO2e: 'electricityHungaryKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityHungaryWTT', TDL: 'electricityHungaryTDL' },
      { name: 'Iceland', CO2e: 'electricityIcelandKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityIcelandWTT', TDL: 'electricityIcelandTDL' },
      { name: 'India', CO2e: 'electricityIndiaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityIndiaWTT', TDL: 'electricityIndiaTDL' },
      { name: 'Indonesia', CO2e: 'electricityIndonesiaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityIndonesiaWTT', TDL: 'electricityIndonesiaTDL' },
      { name: 'Ireland', CO2e: 'electricityIrelandKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityIrelandWTT', TDL: 'electricityIrelandTDL' },
      { name: 'Israel', CO2e: 'electricityAverageKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityIsraelWTT', TDL: 'electricityIsraelTDL' },
      { name: 'Italy', CO2e: 'electricityItalyKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityItalyWTT', TDL: 'electricityItalyTDL' },
      { name: 'Japan', CO2e: 'electricityJapanKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityJapanWTT', TDL: 'electricityJapanTDL' },
      { name: 'Korea (South)', CO2e: 'electricitySouthKoreaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricitySouthKoreaWTT', TDL: 'electricitySouthKoreaTDL' },
      { name: 'Latvia', CO2e: 'electricityLatviaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityLatviaWTT', TDL: 'electricityLatviaTDL' },
      { name: 'Lithuania', CO2e: 'electricityLithuaniaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityLithuaniaWTT', TDL: 'electricityLithuaniaTDL' },
      { name: 'Luxembourg', CO2e: 'electricityLuxembourgKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityLuxembourgWTT', TDL: 'electricityLuxembourgTDL' },
      { name: 'Malaysia', CO2e: 'electricityAverageKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityMalaysiaWTT', TDL: 'electricityMalaysiaTDL' },
      { name: 'Mali', CO2e: 'electricityMaliKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Malta', CO2e: 'electricityMaltaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityMaltaWTT', TDL: 'electricityMaltaTDL' },
      { name: 'Mexico', CO2e: 'electricityMexicoKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityMexicoWTT', TDL: 'electricityMexicoTDL' },
      { name: 'Netherlands', CO2e: 'electricityNetherlandsKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityNetherlandsWTT', TDL: 'electricityNetherlandsTDL' },
      { name: 'New Zealand', CO2e: 'electricityNewZealandKgCO2e', CO2: 'electricityNewZealandKgCO2', CH4: 'electricityNewZealandKgCH4', N2O: 'electricityNewZealandKgN2O', WTT: 'electricityNewZealandWTT', TDLCO2e: 'electricityNewZealandTDL', TDLCO2: 'electricityNewZealandTDLKgCO2', TDLCH4: 'electricityNewZealandTDLKgCH4', TDLN2O: 'electricityNewZealandTDLKgN2O' },
      { name: 'Niger', CO2e: 'electricityNigerKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Nigeria', CO2e: 'electricityNigeriaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Norway', CO2e: 'electricityNorwayKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityNorwayWTT', TDL: 'electricityNorwayTDL' },
      { name: 'Pakistan', CO2e: 'electricityAverageKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityPakistanWTT', TDL: 'electricityPakistanTDL' },
      { name: 'Philippines', CO2e: 'electricityAverageKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityPhilippinesWTT', TDL: 'electricityPhilippinesTDL' },
      { name: 'Poland', CO2e: 'electricityPolandKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityPolandWTT', TDL: 'electricityPolandTDL' },
      { name: 'Portugal', CO2e: 'electricityPortugalKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityPortugalWTT', TDL: 'electricityPortugalTDL' },
      { name: 'Republic of Kenya', CO2e: 'electricityKenyaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Romania', CO2e: 'electricityRomaniaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityRomaniaWTT', TDL: 'electricityRomaniaTDL' },
      { name: 'Russian Federation', CO2e: 'electricityRussiaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityRussiaWTT', TDL: 'electricityRussiaTDL' },
      { name: 'Saudi Arabia', CO2e: 'electricitySaudiArabiaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricitySaudiArabiaWTT', TDL: 'electricitySaudiArabiaTDL' },
      { name: 'Senegal', CO2e: 'electricitySenegalKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Serbia', CO2e: 'electricitySerbiaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Singapore', CO2e: 'electricitySingaporeKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricitySingaporeWTT', TDL: 'electricitySingaporeTDL' },
      { name: 'Slovakia', CO2e: 'electricitySlovakiaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricitySlovakiaWTT', TDL: 'electricitySlovakiaTDL' },
      { name: 'Slovenia', CO2e: 'electricityAverageKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'South Africa', CO2e: 'electricitySouthAfricaKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricitySouthAfricaWTT', TDL: 'electricitySouthAfricaTDL' },
      { name: 'Spain', CO2e: 'electricitySpainKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricitySpainWTT', TDL: 'electricitySpainTDL' },
      { name: 'Sweden', CO2e: 'electricitySwedenKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricitySwedenWTT', TDL: 'electricitySwedenTDL' },
      { name: 'Switzerland', CO2e: 'electricitySwitzerlandKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricitySwitzerlandWTT', TDL: 'electricitySwitzerlandTDL' },
      { name: 'Taiwan', CO2e: 'electricityTaiwanKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityTaiwanWTT', TDL: 'electricityTaiwanTDL' },
      { name: 'Thailand', CO2e: 'electricityThailandKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityThailandWTT', TDL: 'electricityThailandTDL' },
      { name: 'Togo', CO2e: 'electricityTogoKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' },
      { name: 'Turkey', CO2e: 'electricityTurkeyKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityTurkeyWTT', TDL: 'electricityTurkeyTDL' },
      { name: 'Ukraine', CO2e: 'electricityAverageKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityUkraineWTT', TDL: 'electricityUkraineTDL' },
      { name: 'United Arab Emirates', CO2e: 'electricityUnitedArabEmiratesKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityUnitedArabEmiratesWTT', TDL: 'electricityUnitedArabEmiratesTDL' },
      { name: 'United Kingdom', CO2e: 'electricityUnitedKingdomKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityUnitedKingdomWTT', TDL: 'electricityUnitedKingdomTDL' },
      { name: 'United States', CO2e: 'electricityUnitedStatesKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityUnitedStatesWTT', TDL: 'electricityUnitedStatesTDL' },
      { name: 'Vietnam', CO2e: 'electricityVietnamKgCO2e', CO2: null, CH4: null, N2O: null, WTT: 'electricityAverageWTT', TDL: 'electricityAverageTDL' }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Stationary Fuels', // Title of Section on front end,
    title: 'Downstream Leased Assets - Stationary Fuels', // Title of Section on front end
    scope: 3, // scope number
    sectionID: 'downstream-leased-assets', // Default Schema Scope Section Name
    identifier: 'stationary-fuels', // Main Section component identifier for tooltips
    version: 2.75,
    active: true,
    toggleName: 'enterBy',
    emissionSources: [
      {
        category:
          'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Downstream Leased Assets', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 13, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Downstream Leased Assets - Stationary Fuels Petrol', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'petrol',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category:
          'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Downstream Leased Assets', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 13, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Downstream Leased Assets - Stationary Fuels Diesel', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'diesel',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category:
          'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Downstream Leased Assets', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 13, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Downstream Leased Assets - Stationary Fuels Natural Gas', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'natural-gas',
        unit: 'KWH',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category:
          'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Downstream Leased Assets', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 13, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Downstream Leased Assets - Stationary Fuels LPG', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'lpg',
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'petrol',
        tCO2eVariableName: 'kgCO2ePetrol',
        CO2VariableName: 'kgCO2PerUnitPetrol',
        N2OVariableName: 'kgN2OPerUnitPetrol',
        CH4VariableName: 'kgCH4PerUnitPetrol'
      },
      {
        type: 'diesel',
        tCO2eVariableName: 'kgCO2eFuelsDiesel',
        CO2VariableName: 'kgCO2PerUnitPetrol',
        N2OVariableName: 'kgN2OPerUnitPetrol',
        CH4VariableName: 'kgCH4PerUnitPetrol'
      },
      {
        type: 'natural-gas',
        tCO2eVariableName: 'kgCO2eNaturalGas',
        CO2VariableName: 'kgCO2PerUnitNaturalGas',
        N2OVariableName: 'kgN2OPerUnitNaturalGas',
        CH4VariableName: 'kgCH4PerUnitNaturalGas'
      },
      {
        type: 'lpg',
        tCO2eVariableName: 'kgCO2eLPG',
        CO2VariableName: 'kgCO2PerUnitLPG',
        N2OVariableName: 'kgN2OPerUnitLPG',
        CH4VariableName: 'kgCH4PerUnitLPG'
      }
    ],
    type: 'stationary',
    petrol: 0,
    diesel: 0,
    naturalGas: 0,
    LPG: 0,
    annualTotal: 0,
    carbonEmissionsPetrol: 0,
    carbonEmissionsDiesel: 0,
    carbonEmissionsNaturalGas: 0,
    carbonEmissionsLPG: 0,
    carbonEmissions: 0
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Mobile Fuels', // Title of Section on front end
    title: 'Downstream Leased Assets - Mobile Fuels', // Title of Section on front end
    scope: 3, // scope number
    sectionID: 'downstream-leased-assets', // Default Schema Scope Section Name
    identifier: 'mobile-fuels', // Main Section component identifier for tooltips
    version: 2.75,
    active: true,
    toggleName: 'enterBy',
    emissionSources: [
      {
        category:
          'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Downstream Leased Assets', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 13, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Downstream Leased Assets - Mobile Fuels Petrol', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'petrol',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category:
          'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Downstream Leased Assets', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 13, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Downstream Leased Assets - Mobile Fuels Diesel', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'diesel',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'petrol',
        tCO2eVariableName: 'kgCO2ePetrol',
        CO2VariableName: 'kgCO2PerUnitPetrol',
        N2OVariableName: 'kgN2OPerUnitPetrol',
        CH4VariableName: 'kgCH4PerUnitPetrol'
      },
      {
        type: 'diesel',
        tCO2eVariableName: 'kgCO2eDieselCompanyVehicles',
        CO2VariableName: 'kgCO2PerUnitDieselCompanyVehicles',
        N2OVariableName: 'kgn2OPerUnitDieselCompanyVehicles',
        CH4VariableName: 'kgCH4PerUnitDieselCompanyVehicles'
      }
    ],
    type: 'mobile',
    petrol: 0,
    diesel: 0,
    naturalGas: 0,
    LPG: 0,
    annualTotal: 0,
    carbonEmissionsPetrol: 0,
    carbonEmissionsDiesel: 0,
    carbonEmissions: 0
  }
]
export default downstreamLeasedAssetsSchema
