<template>
  <div class="input-container-column">
      <div class="input-section">
        <div class="input-container-row">
          <label>Define your organisation specific metric (emissions intensity denominator)</label>
          <select type="text" :placeholder="'i.e. FTE, revenue $Mil, production tonnes etc'"
            v-model="$store.state.app.reductionPlan.reductionTarget.organisationSpecificMetric"
            @change="updateReductionTarget('organisationSpecificMetric', $store.state.app.reductionPlan.reductionTarget.organisationSpecificMetric)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()">
            <option :value="null">Please select</option>
            <option v-for="metric in metrics" :value="metric.title" :key="metric.id">{{ metric.title }}</option>
          </select>
        </div>
        <div class="input-container-row">
          <label>Define your short term target time frame</label>
          <input type="text" :placeholder="`i.e ${parseInt($store.state.app.selectedYear) + 2}`"
            :value="$store.state.app.reductionPlan.reductionTarget.shortTermTimeFrame !== 0 ? $store.state.app.reductionPlan.reductionTarget.shortTermTimeFrame : ''"
            @change="updateReductionTarget('shortTermTimeFrame', parseInt($event.target.value))" v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()" />
        </div>
        <div class="input-container-row">
          <label>Define your medium term target time frame</label>
          <input type="text" :placeholder="`i.e ${parseInt($store.state.app.selectedYear) + 5}`"
            :value="$store.state.app.reductionPlan.reductionTarget.mediumTermTimeFrame !== 0 ? $store.state.app.reductionPlan.reductionTarget.mediumTermTimeFrame : ''"
            @change="updateReductionTarget('mediumTermTimeFrame', parseInt($event.target.value))" v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()" />
        </div>
        <div class="input-container-row">
          <label>Define your long term target time frame</label>
          <input type="text" :placeholder="`i.e ${parseInt($store.state.app.selectedYear) + 10}`"
            :value="$store.state.app.reductionPlan.reductionTarget.longTermTimeFrame !== 0 ? $store.state.app.reductionPlan.reductionTarget.longTermTimeFrame : ''"
            @change="updateReductionTarget('longTermTimeFrame', parseInt($event.target.value))" v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()" />
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      metrics: []
    }
  },
  async beforeMount () {
    await this.getEmissionIntensityMetrics()
  },
  methods: {
    async updateReductionTarget (field, value) {
      const data = { reductionTarget: { id: this.$store.state.app.reductionPlan.reductionTarget.id } }
      data.reductionTarget[field] = value
      // console.log(data.otherEmissions, 'updating otherEmissions data')
      this.$root.saving = true
      const res = await this.$store.dispatch('updateReductionTarget', data)
      this.$store.state.app.reductionPlan.reductionTarget = res.data.updateReductionTarget
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    async getEmissionIntensityMetrics () {
      const res = await this.$store.dispatch('getEmissionIntensityMetrics', this.$store.state.app.client.baseYear?.year || this.$store.state.app.selectedYear)
      this.metrics = res.readOneReport.emissionIntensityMetrics.nodes
    }
  }
}
</script>
