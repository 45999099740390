const emissionSources = [
  {
    category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Mobile Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Mobile Combustion - Aviation Gas', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'default', // default, naturalGasTDL, electricityTDL, WTT
    id: 'Aviation Gas',
    unit: 'L',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  },
  {
    category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Mobile Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Mobile Combustion - Aviation Fuel Kerosene', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'default', // default, naturalGasTDL, electricityTDL, WTT
    id: 'Aviation Fuel Kerosene',
    unit: 'L',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  }
]

const emissionSourcesWTT = [
  {
    category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Mobile Combustion - Aviation Gas WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
    id: 'Aviation Gas WTT',
    unit: 'L',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  },
  {
    category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
    GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
    GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
    ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
    name: 'Mobile Combustion - Aviation Fuel Kerosene WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
    type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
    id: 'Aviation Fuel Kerosene WTT',
    unit: 'L',
    annualTotal: 0,
    tCO2e: 0,
    CO2: 0,
    CH4: 0,
    N2O: 0,
    emissionSourceInfo: {
      dataSource: '',
      dataQuality: 'Please select',
      assumptions: ''
    }
  }
]

const extraCalculations = [
  {
    type: 'Aviation Gas WTT',
    variableName: 'aviationGasWTT' // Main getWellToTankEmissions() variable Name
  },
  {
    type: 'Aviation Fuel Kerosene WTT',
    variableName: 'aviationFuelKeroseneWTT' // Main getWellToTankEmissions() variable Name
  }
]

const aviationGas = {
  emissionsOfCO2e: {
    variableName: 'aviationGasKgCO2e',
    total: 0
  },
  carbonDioxideEmissions: {
    variableName: 'aviationGasKgCO2',
    total: 0
  },
  nitrosOxideEmissions: {
    variableName: 'aviationGasKgN2O',
    total: 0
  },
  methaneEmissions: {
    variableName: 'aviationGasKgCH4',
    total: 0
  }
}

const aviationFuelKerosene = {
  emissionsOfCO2e: {
    variableName: 'aviationFuelKeroseneKgCO2e',
    total: 0
  },
  carbonDioxideEmissions: {
    variableName: 'aviationFuelKeroseneKgCO2',
    total: 0
  },
  nitrosOxideEmissions: {
    variableName: 'aviationFuelKeroseneKgN2O',
    total: 0
  },
  methaneEmissions: {
    variableName: 'aviationFuelKeroseneKgCH4',
    total: 0
  }
}

export default { emissionSources, emissionSourcesWTT, extraCalculations, aviationGas, aviationFuelKerosene }
