<template>
  <div class="section end-of-life-disposal waste-water-waste">
    <div class="activity-title-row">
      <h1 class="activity-title">EOL Disposal Of Sold Goods</h1>
      <i class="title-icon fad fa-trash"></i>
    </div>
    <div
      class="controls-container"
      :style="{ 'flex-direction': 'row-reverse' }"
    >
      <div class="toggle-btn-container">
        <div
          class="toggle-btn"
          :class="nzActive ? 'active' : 'inactive'"
          @click="updateActiveStatus('Disposal in NZ')"
        >
          <p class="btn-text">Disposal in NZ</p>
        </div>
        <div
          class="toggle-btn"
          :class="overseasActive ? 'active' : 'inactive'"
          @click="updateActiveStatus('Disposal Overseas')"
        >
          <p class="btn-text">Disposal Overseas</p>
        </div>
      </div>
    </div>
    <PurchasedGSCapitialComponent
      v-show="this.schema[this.getSchemaIndex('Disposal in NZ')].active"
      title="Disposal in NZ"
      v-bind:initialSchema="schema[getSchemaIndex('Disposal in NZ')]"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="disposal-in-nz"
      schemaName="Disposal in NZ"
    />

    <PurchasedGSCapitialComponent
      v-show="this.schema[this.getSchemaIndex('Disposal Overseas')].active"
      title="Disposal Overseas"
      v-bind:initialSchema="schema[getSchemaIndex('Disposal Overseas')]"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="disposal-overseas"
      schemaName="Disposal Overseas" />

    <LandfillWasteComponent
      title="Landfill Waste"
      v-bind:initialSchema="schema[this.getSchemaIndex('Landfill Waste')]"
      schemaName="Landfill Waste"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="landfill-component" />
    <!-- <Notes :showNext="false" :notes="schema.notes" v-model="schema.notes" /> -->
    <Notes
      :schemaIndex="0"
      :key="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions" />
    <WasteWaterTreatmentComponent
      title="Waste Composting"
      unit="KG"
      schemaName="Waste Composting"
      v-bind:initialSchema="schema[this.getSchemaIndex('Waste Composting')]"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="waste-composting" />
    <Notes
      :schemaIndex="1"
      :key="1"
      :dataSource="schema[1].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[1].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[1].assumptions"
      @assumptionsChanged="updateAssumptions" />
  </div>
</template>

<script>
import Notes from '../../../Notes.vue'
import LandfillWasteComponent from '../wasteWaterWaste/LandfillWasteComponent'
import WasteWaterTreatmentComponent from '../wasteWaterWaste/WasteWaterTreatmentComponent'
import PurchasedGSCapitialComponent from '../purchasedGSCapitial/PurchasedGSCapitialComponent.vue'

export default {
  metaData () {
    return {
      title: `dCarbon — End of Life Disposal`,
      description: ''
    }
  },
  name: 'activity-end-of-life-disposal',
  components: { LandfillWasteComponent, WasteWaterTreatmentComponent, PurchasedGSCapitialComponent, Notes },
  props: { initialSchema: Object, scope: String },

  data () {
    return {
      schema: {}
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    calculateTotal (schema, schemaName) {
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    },
    updateActiveStatus (name) {
      if (name === 'Disposal in NZ') {
        this.schema[this.getSchemaIndex('Disposal in NZ')].active = true
        this.schema[this.getSchemaIndex('Disposal Overseas')].active = false
      } else {
        this.schema[this.getSchemaIndex('Disposal in NZ')].active = false
        this.schema[this.getSchemaIndex('Disposal Overseas')].active = true
      }
    }
  }
}
</script>
