const EOLDisposalNZ = {
  dataSource: '',
  dataQuality: '',
  assumptions: '',
  name: 'Disposal in NZ', // Title of Section on front end
  title: 'End of Life Disposal - Disposal in NZ',
  scope: 3, // scope number
  sectionID: 'end-of-life-disposal', // Default Schema Scope Section Name
  identifier: 'disposal-in-nz', // Main Section component identifier for tooltips
  version: 2.75,
  annually: true,
  active: true,
  emissionSources: [
    {
      category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
      name: 'Food (NZ) KG', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'default', // default, naturalGasTDL, electricityTDL, WTT
      unit: 'KG',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    },
    {
      category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
      name: 'Garden (NZ) KG', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'default', // default, naturalGasTDL, electricityTDL, WTT
      unit: 'KG',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    },
    {
      category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
      name: 'Paper (NZ) KG', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'default', // default, naturalGasTDL, electricityTDL, WTT
      unit: 'KG',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    },
    {
      category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
      name: 'Wood (NZ) KG', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'default', // default, naturalGasTDL, electricityTDL, WTT
      unit: 'KG',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    },
    {
      category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
      name: 'Textil (NZ) KG', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'default', // default, naturalGasTDL, electricityTDL, WTT
      unit: 'KG',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    }
  ],
  activities: [
    {
      name: 'Food (NZ) KG',
      unit: 'KG',
      variableName: 'FoodNZkgCO2e',
      variableNameCH4: 'FoodNZkgCH4',
      annualTotal: 0.0,
      carbonEmissions: 0.0
    },
    {
      name: 'Garden (NZ) KG',
      unit: 'KG',
      variableName: 'GardenNZkgCO2e',
      variableNameCH4: 'GardenNZkgCH4',
      annualTotal: 0.0,
      carbonEmissions: 0.0
    },
    {
      name: 'Paper (NZ) KG',
      unit: 'KG',
      variableName: 'PaperNZkgCO2e',
      variableNameCH4: 'PaperNZkgCH4',
      annualTotal: 0.0,
      carbonEmissions: 0.0
    },
    {
      name: 'Wood (NZ) KG',
      unit: 'KG',
      variableName: 'WoodNZkgCO2e',
      variableNameCH4: 'WoodNZkgCH4',
      annualTotal: 0.0,
      carbonEmissions: 0.0
    },
    {
      name: 'Textil (NZ) KG',
      unit: 'KG',
      variableName: 'TextilNZkgCO2e',
      variableNameCH4: 'TextilNZkgCH4',
      annualTotal: 0.0,
      carbonEmissions: 0.0
    }
  ]
}
const EOLDisposalOverseas = {
  dataSource: '',
  dataQuality: '',
  assumptions: '',
  name: 'Disposal Overseas', // Title of Section on front end
  title: 'End of Life Disposal - Disposal Overseas',
  scope: 3, // scope number
  sectionID: 'end-of-life-disposal', // Default Schema Scope Section Name
  identifier: 'disposal-overseas', // Main Section component identifier for tooltips
  version: 2.75,
  annually: true,
  active: false,
  emissionSources: [
    {
      category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
      name: 'Wood (recycled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'default', // default, naturalGasTDL, electricityTDL, WTT
      // id ??
      unit: 'Tonnes',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    },
    {
      category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
      name: 'Wood (landfilled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'default', // default, naturalGasTDL, electricityTDL, WTT
      // id ??
      unit: 'Tonnes',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    },
    {
      category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
      name: 'Glass (recycled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'default', // default, naturalGasTDL, electricityTDL, WTT
      // id ??
      unit: 'Tonnes',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    },
    {
      category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
      name: 'Glass (landfilled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'default', // default, naturalGasTDL, electricityTDL, WTT
      // id ??
      unit: 'Tonnes',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    },
    {
      category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
      name: 'Plastic (recycled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'default', // default, naturalGasTDL, electricityTDL, WTT
      // id ??
      unit: 'Tonnes',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    },
    {
      category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
      name: 'Plastic (landfilled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'default', // default, naturalGasTDL, electricityTDL, WTT
      // id ??
      unit: 'Tonnes',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    },
    {
      category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
      name: 'Paper (recycled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'default', // default, naturalGasTDL, electricityTDL, WTT
      // id ??
      unit: 'Tonnes',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    },
    {
      category: 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      subcategory: 'EOL of Sold Goods', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
      GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
      GHGSubcategory: 12, // Customer Data Collection Sheet BLANK Nov2021
      ISOCategory: 5, // Customer Data Collection Sheet BLANK Nov2021
      name: 'Paper (landfilled overseas) - Tonnes', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
      type: 'default', // default, naturalGasTDL, electricityTDL, WTT
      // id ??
      unit: 'Tonnes',
      annualTotal: 0,
      tCO2e: 0,
      CO2: 0,
      CH4: 0,
      N2O: 0,
      emissionSourceInfo: {
        dataSource: '',
        dataQuality: 'Please select',
        assumptions: ''
      }
    }
  ],
  activities: [
    {
      name: 'Wood (recycled overseas) - Tonnes',
      unit: 'Tonnes',
      variableName: 'WoodRecycledOverseaskgCO2e',
      annualTotal: 0.0,
      carbonEmissions: 0.0
    },
    {
      name: 'Wood (landfilled overseas) - Tonnes',
      unit: 'Tonnes',
      variableName: 'WoodLandfilledOverseaskgCO2e',
      annualTotal: 0.0,
      carbonEmissions: 0.0
    },
    {
      name: 'Glass (recycled overseas) - Tonnes',
      unit: 'Tonnes',
      variableName: 'GlassRecycledOverseaskgCO2e',
      annualTotal: 0.0,
      carbonEmissions: 0.0
    },
    {
      name: 'Glass (landfilled overseas) - Tonnes',
      unit: 'Tonnes',
      variableName: 'GlassLandfilledOverseaskgCO2e',
      annualTotal: 0.0,
      carbonEmissions: 0.0
    },
    {
      name: 'Plastic (recycled overseas) - Tonnes',
      unit: 'Tonnes',
      variableName: 'PlasticRecycledOverseaskgCO2e',
      annualTotal: 0.0,
      carbonEmissions: 0.0
    },
    {
      name: 'Plastic (landfilled overseas) - Tonnes',
      unit: 'Tonnes',
      variableName: 'PlasticLandfilledOverseaskgCO2e',
      annualTotal: 0.0,
      carbonEmissions: 0.0
    },
    {
      name: 'Paper (recycled overseas) - Tonnes',
      unit: 'Tonnes',
      variableName: 'PaperRecycledOverseaskgCO2e',
      annualTotal: 0.0,
      carbonEmissions: 0.0
    },
    {
      name: 'Paper (landfilled overseas) - Tonnes',
      unit: 'Tonnes',
      variableName: 'PaperLandfilledOverseaskgCO2e',
      annualTotal: 0.0,
      carbonEmissions: 0.0
    }
  ]
}

export default { EOLDisposalNZ, EOLDisposalOverseas }
