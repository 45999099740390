<template>
  <div class="toolbar shadow">
    <div class="toolbar-inner">
      <img class="toolbar-logo" src="@/assets/images/dCarbonLogo.png" />
      <div class="toolbar-status">
        <div class="status" v-if="$root.editing">
          <div class="icon-fad editing"></div>
        </div>
        <div class="status" v-if="$root.saving">
          <div class="icon-fad saving"></div>
        </div>

        <template v-if="$store.state.memberSessions.otherEditors.length">
          <div
            class="editor-bubble"
            v-for="editor in $store.state.memberSessions.otherEditors"
            :key="editor.memberID"
          >
            <p :title="editor.member.firstName + ' ' + editor.member.surname">{{ editor.member.firstName[0] + editor.member.surname[0] }}</p>
          </div>
        </template>
      </div>
      <div class="toolbar-content">
        <div v-if="$store.state.user.clientID && $store.state.user.organisationID" class="main-toolbar-content">
          <div class="row">
            <p
              class="client-name"
            >
              <span v-if="$store.state.app.client"><i class="fad fa-building" style="margin-right: 5px"></i> {{ $store.state.app.client.title }}</span>
            </p>
            <BUDropdown @onBUChange="$emit('onBUChange', $event)" :showBUMenu="showBUMenu"/>
          </div>
          <MonthDropdown @onMonthChange="$emit('onMonthChange', $event)" />
          <YearDropdown @onYearChange="$emit('onYearChange', $event)" />
        </div>
        <div class="row menu account-menu" @click="showUserMenu = !showUserMenu">
          <p class="image pointer"><i class="fas fa-user"></i></p>
          <div v-if="showUserMenu" class="user-menu">
            <div class="menu-item">
              {{ $store.state.user.firstName }}
            </div>

            <div v-if="$store.state.user.groups.includes('administrators')" class="menu-item pointer">
              <a :href="adminURL" target="_blank">Admin area</a>
            </div>

            <div class="menu-item pointer link" @click="logout">
              Logout
              <i class="fad fa-sign-out"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MonthDropdown from './MonthDropdown.vue'
import YearDropdown from './YearDropdown.vue'
import BUDropdown from './BUDropdown.vue'
export default {
  components: { YearDropdown, MonthDropdown, BUDropdown },
  data () {
    return {
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      showMonthMenu: false,
      showYearMenu: false,
      showUserMenu: false,
      showBUMenu: false,
      adminURL: process.env.VUE_APP_APIURL + 'admin'
    }
  },
  created () {
    window.addEventListener('click', (e) => {
      // close user-menu when clicked outside
      if (this.showUserMenu && !document.querySelector('.account-menu').contains(e.target)) this.showUserMenu = false
      if (this.showYearMenu && !document.querySelector('.year-menu').contains(e.target)) this.showYearMenu = false
    })
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style></style>
