<template>
  <div>
    <div class="input-item long">
      <label
        for="CO2"
        class="input-title">CO2:</label>
      <input
        :disabled="$root.userDetails.organisationStatus === 'Completed'"
        :min="0"
        type="number" step="0.01"
        name="name"
        v-model.number="source.CO2"
        v-on:change="() => {
          source.CO2 = source.CO2 || 0;
          $parent.saveSources()
        }"
        v-on:focus="$root.setEditing()"
        v-on:blur="$root.unsetEditing()" />
    </div>

    <div class="input-item long">
      <label
        for="CH4"
        class="input-title">CH4:</label>
      <input
        :disabled="$root.userDetails.organisationStatus === 'Completed'"
        :min="0"
        type="number" step="0.01"
        name="name"
        v-model.number="source.CH4"
        v-on:change="() => {
          source.CH4 = source.CH4 || 0;
          $parent.saveSources()
        }"
        v-on:focus="$root.setEditing()"
        v-on:blur="$root.unsetEditing()" />
    </div>

    <div class="input-item long">
      <label
        for="N2O"
        class="input-title">N2O:</label>
      <input
        :disabled="$root.userDetails.organisationStatus === 'Completed'"
        :min="0"
        type="number" step="0.01"
        name="name"
        v-model.number="source.N2O"
        v-on:change="() => {
          source.N2O = source.N2O || 0;
          $parent.saveSources()
        }"
        v-on:focus="$root.setEditing()"
        v-on:blur="$root.unsetEditing()" />
    </div>

    <div v-if="'HFC' in source" class="input-item long">
      <label
        for="HFC"
        class="input-title">HFC:</label>
      <input
        :disabled="$root.userDetails.organisationStatus === 'Completed'"
        :min="0"
        type="number" step="0.01"
        name="name"
        v-model.number="source.HFC"
        v-on:change="() => {
          source.HFC = source.HFC || 0;
          $parent.saveSources()
        }"
        v-on:focus="$root.setEditing()"
        v-on:blur="$root.unsetEditing()" />
    </div>

    <div v-if="'PFC' in source" class="input-item long">
      <label
        for="PFC"
        class="input-title">PFC:</label>
      <input
        :disabled="$root.userDetails.organisationStatus === 'Completed'"
        :min="0"
        type="number" step="0.01"
        name="name"
        v-model.number="source.PFC"
        v-on:change="() => {
          source.PFC = source.PFC || 0;
          $parent.saveSources()
        }"
        v-on:focus="$root.setEditing()"
        v-on:blur="$root.unsetEditing()" />
    </div>

    <div v-if="'SF6' in source" class="input-item long">
      <label
        for="SF6"
        class="input-title">SF6:</label>
      <input
        :disabled="$root.userDetails.organisationStatus === 'Completed'"
        :min="0"
        type="number" step="0.01"
        name="name"
        v-model.number="source.SF6"
        v-on:change="() => {
          source.SF6 = source.SF6 || 0;
          $parent.saveSources()
        }"
        v-on:focus="$root.setEditing()"
        v-on:blur="$root.unsetEditing()" />
    </div>

  </div>
</template>

<script>
export default {
  name: 'additional-ghg-inputs',
  props: { source: Object }
}
</script>
