const variables = {
  electricity: {
    tCO2e: 'electricityNewZealandKgCO2e',
    CO2: 'electricityNewZealandKgCO2',
    N2O: 'electricityNewZealandKgN2O',
    CH4: 'electricityNewZealandKgCH4',
    TDLCO2e: 'electricityNewZealandTDL',
    TDLCO2: 'electricityNewZealandTDLKgCO2',
    TDLCH4: 'electricityNewZealandTDLKgCH4',
    TDLN2O: 'electricityNewZealandTDLKgN2O',
    WTT: 'electricityNewZealandWTT',
    unit: 'kWh',
    label: 'Electricity'
  },
  naturalGas: {
    tCO2e: 'kgCO2eNaturalGas',
    N2O: 'kgN2OPerUnitNaturalGas',
    CH4: 'kgCH4PerUnitNaturalGas',
    CO2: 'kgCO2PerUnitNaturalGas',
    WTT: 'naturalGasWTT',
    TDLCO2e: 'naturalGasTDLkWhCO2e',
    TDLCH4: 'naturalGasTDLkWhCH4',
    unit: 'kWh',
    label: 'Natural Gas'
  },
  diesel: {
    tCO2e: 'kgCO2eDieselCompanyVehicles',
    N2O: 'kgn2OPerUnitDieselCompanyVehicles',
    CH4: 'kgCH4PerUnitDieselCompanyVehicles',
    CO2: 'kgCO2PerUnitDieselCompanyVehicles',
    WTT: 'dieselWTT',
    unit: 'L',
    label: 'Diesel'
  },
  petrol: {
    tCO2e: 'kgCO2ePetrol',
    N2O: 'kgN2OPerUnitPetrol',
    CH4: 'kgCH4PerUnitPetrol',
    CO2: 'kgCO2PerUnitPetrol',
    WTT: 'petrolWTT',
    unit: 'L',
    label: 'Petrol'
  }
}

export default { variables }
