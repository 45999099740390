<template>
  <div class="emissions-report">
    <div class="btn-wrapper">
    </div>
    <div id="emissions-report">
      <ReportCoverPage type='emissions' />
      <TableOfContents :pages="pages" />
      <div class="spacer"></div>
      <EmissionsReportOutput
        :pages="pages"
        :methodologyDiagram="methodologyDiagram"
        :emissionsByScope="emissionsByScope"
        :emissionSources="emissionSources"
        :carbonPrice="carbonPrice"
        :overflowData="overflowData" />
    </div>
    <div class="btn-wrapper">
      <div class="submit-btn" @click="downloadReport()">
        <div class="btn-text">
          Download
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportCoverPage from './ReportCoverPage.vue'
import TableOfContents from './TableOfContents.vue'
import EmissionsReportOutput from './EmissionsReportOutput.vue'
// import axios from '@/axios'
export default {
  props: {
    pages: Array,
    methodologyDiagram: String,
    emissionsByScope: Object,
    emissionSources: Array,
    carbonPrice: Number,
    overflowData: Object
  },
  components: {
    ReportCoverPage,
    TableOfContents,
    EmissionsReportOutput
  },
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  methods: {
    downloadReport () {
      window.print()
    }
  }
}
</script>
