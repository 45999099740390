<template>
  <div class="input-section">
    <!-- Modal start -->
    <div class="tooltip">
      <h1 class="section-title">{{ title }}</h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <!-- Modal end -->
    <!-- v-show="!schema.annually" -->
    <Controls
      v-if="$root.userDetails.organisationStatus !== 'Completed'"
      v-on:add="addService()"
      v-on:remove="removeService()"
      v-on:update="calculateCarbonEmissions()"
    />
    <!-- class="input-container-row tight" -->
    <div
      class="input-container-column"
      v-for="(service, index) in schema.services"
      :key="`service-${index}`"
    >
      <div
        v-if="percentage"
        class="input-item stacked"

      >
        <label>Percentage Owned</label>
        <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
          v-on:change="() => {
            service.values.percentageOwned = service.values.percentageOwned || 0;
            calculateCarbonEmissions()
          }"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
          v-model.number="service.values.percentageOwned"
          type="number" step="0.01"
          name="percentageOwned"
          min="0"
        />
      </div>
      <div class="input-container-row tight">
        <div class="input-item stacked">
          <label>Name</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model="service.values.name"
            :placeholder="service.values.name"
            type="text"
            name="annualTotal"
          />
        </div>
        <div class="input-item long">
          <label>Explanation</label>
          <textarea
          class="long"
          rows="4"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model="service.notes.name"
            :placeholder="service.notes.name"
            type="text"
            name="nameNotes"
          />
        </div>
      </div>
      <div class="input-container-row tight">
        <div class="input-item stacked">
          <label>Stationary Fuels</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-on:change="() => {
              service.values.stationaryFuels = service.values.stationaryFuels || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model.number="service.values.stationaryFuels"
            :placeholder="service.values.stationaryFuels"
            type="number" step="0.01"
            name="stationaryFuels"
            min="0"
          />
        </div>
        <div class="input-item long">
          <label>Explanation</label>
          <textarea
          class="long"
          rows="4"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model="service.notes.stationaryFuels"
            :placeholder="service.notes.stationaryFuels"
            type="text"
            name="stationaryFuelsNotes"
          />
        </div>
      </div>
      <div class="input-container-row tight">
        <div class="input-item stacked">
          <label>Company Vehicles</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-on:change="() => {
              service.values.companyVehicles = service.values.companyVehicles || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model.number="service.values.companyVehicles"
            :placeholder="service.values.companyVehicles"
            type="number" step="0.01"
            name="companyVehicles"
            min="0"
          />
        </div>
        <div class="input-item long">
          <label>Explanation</label>
          <textarea
          class="long"
          rows="4"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model="service.notes.companyVehicles"
            :placeholder="service.notes.companyVehicles"
            type="text"
            name="companyVehiclesNotes"
          />
        </div>
      </div>
      <div class="input-container-row tight">
        <div class="input-item stacked">
          <label>Industrial Processes</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-on:change="() => {
              service.values.industrialProcesses = service.values.industrialProcesses || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model.number="service.values.industrialProcesses"
            :placeholder="service.values.industrialProcesses"
            type="number" step="0.01"
            name="industrialProcesses"
            min="0"
          />
        </div>
        <div class="input-item long">
          <label>Explanation</label>
          <textarea
          class="long"
          rows="4"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model="service.notes.industrialProcesses"
            :placeholder="service.notes.industrialProcesses"
            type="text"
            name="industrialProcessesNotes"
          />
        </div>
      </div>
      <div class="input-container-row tight">
        <div class="input-item stacked">
          <label>Fugitive Emissions</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-on:change="() => {
              service.values.fugitiveEmissions = service.values.fugitiveEmissions || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model.number="service.values.fugitiveEmissions"
            :placeholder="service.values.fugitiveEmissions"
            type="number" step="0.01"
            name="fugitiveEmissions"
            min="0"
          />
        </div>
        <div class="input-item long">
          <label>Explanation</label>
          <textarea
          class="long"
          rows="4"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model="service.notes.fugitiveEmissions"
            :placeholder="service.notes.fugitiveEmissions"
            type="text"
            name="fugitiveEmissionsNotes"
          />
        </div>
      </div>
      <div class="input-container-row tight">
        <div class="input-item stacked">
          <label>Land Use Change</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-on:change="() => {
              service.values.landUseChange = service.values.landUseChange || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model.number="service.values.landUseChange"
            :placeholder="service.values.landUseChange"
            type="number" step="0.01"
            name="landUseChange"
            min="0"
          />
        </div>
        <div class="input-item long">
          <label>Explanation</label>
          <textarea
          class="long"
          rows="4"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model="service.notes.landUseChange"
            :placeholder="service.notes.landUseChange"
            type="text"
            name="landUseChangeNotes"
          />
        </div>
      </div>
      <div class="input-container-row tight">
        <div class="input-item stacked">
          <label>Electricity</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-on:change="() => {
              service.values.electricity = service.values.electricity || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model.number="service.values.electricity"
            :placeholder="service.values.electricity"
            type="number" step="0.01"
            name="electricity"
            min="0"
          />
        </div>
        <div class="input-item long">
          <label>Explanation</label>
          <textarea
          class="long"
          rows="4"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model="service.notes.electricity"
            :placeholder="service.notes.electricity"
            type="text"
            name="electricityNotes"
          />
        </div>
      </div>
      <div v-if="scope3" class="input-container-row tight">
        <div
          class="input-item stacked"

        >
          <label>Scope 3</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model.number="service.values.scope3"
            :placeholder="service.values.scope3"
            type="number" step="0.01"
            name="scope3"
          />
        </div>
        <div class="input-item long">
          <label>Explanation</label>
          <textarea
          class="long"
          rows="4"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model="service.notes.scope3"
            :placeholder="service.notes.scope3"
            type="text"
            name="scope3Notes"
          />
        </div>
      </div>
        <div class="spacer"></div>
      <!-- </div> -->
    </div>
    <!-- <p>{{ $root.formatNumber(this.schema.totalEmissions.carbonEmissions) }}</p> -->
  </div>
</template>

<script>
import Controls from '../../../controls'
import axios from '@/axios'
import Modal from '../../../modal/Modal.vue'

export default {
  name: 'contracted-services-component',
  props: {
    title: String,
    initialSchema: Object,
    scope3: Boolean,
    schemaName: String,
    percentage: Boolean,
    activityID: String,
    identifier: String
  },
  components: { Controls, Modal },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },
  mounted () {
    // this.calculateCarbonEmissions()
  },

  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions () {
      var totalEmissions = 0
      var emissions = 0
      var emissionSources = []
      let exampleSource = this.schema.emissionSources[0]
      this.schema.services.forEach(service => {
        if (this.percentage) {
          emissions =
            (parseFloat(
              service.values.stationaryFuels *
                (service.values.percentageOwned / 100)
            ) || 0) +
            (parseFloat(
              service.values.companyVehicles *
                (service.values.percentageOwned / 100)
            ) || 0) +
            (parseFloat(
              service.values.industrialProcesses *
                (service.values.percentageOwned / 100)
            ) || 0) +
            (parseFloat(
              service.values.fugitiveEmissions *
                (service.values.percentageOwned / 100)
            ) || 0) +
            (parseFloat(
              service.values.landUseChange *
                (service.values.percentageOwned / 100)
            ) || 0) +
            (parseFloat(
              service.values.electricity *
                (service.values.percentageOwned / 100)
            ) || 0) +
            (parseFloat(
              service.values.scope3 * (service.values.percentageOwned / 100)
            ) || 0)
          totalEmissions += emissions
        } else {
          emissions =
            (parseFloat(service.values.stationaryFuels) || 0) +
            (parseFloat(service.values.companyVehicles) || 0) +
            (parseFloat(service.values.industrialProcesses) || 0) +
            (parseFloat(service.values.fugitiveEmissions) || 0) +
            (parseFloat(service.values.landUseChange) || 0) +
            (parseFloat(service.values.electricity) || 0) +
            (parseFloat(service.values.scope3) || 0)
          totalEmissions += emissions
        }
        service.values.carbonEmissions = emissions
        emissionSources.push({
          category: exampleSource.category,
          subcategory: exampleSource.subcategory,
          GHGScope: exampleSource.GHGScope,
          GHGSubcategory: exampleSource.GHGSubcategory,
          ISOCategory: exampleSource.ISOCategory,
          name: exampleSource.name.split('-')[0] + `- ${service.values.name}`,
          type: exampleSource.type,
          id: service.values.name,
          unit: exampleSource.unit,
          annualTotal: emissions,
          tCO2e: emissions,
          emissionSourceInfo: {
            dataSource: exampleSource.emissionSourceInfo.dataSource,
            dataQuality: exampleSource.emissionSourceInfo.dataQuality,
            assumptions: exampleSource.emissionSourceInfo.assumptions
          }
        })
      })
      this.schema.emissionSources = emissionSources

      this.schema.totalEmissions.carbonEmissions = totalEmissions
      this.$emit('calculateTotal', this.schema, self.schemaName)
      this.$root.saveData()
    },
    addService () {
      if (this.percentage) {
        this.schema.services.push({
          values: {
            name: 'Investment 1',
            stationaryFuels: 0,
            companyVehicles: 0,
            industrialProcesses: 0,
            fugitiveEmissions: 0,
            landUseChange: 0,
            electricity: 0,
            percentageOwned: 10
          },
          notes: {
            stationaryFuels: '',
            companyVehicles: '',
            industrialProcesses: '',
            fugitiveEmissions: '',
            landUseChange: '',
            electricity: ''
          }
        })
      } else {
        this.schema.services.push({
          values: {
            name: 'Enter name',
            stationaryFuels: 0,
            companyVehicles: 0,
            industrialProcesses: 0,
            fugitiveEmissions: 0,
            landUseChange: 0,
            electricity: 0,
            scope3: 0
          },
          notes: {
            stationaryFuels: '',
            companyVehicles: '',
            industrialProcesses: '',
            fugitiveEmissions: '',
            landUseChange: '',
            electricity: '',
            scope3: ''
          }
        })
      }
    },
    removeService () {
      if (this.schema.services.length > 1) {
        this.schema.services.pop()
        this.calculateCarbonEmissions()
      }
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.identifier}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
