<template>
  <div class="business-fuels">
    <div class="tooltip">
      <h1 class="section-title">
        {{ title }}
      </h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <div>
      <h3 class="secondary-title">Volume consumed ({{ measurement }})</h3>
      <div class="fuels-consumed">
        <div class="fuel-type">
          <label for="petrol" class="fuel-title">Petrol</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-on:change="() => {
              schema.emissionSources[0].annualTotal = schema.emissionSources[0].annualTotal || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model.number="schema.emissionSources[0].annualTotal"
            type="number" step="0.01"
            name="petrol"
            min="0"
          />
        </div>
        <div class="fuel-type">
          <label for="diesel" class="fuel-title">Diesel</label>
          <input :disabled="$root.userDetails.organisationStatus === 'Completed'"
            v-on:change="() => {
              schema.emissionSources[1].annualTotal = schema.emissionSources[1].annualTotal || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model.number="schema.emissionSources[1].annualTotal"
            type="number" step="0.01"
            name="diesel"
            min="0"
          />
        </div>
      </div>
    </div>
    <h3 class="secondary-title">
      Carbon Emissions: {{ $root.formatNumber(tCO2e) }}
    </h3>

    <!-- <h2>Carbon Emissions: {{ $root.formatNumber(schema.carbonEmissions) }}</h2> -->
  </div>
</template>

<script>
import axios from '@/axios'
import Modal from '../../../modal/Modal.vue'
export default {
  components: { Modal },
  name: 'business-fuels-component',
  props: {
    title: String,
    initialSchema: Object,
    measurement: String,
    activityID: String,
    identifier: String,
    schemaName: String
  },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },
  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions () {
      var self = this
      return axios
        .post(
          `${
            process.env.VUE_APP_APIURL
          }api/calculateScopeThreeBusinessTravelFuels/`,
          {
            schema: this.schema
          }
        )
        .then(function (response) {
          self.schema = response.data.result
          self.$emit(
            'calculateTotal',
            response.data.result,
            self.schemaName
          )
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          self.$root.saveData()
        })
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.identifier}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  computed: {
    tCO2e () {
      return this.schema.emissionSources[0].tCO2e + this.schema.emissionSources[1].tCO2e
    }
  }
}
</script>
