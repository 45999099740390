<template>
  <div>
    <CarbonFootprintSummary :emissionsByScope="emissionsByScope" />
    <MonthlyTotalEmissionsBarGraph v-if="monthlyReportAvailable"/>
    <BusinessUnitTable v-if="multipleBusinessUnits"/>
  </div>
</template>

<script>
import CarbonFootprintSummary from './CarbonFootprintSummary.vue'
import MonthlyTotalEmissionsBarGraph from '../../graphs/MonthlyTotalEmissionsBarGraph'
import BusinessUnitTable from './BusinessUnitTable.vue'

export default {
  name: 'dashboard-summary',
  props: { emissionsByScope: Object },
  components: {
    CarbonFootprintSummary,
    MonthlyTotalEmissionsBarGraph,
    BusinessUnitTable
  },
  computed: {
    monthlyReportAvailable () {
      // check periods for month !== 0
      let available = false
      this.$store.state.app.client.allBusinessUnits.nodes.forEach(businessUnit => {
        businessUnit.periods.nodes.forEach(period => {
          if (period.month !== '0' && period.emissionsSources.nodes.length > 0) {
            available = true
          }
        })
      })
      return available
    },
    multipleBusinessUnits () {
      // check for more than one business unit with emissions
      let count = 0
      this.$store.state.app.client.allBusinessUnits.nodes.forEach(businessUnit => {
        let hasEmissions = false
        businessUnit.periods.nodes.forEach(period => {
          if (period.emissionsSources.nodes.length > 0) {
            hasEmissions = true
          }
        })
        if (hasEmissions) {
          count++
        }
      })
      return count > 1
    }
  }
}
</script>
