<template>
  <div >
  <div v-if="loaded" class="reduction-plan" :style="`max-width: 800px`">
    <h1 class="section-title activity-title">Reduction Plan</h1>
    <div class="description">
      List your top 5 emissions sources, please add the reduction
      time frame, Initiative to be implemented and the staff member who is responsible
    </div>
    <div class="input-container-column">
      <div v-for="(reductionSource, index) in sortedReductionEmissionsSources" :key="reductionSource.id"
        class="input-section">
        <div class="input-container-row">
          <div class="secondary-title" :style="'font-weight:600'">{{ `${index + 1}. ${getEmissionsSourceName(reductionSource)}` }}</div>
          <i v-if="reductionSource.addedByAnalyst" class="fas fa-trash" @click="deleteReductionEmissionSource(reductionSource.id)"></i>
        </div>
        <div class="input-container-row">
          <label>Base Year Emissions:</label>
          <label>
            {{ $root.formatNumber(reductionSource.baseYearEmissionsSource.tCO2e) }} tCO<sub>2</sub>e
          </label>
        </div>
        <div v-if="reductionSource.baseYearEmissionsSource.tCO2e !== reductionSource.baseYearEmissionsSource.tCO2eMarketRate" class="input-container-row">
          <label>Base Year Emissions Market Rate:</label>
          <label>
            {{ $root.formatNumber(reductionSource.baseYearEmissionsSource.tCO2eMarketRate) }} tCO<sub>2</sub>e
          </label>
        </div>
        <div v-if="!currentYearIsBaseYear">
        <div class="input-container-row">
          <label>Current Year Emissions:</label>
          <label>
            {{ $root.formatNumber(reductionSource.emissionsSource.tCO2e) }} tCO<sub>2</sub>e
          </label>
        </div>
        <div v-if="reductionSource.emissionsSource.tCO2e !== reductionSource.emissionsSource.tCO2eMarketRate" class="input-container-row">
          <label>Current Year Emissions Market Rate:</label>
          <label>
            {{ $root.formatNumber(reductionSource.emissionsSource.tCO2eMarketRate) }} tCO<sub>2</sub>e
          </label>
        </div>
      </div>
        <div class="input-container-row">
          <label>Term:</label>
          <select v-model="reductionSource.term" v-on:change="updateReductionEmissionSource('term', reductionSource)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()">
            <option :value="null">Please select</option>
            <option value="Short">Short Term</option>
            <option value="Medium">Medium Term</option>
            <option value="Long">Long Term</option>
          </select>
        </div>
        <SelectRecommendations v-on:addRecommendation="addToInitiative($event, reductionSource)" />
        <div class="input-container-row">
          <label>Other Initiatives to be implemented:</label>
          <textarea rows="4" cols="75" v-model="reductionSource.initiative"
            v-on:change="updateReductionEmissionSource('initiative', reductionSource)" v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()" />
        </div>
        <div class="input-container-row">
          <label>Responsibility (please enter name of person and role):</label>
          <input type="text" v-model="reductionSource.responsibility"
            v-on:change="updateReductionEmissionSource('responsibility', reductionSource)" v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()" />
        </div>
        <div class="input-container-row">
          <label>For Year 1 and onwards, how did you go? Please comment on previous year's performance:</label>
          <textarea rows="4" cols="75" v-model="reductionSource.previousPerformanceComment"
            v-on:change="updateReductionEmissionSource('previousPerformanceComment', reductionSource)"
            v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()" />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
  </div>
</template>

<script>
import emissionsMixin from '../../mixins/emissionsMixin.vue'
import SelectRecommendations from './recommendations/selectRecommendations.vue'

export default {
  mixins: [emissionsMixin],
  components: { SelectRecommendations },
  data () {
    return {
      loaded: false
    }
  },
  async mounted () {
    await this.updateData()
    this.loaded = true
  },
  methods: {
    async updateData () {
      if (this.emissionsSourcesToCreate.length) {
        const res = await this.$store.dispatch('createReductionEmissionSources', this.emissionsSourcesToCreate)
        this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.push(...res.map(x => x.data.createReductionEmissionSource))
        // resort the array
        this.$store.state.app.reductionPlan.reductionEmissionSources.nodes = this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.sort((a, b) => {
          return b.baseYearEmissionsSource.tCO2eMarketRate - a.baseYearEmissionsSource.tCO2eMarketRate
        })
      }
      if (this.emissionsSourcesToUpdate.length) {
        const res = await this.$store.dispatch('updateReductionEmissionSources', this.emissionsSourcesToUpdate)
        res.forEach(source => {
          let index = this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.findIndex(x => x.id === source.data.updateReductionEmissionSource.id)
          if (index) {
            this.$store.state.app.reductionPlan.reductionEmissionSources.nodes[index] = source.data.updateReductionEmissionSource
          }
        })
        // resort the array
        this.$store.state.app.reductionPlan.reductionEmissionSources.nodes = this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.sort((a, b) => {
          return b.baseYearEmissionsMR - a.baseYearEmissionsMR
        })
      }
      if (this.emissionsSourcesToDelete.length) {
        await this.$store.dispatch('deleteReductionEmissionSources', this.emissionsSourcesToDelete)
        this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.forEach((source, index) => {
          if (this.emissionsSourcesToDelete.includes(source.id)) {
            this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.splice(index, 1)
          }
        })
      }
    },
    async updateReductionEmissionSource (field, source) {
      const data = { reductionEmissionSource: { id: source.id } }
      data.reductionEmissionSource[field] = source[field]
      // console.log(data.otherEmissions, 'updating otherEmissions data')
      this.$root.saving = true
      await this.$store.dispatch('updateReductionEmissionSource', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    async deleteReductionEmissionSource (id) {
      this.$root.saving = true
      await this.$store.dispatch('deleteReductionEmissionSources', [id])
      let index = this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.findIndex(x => x.id === id)
      this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.splice(index, 1)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    getEmissionsSourceName (reductionSource) {
      return reductionSource.emissionsSource.name ? reductionSource.emissionsSource.name : reductionSource.baseYearEmissionsSource.name
    },
    addToInitiative (recommendation, reductionSource) {
      if (!reductionSource.initiative || !reductionSource.initiative.trim()) reductionSource.initiative = (reductionSource.initiative || '') + recommendation.content
      else reductionSource.initiative += '\n\n' + recommendation.content
      this.updateReductionEmissionSource('initiative', reductionSource)
    }
  },
  computed: {
    currentYearIsBaseYear () {
      return this.$store.state.app.client.period.nodes[0].year === this.$store.state.app.client.baseYear.year
    },
    sortedReductionEmissionsSources () {
      // sort by base year emissions market rate and put yhe ones added by analyst at the bottom
      return [...this.$store.state.app.reductionPlan.reductionEmissionSources.nodes].sort((a, b) => {
        if (a.addedByAnalyst && !b.addedByAnalyst) {
          return 1
        } else if (!a.addedByAnalyst && b.addedByAnalyst) {
          return -1
        } else {
          return b.baseYearEmissionsSource.tCO2e - a.baseYearEmissionsSource.tCO2e
        }
      })
    },
    baseYearEmissionSources () {
      const currentYearEmissionsSources = this.$store.state.app.client.period.nodes[0].emissionsSources.nodes.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
      const baseYearEmissionsSources = this.$store.state.app.client.baseYear.emissionsSources.nodes.filter(es => es.rule !== 'hide-in-report' && es.rule !== 'hide')
      // loop through currentYearEmissionsSources and add base year ID if a matching name can be found
      const emissionsSources = baseYearEmissionsSources.map(x => {
        let currentYearEmissionsSource = currentYearEmissionsSources.find(y => y['name'].toLowerCase() === x['name'].toLowerCase())
        if (currentYearEmissionsSource) {
          x['emissionsSourceID'] = currentYearEmissionsSource.ID
        } else {
          x['emissionsSourceID'] = '0'
        }
        return x
      }).sort((a, b) => {
        return b.tCO2eMarketRate - a.tCO2eMarketRate
      })
      return emissionsSources
      // return this.getAllEmissionSources(filteredEmissionsSources)
    },
    topFiveBaseYearEmissionSources () {
      return this.baseYearEmissionSources.slice(0, 5).map(x => {
        return {
          'reductionPlanID': this.$store.state.app.reductionPlan.id,
          'baseYearEmissionsSourceID': x.ID,
          'emissionsSourceID': x.emissionsSourceID
        }
      })
    },
    marketRateExists () {
      return this.checkEmissionsSourcesForMarketRate(this.$root.allEmissionSources)
    },
    emissionsSourcesToUpdate () {
      // compare this.topFiveEmissionSources to saved reductionEmissionSources and return the difference
      if (this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.length) {
        return this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.filter(x => {
          // include only sources which emissionsSourceID is different than the one in top five
          return this.topFiveBaseYearEmissionSources.find(y => y.baseYearEmissionsSourceID === x.baseYearEmissionsSource.id && y.emissionsSourceID !== x.emissionsSource.id)
        }).map(x => {
          // console.log(x, 'emissionsSourcesToUpdate')
          let source = this.topFiveBaseYearEmissionSources.find(y => y.baseYearEmissionsSourceID === x.baseYearEmissionsSource.id)
          return {
            'id': x.id,
            'emissionsSourceID': source.emissionsSourceID
          }
        })
      } else {
        return []
      }
    },
    emissionsSourcesToDelete () {
      // compare this.topFiveEmissionSources to saved reductionEmissionSources and return the difference
      if (this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.length) {
        const emissionsSourcesNotAdded = this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.filter(x => !x.addedByAnalyst)
        return emissionsSourcesNotAdded.filter(x => {
          return !this.topFiveBaseYearEmissionSources.find(y => y.baseYearEmissionsSourceID === x.baseYearEmissionsSource.id)
        }).map(x => x.id)
      } else {
        return []
      }
    },
    emissionsSourcesToCreate () {
      // compare this.topFiveEmissionSources to saved reductionEmissionSources and return the difference
      if (this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.length) {
        // get all sources that are not in the top 5
        return this.topFiveBaseYearEmissionSources.filter(x => {
          return !this.$store.state.app.reductionPlan.reductionEmissionSources.nodes.find(y => y.baseYearEmissionsSource.id === x.baseYearEmissionsSourceID)
        })
      } else {
        return this.topFiveBaseYearEmissionSources
      }
    }
  }
}
</script>
