<template>
  <div class="section purchased">
    <div class="activity-title-row">
      <h1 class="activity-title">Purchased</h1>
      <i class="title-icon fad fa-hand-holding-usd"></i>
    </div>

    <PurchasedGSCapitialComponent
      title="Purchased Goods and Services"
      v-bind:initialSchema="schema[getSchemaIndex('Purchased Goods and Services')]"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="purchased-goods"
      schemaName="Purchased Goods and Services"
    />
    <Notes
      :schemaIndex="0"
      :key="0"
      :dataSource="schema[0].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[0].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[0].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>

    <PotableWaterTreatmentComponent
      title="Potable Water"
      unit="m3"
      :initialSchema="schema[getSchemaIndex('Potable Water')]"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="portable-water"
      schemaName="Potable Water"
    />
    <Notes
      :schemaIndex="1"
      :key="1"
      :dataSource="schema[1].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[1].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[1].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <div class="spacer"></div>

    <PGSContractedServicesComponent
      title="Contracted services and providers"
      :scope3="true"
      :initialSchema="schema[getSchemaIndex('Contracted Services and Providers')]"
      @calculateTotal="calculateTotal"
      :activityID="$options.name"
      identifier="contracted-services"
      schemaName="Contracted Services and Providers"
    />
    <Notes
      :schemaIndex="2"
      :key="2"
      :dataSource="schema[2].dataSource"
      @dataSourceChanged="updateDataSource"
      :dataQuality="schema[2].dataQuality"
      @dataQualityChanged="updateDataQuality"
      :assumptions="schema[2].assumptions"
      @assumptionsChanged="updateAssumptions"
    />
    <NextSectionButton
      :nextRouteSchema="$root.goodsAndServices.schemas[1]"
      :nextRouteActivity="$root.goodsAndServices.sections[1]"
      sectionName="goodsAndServices"
    />
  </div>
</template>

<script>
import PurchasedGSCapitialComponent from './PurchasedGSCapitialComponent'
import PotableWaterTreatmentComponent from '../wasteWaterWaste/WasteWaterTreatmentComponent'
import PGSContractedServicesComponent from './PGSContractedServicesComponent'
import Notes from '../../../Notes.vue'
import NextSectionButton from '../../../NextSectionButton.vue'

export default {
  metaData () {
    return {
      title: `dCarbon — Purchased`,
      description: ''
    }
  },
  name: 'activity-purchased',
  components: {
    PurchasedGSCapitialComponent,
    PotableWaterTreatmentComponent,
    PGSContractedServicesComponent,
    Notes,
    NextSectionButton
  },
  props: { initialSchema: Object, scope: String },

  data () {
    return {
      schema: {}
    }
  },
  beforeMount () {
    this.schema = this.$root.getSchema(this.scope)
  },
  methods: {
    updateDataSource (value) {
      this.schema[value.schemaIndex].dataSource = value.val
    },
    updateDataQuality (value) {
      this.schema[value.schemaIndex].dataQuality = value.val
    },
    updateAssumptions (value) {
      this.schema[value.schemaIndex].assumptions = value.val
    },
    calculateTotal (schema, schemaName) {
      // console.log(schemaName)
      let schemaIndex = this.getSchemaIndex(schemaName)
      this.schema[schemaIndex] = schema
      this.$root.updateMasterSchema(this.schema, this.scope)
    },
    getSchemaIndex (name) {
      return this.schema.findIndex(s => s.name === name)
    }
    // calculateTotal (schema, schemaName) {
    //   this.schema[schemaName] = schema
    //   this.schema.totalEmissions.totalCarbonEmissions =
    //     this.schema.purchasedGoodsSchema.totalEmissions.totalCarbonEmissions +
    //     this.schema.potableWaterSchema.totalCarbonEmissions +
    //     this.schema.contractedServicesSchema.totalEmissions.carbonEmissions
    //   this.$root.updateMasterSchema(this.schema)
    // }
  }
}
</script>
